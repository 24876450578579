import { ApiBase } from '@/libs/apis/base';

export class ClaimCaseHandlerApi extends ApiBase {
  constructor(vue) {
    super(vue, 'claim', true);
    this.errorName = 'CaseHandlerApiError';
  }

  list() {
    return super.get('/case/handler/?page_size=50', 'Failed to retrieve case handlers');
  }
}
