export const ROUTES = {
  TOOLS: 'tools',
  COLLECTION_LETTERS: 'collection-letters'
};

const tools = {
  path: '/ops/tools/',
  component: () => import('@/pages/ops/tools/tools'),
  name: ROUTES.TOOLS
};

export default [tools];
