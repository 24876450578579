var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.walkthroughSteps.length
        ? _c("WalkthroughCoordinator", {
            attrs: {
              steps: _vm.walkthroughSteps,
              "call-final-action-on-skip": ""
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.shouldUseVerticalNavbar
        ? _c("FpVerticalNavBar", {
            attrs: {
              inverted: _vm.isInvertedColour,
              logo: _vm.isInvertedColour ? _vm.logoWhite : _vm.logo,
              "organization-options": _vm.organizationOptions,
              role: _vm.role,
              "full-width": _vm.isAdmin,
              "role-options": _vm.roleOptions,
              organization: _vm.organizationId,
              "navigation-items": _vm.verticalNavbarItems,
              alert: _vm.alert,
              "profile-options": _vm.profileOptions,
              profile: _vm.profile,
              "show-notification-icon": _vm.showNotificationIcon,
              count: _vm.count
            },
            on: {
              "update:role": function($event) {
                _vm.role = $event
              },
              "update:organization": function($event) {
                _vm.organizationId = $event
              },
              "notifications-link-click": _vm.handleNotificationsLinkClick,
              "profile-option-click": _vm.handleProfileOptionClick,
              "update-organization": _vm.switchOrganization,
              "role-option-click": _vm.handleRoleOptionClick
            }
          })
        : _c("FpHorizontalNavBar", {
            attrs: {
              inverted: _vm.isInvertedColour,
              "show-navigation-items-on-desktop": "",
              logo: _vm.isInvertedColour ? _vm.logoWhite : _vm.logo,
              "navigation-items": _vm.horizontalNavbarPrimaryItems,
              "secondary-items": _vm.horizontalNavbarSecondaryItems,
              alert: _vm.alert,
              role: _vm.role,
              "role-options": _vm.roleOptions,
              "profile-options": _vm.profileOptions,
              profile: _vm.profile,
              "show-notification-icon": _vm.showNotificationIcon,
              count: _vm.count
            },
            on: {
              "update:role": function($event) {
                _vm.role = $event
              },
              "profile-option-click": _vm.handleProfileOptionClick,
              "help-option-click": _vm.handleHelpOptionClick,
              "role-option-click": _vm.handleRoleOptionClick
            }
          }),
      _vm._v(" "),
      _c(
        "div",
        { style: _vm.cssVars, attrs: { id: "navbar-content-container" } },
        [
          _vm.isDemoAndViewingAsAgent ? _c("NavDemo") : _vm._e(),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }