import { InternalMetadataApiBase } from '@/libs/apis/metadata/internal/base';
import * as querystring from 'querystring';
import { InternalMetadataUserPropertyModel } from '@/models/internal/metadata';

export class InternalMetadataUserPropertyApi extends InternalMetadataApiBase {
  markPrimary(id) {
    const { data } = this.post(`user-property/${encodeURIComponent(id)}/mark-primary`);
    return new InternalMetadataUserPropertyModel(data);
  }

  async create({
    name,
    tenantId,
    userId,
    landlordId,
    guarantorId,
    phoneNumber,
    kind,
    email,
    line_1,
    line_2,
    postcode,
    city,
    country
  }) {
    const { data: result } = await this.post(`user-property/`, {
      name: name,
      tenant_id: tenantId,
      user_id: userId,
      landlord_id: landlordId,
      guarantor_id: guarantorId,
      phone_number: phoneNumber,
      kind: kind,
      email: email,
      line_1: line_1,
      line_2: line_2,
      postcode: postcode,
      city: city,
      country: country
    });
    return new InternalMetadataUserPropertyModel(result);
  }

  async list({ tenantId, userId, landlordId, guarantorId, pageSize = 100 }) {
    const qs = querystring.stringify({
      tenant: tenantId,
      user: userId,
      landlord: landlordId,
      guarantor: guarantorId,
      page_size: pageSize
    });
    const { data } = await this.get(`user-property/?${qs}`);
    return data.results.map(result => new InternalMetadataUserPropertyModel(result));
  }
}
