import BaseModel from '@/models/base';
import DocumentModel from '@/models/document';
import check from 'check-types';

export const MANAGED_BY_OPTIONS = {
  AGENT: 'agent',
  LANDLORD: 'landlord'
};

export const DEPOSIT_PROVIDER_OPTIONS = {
  MY_DEPOSITS: 'my_deposits',
  TDS: 'tds',
  DPS: 'dps'
};

export const DEPOSIT_CERTIFICATE_VERSIONS = {
  VERSION_1_0: '1.0',
  VERSION_2_0: '2.0'
};

export const DEPOSIT_TYPE_OPTIONS = {
  CUSTODIAL: 'custodial',
  INSURED: 'insured'
};

export const MIN_DEPOSIT_AMOUNT_CENTS = 100;
export const MAX_DEPOSIT_AMOUNT_CENTS = 1500000;

export class TraditionalDepositModel extends BaseModel {
  constructor(data) {
    super(data);

    this.depositCertificate = this.depositCertificate
      ? new DocumentModel(this.depositCertificate)
      : null;
  }

  defaults() {
    return {
      managedBy: '',
      hasBeenRegistered: null,
      depositCertificate: null,
      depositAmount: 0,
      depositProvider: '',
      depositType: ''
    };
  }

  toFlatpieModel() {
    const data = super.toFlatpieModel();

    if (check.object(this.depositCertificate)) {
      data['deposit_certificate_id'] = this.depositCertificate.id;
    }

    if (this.hasBeenRegistered === null || this.managedBy === MANAGED_BY_OPTIONS.LANDLORD) {
      data['has_been_registered'] = false;
    }

    return data;
  }
}
