import config from '@/libs/utils/config';

(() => {
  if (!config.user) return;
  const userId = config.user.id || null;
  const userData = config.hotjarUserData;
  // eslint-disable-next-line no-console
  // console.debug('Identifying user', userId, userData);
  const hj =
    window.hj ||
    function() {
      // eslint-disable-next-line no-console
      // console.debug('HotJar not enabled. Was called with:', ...arguments);
    };
  hj('identify', userId, userData);
})();
