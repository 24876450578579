import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { resolveConstant } from '@/libs/utils/helpers';
import * as Integrations from '@sentry/integrations';
import config from '@/libs/utils/config';

(() => {
  const environment = config.env;
  const release = process.env.VERSION;
  const userData = config.sentryUserData;
  // console.debug('Setting up Sentry', { environment, release, userData });
  if (process.env.NODE_ENV !== 'production') {
    // console.debug(`Skipping because environment is not production ${process.env.NODE_ENV}`);
    return;
  }
  Sentry.init({
    dsn: resolveConstant('SENTRY_DSN'),
    integrations: [
      new Integrations.Vue({ Vue, attachProps: true }),
      new Integrations.ExtraErrorData({ depth: 5 })
    ],
    ignoreErrors: ['Object Not Found Matching Id'],
    environment,
    release,
    normalizeDepth: 10
  });
  Sentry.configureScope(function(scope) {
    scope.setUser(userData);
  });
})();
