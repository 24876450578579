export const ROUTES = {
  SIGN_IN_WITH_PASSWORD: 'SIGN_IN_WITH_PASSWORD',
  CHECK_YOUR_MAILBOX: 'CHECK_YOUR_MAILBOX',
  RESET_PASSWORD: 'RESET_PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  LOGIN: 'LOGIN',
  RESET_PASSWORD_CONFIRM: 'RESET_PASSWORD_CONFIRM'
};

export const USER_TYPE = {
  AGENT: 'agent',
  LANDLORD: 'landlord',
  TENANT: 'tenant',
  GUARANTOR: 'guarantor'
};

export const SIGNUP_REDIRECT_ROUTES = {
  AGENT: '/accounts-signup/agent/',
  LANDLORD: '/accounts-signup/landlord/',
  TENANT: '/accounts-signup/tenant/',
  GUARANTOR: '/accounts-signup/guarantor/'
};

export const signInWithPassword = {
  name: ROUTES.SIGN_IN_WITH_PASSWORD,
  path: '/login/sign-in-with-password',
  props: true,
  component: () => import('@/pages/login/sign_in_with_password.vue')
};

export const login = {
  name: ROUTES.LOGIN,
  path: '/login',
  component: () => import('@/pages/login/login.vue')
};

export const resetPassword = {
  name: ROUTES.RESET_PASSWORD,
  path: '/login/reset-password',
  component: () => import('@/pages/login/reset_password.vue')
};

export const forgotPassword = {
  name: ROUTES.FORGOT_PASSWORD,
  path: '/login/forgot-password',
  props: true,
  component: () => import('@/pages/login/forgot_password.vue')
};

export const checkYourMailbox = {
  name: ROUTES.CHECK_YOUR_MAILBOX,
  path: '/login/check-your-mailbox',
  props: true,
  component: () => import('@/pages/login/check_your_mailbox.vue')
};

export const resetPasswordConfirm = {
  name: ROUTES.RESET_PASSWORD_CONFIRM,
  path: '/login/password-reset-confirm/confirm/:uuid/:token',
  component: () => import('@/pages/login/reset_password.vue'),
  props: true
};

export default [
  signInWithPassword,
  resetPassword,
  checkYourMailbox,
  forgotPassword,
  login,
  resetPasswordConfirm
];
