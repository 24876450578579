import { UserApiBase } from '@/libs/apis/user/base';

export class UserLoginApi extends UserApiBase {
  async registrationStatus(email) {
    return await super.post('registration-status/', { email: email });
  }

  // Currently unused because sign-in-without-password page is unreachable
  async passwordlessLoginEmail(email) {
    return await super.post('passwordless-login-email/', { email: email });
  }

  async updateInvitesForUser(email) {
    return await super.post('update-invites-for-user/', { email: email });
  }
}
