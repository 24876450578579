import config from '@/libs/utils/config';

export const INCENTIVE_ROUTES = {
  ROOT: 'AGENT_INCENTIVE_ROOT',
  INTRO: 'AGENT_INCENTIVE_INTRO',
  DASHBOARD: 'AGENT_INCENTIVE_DASHBOARD'
};

export const root = {
  path: '/agent/cash-rewards',
  name: INCENTIVE_ROUTES.ROOT,
  beforeEnter: (to, from, next) => {
    if (config.user.state.hasSeenIncentiveDashboardIntro) {
      next({ name: INCENTIVE_ROUTES.DASHBOARD });
    } else {
      next({ name: INCENTIVE_ROUTES.INTRO });
    }
  }
};

export const intro = {
  path: '/agent/cash-rewards/intro',
  component: () => import('@/pages/agent/incentive/intro.vue'),
  name: INCENTIVE_ROUTES.INTRO
};

export const dashboard = {
  path: '/agent/cash-rewards/dashboard',
  component: () => import('@/pages/agent/incentive/dashboard.vue'),
  name: INCENTIVE_ROUTES.DASHBOARD
};

export default [root, intro, dashboard];
