import Vue from 'vue';
import Vuex from 'vuex';
import * as planStore from '@/store/plan';
import { PLAN } from '@/store/modules';

import 'es6-promise/auto';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    [PLAN]: planStore
  }
});

export default store;
