import { snakeCaseKeys, camelCaseKeys } from '@/libs/utils/normalise_object_keys.js';
import _assignWith from 'lodash/assignWith';
import _toPairs from 'lodash/toPairs';
import _fromPairs from 'lodash/fromPairs';
import check from 'check-types';
import mergeWith from 'lodash/mergeWith';

export default class BaseModel {
  constructor(data = {}) {
    check.assert.object(data, '@arg data must be of type Object');
    const defaults = this.defaults();
    const normalisedData = camelCaseKeys(data);

    _assignWith(this, defaults, normalisedData, (old, val) => {
      if (check.not.undefined(val) && check.null(old)) return val;
    });
  }

  defaults() {
    throw new Error('.defaults() must be implemented');
  }

  toFlatpieModel() {
    const attemptFlatpieModel = model => {
      return check.instance(model, BaseModel) ? model.toFlatpieModel() : model;
    };

    const result = snakeCaseKeys(this);

    const pairs = _toPairs(result).map(([key, val]) => {
      let newValue = attemptFlatpieModel(val);
      if (check.array(val)) newValue = val.map(attemptFlatpieModel);
      return [key, newValue];
    });

    return _fromPairs(pairs);
  }

  merge(other) {
    return mergeWith(this, other, (old, val) => (check.array(old) ? val : undefined));
  }
}
