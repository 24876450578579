import { FlatbondApiBase } from '@/libs/apis/flatbond';
import qs from 'querystring';

export * from './base';
export * from './draft';
export * from './extend';
export * from './guarantor';
export * from './internal';
export * from './landlord';

export class FlatbondApi extends FlatbondApiBase {
  constructor(vue) {
    super(vue, 'flatbond', true);
    this.errorName = 'FlatbondApiError';
  }

  async getDocumentsToSign(flatbondId) {
    return this.get(`${flatbondId}/documents-to-sign/`);
  }

  getListQuery(queryParams = {}) {
    queryParams.product_type = queryParams.type;
    delete queryParams.type;
    queryParams.filter_plans_requiring_action = queryParams.plansRequiringAction;
    delete queryParams.plansRequiringAction;
    queryParams.created_by = queryParams.createdBy;
    delete queryParams.createdBy;
    queryParams.exclude_product_type = queryParams.excludeProductType;
    delete queryParams.excludeProductType;
    return queryParams;
  }

  list(queryParams = {}) {
    return super.get(`/?${qs.encode(this.getListQuery(queryParams))}`);
  }

  get(flatbondId) {
    return super.get(`/${flatbondId}/`);
  }

  sendLandlordInvite(flatbondId) {
    return super.post(
      `${flatbondId}/send_landlord_invite/`,
      {},
      'Failed to re-send landlord invite'
    );
  }

  statistics() {
    return super.get(`/statistics/`);
  }

  calculateMembershipFee(weeklyRentInPence, productType) {
    return super.get(`/membership-fee/?rent=${weeklyRentInPence}&product_type=${productType}/`);
  }

  getNoDepositProtectionLevel(planId) {
    return super.get(
      `/upsell/${planId}/get-no-deposit-protection-level/`,
      'failed to fetch no deposit protection level'
    );
  }

  updateFlatbondToNoDeposit(planUpsellId) {
    return super.post(
      `/upsell/${planUpsellId}/update-flatbond-to-no-deposit/`,
      'failed to update to no deposit'
    );
  }

  updateFlatbondToTraditionalDeposit(planUpsellId) {
    return super.post(
      `/upsell/${planUpsellId}/update-flatbond-to-traditional-deposit/`,
      'failed to update to traditional deposit'
    );
  }

  createLandlordDecision(planUpsellId, data) {
    return super.put(
      `/upsell/${planUpsellId}/create-landlord-decision/`,
      data,
      'failed to create landlord decision'
    );
  }

  getLandlordUpsellDecision(planUpsellId) {
    return super.get(
      `/upsell/${planUpsellId}/get-landlord-decision/`,
      'failed to fetch landlord decision'
    );
  }

  resendLandlordUpsellDecision(planId) {
    return super.get(`/${planId}/resend-upsell-decision/`);
  }

  moveToPendingTenantAction(planId) {
    return super.patch(`/${planId}/move-to-pending-tenant-action/`);
  }
}
