import BaseModel from '../base';

export default class BankAccountModel extends BaseModel {
  constructor(data) {
    super(data);
  }

  defaults() {
    return {
      nameOnAccount: null,
      accountNumber: null,
      sortCode: null
    };
  }
}
