export const ROUTES = {
  TENANTS: 'TENANTS'
};

export const tenants = {
  path: '/agent/tenants/',
  name: ROUTES.TENANTS,
  component: () => import('@/pages/agent/tenants/tenants.vue')
};

export const INCOME_SOURCE_TYPES = {
  FULL_TIME: 'full_time_employment',
  PART_TIME: 'part_time_employment',
  SELF_EMPLOYED: 'self_employed',
  UNEMPLOYED: 'unemployed',
  UK_SCHOLARSHIP: 'uk_scholarship',
  UK_STUDENT: 'uk_student',
  OVERSEA_STUDENT: 'oversea_student',
  OVERSEA_SCHOLARSHIP: 'oversea_scholarship',
  CONTRACT: 'contract',
  PENSION: 'pension',
  BENEFITS: 'benefits',
  SAVINGS: 'savings',
  RENT_IN_ADVANCE: 'rent_in_advance',
  OTHER: 'other'
};

export const INCOME_SOURCE_TYPE_TO_LABEL_ID = {
  [INCOME_SOURCE_TYPES.FULL_TIME]: 'reviewFlatbond.referenceReport.option.full_time_employment',
  [INCOME_SOURCE_TYPES.PART_TIME]: 'reviewFlatbond.referenceReport.option.part_time_employment',
  [INCOME_SOURCE_TYPES.SELF_EMPLOYED]: 'reviewFlatbond.referenceReport.option.self_employed',
  [INCOME_SOURCE_TYPES.UNEMPLOYED]: 'reviewFlatbond.referenceReport.option.unemployed',
  [INCOME_SOURCE_TYPES.UK_SCHOLARSHIP]: 'reviewFlatbond.referenceReport.option.uk_scholarship',
  [INCOME_SOURCE_TYPES.UK_STUDENT]: 'reviewFlatbond.referenceReport.option.uk_student',
  [INCOME_SOURCE_TYPES.OVERSEA_SCHOLARSHIP]:
    'reviewFlatbond.referenceReport.option.oversea_scholarship',
  [INCOME_SOURCE_TYPES.OVERSEA_STUDENT]: 'reviewFlatbond.referenceReport.option.oversea_student',
  [INCOME_SOURCE_TYPES.UK_SCHOLARSHIP]: 'reviewFlatbond.referenceReport.option.uk_scholarship',
  [INCOME_SOURCE_TYPES.UK_SCHOLARSHIP]: 'reviewFlatbond.referenceReport.option.uk_scholarship',
  [INCOME_SOURCE_TYPES.CONTRACT]: 'reviewFlatbond.referenceReport.option.contract',
  [INCOME_SOURCE_TYPES.PENSION]: 'reviewFlatbond.referenceReport.option.pension',
  [INCOME_SOURCE_TYPES.BENEFITS]: 'reviewFlatbond.referenceReport.option.benefits',
  [INCOME_SOURCE_TYPES.SAVINGS]: 'reviewFlatbond.referenceReport.option.savings',
  [INCOME_SOURCE_TYPES.RENT_IN_ADVANCE]: 'reviewFlatbond.referenceReport.option.rent_in_advance',
  [INCOME_SOURCE_TYPES.OTHER]: 'reviewFlatbond.referenceReport.option.other'
};

export default [tenants];
