import { InternalTransactionApiBase } from '@/libs/apis/transaction/internal/base';

export class InternalTransactionDiscountDescriptionApi extends InternalTransactionApiBase {
  list(query = '') {
    return super.get(`discount-description/${query}`, 'Could not retrieve discount descriptions');
  }

  createDiscount(flatbondTenantId, descriptionId) {
    const data = {
      flatbond_tenant_id: flatbondTenantId
    };

    return super.post(
      `discount-description/${descriptionId}/create-discount/`,
      data,
      'Could not add discount.'
    );
  }
}
