import BaseModel from '@/models/base';

export class InternalMetadataTag extends BaseModel {
  defaults() {
    return {
      name: ''
    };
  }
}

export class InternalMetadataTagEdge extends BaseModel {
  constructor(data) {
    super(data);
    this.tag = new InternalMetadataTag(data.tag);
  }

  defaults() {
    return {
      tag: null
    };
  }
}

export class InternalMetadataUserModel extends BaseModel {
  defaults() {
    return {
      firstName: '',
      lastName: ''
    };
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export const METADATA_USER_PROPERTY_KIND = {
  EMAIL: 'email',
  PHONE_NUMBER: 'phone_number',
  ADDRESS: 'address'
};

export class InternalMetadataUserPropertyModel extends BaseModel {
  constructor(data) {
    super(data);
    this.createdOn = new Date(this.createdOn);
    this.createdBy = this.createdBy && new InternalMetadataUserModel(this.createdBy);
  }

  defaults() {
    return {
      name: null,
      id: null,
      kind: null,
      email: null,
      line1: null,
      line2: null,
      city: null,
      postcode: null,
      country: null,
      phoneNumber: null,
      primary: null,
      createdOn: new Date(0),
      createdBy: null
    };
  }
}

export class InternalMetadataNoteSubjectModel extends BaseModel {
  defaults() {
    return {
      email: '',
      firstName: null,
      lastName: null
    };
  }
}

export class InternalMetadataNoteModel extends BaseModel {
  constructor(data) {
    super(data);
    this.subject = this.subject && new InternalMetadataNoteSubjectModel(this.subject);
    this.createdBy = this.createdBy && new InternalMetadataUserModel(this.createdBy);
    this.createdOn = new Date(this.createdOn);
  }

  defaults() {
    return {
      subject: null,
      pinned: false,
      comment: '',
      createdOn: new Date(0),
      createdBy: null
    };
  }
}
