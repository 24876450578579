import BaseModel from '@/models/base';
import { InternalUserAddressModel } from '@/models/internal/user/address';

export class InternalUserModel extends BaseModel {
  constructor(data) {
    super(data);

    this.activeAddress = this.activeAddress
      ? new InternalUserAddressModel(this.activeAddress)
      : null;
    this.inactiveAddresses = this.inactiveAddresses.map(
      address => new InternalUserAddressModel(address)
    );
  }

  defaults() {
    return {
      id: null,
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      lastLogin: '',
      activeAddress: null,
      inactiveAddresses: [],
      stripeCustomerId: ''
    };
  }
}

export * from './address';
