import _defaultsDeep from 'lodash/defaultsDeep';
import { camelCaseKeys, snakeCaseKeys } from '@/libs/utils/normalise_object_keys.js';
import { TenantApiBase } from './base';
import { TenantStatusModel } from '@/models/tenant';
import FlatbondModel from '@/models/flatbond/flatbond';

export class TenantApi extends TenantApiBase {
  list(query = '') {
    return super.get(`${query}`, 'Failed to list tenants');
  }

  resendInvite(tenantId) {
    return super.post(`${tenantId}/send_invite/`, 'Failed re-send invite to tenant');
  }

  redirectToIntegration(tenantId) {
    return super.get(`${tenantId}/redirect-to-integration/`, 'Failed redirect to integration');
  }

  updatePreviousAddress(tenantId, data) {
    return super.post(
      `${tenantId}/update-previous-address/`,
      data,
      'Failed updated previous address'
    );
  }

  confirmElectronicDocumentsTC(tenantId) {
    return super.patch(
      `${tenantId}/confirm-electronic-documents-tc/`,
      {},
      'Failed to confirm electronic documents TC'
    );
  }

  setOffPlatformTraditionalDeposit(tenantId) {
    return super.post(
      `${tenantId}/set-off-platform-traditional-deposit/`,
      {},
      'Failed to set plan as off platform traditional deposit'
    );
  }

  getAdjudicationCosts() {
    return super.get('raise-dispute-cost', 'Failed to retrieve adjudication costs');
  }

  retrieve(tenantId) {
    return super.get(`${tenantId}`, 'Failed to retrieve tenant');
  }

  confirmTerms(tenantId, data) {
    return super.post(`${tenantId}/confirm/`, data, 'Failed to confirm terms for this tenant');
  }

  getDownloadTermsUrl(tenantId) {
    return `${this.baseUrl}/${this.namespace}/${tenantId}/download-terms/`;
  }

  async getStatus() {
    const result = await super.get(`status`, 'Failed get statuses');
    return new TenantStatusModel(result.data);
  }

  normaliseChargeCaseResponse(data) {
    const normalisedData = camelCaseKeys(data);
    return _defaultsDeep(normalisedData, {
      amountPaid: 0,
      establishedAmount: 0,
      amountDue: 0,
      requestedPaymentSplit: 0,
      numberOfTenants: 0
    });
  }

  getChargeCase(tenantId) {
    return super
      .get(`${tenantId}/charge-case`, 'Falied to retrieve tenant charge case')
      .then(response => this.normaliseChargeCaseResponse(response.data))
      .catch(() => null);
  }

  postChargeCase(tenantId, payload) {
    const url = `${tenantId}/charge-case/`;
    const data = snakeCaseKeys(payload);
    return super
      .post(url, data, 'Failed to set the requested split payment amount')
      .then(response => this.normaliseChargeCaseResponse(response.data))
      .catch(() => null);
  }

  async getTenancies(userId) {
    const { data } = await super.get(`${userId}/get-tenancies/`, 'Failed to get tenancies');
    if (data) return data.map(flatbond => new FlatbondModel(flatbond));
  }
}
