import { ApiBase } from '@/libs/apis/base';
import { BankTransferIntentModel } from '@/models/claim';

export class BankTransferIntentApi extends ApiBase {
  constructor(vue) {
    super(vue, 'claim/bank-transfer-intent');
    this.errorName = 'BankTransferIntentApiError';
  }

  async create(bankTransferIntent) {
    const { data } = await super.post('', bankTransferIntent.toFlatpieModel());
    return new BankTransferIntentModel(data);
  }

  markAsTransferred(bankTransferIntent) {
    return super.post(`${bankTransferIntent.id}/mark-as-transferred/`, {});
  }
}
