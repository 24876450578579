import BaseModel from '@/models/base';
import { TraditionalDepositModel } from '@/models/flatbond/traditional_deposit';
import FlatbondLandlordModel from '@/models/flatbond/landlord';
import { UserModel } from '@/models/user';
import {
  FlatbondSubscriptionItemModel,
  STATUS_OPTIONS
} from '@/models/flatbond/flatbond_subscription_item';
import Moment from 'moment';
import { FLATBOND_PRODUCT_TYPES } from '@/libs/constants/generatedConstants';

export default class FlatbondModel extends BaseModel {
  constructor(data) {
    super(data);

    this.landlord = this.landlord ? new FlatbondLandlordModel(this.landlord) : null;
    this.traditionalDeposit = this.traditionalDeposit
      ? new TraditionalDepositModel(this.traditionalDeposit)
      : null;
    this.activelyAdministeredBy = this.activelyAdministeredBy
      ? new UserModel(this.activelyAdministeredBy)
      : null;
    this.boostSubscription = this.boostSubscription
      ? new FlatbondSubscriptionItemModel(this.boostSubscription)
      : null;
  }

  defaults() {
    return {
      id: null,
      activelyAdministeredBy: null,
      address: '',
      application: null,
      batchPayment: null,
      boostMonthlyPrice: null,
      boostRentPercentage: null,
      boostSubscription: null,
      branch: null,
      city: '',
      closeDate: null,
      country: '',
      createdBy: null,
      depositAmount: null,
      eligibleForBoost: false,
      freeBoostOfferExpiryDate: null,
      freeBoostOfferStartDate: null,
      freeBoostAvailableUntil: null,
      hasFreeBoostOffer: false,
      hasBoostActive: false,
      hasGuarantor: null,
      isAstSigned: null,
      isPreVerified: false,
      landlord: null,
      landlordEmail: null,
      landlordInterestedInBoost: false,
      managedBy: null,
      mydepositsReference: null,
      postcode: '',
      productType: null,
      rent: null,
      startDate: null,
      status: null,
      tags: [],
      tenantType: null,
      tenants: [],
      traditionalDeposit: null,
      type: null,
      hasLandlordSignatureImprovementsEnabled: false,
      tenancyTerminationDate: null
    };
  }

  get currentPlanMonthlyCost() {
    if (this.boostSubscription && this.boostSubscription.status === STATUS_OPTIONS.ACTIVE) {
      return this.boostSubscription.amount;
    }
    return 0;
  }

  get freeBoostDaysLeft() {
    if (!this.hasFreeBoostOffer || !this.freeBoostAvailableUntil) return 0;
    const today = Moment().startOf('day');
    const freeBoostAvailableUntilDate = Moment(this.freeBoostAvailableUntil).startOf('day');
    return Math.min(freeBoostAvailableUntilDate.diff(today, 'days') + 1);
  }

  get paidBoostDaysLeft() {
    const today = Moment().startOf('day');
    const boostAvailableUntil = Moment(this.startDate)
      .startOf('day')
      .add(30, 'd');
    return boostAvailableUntil.diff(today, 'days') + 1;
  }

  get isUnselectedPlanType() {
    return this.productType === FLATBOND_PRODUCT_TYPES.UNSELECTED;
  }

  get isNoDeposit() {
    return this.productType === FLATBOND_PRODUCT_TYPES.NO_DEPOSIT;
  }

  get isTraditionalDeposit() {
    return this.productType === FLATBOND_PRODUCT_TYPES.TRADITIONAL_DEPOSIT;
  }
}
