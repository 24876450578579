import { landlordActivatePlanRedirect } from '@/pages/landlord/flatbonds/redirects';
import { landlordClosePlanFlow } from './close/routes';

export const LANDLORD_PLAN_ROUTES = {
  EXTEND: 'LANDLORD_PLAN_EXTEND',
  TRADITIONAL_DEPOSIT_DETAIL: 'LANDLORD_PLAN_TRADITIONAL_DEPOSIT_DETAIL',
  ACTIVATE: 'LANDLORD_PLAN_ACTIVATE',
  PLAN_DOCUMENTS: 'LANDLORD_PLAN_DOCUMENTS',
  PLAN_DETAILS: 'LANDLORD_PLAN_DETAILS',
  CLOSE_PLAN: 'LANDLORD_CLOSE_PLAN',
  CHARGES_DETAIL: 'LANDLORD_CHARGES_DETAIL',
  CHARGES_PLAN_DETAIL: 'LANDLORD_CHARGES_PLAN_DETAIL',
  CHARGES_PLAN_DOCUMENTS: 'LANDLORD_CHARGES_PLAN_DOCUMENTS'
};

export const landlordExtendPlanFlow = {
  name: LANDLORD_PLAN_ROUTES.EXTEND,
  path: `/landlord/flatbonds/:flatbondId/extend`,
  component: () => import('@/pages/landlord/flatbonds/extend/flow.vue'),
  props: true
};

export const landlordTraditionalDepositPlanDetail = {
  name: LANDLORD_PLAN_ROUTES.TRADITIONAL_DEPOSIT_DETAIL,
  path: `/landlord/traditional-tenancy/:flatbondId`,
  component: () => import('@/pages/landlord/flatbonds/traditional_deposit/detail.vue'),
  props: true
};

export const landlordActivatePlanFlow = {
  name: LANDLORD_PLAN_ROUTES.ACTIVATE,
  path: `/landlord/plan/:planId/activate`,
  beforeEnter: landlordActivatePlanRedirect,
  component: () => import('@/pages/landlord/flatbonds/activate/flow.vue'),
  props: route => {
    let planId = Number.parseInt(route.params.planId, 10);
    if (Number.isNaN(planId)) {
      planId = -1;
    }
    const fromWelcome = route.params.fromWelcome;

    return {
      planId,
      fromWelcome
    };
  }
};

export const landlordChargesDetail = {
  path: '/landlord/plan/:planId/charges',
  component: () => import('@/pages/common/charges/detail'),
  props: route => {
    return { landlordEnabled: true, planId: route.params.planId };
  },
  children: [
    {
      name: LANDLORD_PLAN_ROUTES.CHARGES_DETAIL,
      path: '',
      component: () => import('@/components/plan/charges/detail'),
      props: true
    },
    {
      name: LANDLORD_PLAN_ROUTES.CHARGES_PLAN_DETAIL,
      path: 'plan',
      props: true,
      component: () => import('@/components/plan/charges/plan_details')
    },
    {
      name: LANDLORD_PLAN_ROUTES.CHARGES_PLAN_DOCUMENTS,
      path: 'documents',
      props: true,
      component: () => import('@/components/plan/charges/documents')
    }
  ]
};

export const landlordPlanPage = {
  path: '/landlord/plan/:planId',
  component: () => import('@/pages/common/plan/tabs'),
  props: true,
  children: [
    {
      name: LANDLORD_PLAN_ROUTES.PLAN_DETAILS,
      path: '',
      component: () => import('@/pages/common/plan/details_tab.vue'),
      props: true
    },
    {
      name: LANDLORD_PLAN_ROUTES.PLAN_DOCUMENTS,
      path: '/landlord/plan/:planId/documents',
      component: () => import('@/pages/common/plan/documents_tab.vue'),
      props: true
    }
  ]
};

export default [
  landlordExtendPlanFlow,
  landlordTraditionalDepositPlanDetail,
  landlordActivatePlanFlow,
  landlordChargesDetail,
  landlordPlanPage,
  landlordClosePlanFlow
];
