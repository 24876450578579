import config from '@/libs/utils/config';
import { CARD_TYPES } from '@/components/agent/materials/constants';

export default {
  methods: {
    filterCardsForCategory(cards, categoryToFilter) {
      const agentType = config.user.isABTRAgent ? CARD_TYPES.BTR : CARD_TYPES.AGENCY;

      return cards
        .filter(
          ({ category, type }) =>
            category === categoryToFilter && (type === agentType || type === CARD_TYPES.COMMON)
        )
        .sort((firstElement, secondElement) => this._sortByType(firstElement, secondElement));
    },
    _sortByType(firstElement, secondElement) {
      if (firstElement.type === secondElement.type) {
        return 0;
      }

      if (firstElement.type === CARD_TYPES.COMMON) {
        return 1;
      }

      return -1;
    }
  }
};
