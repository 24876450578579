import { TrainingApiBase } from '@/libs/apis/training/base';

export class TrainingStatusApi extends TrainingApiBase {
  getOrCreate() {
    return super.get('status/get-or-create/', 'Failed to get or create the training status');
  }
  completeTraining() {
    return super.put('status/complete/', 'Failed to mark training as complete');
  }
}
