import BaseModel from '../base';

export default class FlatbondLandlordModel extends BaseModel {
  defaults() {
    return {
      id: null,
      companyName: '',
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      address: '',
      addressExtra: '',
      postcode: '',
      city: '',
      country: '',
      type: '',
      hasSignedUp: false,
      invitedOn: null,
      myDepositId: null
    };
  }
}
