import { UserApiBase } from '@/libs/apis/user/base';

export class UserAliasApi extends UserApiBase {
  viewAs(alias) {
    const isValidAlias = ['admin', 'agent', 'landlord', 'guarantor', 'tenant'].some(
      i => i === alias
    );
    if (!isValidAlias) throw new Error(`alias "${alias}" is invalid`);
    return super.post(`view-as/${encodeURI(alias)}/`, {}, 'Failed to change user alias');
  }
}
