import { GuarantorApiBase } from '@/libs/apis/guarantor/base';

export class GuarantorConfirmationApi extends GuarantorApiBase {
  getOrCreate(guarantorId) {
    return super.get(
      `${guarantorId}/confirmation/get-or-create`,
      'Failed to fetch guarantor confirmation'
    );
  }

  update(guarantorId, confirmationId, data) {
    return super.put(
      `${guarantorId}/confirmation/${confirmationId}/`,
      data,
      'Failed to update guarantor confirmation'
    );
  }
}
