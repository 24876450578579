<template>
  <div :style="{ backgroundColor: '#FFD601' }">
    <FpWrapper>
      <FpFlex space-around compact :justify-content="flexJustifyContent.spaceBetween">
        <FpFlexItem>
          <FpHeader4>{{ $t('common.demoAlert.textTitle') }}</FpHeader4>
          <FpBody>{{ $t('common.demoAlert.textDescription') }}</FpBody>
        </FpFlexItem>
        <FpFlexItem>
          <FpDropdownButton :open="actionsOpen" @toggle="actionsOpen = !actionsOpen">
            <template #button>{{ $t('common.demoAlert.actionButton') }}</template>
            <template #dropdown>
              <FpDropdownButtonItem @click="createNoDepositReadyForEndOfTenancy">{{
                $t('common.demoAlert.actions.createNoDepositReadyForEndOfTenancy')
              }}</FpDropdownButtonItem>
              <FpDropdownButtonItem @click="createUnselectedReadyForTenantDecision">
                {{ $t('common.demoAlert.actions.createUnselectedReadyForTenantDecision') }}
              </FpDropdownButtonItem>
              <FpDropdownButtonItem @click="createDraftPlan">
                {{ $t('common.demoAlert.actions.createDraftPlan') }}
              </FpDropdownButtonItem>
              <FpDropdownButtonItem @click="createTDReadyForTenantAction">
                {{ $t('common.demoAlert.actions.createTDReadyForTenantAction') }}
              </FpDropdownButtonItem>
              <FpDropdownButtonItem v-if="hasPlan" @click="activateDeposit">
                {{ $t('common.demoAlert.actions.activateDeposit') }}
              </FpDropdownButtonItem>
              <FpDropdownButtonItem v-if="canOfferReferencing" @click="createCompletedReference">{{
                $t('common.demoAlert.actions.createCompletedReference')
              }}</FpDropdownButtonItem>
            </template>
          </FpDropdownButton>
        </FpFlexItem>
      </FpFlex>
      <ApiFeedbackModal
        v-if="actionFeedbackModalVisible"
        :resolved="actionResolved"
        :resolved-with-error="actionResolvedWithError"
        :header-on-success="successHeader"
        :extra-message-on-success="successHeaderExtra"
        :header-on-error="errorHeader"
        :extra-success-action-label="extraSuccessActionLabel"
        @close="closeActionFeedbackModal"
        @retry="retryLastAction"
        @extra="viewLastPlanCreated"
      />
      <ApiFeedbackModal
        v-if="createReferenceFeedbackModalVisible"
        :resolved="actionResolved"
        :resolved-with-error="actionResolvedWithError"
        :header-on-success="$t('common.demoAlert.actions.createCompletedReference.successHeader')"
        :extra-message-on-success="
          $t('common.demoAlert.actions.createCompletedReference.successExtra')
        "
        :header-on-error="$t('common.demoAlert.actions.createCompletedReference.errorHeader')"
        :extra-success-action-label="
          createdReferenceId
            ? $t('common.demoAlert.actions.createCompletedReference.extraActionLabel')
            : null
        "
        @close="closeActionFeedbackModal"
        @retry="retryLastAction"
        @extra="viewLastReferenceCreated"
      />
      <ApiFeedbackModal
        v-if="createDraftPlanModalVisible"
        :resolved="actionResolved"
        :resolved-with-error="actionResolvedWithError"
        :header-on-success="$t('common.demoAlert.actions.createDraftPlan.successHeader')"
        :extra-message-on-success="$t('common.demoAlert.actions.createDraftPlan.successExtra')"
        :header-on-error="$t('common.demoAlert.actions.createDraftPlan.errorHeader')"
        :extra-success-action-label="
          createdDraftId ? $t('common.demoAlert.actions.createDraftPlan.extraActionLabel') : null
        "
        @close="closeActionFeedbackModal"
        @retry="retryLastAction"
        @extra="viewLastDraftCreated"
      />
    </FpWrapper>
  </div>
</template>

<script>
import {
  FpBody,
  FpHeader4,
  FpFlex,
  FpFlexItem,
  FpDropdownButton,
  FlexJustifyContent
} from '@flatfair/vue-fairplay';
import { FpDropdownButtonItem } from '@flatfair/vue-fairplay/molecules';
import { FpWrapper } from '@flatfair/vue-fairplay/atoms';
import ApiFeedbackModal from '@/elements/api_feedback_modal/api_feedback_modal';
import { PlanDemoApi } from '@/libs/apis/plan/demo';
import config from '@/libs/utils/config';

const CREATE_NO_DEPOSIT_READY_FOR_END_OF_TENANCY_ACTION = 1;
const ACTIVATE_DEPOSIT = 2;
const CREATE_UNSELECTED_READY_FOR_TENANT_DECISION = 3;
const CREATE_COMPLETED_REFERENCE = 4;
const CREATE_TD_READY_FOR_TENANT_ACTION = 5;
const CREATE_DRAFT_PLAN = 6;

export default {
  components: {
    FpWrapper,
    FpBody,
    FpHeader4,
    FpFlex,
    FpFlexItem,
    FpDropdownButton,
    FpDropdownButtonItem,
    ApiFeedbackModal
  },
  data() {
    return {
      actionsOpen: false,
      flexJustifyContent: FlexJustifyContent,
      actionFeedbackModalVisible: false,
      createReferenceFeedbackModalVisible: false,
      createDraftPlanModalVisible: false,
      actionResolved: false,
      actionResolvedWithError: false,
      lastActionClicked: null,
      lastPlanCreatedId: null,
      createdReferenceId: null,
      createdDraftId: null,
      planId: 0
    };
  },
  computed: {
    hasPlan() {
      return this.planId > 0;
    },
    canOfferReferencing() {
      return config.user.currentOrganizationUser.group.canOfferReferencing;
    },
    successHeader() {
      if (this.lastActionClicked === CREATE_NO_DEPOSIT_READY_FOR_END_OF_TENANCY_ACTION) {
        return this.$t(
          'common.demoAlert.actions.createNoDepositReadyForEndOfTenancy.successHeader'
        );
      } else if (this.lastActionClicked === CREATE_UNSELECTED_READY_FOR_TENANT_DECISION) {
        return this.$t(
          'common.demoAlert.actions.createUnselectedReadyForTenantDecision.successHeader'
        );
      } else if (this.lastActionClicked === CREATE_TD_READY_FOR_TENANT_ACTION) {
        return this.$t('common.demoAlert.actions.createTDReadyForTenantAction.successHeader');
      } else if (this.lastActionClicked === CREATE_DRAFT_PLAN) {
        return this.$t('common.demoAlert.actions.createDraftPlan.successHeader');
      }
      return '';
    },
    successHeaderExtra() {
      if (this.lastActionClicked === CREATE_NO_DEPOSIT_READY_FOR_END_OF_TENANCY_ACTION) {
        return this.$t('common.demoAlert.actions.createNoDepositReadyForEndOfTenancy.successExtra');
      } else if (this.lastActionClicked === CREATE_UNSELECTED_READY_FOR_TENANT_DECISION) {
        return this.$t(
          'common.demoAlert.actions.createUnselectedReadyForTenantDecision.successExtra'
        );
      } else if (this.lastActionClicked === CREATE_TD_READY_FOR_TENANT_ACTION) {
        return this.$t('common.demoAlert.actions.createTDReadyForTenantAction.successExtra');
      } else if (this.lastActionClicked === CREATE_DRAFT_PLAN) {
        return this.$t('common.demoAlert.actions.createDraftPlan.successExtra');
      }
      return '';
    },
    errorHeader() {
      if (this.lastActionClicked === CREATE_NO_DEPOSIT_READY_FOR_END_OF_TENANCY_ACTION) {
        return this.$t('common.demoAlert.actions.createNoDepositReadyForEndOfTenancy.errorHeader');
      } else if (this.lastActionClicked === CREATE_UNSELECTED_READY_FOR_TENANT_DECISION) {
        return this.$t(
          'common.demoAlert.actions.createUnselectedReadyForTenantDecision.errorHeader'
        );
      } else if (this.lastActionClicked === CREATE_TD_READY_FOR_TENANT_ACTION) {
        return this.$t('common.demoAlert.actions.createTDReadyForTenantAction.errorHeader');
      } else if (this.lastActionClicked === CREATE_DRAFT_PLAN) {
        return this.$t('common.demoAlert.actions.createDraftPlan.errorHeader');
      }
      return '';
    },
    extraSuccessActionLabel() {
      if (this.lastPlanCreatedId) {
        if (this.lastActionClicked === CREATE_NO_DEPOSIT_READY_FOR_END_OF_TENANCY_ACTION) {
          return this.$t(
            'common.demoAlert.actions.createNoDepositReadyForEndOfTenancy.extraActionLabel'
          );
        } else if (this.lastActionClicked === CREATE_UNSELECTED_READY_FOR_TENANT_DECISION) {
          return this.$t(
            'common.demoAlert.actions.createUnselectedReadyForTenantDecision.extraActionLabel'
          );
        } else if (this.lastActionClicked === CREATE_TD_READY_FOR_TENANT_ACTION) {
          return this.$t('common.demoAlert.actions.createTDReadyForTenantAction.extraActionLabel');
        } else if (this.lastActionClicked === CREATE_DRAFT_PLAN) {
          return this.$t('common.demoAlert.actions.createDraftPlan.extraActionLabel');
        }
      }
      return '';
    }
  },
  created() {
    const PLAN_KEY_WORD = '/plan/';
    let url = window.location.toString();
    const planPathIndex = url.search(PLAN_KEY_WORD);
    if (planPathIndex >= 0) {
      url = url.slice(planPathIndex + PLAN_KEY_WORD.length, url.length);
      this.planId = url.split('/')[0];
    }
  },
  methods: {
    closeActionFeedbackModal() {
      this.actionFeedbackModalVisible = false;
      this.createReferenceFeedbackModalVisible = false;
      this.createDraftPlanModalVisible = false;
      this.actionResolved = false;
      this.actionResolvedWithError = false;
      this.lastActionClicked = null;
    },
    async createNoDepositReadyForEndOfTenancy() {
      this.actionsOpen = false;
      this.lastActionClicked = CREATE_NO_DEPOSIT_READY_FOR_END_OF_TENANCY_ACTION;
      this.actionFeedbackModalVisible = true;
      const planDemoApi = new PlanDemoApi(this);

      try {
        const response = await planDemoApi.createNoDepositReadyForEndOfTenancy();
        this.lastPlanCreatedId = response.data.id;
      } catch {
        this.actionResolvedWithError = true;
      } finally {
        this.actionResolved = true;
      }
    },
    async createCompletedReference() {
      this.actionsOpen = false;
      this.lastActionClicked = CREATE_COMPLETED_REFERENCE;
      this.createReferenceFeedbackModalVisible = true;
      const planDemoApi = new PlanDemoApi(this);
      try {
        const { data } = await planDemoApi.createCompletedReference();
        this.createdReferenceId = data.id;
      } catch (e) {
        this.actionResolvedWithError = true;
      } finally {
        this.actionResolved = true;
      }
    },
    async createDraftPlan() {
      this.actionsOpen = true;
      this.lastActionClicked = CREATE_DRAFT_PLAN;
      this.createDraftPlanModalVisible = true;
      const planDemoApi = new PlanDemoApi(this);

      try {
        const response = await planDemoApi.createDraftPlan();
        this.createdDraftId = response.data.id;
      } catch {
        this.actionResolvedWithError = true;
      } finally {
        this.actionResolved = true;
      }
    },
    async createUnselectedReadyForTenantDecision() {
      this.actionsOpen = false;
      this.lastActionClicked = CREATE_UNSELECTED_READY_FOR_TENANT_DECISION;
      this.actionFeedbackModalVisible = true;
      const planDemoApi = new PlanDemoApi(this);

      try {
        const response = await planDemoApi.createUnselectedPlanReadyForTenantDecision();
        this.lastPlanCreatedId = response.data.id;
      } catch {
        this.actionResolvedWithError = true;
      } finally {
        this.actionResolved = true;
      }
    },
    async createTDReadyForTenantAction() {
      this.actionsOpen = false;
      this.lastActionClicked = CREATE_TD_READY_FOR_TENANT_ACTION;
      this.actionFeedbackModalVisible = true;
      const planDemoApi = new PlanDemoApi(this);

      try {
        const response = await planDemoApi.createTraditionalDepositPlanReadyForTenantAction();
        this.lastPlanCreatedId = response.data.id;
      } catch {
        this.actionResolvedWithError = true;
      } finally {
        this.actionResolved = true;
      }
    },
    async activateDeposit() {
      this.actionsOpen = false;
      this.lastActionClicked = ACTIVATE_DEPOSIT;
      const planDemoApi = new PlanDemoApi(this);

      try {
        await planDemoApi.activateDeposit(this.planId);
      } catch {
        this.actionResolvedWithError = true;
      } finally {
        this.actionResolved = true;
      }
    },
    retryLastAction() {
      if (this.lastActionClicked === CREATE_NO_DEPOSIT_READY_FOR_END_OF_TENANCY_ACTION) {
        this.closeActionFeedbackModal();
        return this.createNoDepositReadyForEndOfTenancy();
      } else if (this.lastActionClicked === CREATE_UNSELECTED_READY_FOR_TENANT_DECISION) {
        this.closeActionFeedbackModal();
        return this.createUnselectedReadyForTenantDecision();
      } else if (this.lastActionClicked === CREATE_TD_READY_FOR_TENANT_ACTION) {
        this.closeActionFeedbackModal();
        return this.createTDReadyForTenantAction();
      } else if (this.lastActionClicked === CREATE_DRAFT_PLAN) {
        this.closeActionFeedbackModal();
        return this.createDraftPlan();
      }
      if (this.lastActionClicked === CREATE_COMPLETED_REFERENCE) {
        this.closeActionFeedbackModal();
        return this.createCompletedReference();
      }
    },
    viewLastPlanCreated() {
      window.location = `/flatbonds/${this.lastPlanCreatedId}`;
    },
    viewLastReferenceCreated() {
      window.location = `/referencing/#/application/${this.createdReferenceId}`;
    },
    viewLastDraftCreated() {
      window.location = `/agent/flatbonds/draft/${this.createdDraftId}/review`;
    }
  }
};
</script>
