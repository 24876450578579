import { DepositApiBase } from './base';

export class DepositApi extends DepositApiBase {
  list(query = '') {
    return super.get(`${query}`, 'Failed to list deposits');
  }

  listAvailableForBatchPayment(branchId) {
    return super.get(
      `available-for-batch-payment?branch_id=${branchId}`,
      'Failed to list deposits'
    );
  }
}
