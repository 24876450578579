import { InternalLandlordApiBase } from './base';
import { formatDateForApi } from '@/libs/utils/helpers';
import FlatbondLandlordModel from '@/models/flatbond/landlord';

export class InternalLandlordApi extends InternalLandlordApiBase {
  get(landlordId) {
    return super.get(`${landlordId}/`, 'Failed to get landlord');
  }

  listLandlords(query = '') {
    return super.get(`${query}`, 'Failed to list landlords');
  }

  async create(landlord) {
    const { data } = await super.post('', landlord.toFlatpieModel(), 'Failed to create landlord');
    return new FlatbondLandlordModel(data);
  }

  update({ landlordId, address, city, postcode, country, dateOfBirth, nationality }) {
    const data = {
      address: address,
      city: city,
      postcode: postcode,
      country: country,
      date_of_birth: formatDateForApi(dateOfBirth),
      nationality: nationality || ''
    };
    return super.put(`${landlordId}/`, data, 'Failed to update landlord');
  }
}
