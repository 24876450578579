import { AccountsApiBase } from '@/libs/apis/accounts/base';
import { UserModel } from '@/models/user';

export class SignupAccountApi extends AccountsApiBase {
  constructor(vue) {
    super(vue, 'signup');
  }

  async signup(signupData) {
    const { data } = await super.post('signup-user/', signupData);
    return new UserModel(data);
  }

  async additionalDetailsSignup(data) {
    await super.put('update-additional-signup-details/', data);
  }

  getAccessToken(token) {
    return super.get(`check-access-token/?token=${token}`);
  }
}
