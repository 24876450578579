import BaseModel from '@/models/base';

export class InternalUserAddressModel extends BaseModel {
  defaults() {
    return {
      id: null,
      createdOn: null,
      updatedOn: null,
      line1: '',
      line2: '',
      city: '',
      postcode: '',
      country: ''
    };
  }
}
