import config from '@/libs/utils/config';
import { ROUTE_404_NAME } from '@/pages';

export const ROUTES = {
  CHOOSE_ROLE: 'CHOOSE_ROLE',
  CHANGE_ROLE: 'CHANGE_ROLE'
};

export const chooseRole = {
  name: ROUTES.CHOOSE_ROLE,
  path: '/role/choose',
  component: () => import('@/pages/role/choose_role.vue'),
  beforeEnter(to, from, next) {
    if (config.canViewAs.length > 1) {
      next();
    } else {
      window.location = '/';
    }
  }
};

export const changeRole = {
  name: ROUTES.CHANGE_ROLE,
  path: '/role/change/:expectedRoleName',
  component: () => import('@/pages/role/change_role.vue'),
  beforeEnter(to, from, next) {
    if (config.canViewAs.includes(to.params.expectedRoleName)) {
      next();
    } else {
      next({ name: ROUTE_404_NAME });
    }
  },
  props: true
};

export default [chooseRole, changeRole];
