import { IconCode } from '@flatfair/vue-fairplay/atoms';

export const NOTIFICATION_TYPE = {
  'Last week': {
    title: 'notifications.notificationType.lastWeek.title',
    description: {
      increased: 'notifications.notificationType.lastWeek.increase.description',
      decreased: 'notifications.notificationType.lastWeek.decrease.description',
      noChange: 'notifications.notificationType.lastWeek.noChange.description',
      noListings: 'notifications.notificationType.lastWeek.noListings.description'
    }
  }
};

export const NOTIFICATION_CATEGORY_AND_ICON = {
  insights: { label: 'notifications.notificationCategory.insights', icon: IconCode.chartLine }
};

export const HOURS_SINCE_SEEN_BEFORE_MARKING_AS_READ = 6;

export const NUMBER_OF_PLACEHOLDER_NOTIFICATIONS = 5;
