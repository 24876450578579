import { InternalTenantApiBase } from '@/libs/apis/tenant/internal/base.js';

export class InternalFlatbondTransactionRefundApi extends InternalTenantApiBase {
  list(query = '') {
    return super.get(
      `flatbond-transaction-refund/${query}`,
      'Could not retrieve the list of refunds'
    );
  }
}
