import { ApiBase } from '@/libs/apis/base';
import check from 'check-types';
import DraftChargesCaseModel from '@/models/draft_charges_case';

export class ClaimDraftApi extends ApiBase {
  constructor(vue) {
    super(vue, 'claim/draft', true);
    this.errorName = 'ClaimDraftApiError';
  }

  async get(draftId) {
    check.assert.assigned(draftId, 'draft ID is required but not provided');
    const { data } = await super.get(`${draftId}/`, 'Failed to fetch claim draft');
    return new DraftChargesCaseModel(data);
  }

  async create(payload) {
    check.assert.instance(
      payload,
      DraftChargesCaseModel,
      'payload must be an instance of DraftChargesCaseModel'
    );
    const { data } = await super.post('', payload.toFlatpieModel(), 'Failed to create claim draft');
    return new DraftChargesCaseModel(data);
  }

  async update(draftId, payload) {
    check.assert.assigned(draftId, 'draft ID is required but not provided');
    check.assert.instance(
      payload,
      DraftChargesCaseModel,
      'payload must be an instance of DraftChargesCaseModel'
    );
    const { data } = await super.put(
      `${draftId}/`,
      payload.toFlatpieModel(),
      'Failed to update claim draft'
    );
    return new DraftChargesCaseModel(data);
  }

  convertToChargesCase(draftId) {
    check.assert.assigned(draftId, 'draft ID is required but not provided');
    return super.post(`${draftId}/convert-to-charges-case/`, {}, 'Failed to submit charges');
  }

  deleteDraftChargesCase(flatbondId) {
    check.assert.assigned(flatbondId, 'flatbond ID required but not provided');
    return super.delete(
      `delete-draft-charges-case/?flatbond_id=${flatbondId}`,
      'Failed to delete draft charges case'
    );
  }
}
