import { InternalMetadataApiBase } from '@/libs/apis/metadata/internal/base';
import { InternalMetadataTag } from '@/models/internal/metadata';
import qs from 'querystring';

export class InternalMetadataTagApi extends InternalMetadataApiBase {
  async search(q) {
    const query = qs.encode({
      search: q,
      page_size: 100
    });
    const { data } = await this.get(`tag/?${query}`);
    const results = data.results.map(result => new InternalMetadataTag(result));
    return { ...data, results };
  }

  async create({ name }) {
    const { data } = await this.post('tag/', { name });
    return new InternalMetadataTag(data);
  }
}
