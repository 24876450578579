import {
  redirectToFlow10IfFlow20Disabled,
  redirectToHomepageIfUserHasSignedUp
} from '@/pages/guarantor/redirects';

export const GUARANTOR_SIGN_UP_ROUTES = {
  FLOW_1_0: 'GUARANTOR_SIGN_UP_FLOW_1_0',
  FLOW_2_0: 'GUARANTOR_SIGN_UP_FLOW_2_0'
};

const guarantorSignUpFlow10 = {
  path: '/guarantor/signup',
  name: GUARANTOR_SIGN_UP_ROUTES.FLOW_1_0,
  beforeEnter: redirectToHomepageIfUserHasSignedUp,
  component: () => import('@/pages/guarantor/sign_up/flow10.vue')
};

const guarantorSignUpFlow20 = {
  path: '/guarantor/:guarantorId/signup',
  name: GUARANTOR_SIGN_UP_ROUTES.FLOW_2_0,
  beforeEnter: redirectToFlow10IfFlow20Disabled,
  props: route => {
    let guarantorId = Number.parseInt(route.params.guarantorId, 10);
    if (Number.isNaN(guarantorId)) {
      guarantorId = -1;
    }
    return { guarantorId };
  },
  component: () => import('@/pages/guarantor/sign_up/flow20.vue')
};

export default [guarantorSignUpFlow20, guarantorSignUpFlow10];
