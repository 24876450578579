import { referencingUpSellRoute } from '@/pages/tenant/referencing/up_sell';

export const ROUTES = {
  REFERENCING_LIST: 'tenant-referencing-list',
  REFERENCING_DETAILS: 'tenant-referencing-details'
};

export const referencingList = {
  name: ROUTES.REFERENCING_LIST,
  path: '/tenant/referencing',
  component: () => import('@/pages/common/referencing/list')
};

export const referencingDetails = {
  name: ROUTES.REFERENCING_DETAILS,
  path: '/tenant/referencing/details/:referencingRequestId',
  props: true,
  component: () => import('@/pages/tenant/referencing/details/details')
};

export default [referencingList, referencingUpSellRoute, referencingDetails];
