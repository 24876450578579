import signUpRoutes from '@/pages/guarantor/sign_up';
import referencingRoutes from '@/pages/guarantor/referencing';
import { guarantorHomepageRedirect } from '@/pages/guarantor/redirects';

export const GUARANTOR_ROUTES = {
  GUARANTOR_HOMEPAGE: 'GUARANTOR_HOMEPAGE',
  GUARANTOR_SETTINGS: 'GUARANTOR_SETTINGS',
  GUARANTOR_TENANCIES: 'GUARANTOR_TENANCIES'
};

export const guarantorHomepage = {
  name: GUARANTOR_ROUTES.GUARANTOR_HOMEPAGE,
  path: '/guarantor',
  beforeEnter: guarantorHomepageRedirect,
  component: () => import('@/pages/guarantor/homepage.vue')
};

export const guarantorTenancies = {
  name: GUARANTOR_ROUTES.GUARANTOR_TENANCIES,
  path: '/guarantor/tenancies',
  beforeEnter: guarantorHomepageRedirect,
  component: () => import('@/pages/guarantor/tenancies.vue')
};

export const guarantorSettings = {
  name: GUARANTOR_ROUTES.GUARANTOR_SETTINGS,
  path: '/guarantor/settings',
  component: () => import('@/pages/common/settings/base.vue')
};

export default [
  ...signUpRoutes,
  guarantorHomepage,
  guarantorSettings,
  guarantorTenancies,
  ...referencingRoutes
];
