import { InternalMetadataApiBase } from '@/libs/apis/metadata/internal/base';
import { InternalMetadataTagEdge } from '@/models/internal/metadata';
import querystring from 'querystring';

export class InternalMetadataTagEdgeApi extends InternalMetadataApiBase {
  async create({
    tagId,
    chargeCaseId,
    userId,
    flatbondId,
    landlordId,
    tenantId,
    guarantorId,
    userPropertyId,
    extensionId,
    noteId
  }) {
    const { data } = await this.post('tag-edge/', {
      tag_id: tagId,
      charge_case_id: chargeCaseId,
      user_id: userId,
      user_property_id: userPropertyId,
      flatbond_id: flatbondId,
      landlord_id: landlordId,
      tenant_id: tenantId,
      guarantor_id: guarantorId,
      extension_id: extensionId,
      note_id: noteId
    });
    return new InternalMetadataTagEdge(data);
  }

  async list({
    chargeCaseId,
    userId,
    flatbondId,
    landlordId,
    tenantId,
    guarantorId,
    userPropertyId,
    extensionId,
    noteId
  }) {
    const qs = querystring.stringify({
      charge_case__id: chargeCaseId,
      user__id: userId,
      user_property__id: userPropertyId,
      flatbond__id: flatbondId,
      landlord__id: landlordId,
      tenant__id: tenantId,
      guarantor__id: guarantorId,
      extension__id: extensionId,
      note__id: noteId,
      page_size: 1000
    });
    const {
      data: { results }
    } = await this.get(`tag-edge/?${qs}`);
    return results.map(result => new InternalMetadataTagEdge(result));
  }

  async destroy(edgeId) {
    await this.delete(`tag-edge/${encodeURIComponent(edgeId)}`);
  }
}
