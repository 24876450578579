var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "FpModal",
        {
          on: {
            close: function($event) {
              return _vm.$emit("close")
            }
          },
          scopedSlots: _vm._u(
            [
              _vm.resolved
                ? {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "FpFlex",
                          { attrs: { compact: "" } },
                          [
                            _c(
                              "FpFlexItem",
                              { attrs: { grow: "" } },
                              [
                                _c(
                                  "FpButton",
                                  {
                                    attrs: {
                                      "data-testid": _vm.TEST_IDS.CLOSE_BUTTON,
                                      secondary:
                                        _vm.resolvedWithError ||
                                        !!_vm.extraSuccessActionLabel,
                                      fluid: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.$emit("close")
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.$t(
                                            "apiFeedbackModal.actions.close"
                                          )
                                        ) +
                                        "\n          "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.resolvedWithError && _vm.allowsRetry
                              ? _c(
                                  "FpFlexItem",
                                  { attrs: { grow: "" } },
                                  [
                                    _c(
                                      "FpButton",
                                      {
                                        attrs: {
                                          fluid: "",
                                          "data-testid":
                                            _vm.TEST_IDS.TRY_AGAIN_BUTTON
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("retry")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.$t(
                                                "apiFeedbackModal.actions.tryAgain"
                                              )
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm.extraSuccessActionLabel
                              ? _c(
                                  "FpFlexItem",
                                  { attrs: { grow: "" } },
                                  [
                                    _c(
                                      "FpButton",
                                      {
                                        attrs: {
                                          fluid: "",
                                          "data-testid":
                                            _vm.TEST_IDS.EXTRA_SUCCESS_BUTTON
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.$emit("extra")
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n            " +
                                            _vm._s(
                                              _vm.extraSuccessActionLabel
                                            ) +
                                            "\n          "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [
          !_vm.resolved
            ? [
                _c(
                  "FpFlexGrid",
                  { attrs: { "space-around": "", compact: "" } },
                  [
                    _c(
                      "FpFlexGridItem",
                      [
                        _c(
                          "FpImage",
                          {
                            style: { width: "auto" },
                            attrs: {
                              title: _vm.$t(
                                "apiFeedbackModal.loading.image.title"
                              )
                            }
                          },
                          [
                            _c("img", {
                              style: { height: "100px" },
                              attrs: {
                                src: _vm.loadingCircle,
                                alt: _vm.$t(
                                  "apiFeedbackModal.loading.image.alt"
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : !_vm.resolvedWithError
            ? [
                _c(
                  "FpFlexGrid",
                  { attrs: { "space-around": "", compact: "" } },
                  [
                    _c(
                      "FpFlexGridItem",
                      [
                        _c(
                          "FpImage",
                          {
                            style: { width: "auto" },
                            attrs: {
                              title: _vm.$t(
                                "apiFeedbackModal.success.image.title"
                              )
                            }
                          },
                          [
                            _c("img", {
                              style: { height: "100px" },
                              attrs: {
                                src: _vm.successCheckNew,
                                alt: _vm.$t(
                                  "apiFeedbackModal.success.image.alt"
                                )
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "FpFlexGridItem",
                      [_c("FpHeader1", [_vm._v(_vm._s(_vm.headerOnSuccess))])],
                      1
                    ),
                    _vm._v(" "),
                    _vm.extraMessageOnSuccess
                      ? _c(
                          "FpFlexGridItem",
                          [
                            _c("FpBody", [
                              _vm._v(_vm._s(_vm.extraMessageOnSuccess))
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm.resolvedWithError
            ? [
                _c(
                  "FpFlexGrid",
                  { attrs: { "space-around": "", compact: "" } },
                  [
                    _c(
                      "FpFlexGridItem",
                      [
                        _c(
                          "FpImage",
                          {
                            style: { width: "auto" },
                            attrs: {
                              title: _vm.$t(
                                "apiFeedbackModal.error.image.title"
                              )
                            }
                          },
                          [
                            _c("img", {
                              style: { height: "100px" },
                              attrs: {
                                src: _vm.feedbackNegative,
                                alt: _vm.$t("apiFeedbackModal.error.image.alt")
                              }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "FpFlexGridItem",
                      [_c("FpHeader1", [_vm._v(_vm._s(_vm.headerOnError))])],
                      1
                    ),
                    _vm._v(" "),
                    _vm.messageToAskForHelpFromSupport
                      ? _c(
                          "FpFlexGridItem",
                          [
                            _c(
                              "FpBody",
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t(
                                        "apiFeedbackModal.errorMessage.nonClickable"
                                      )
                                    ) +
                                    "\n            "
                                ),
                                _c(
                                  "FpLink",
                                  {
                                    attrs: { button: "" },
                                    on: { click: _vm.contactSupport }
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "apiFeedbackModal.errorMessage.clickable"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("FpModalTarget")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }