import { ReferencingApiBase } from '@/libs/apis/referencing/base';

export class InternalReferenceResponseApi extends ReferencingApiBase {
  constructor(vue) {
    super(vue, 'internal/response');
  }

  getDownloadLink(responseId) {
    return `${this.baseUrl}/${this.namespace}/${responseId}/download-pdf/`;
  }
}
