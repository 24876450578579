export const DEPOSITS_ROUTES = {
  TDS_AUTHORIZATION_LOADING: 'TDS_AUTHORIZATION_LOADING',
  TDS_CONNECT: 'TDS_CONNECT',
  TDS_LOGIN: 'TDS_LOGIN',
  MYDEPOSITS_LOGIN: 'MYDEPOSITS_LOGIN'
};

export const loading = {
  path: '/agent/deposits/tds/loading',
  name: DEPOSITS_ROUTES.TDS_AUTHORIZATION_LOADING,
  component: () => import('@/pages/agent/deposits/tds_authrization_loading.vue')
};

export const connect = {
  path: '/agent/deposits/tds/connect',
  name: DEPOSITS_ROUTES.TDS_CONNECT,
  component: () => import('@/pages/agent/deposits/tds_connect.vue')
};

export const login = {
  path: '/agent/deposits/tds/connect/login',
  name: DEPOSITS_ROUTES.TDS_LOGIN,
  component: () => import('@/pages/agent/deposits/tds_login.vue')
};

export const mydepositsLogin = {
  path: '/agent/deposits/mydeposits/connect/login',
  name: DEPOSITS_ROUTES.MYDEPOSITS_LOGIN,
  component: () => import('@/pages/agent/deposits/mydeposits_login.vue')
};

export default [loading, connect, login, mydepositsLogin];
