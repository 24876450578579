import { ApiBase } from '@/libs/apis/base';
import check from 'check-types';
import ChargesCaseModel from '@/models/charges_case';

export class ChargesCaseApi extends ApiBase {
  constructor(vue) {
    super(vue, 'claim/case', true);
    this.errorName = 'ChargesCaseApiError';
  }

  async getTimeline(planId) {
    check.assert.assigned(planId, 'plan ID is required but not provided');
    const { data } = await super.get(`timeline/?flatbond_id=${planId}`, 'Failed to fetch timeline');
    return new ChargesCaseModel(data);
  }
}
