import enGb from '@/locale/en-gb.json';
import enUs from '@/locale/en-us.json';
import config from '@/libs/utils/config';
import 'intl';
import 'intl/locale-data/jsonp/en.js';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import { IntlMessageFormat } from 'intl-messageformat';

export const loadLocale = (locale, rawMessages) =>
  Object.keys(rawMessages).reduce(
    (acc, key) => ({
      ...acc,
      [key]: new IntlMessageFormat(rawMessages[key], locale)
    }),
    {}
  );

const messagesMap = {
  // eslint-disable-next-line no-unused-vars
  'en-gb': _ => enGb,
  // eslint-disable-next-line no-unused-vars
  'en-us': _ => {
    return { ...enGb, ...enUs };
  }
};
//
const findLocale = ({ locale, messages }) => {
  const currentLocale = locale || config.locale.toLowerCase() || 'en-gb';
  const currentMessages = (messages || messagesMap[currentLocale])(currentLocale);

  return { currentLocale, currentMessages };
};

export default ({ locale, messages } = {}) => {
  const messagesCache = {};
  const { currentLocale, currentMessages } = findLocale({ locale, messages });
  const t = (key, values, defaultValue) => {
    if (key in messagesCache === false && key in currentMessages) {
      messagesCache[key] = new IntlMessageFormat(currentMessages[key], locale);
    }
    const msg = messagesCache[key];
    if (!msg) {
      return defaultValue || key;
    }
    return msg.format(values);
  };
  const tm = key => {
    if (key in messagesCache === false) {
      messagesCache[key] = new IntlMessageFormat(currentMessages[key], locale);
    }
    return messagesCache[key];
  };
  const td = (date, options) => {
    return Intl.DateTimeFormat(currentLocale, options).format(date);
  };
  const tr = (val, unit, options) => {
    return new Intl.RelativeTimeFormat(currentLocale, options).format(val, unit);
  };
  const tn = (number, options) => {
    return Intl.NumberFormat(currentLocale, options).format(number);
  };
  return {
    t,
    td,
    tn,
    tm,
    tr,
    install(Vue) {
      Vue.prototype.$t = t;
      Vue.prototype.$td = td;
      Vue.prototype.$tn = tn;
      Vue.prototype.$tm = tm;
      Vue.prototype.$tr = tr;
    }
  };
};

export const NUMBER_FORMAT_GBP = { style: 'currency', currency: 'GBP' };
export const NUMBER_FORMAT_USD = { style: 'currency', currency: 'USD' };
export const NUMBER_FORMAT_PERCENTAGE = { style: 'percent' };
export const DATE_FORMAT_LONG = { dateStyle: 'long' };
export const DATE_FORMAT_DISPLAY = { day: 'numeric', month: 'short', year: 'numeric' };
export const DATE_FORMAT_DISPLAY_LONG = { day: 'numeric', month: 'long', year: 'numeric' };
export const DATE_FORMAT_DISPLAY_NUMERIC = { day: 'numeric', month: 'numeric', year: 'numeric' };
export const DATE_TIME_FORMAT_DISPLAY_LONG = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};
export const DATE_TIME_FORMAT_DISPLAY = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

export const IntlHtml = {
  props: {
    id: {
      type: String,
      required: true
    },
    variables: {
      type: Object,
      default: () => ({})
    },
    tags: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    message() {
      return this.$tm(this.id);
    }
  },
  methods: {
    tagRenderer(h) {
      return Object.keys(this.tags).reduce((acc, key) => {
        const tagHandler = this.tags[key];
        return {
          ...acc,
          [key]: (...children) => {
            if (Array.isArray(tagHandler)) {
              const [tag, domProps = {}] = tagHandler;
              return h(tag, { domProps }, children);
            } else {
              return tagHandler(h, children);
            }
          }
        };
      }, {});
    }
  },
  render(h) {
    return h('span', this.message.format({ ...this.variables, ...this.tagRenderer(h) }));
  }
};
