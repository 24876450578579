import { InternalFlatbondApiBase } from './base';
import { InternalFlatbondGuarantorModel } from '@/models/internal/flatbond_guarantors';
import GuarantorModel from '@/models/guarantor';
import qs from 'querystring';

export class InternalFlatbondGuarantorApi extends InternalFlatbondApiBase {
  constructor(vue) {
    super(vue, '/guarantors');
  }

  async listQuery(query = '') {
    const { data } = await super.get(`${query}`, 'Failed to list guarantors');
    return data.results.map(guarantor => new GuarantorModel(guarantor));
  }

  async list({ flatbond } = {}) {
    const { data } = await super.get(`?${qs.encode({ flatbond })}`, 'Failed to list guarantors');
    return {
      ...data,
      results: data.results.map(guarantor => new InternalFlatbondGuarantorModel(guarantor))
    };
  }

  async get(guarantorId) {
    const { data } = await super.get(`${guarantorId}/`, 'Failed to get guarantor');
    return new InternalFlatbondGuarantorModel(data);
  }

  async update(guarantorId, model) {
    await super.put(`${guarantorId}/`, {
      first_name: model.firstName,
      last_name: model.lastName,
      email: model.email,
      tenant_ids: model.tenants.map(({ id }) => id)
    });
  }

  async create(flatbondId, { email, firstName, lastName, phoneNumber, tenantIds }) {
    await super.post('', {
      flatbond_id: flatbondId,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phoneNumber,
      tenant_ids: tenantIds
    });
  }

  remove(guarantorId) {
    return super.post(`${guarantorId}/remove/`, {}, 'Failed re-send remove to guarantor');
  }
}
