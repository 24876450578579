import { InternalMetadataApiBase } from '@/libs/apis/metadata/internal/base';

export class InternalMetadataActionApi extends InternalMetadataApiBase {
  async complete(actionId) {
    return await super.put(
      `action/${encodeURI(actionId)}/complete/`,
      null,
      'Failed to mark action as completed'
    );
  }

  async reset(actionId) {
    return await super.put(
      `action/${encodeURI(actionId)}/reset/`,
      null,
      'Failed to mark action as completed'
    );
  }
}
