import BaseModel from '@/models/base';
import { AddressModel } from '@/models/user/address';

export class UserModel extends BaseModel {
  constructor(data) {
    super(data);

    this.activeAddress = this.activeAddress ? new AddressModel(this.activeAddress) : null;
    this.inactiveAddresses = this.inactiveAddresses.map(address => new AddressModel(address));
  }

  defaults() {
    return {
      id: null,
      firstName: '',
      lastName: '',
      fullName: '',
      activeAddress: null,
      inactiveAddresses: [],
      physicalHealth: false,
      mentalHealth: false,
      lifeEvents: false,
      capability: false
    };
  }
}

export * from './address';
