export const LANDLORD_OFFERS_ROUTES = {
  OFFERS: 'LANDLORD_OFFERS'
};

const landlordOffers = {
  path: '/landlord/offers',
  name: LANDLORD_OFFERS_ROUTES.OFFERS,
  component: () => import('@/components/landlord_offers/landlord_offers.vue')
};

export default [landlordOffers];
