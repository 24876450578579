import BaseModel from '../base';
import moment from 'moment';
import { LANDLORD_OFFER_STATUS, LANDLORD_OFFER_TYPE } from '@/libs/utils/constants';

export const LANDLORD_OFFER_TYPE_TO_LABEL_ID = {
  [LANDLORD_OFFER_TYPE.ASSIGNMENT]: 'ops.landlordOffer.list.type.assignment',
  [LANDLORD_OFFER_TYPE.APPOINTMENT]: 'ops.landlordOffer.list.type.appointment',
  [LANDLORD_OFFER_TYPE.APPOINTMENT_AND_ASSIGNMENT]:
    'ops.landlordOffer.list.type.appointmentAndAssignment'
};

export const LANDLORD_OFFER_STATUS_TO_LABEL_ID = {
  [LANDLORD_OFFER_STATUS.PENDING_APPROVAL]: 'ops.landlordOffer.list.status.pendingApproval',
  [LANDLORD_OFFER_STATUS.PENDING_ACTION]: 'ops.landlordOffer.list.status.pendingAction',
  [LANDLORD_OFFER_STATUS.PENDING_APPOINTMENT]: 'ops.landlordOffer.list.status.pendingAppointment',
  [LANDLORD_OFFER_STATUS.PENDING_APPOINTMENT_APPROVAL]:
    'ops.landlordOffer.list.status.pendingAppointmentApproval',
  [LANDLORD_OFFER_STATUS.PENDING_INTERNAL_SIGNATURE]:
    'ops.landlordOffer.list.status.pendingInternalSignature',
  [LANDLORD_OFFER_STATUS.PENDING_LANDLORD_SIGNATURE]:
    'ops.landlordOffer.list.status.pendingLandlordSignature',
  [LANDLORD_OFFER_STATUS.PENDING_OFFER_PAYMENT]:
    'ops.landlordOffer.list.status.pendingOfferPayment',
  [LANDLORD_OFFER_STATUS.COMPLETED]: 'ops.landlordOffer.list.status.completed',
  [LANDLORD_OFFER_STATUS.CANCELLED]: 'ops.landlordOffer.list.status.cancelled',
  [LANDLORD_OFFER_STATUS.REJECTED]: 'ops.landlordOffer.list.status.rejected',
  [LANDLORD_OFFER_STATUS.EXPIRED]: 'ops.landlordOffer.list.status.expired'
};

class InternalLandlordOfferCaseFlatbondModel extends BaseModel {
  defaults() {
    return {
      id: null
    };
  }
}

class InternalLandlordOfferCaseHandlerModel extends BaseModel {
  defaults() {
    return {
      id: null,
      fullName: ''
    };
  }
}

class InternalLandlordOfferCaseModel extends BaseModel {
  constructor(data) {
    super(data);

    this.handler = this.handler && new InternalLandlordOfferCaseHandlerModel(this.handler);
    this.flatbond = this.flatbond && new InternalLandlordOfferCaseFlatbondModel(this.flatbond);
  }

  defaults() {
    return {
      id: null,
      handler: null,
      flatbond: null
    };
  }
}

export class InternalLandlordOfferModel extends BaseModel {
  constructor(data) {
    super(data);

    this.case = new InternalLandlordOfferCaseModel(this.case);
    this.statusChangeDate = this.statusChangeDate && moment(this.statusChangeDate);
  }

  defaults() {
    return {
      case: null,
      id: null,
      status: '',
      statusChangeDate: '',
      totalAppointment: null,
      totalAssignment: null
    };
  }

  statusLabel($t) {
    return $t(LANDLORD_OFFER_STATUS_TO_LABEL_ID[this.status]);
  }

  typeLabel($t) {
    return $t(LANDLORD_OFFER_TYPE_TO_LABEL_ID[this.type]);
  }

  get type() {
    if (this.totalAssignment && !this.totalAppointment) {
      return LANDLORD_OFFER_TYPE.ASSIGNMENT;
    }
    if (!this.totalAssignment && this.totalAppointment) {
      return LANDLORD_OFFER_TYPE.APPOINTMENT;
    }
    return LANDLORD_OFFER_TYPE.APPOINTMENT_AND_ASSIGNMENT;
  }

  get isPending() {
    return (
      [
        LANDLORD_OFFER_STATUS.PENDING_APPROVAL,
        LANDLORD_OFFER_STATUS.PENDING_ACTION,
        LANDLORD_OFFER_STATUS.PENDING_APPOINTMENT,
        LANDLORD_OFFER_STATUS.PENDING_APPOINTMENT_APPROVAL,
        LANDLORD_OFFER_STATUS.PENDING_INTERNAL_SIGNATURE,
        LANDLORD_OFFER_STATUS.PENDING_LANDLORD_SIGNATURE,
        LANDLORD_OFFER_STATUS.PENDING_OFFER_PAYMENT
      ].indexOf(this.status) >= 0
    );
  }

  get isFailing() {
    return (
      [
        LANDLORD_OFFER_STATUS.CANCELLED,
        LANDLORD_OFFER_STATUS.REJECTED,
        LANDLORD_OFFER_STATUS.EXPIRED
      ].indexOf(this.status) >= 0
    );
  }
}
