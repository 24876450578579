import BaseModel from '@/models/base';

export default class TenantReferenceEmployerModel extends BaseModel {
  constructor(data) {
    super(data);
  }

  defaults() {
    return {
      id: null,
      name: null
    };
  }
}
