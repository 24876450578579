import BaseModel from '@/models/base';
import { DOCUMENT_TYPES } from '@/libs/constants/generatedConstants';
import { InternalUserModel } from '@/models/internal/user';

export class GenericDocumentModel extends BaseModel {
  defaults() {
    return {
      type: null,
      url: null,
      id: null,
      file_name: null,
      file: null
    };
  }

  get isReferencingResult() {
    return this.type === DOCUMENT_TYPES.REFERENCING_RESULT;
  }

  get internalDownloadLink() {
    return `/api/private/v1/document/internal/generic/${this.id}/downloadable-link/`;
  }
}

export class InternalGuarantorTenantModel extends BaseModel {
  defaults() {
    return {
      inviteEmail: '',
      firstName: null,
      lastName: null,
      companyName: null,
      companyDirectorName: null
    };
  }

  get displayName() {
    if (this.firstName || this.lastName) {
      return `${this.firstName} ${this.lastName}`.trim();
    }
    return `${this.companyName} ${this.companyDirectorName &&
      `(${this.companyDirectorName})`}`.trim();
  }
}

export class InternalGuarantorFlatbondModel extends BaseModel {
  constructor(data) {
    super(data);
    this.tenants = this.tenants.map(tenant => new InternalGuarantorTenantModel(tenant));
  }

  defaults() {
    return {
      tenants: []
    };
  }

  get tenantRecord() {
    return this.tenants.reduce((acc, tenant) => ({ ...acc, [tenant.id]: tenant }), {});
  }
}

export class InternalFlatbondGuarantorModel extends BaseModel {
  constructor(data) {
    super(data);
    this.user = this.user ? new InternalUserModel(this.user) : null;
    this.tenants = this.tenants.map(tenant => new InternalGuarantorTenantModel(tenant));
    this.flatbond = new InternalGuarantorFlatbondModel(this.flatbond);
    this.documents = this.documents.map(document => new GenericDocumentModel(document));
  }

  get referencingDocument() {
    return this.documents.find(doc => doc.isReferencingResult);
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`.trim();
  }

  defaults() {
    return {
      id: null,
      user: null,
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      flatbond: new InternalGuarantorFlatbondModel(),
      tenants: [],
      documents: [],
      referencingResult: null,
      referencingConditionType: null,
      referencingCondition: null,
      referencingProvider: null,
      hasConfirmedKeyPoints: false,
      hasSignedTermsAndConditions: false,
      hasProvidedPaymentDetails: false
    };
  }
}
