import BaseModel from '@/models/base';

export class RecipientModel extends BaseModel {
  constructor(data) {
    super(data);
  }

  defaults() {
    return {
      email: '',
      fullName: ''
    };
  }
}

export class RecipientsModel extends BaseModel {
  constructor(data) {
    super(data);

    this.tenants = this.tenants.map(tenant => new RecipientModel(tenant));
    this.tenantsRemovedFromFlatbonds = this.tenantsRemovedFromFlatbonds.map(
      tenant => new RecipientModel(tenant)
    );
    if (this.landlord) {
      this.landlord = new RecipientModel(this.landlord);
    }
    this.guarantors = this.guarantors.map(guarantor => new RecipientModel(guarantor));
  }

  defaults() {
    return {
      tenants: [],
      tenantsRemovedFromFlatbonds: [],
      landlord: null,
      guarantors: []
    };
  }
}

export class MessageModel extends BaseModel {
  constructor(data) {
    super(data);

    this.sentOn = new Date(this.sentOn);

    if (this.isBodyEncoded) {
      this.body = Buffer.from(this.body, 'base64').toString('utf-8');
    }
  }

  defaults() {
    return {
      id: null,
      subject: null,
      emailTo: null,
      sentOn: new Date(),
      body: '',
      isBodyEncoded: false,
      system: ''
    };
  }
}

export class MessagesModel extends BaseModel {
  constructor(data) {
    super(data);

    this.messages = this.messages.map(message => new MessageModel(message));
  }

  defaults() {
    return {
      messages: [],
      nextPage: null
    };
  }
}
