import BaseModel from '@/models/base';
import { InternalUserModel } from '@/models/internal/user';

class InternalFlatbondClaimCaseModel extends BaseModel {
  defaults() {
    return {};
  }
}

class InternalFlatbondLandlordOfferModel extends BaseModel {
  defaults() {
    return {};
  }
}

class InternalOrganizationBrandModel extends BaseModel {
  defaults() {
    return {};
  }
}

export class InternalOrganizationUnitModel extends BaseModel {
  constructor(data) {
    super(data);
    this.brand = this.brand && new InternalOrganizationBrandModel(this.brand);
  }

  defaults() {
    return {};
  }
}

export class InternalOrganizationUserModel extends BaseModel {
  constructor(data) {
    super(data);
  }

  defaults() {
    return {};
  }
}

export class InternalFlatbondModel extends BaseModel {
  constructor(data) {
    super(data);
    this.claimCase = this.claimCase && new InternalFlatbondClaimCaseModel(this.claimCase);
    this.branch = this.branch && new InternalOrganizationUnitModel(this.branch);
    this.activelyAdministeredBy =
      this.activelyAdministeredBy && new InternalUserModel(this.activelyAdministeredBy);
    this.landlordOffer =
      this.landlordOffer && new InternalFlatbondLandlordOfferModel(this.landlordOffer);
  }

  get isAgentManaged() {
    return this.managedBy === 'agent';
  }

  defaults() {
    return {};
  }
}
