import { InternalTransactionApiBase } from '@/libs/apis/transaction/internal/base';

export class InternalTransactionDiscountApi extends InternalTransactionApiBase {
  getDiscountsForFlatbond(flatbondId) {
    return super.get(
      `discount?flatbond_tenant__flatbond_id=${flatbondId}`,
      'Could not retrieve discounts for flatbond'
    );
  }

  remove(discountId) {
    return super.delete(`discount/${discountId}`, `Could not remove discount ${discountId}`);
  }
}
