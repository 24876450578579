import { ApiBase } from '@/libs/apis/base';

export * from './seller';
export * from './unit';
export * from './boost';

export class OrganisationUserApi extends ApiBase {
  constructor(vue) {
    super(vue, 'organization/organization-user', false);
    this.errorName = 'OrganisationUserApiError';
  }

  list() {
    let queryParams = '';
    return super.get(`?${queryParams}`, 'Failed to list users');
  }
}
