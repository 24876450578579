import BaseModel from '@/models/base';

export const STATUS_OPTIONS = {
  INCOMPLETE: 'incomplete',
  ACTIVE: 'active',
  CANCELLED: 'cancelled'
};

export const BILLING_TYPE_OPTIONS = {
  FREE: 'free',
  CONSOLIDATED: 'consolidated',
  SUBSCRIPTION: 'subscription'
};

export class FlatbondSubscriptionItemModel extends BaseModel {
  defaults() {
    return {
      id: null,
      amount: 0,
      status: null,
      billingType: null
    };
  }
}
