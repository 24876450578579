import { TrainingApiBase } from '@/libs/apis/training/base';

export class TrainingVideoApi extends TrainingApiBase {
  getOrCreate() {
    return super.get('video/get-or-create/', 'Failed to get or create the training video');
  }

  complete() {
    return super.post('video/complete/', 'Failed to mark training video as complete');
  }
}
