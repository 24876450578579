import TenantReferenceModel from '@/models/tenant/tenant_reference';
import TenantReferenceTotalDataModel from '@/models/tenant/total_tenant_data';
import { InternalTenantApiBase } from '@/libs/apis/tenant/internal/base';

export class TenantReferenceApi extends InternalTenantApiBase {
  async getTenantReferenceTotals(flatbondId) {
    const { data } = await super.get(
      `/tenant-reference/get-total-calculations?flatbond_id=${flatbondId}`,
      'Failed to get total tenant calculations'
    );
    return new TenantReferenceTotalDataModel(data);
  }

  async getTenantReferences(flatbondId) {
    const { data } = await super.get(
      `/tenant-reference/get-tenant-references?flatbond_id=${flatbondId}`,
      'Failed to get tenant references'
    );
    return data.map(tenant => new TenantReferenceModel(tenant));
  }

  createOrUpdateTenantReference(tenantReference) {
    return super.post(
      '/tenant-reference/create-or-update-tenant/',
      tenantReference.toFlatpieModel(),
      'Failed to create tenant reference'
    );
  }
}
