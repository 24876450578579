import BaseModel from '../base';
import check from 'check-types';
import { DOCUMENT_TYPES } from '../document';
import { UserModel } from '@/models/user';
import ChangeOfTenantsModel from '@/models/tenant/change_of_tenants';

export default class FlatbondTenantModel extends BaseModel {
  constructor(data) {
    super(data);

    if (this.user) {
      if (typeof this.user === 'object') {
        this.user = new UserModel(this.user);
      } else if (typeof this.user === 'number') {
        this.user = new UserModel({ id: this.user });
      }
    }
    this.outgoingChangeOfTenants = this.outgoingChangeOfTenants
      ? new ChangeOfTenantsModel(this.outgoingChangeOfTenants)
      : null;
  }

  defaults() {
    return {
      id: null,
      email: '',
      fullName: '',
      newReferencingDocument: null,
      hasReferenceUploadedForExtension: false,
      referencingResult: '',
      referencingCondition: '',
      referencingConditionType: '',
      flatbondTenancyType: '',
      newAdditionalReferencingDocuments: [],
      documents: [],
      hasPaid: false,
      hasMembershipPaymentInProgress: false,
      hasSignedTc: false,
      hasSignedUp: false,
      user: null,
      invitedOn: null,
      refundedOn: null,
      incomingChangeOfSharers: false,
      outgoingChangeOfTenants: null,
      membershipFee: 0,
      discountedAmount: 0,
      flatbondStartDate: null,
      flatbondCloseDate: null,
      flatbondGroupName: null,
      tenantFlatbondCostIncludingTax: 0,
      flatbondNumberOfDaysToSubmitCharges: null,
      flatbondOnlyCountBusinessDaysOnDaysToSubmitCharges: null,
      flatbondNumberOfDaysForTenantToRespondToCharges: null,
      isLeadTenant: false,
      previousAddress: false,
      confirmTraditionalDepositElectronicContact: false
    };
  }

  toFlatpieModel() {
    const result = super.toFlatpieModel();

    if (check.nonEmptyArray(this.newAdditionalReferencingDocuments)) {
      result[
        'new_additional_referencing_documents'
      ] = this.newAdditionalReferencingDocuments.filter(d => check.object(d)).map(d => d.id);
    }

    if (check.object(this.newReferencingDocument)) {
      result['new_referencing_document'] = this.newReferencingDocument.id;
    }

    result['flatbond_tenant_id'] = this.id;
    result['invite_email'] = this.email;
    return result;
  }

  needsReferencing() {
    return (
      this.referencingResult === null ||
      check.emptyArray(
        this.documents.filter(document => document.type === DOCUMENT_TYPES.REFERENCING_RESULT)
      )
    );
  }

  membershipFeeIncludingDiscount() {
    return this.membershipFee - this.discountedAmount;
  }
}

export class TenantStatusModel extends BaseModel {
  defaults() {
    return {
      hasUnselectedPlanToSignOrPay: null,
      hasTraditionalDepositToPay: null,
      hasPlansToSignOrPay: null,
      hasPlanInAdjudication: null,
      hasActiveCase: null,
      hasActivePlan: null,
      hasCasePendingPayment: null,
      hasChargesToStartNegotiating: null,
      hasChargesToRespond: null,
      hasPlansCreated: null
    };
  }
}
