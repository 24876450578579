import '@/libs/utils/mixpanel';
import 'normalize.css';
import '@/libs/utils/hotjar';
import '@/libs/utils/sentry';
import '@/../scss/hellosign.scss';
import '@/libs/utils/public_path';
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VueRouter from 'vue-router';
import VueGtag from 'vue-gtag';
import Notifications from 'vue-notification';
import config from '@/libs/utils/config';
import FfThemeProvider from '@/elements/theme_provider.vue';
import VueIntl from '@/plugins/VueIntl';
import router from '@/pages';
import 'typeface-roboto';
import VueYouTubeEmbed from 'vue-youtube-embed';
import '@flatfair/typeface-untitled-serif';
import { FpModalTarget } from '@flatfair/vue-fairplay/molecules';
import VueCompositionAPI from '@vue/composition-api';
import { logExceptionOnSentry } from '@/libs/utils/helpers';
import BrowserDetect from 'vue-browser-detect-plugin';
import { store } from '@/store';

// eslint-disable-next-line no-console
console.debug({ version: process.env.VERSION });

Vue.use(VueCompositionAPI);
Vue.use(Notifications);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(VueIntl());
Vue.use(VueYouTubeEmbed);
Vue.use(BrowserDetect);

if (config.isProduction) {
  try {
    Vue.use(
      VueGtag,
      {
        config: { id: 'UA-88250985-1' },
        include: [{ id: 'GTM-WCQW95W' }]
      },
      router
    );
  } catch (error) {
    logExceptionOnSentry(error);
  }
}

const element = document.querySelector('#main');

new Vue({
  element,
  el: element,
  router,
  store,
  components: {
    FfThemeProvider,
    FpModalTarget
  },
  template: `
    <FfThemeProvider>
    <router-view></router-view>
    <FpModalTarget />
    </FfThemeProvider>`
});
