import { InternalClaimApiBase } from './base';

export class InternalFlatbondChargeCaseTransaction extends InternalClaimApiBase {
  list({ pageSize, page, caseId }) {
    const query = `?page=${page}&page_size=${pageSize}&case__id=${caseId}`;
    return super.get(`transaction/${query}`, 'Failed to retrieve the charge case transactions');
  }

  create({ amountPaid, caseId, collectedFrom, collectionMethod, paidOn, paidOnBehalf }) {
    const body = {
      amount_paid: amountPaid,
      case_id: caseId,
      collected_from_id: collectedFrom,
      collection_method: collectionMethod,
      paid_on: paidOn,
      paid_on_behalf: paidOnBehalf
    };
    return super.post('transaction/', body, 'Failed to create the charge case transactions');
  }
}
