<template>
  <FpContainer grow elevated>
    <FpFlex
      compact
      :justify-content="FlexJustifyContent.spaceBetween"
      :direction="FlexDirection.column"
      style="height: 100%"
    >
      <template v-if="loading">
        <FpFlexItem
          ><FpFlexGrid compact>
            <FpFlexGridItem><FpPlaceholder /> </FpFlexGridItem
            ><FpFlexGridItem><FpPlaceholder /> </FpFlexGridItem
            ><FpFlexGridItem><FpPlaceholder /> </FpFlexGridItem> </FpFlexGrid
        ></FpFlexItem>

        <FpFlexItem :style="{ height: '100%' }"
          ><FpPlaceholder :style="{ height: '100%', width: '100%' }" />
        </FpFlexItem>
      </template>
      <template v-else>
        <FpFlexItem grow>
          <FpFlexGrid compact space-around>
            <FpFlexGridItem v-if="tags || saleBooster">
              <FpFlex
                compact
                :justify-content="FlexJustifyContent.spaceBetween"
                :align-items="FlexAlignItems.center"
              >
                <FpFlexItem>
                  <FpFlex wrap compact :align-items="FlexAlignItems.center">
                    <FpFlexItem v-for="(tag, index) of tags" :key="index">
                      <FpBadge>
                        {{ $t(`card.tags.${tag}`) }}
                      </FpBadge>
                    </FpFlexItem>
                  </FpFlex>
                </FpFlexItem>
                <FpFlexItem v-if="saleBooster">
                  <FpAvatar
                    info
                    icon-code=""
                    :size="AvatarSize.small"
                    :title="$t('card.tags.saleBooster.title')"
                  />
                </FpFlexItem>
              </FpFlex>
            </FpFlexGridItem>
            <FpFlexGridItem :style="{ minHeight: '48px' }">
              <FpHeader3>{{ title }}</FpHeader3>
            </FpFlexGridItem>
            <FpFlexGridItem v-if="description" :style="{ height: '100px' }">
              <FpBody :margin-bottom="4">{{ description }}</FpBody>
            </FpFlexGridItem>
            <FpFlexGridItem v-if="link">
              <FpLink external text :href="link.url" target="_blank" @click="trackClick">{{
                link.label
              }}</FpLink>
            </FpFlexGridItem>
          </FpFlexGrid>
        </FpFlexItem>
      </template>
    </FpFlex>
  </FpContainer>
</template>
<script>
import { PAGES } from '@/components/agent/materials/footer';
import * as trackEvent from '@/libs/utils/track_event';
import {
  FpFlex,
  FpAvatar,
  FpHeader3,
  FpContainer,
  FpPlaceholder,
  AvatarSize,
  FpFlexGridItem,
  FpBadge,
  FpFlexGrid,
  FpFlexItem,
  FpLink,
  FpBody
} from '@flatfair/vue-fairplay';
import FlexMixin from '@/libs/constants/flexMixin';

export default {
  components: {
    FpFlex,
    FpContainer,
    FpFlexGridItem,
    FpFlexGrid,
    FpHeader3,
    FpLink,
    FpBadge,
    FpAvatar,
    FpPlaceholder,
    FpBody,
    FpFlexItem
  },
  mixins: [FlexMixin],
  props: {
    onPage: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    link: {
      type: Object,
      default: null
    },
    saleBooster: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.trackEvent = trackEvent;
    this.AvatarSize = AvatarSize;
  },
  methods: {
    trackClick() {
      if (this.onPage === PAGES.LEARN) {
        this.trackEvent.button({ label: 'Click book training session' });
      }
      if (this.onPage === PAGES.MATERIALS) {
        this.trackEvent.button({ label: 'Click download material' });
      }
    }
  }
};
</script>
