export const ROUTES = {
  REFERENCING_LIST: 'guarantor-referencing-list'
};

export const referencingList = {
  name: ROUTES.REFERENCING_LIST,
  path: '/guarantor/referencing',
  component: () => import('@/pages/common/referencing/list')
};

export default [referencingList];
