import { MessagesApiBase } from '@/libs/apis/messages/base';
import { MessagesModel } from '@/models/messages';
import qs from 'querystring';

export class GmailMessagesApi extends MessagesApiBase {
  async list(searchQuery = null, page = null) {
    const query = qs.encode({
      page,
      query: searchQuery
    });
    const response = await super.get(`gmail/?${query}`);
    return new MessagesModel(response.data);
  }
}
