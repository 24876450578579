import { UserApiBase } from '@/libs/apis/user/base';
import { UserModel } from '@/models/user';

export class UserApi extends UserApiBase {
  async get() {
    const { data } = await super.get('', 'Failed to fetch user');
    return new UserModel(data);
  }

  async getUserForAdditionalDetails(userId) {
    const { data } = await super.get(
      `${userId}/get-user-for-additional-details/`,
      'Failed to fetch user for additional details'
    );
    return new UserModel(data);
  }

  async updateUserVulnerability(userId, data) {
    await super.patch(
      `${userId}/update-user-vulnerability/`,
      data,
      'Failed to fetch user vulnerability'
    );
  }
}
