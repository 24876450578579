import { ApiBase } from '@/libs/apis/base';

export class PaymentApiBase extends ApiBase {
  constructor(vue) {
    super(vue, 'payment', false);
    this.errorName = 'PaymentApiError';
  }
}

export class PaymentApi extends PaymentApiBase {
  refreshTenantPayment(flatbondTenantId, paymentType) {
    const data = {
      flatbond_tenant_id: flatbondTenantId,
      type: paymentType
    };
    return super.put('refresh-tenant-payment/', data, 'Failed to refresh payment data');
  }

  getOrCreateTenantPayment(flatbondTenantId, paymentType) {
    const data = {
      flatbond_tenant_id: flatbondTenantId,
      type: paymentType
    };
    return super.put(
      'get-or-create-tenant-payment/',
      data,
      `Failed to get or create ${paymentType} payment for tenant with id ${flatbondTenantId}`
    );
  }

  getTenantPayment(paymentId) {
    return super.get(
      `get-tenant-payment/?payment_id=${paymentId}`,
      'Failed to refresh payment data'
    );
  }

  paymentInProgress(paymentId) {
    return super.put(`${paymentId}/in-progress/`, {}, 'Failed to mark payment in progress');
  }

  getStripePublicKey() {
    return super.get('stripe-public-key', 'Failed to get payment public key');
  }

  setupPaymentMethod() {
    return super.post('setup-payment-method/', 'Failed to setup payment method');
  }

  paymentCheck(paymentId, nameOnCard) {
    const data = {
      name_on_card: nameOnCard
    };
    return super.post(`${paymentId}/check/`, data, 'Failed to check payment');
  }
}
