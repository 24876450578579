import BaseModel from '@/models/base';

export default class ProspectiveTenantModel extends BaseModel {
  defaults() {
    return {
      rent: 0,
      inviteId: null,
      agentName: null,
      agentHomeUnitName: null,
      noDepositMembershipFeeWithVat: 0,
      interestedInNoDeposit: false,
      hasSeenNoDepositUpsell: false,
      notInterestedReason: null,
      createdOn: new Date(0),
      firstUpsellViewOn: null,
      planType: null
    };
  }
}
