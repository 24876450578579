import { logAPIError, showNotification } from '@/libs/utils/helpers';
import config from '@/libs/utils/config';
import axios from 'axios';

const REQUEST_TYPE = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
};

export class ApiBase {
  constructor(vue, namespace = null, preventNotifications = true) {
    this.vue = vue;
    this.baseUrl = '/api/private/v1';
    this.namespace = namespace;
    this.errorName = 'ApiBaseError';
    this.preventNotifications = preventNotifications;
  }

  get(path, errorMessage = null) {
    return this._performRequest(REQUEST_TYPE.GET, path, null, errorMessage);
  }

  post(path, data, errorMessage = null) {
    return this._performRequest(REQUEST_TYPE.POST, path, data, errorMessage);
  }

  put(path, data, errorMessage = null) {
    return this._performRequest(REQUEST_TYPE.PUT, path, data, errorMessage);
  }

  delete(path, errorMessage = null) {
    return this._performRequest(REQUEST_TYPE.DELETE, path, null, errorMessage);
  }

  patch(path, data, errorMessage = null) {
    return this._performRequest(REQUEST_TYPE.PATCH, path, data, errorMessage);
  }

  _performRequest(type, path, data, errorMessage = null) {
    const formattedErrorMessage = errorMessage
      ? errorMessage
      : `Failed to perform ${type} on ${this.namespace} with ${path}`;

    switch (type) {
      case REQUEST_TYPE.GET:
        return axios
          .get(`${this.baseUrl}/${this.namespace}/${path}`, this._getOptions())
          .catch(this._handleError(formattedErrorMessage));
      case REQUEST_TYPE.POST:
        return axios
          .post(`${this.baseUrl}/${this.namespace}/${path}`, data, this._getOptions())
          .catch(this._handleError(formattedErrorMessage));
      case REQUEST_TYPE.PUT:
        return axios
          .put(`${this.baseUrl}/${this.namespace}/${path}`, data, this._getOptions())
          .catch(this._handleError(formattedErrorMessage));
      case REQUEST_TYPE.DELETE:
        return axios
          .delete(`${this.baseUrl}/${this.namespace}/${path}`, this._getOptions())
          .catch(this._handleError(formattedErrorMessage));
      case REQUEST_TYPE.PATCH:
        return axios
          .patch(`${this.baseUrl}/${this.namespace}/${path}`, data, this._getOptions())
          .catch(this._handleError(formattedErrorMessage));
    }
  }

  _getOptions() {
    return {
      headers: {
        'X-CSRFTOKEN': config.csrfToken
      }
    };
  }

  _handleError(errorTitle) {
    return error => {
      const extraErrorDetails = logAPIError(this.errorName, error);

      showNotification(this.vue, errorTitle, 'error', 'main', null, this.preventNotifications);

      if (extraErrorDetails.data) {
        if (extraErrorDetails.data.detail) {
          showNotification(
            this.vue,
            extraErrorDetails.data.detail,
            'error',
            'main',
            null,
            this.preventNotifications
          );
        } else if (extraErrorDetails.data.amount) {
          showNotification(
            this.vue,
            extraErrorDetails.data.amount[0],
            'error',
            'main',
            null,
            this.preventNotifications
          );
        } else {
          showNotification(
            this.vue,
            extraErrorDetails.data[0],
            'error',
            'main',
            null,
            this.preventNotifications
          );
        }
      }

      throw error;
    };
  }

  static _paginationQuery(page) {
    return `page=${page + 1}`;
  }
}

export class PublicApiBase extends ApiBase {
  constructor(vue, namespace = null, preventNotifications = true) {
    super(vue, namespace, preventNotifications);

    this.baseUrl = '/api/public/v1';
    this.errorName = 'PublicApiBaseError';
  }
}
