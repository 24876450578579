import BaseModel from '../base';

export const LANDLORD_TYPES = {
  COMPANY: 'CORPORATE',
  PRIVATE: 'PRIVATE'
};

export const LANDLORD_EMAIL_TYPES = {
  LANDLORD: 'landlord',
  AGENCY: 'agency'
};

export const LANDLORD_UPSELL_STATUSES = {
  NO_DEPOSIT_APPROVED: 'no_deposit_approved',
  UPSELL: 'upsell',
  NO_DEPOSIT_DECLINED: 'no_deposit_declined'
};

export default class DraftLandlord extends BaseModel {
  defaults() {
    return {
      companyName: '',
      title: '',
      firstName: '',
      lastName: '',
      emailType: LANDLORD_EMAIL_TYPES.LANDLORD,
      email: '',
      address: '',
      addressExtra: '',
      postcode: '',
      city: '',
      country: '',
      landlordType: '',
      phoneNumber: '',
      landlordUpsellStatus: null
    };
  }

  displayName() {
    if (this.landlordType === LANDLORD_TYPES.COMPANY) {
      return this.companyName;
    } else {
      return `${this.firstName} ${this.lastName}`;
    }
  }
}
