import { PropertyApplicationApiBase } from './base';

export class ApplicationGuarantorApi extends PropertyApplicationApiBase {
  createGuarantor(applicationId, firstName, lastName, email, rentShare, applicantId) {
    const data = {
      first_name: firstName,
      last_name: lastName,
      user_email: email,
      rent_share: rentShare,
      guarantor_for: applicantId
    };

    return super.post(
      `${applicationId}/guarantors/`,
      data,
      `Could not add a guarantor for applicant ${applicantId}`
    );
  }
}
