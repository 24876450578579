import { InternalFlatbondApiBase } from './base';

export class InternalTraditionalDepositApi extends InternalFlatbondApiBase {
  constructor(vue) {
    super(vue, `/traditional-deposits`);
    this.errorName = 'InternalTraditionalDepositApi';
  }

  put(id, data) {
    return super.put(`${id}/`, data, 'Failed to update traditional deposit');
  }

  list(query = '') {
    return super.get(`${query}`, 'Could not retrieve a list of deposits!');
  }
}
