export const ROUTES = {
  LANDLORD_OFFER_LIST_PAGE: 'ops-landlord-offer-list',
  LANDLORD_OFFER_DETAIL_PAGE: 'ops-landlord-offer-detail'
};

const landlordOfferList = {
  path: '/ops/landlord-offer',
  component: () => import('@/pages/ops/landlord_offer/list'),
  name: ROUTES.LANDLORD_OFFER_LIST_PAGE
};

const landlordOfferDetail = {
  path: '/ops/landlord-offer/:offerId',
  component: () => import('@/pages/ops/landlord_offer/detail'),
  name: ROUTES.LANDLORD_OFFER_DETAIL_PAGE
};

export default [landlordOfferList, landlordOfferDetail];
