import BaseModel from '../base';
import check from 'check-types';
import FlatbondTenantModel from '@/models/tenant';
import { UserModel } from '@/models/user';

export const REFERENCING_RESULTS = {
  APPROVED: 'approved',
  REJECTED: 'rejected'
};

export const MAX_NUMBER_GUARANTORS = 4;
export const MIN_NUMBER_GUARANTORS = 1;
export const EXTENDED_MAX_GUARANTORS = 10;

export const REFERENCING_RESULT_OPTIONS = [
  {
    label: 'draftFlatbond.guarantor.referencingResultOptions.approved',
    value: REFERENCING_RESULTS.APPROVED
  },
  {
    label: 'draftFlatbond.guarantor.referencingResultOptions.rejected',
    value: REFERENCING_RESULTS.REJECTED
  }
];

export const mapTranslatedOptions = $t => ({ label, value }) => ({ label: $t(label), value });

export default class GuarantorModel extends BaseModel {
  constructor(data) {
    super(data);
    if (this.phoneNumber === null) {
      this.phoneNumber = '';
    }
    this.user = this.user ? new UserModel(this.user) : null;
    this.tenants = this.tenants.map(tenant => new FlatbondTenantModel(tenant));
  }

  defaults() {
    return {
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      phoneNumber: '',
      newReferencingDocument: null,
      referencingResult: '',
      guarantorFor: [],
      hasCompletedFlow: false,
      hasProvidedPaymentDetails: false,
      tenants: [],
      invalidPhoneNumber: false,
      saving: false,
      has20FlowEnabled: false,
      invitedOn: null,
      hasSignedUp: false
    };
  }

  displayName() {
    return `${this.firstName} ${this.lastName}`;
  }

  displayReferencingResult($t) {
    if (!this.referencingResult) return this.referencingResult;

    const option = REFERENCING_RESULT_OPTIONS.find(opt => opt.value === this.referencingResult);

    return $t(option.label) || this.referencingResult;
  }

  displayTenantNames(tenants) {
    return tenants
      .filter(tenant => this.guarantorFor.indexOf(tenant.email) >= 0)
      .map(tenant => tenant.fullName)
      .join(', ');
  }

  toFlatpieModel() {
    const result = super.toFlatpieModel();

    if (check.object(this.newReferencingDocument)) {
      result['new_referencing_document'] = this.newReferencingDocument.id;
    }

    return result;
  }
}

export * from './confirmation';
