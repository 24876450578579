<template>
  <div>
    <WalkthroughCoordinator
      v-if="walkthroughSteps.length"
      :steps="walkthroughSteps"
      call-final-action-on-skip
    />
    <FpVerticalNavBar
      v-if="shouldUseVerticalNavbar"
      :inverted="isInvertedColour"
      :logo="isInvertedColour ? logoWhite : logo"
      :organization-options="organizationOptions"
      :role.sync="role"
      :full-width="isAdmin"
      :role-options="roleOptions"
      :organization.sync="organizationId"
      :navigation-items="verticalNavbarItems"
      :alert="alert"
      :profile-options="profileOptions"
      :profile="profile"
      :show-notification-icon="showNotificationIcon"
      :count="count"
      @notifications-link-click="handleNotificationsLinkClick"
      @profile-option-click="handleProfileOptionClick"
      @update-organization="switchOrganization"
      @role-option-click="handleRoleOptionClick"
    />
    <FpHorizontalNavBar
      v-else
      :inverted="isInvertedColour"
      show-navigation-items-on-desktop
      :logo="isInvertedColour ? logoWhite : logo"
      :navigation-items="horizontalNavbarPrimaryItems"
      :secondary-items="horizontalNavbarSecondaryItems"
      :alert="alert"
      :role.sync="role"
      :role-options="roleOptions"
      :profile-options="profileOptions"
      :profile="profile"
      :show-notification-icon="showNotificationIcon"
      :count="count"
      @profile-option-click="handleProfileOptionClick"
      @help-option-click="handleHelpOptionClick"
      @role-option-click="handleRoleOptionClick"
    />
    <div id="navbar-content-container" :style="cssVars">
      <NavDemo v-if="isDemoAndViewingAsAgent" />
      <slot />
    </div>
  </div>
</template>

<script>
import { PropertyApplicationApi, NotificationsApi } from '@/libs/apis';
import { FpHorizontalNavBar, FpVerticalNavBar, IconCode } from '@flatfair/vue-fairplay';
import { MEDIUM_BREAK_POINT } from '@flatfair/vue-fairplay/atoms';
import config, { FEATURE_FLAGS } from '@/libs/utils/config';
import navConfig from './config';
import navItems from './nav_items';
import { UserAliasApi, UserStateApi } from '@/libs/apis/user';
import { settingsRoute } from '@/libs/utils/helpers';
import axios from 'axios';
import get from 'lodash/get';
import WalkthroughCoordinator from '@/components/walkthrough_coordinator';
import * as trackEvent from '@/libs/utils/track_event';
import NavDemo from '@/components/nav_container/nav_demo';
import { USER_TYPES } from '@/libs/constants/generatedConstants';
import flatfairLogoBlue from '@/images/logos/flatfair_logo_blue.png';
import flatfairLogoWhite from '@/images/logos/flatfair_white_with_white_house.png';

const SECONDARY_ITEMS_OBJECT_KEY = 'help';

export default {
  name: 'NavContainer',

  components: {
    FpVerticalNavBar,
    WalkthroughCoordinator,
    NavDemo,
    FpHorizontalNavBar
  },

  data: function() {
    return {
      marginLeftContent: 0,
      supportButtonUnsubscribe: null,
      active: false,
      count: 0
    };
  },

  computed: {
    isInvertedColour() {
      return config.viewingAs !== USER_TYPES.FLATFAIR_ADMIN;
    },
    isAdmin() {
      return config.viewingAs === USER_TYPES.FLATFAIR_ADMIN;
    },
    cssVars() {
      return {
        '--marginLeftContent': this.marginLeftContent
      };
    },
    shouldUseVerticalNavbar() {
      return (
        config.viewingAs === USER_TYPES.AGENT || config.viewingAs === USER_TYPES.FLATFAIR_ADMIN
      );
    },
    alert() {
      if (this.$browserDetect.isIE) {
        return this.$t('nav.ie.noSupportAlert');
      }

      return '';
    },
    showNotificationIcon() {
      return (
        config.viewingAs === USER_TYPES.AGENT && config.featureEnabled(FEATURE_FLAGS.NOTIFICATIONS)
      );
    },
    organizationId: {
      get() {
        return get(this, 'config.user.currentOrganizationUser.id', null);
      },

      set(id) {
        this.switchOrganization(id);
      }
    },
    role: {
      get() {
        return config.viewingAs;
      }
    },

    verticalNavbarItems() {
      for (const navSection in navItems) {
        navItems[navSection].label = this.$t(navItems[navSection].label);
        navItems[navSection].items = navItems[navSection].items
          .filter(item => item.isEnabledForContext(this.config))
          .map(link => link.withTranslations(this.$t));

        if (navItems[navSection].items.length === 0) {
          delete navItems[navSection];
        }
      }

      return navItems;
    },

    horizontalNavbarPrimaryItems() {
      const horizontalPrimaryNavItems = { ...navItems };
      delete horizontalPrimaryNavItems[SECONDARY_ITEMS_OBJECT_KEY];

      for (const navSection in horizontalPrimaryNavItems) {
        horizontalPrimaryNavItems[navSection].label = this.$t(
          horizontalPrimaryNavItems[navSection].label
        );
        horizontalPrimaryNavItems[navSection].items = horizontalPrimaryNavItems[navSection].items
          .filter(item => item.isEnabledForContext(this.config))
          .map(link => link.withTranslations(this.$t));

        if (horizontalPrimaryNavItems[navSection].items.length === 0) {
          delete horizontalPrimaryNavItems[navSection];
        }
      }

      return horizontalPrimaryNavItems;
    },

    horizontalNavbarSecondaryItems() {
      const horizontalSecondaryNavItems = { ...navItems };

      horizontalSecondaryNavItems[SECONDARY_ITEMS_OBJECT_KEY].label = this.$t(
        horizontalSecondaryNavItems[SECONDARY_ITEMS_OBJECT_KEY].label
      );
      horizontalSecondaryNavItems[SECONDARY_ITEMS_OBJECT_KEY].items = horizontalSecondaryNavItems[
        SECONDARY_ITEMS_OBJECT_KEY
      ].items
        .filter(item => item.isEnabledForContext(this.config))
        .map(link => link.withTranslations(this.$t));

      if (horizontalSecondaryNavItems[SECONDARY_ITEMS_OBJECT_KEY].items.length === 0) {
        return {};
      }

      return {
        [SECONDARY_ITEMS_OBJECT_KEY]: horizontalSecondaryNavItems[SECONDARY_ITEMS_OBJECT_KEY]
      };
    },

    primaryLinks() {
      return {
        label: this.$t('nav.linksHeading.main'),
        items: navConfig.primaryLinks
          .filter(link => link.isEnabledForContext(this.config))
          .map(link => link.withTranslations(this.$t))
      };
    },

    secondaryLinks() {
      return navConfig.secondaryLinks
        .filter(link => link.isEnabledForContext(this.config))
        .map(link => link.withTranslations(this.$t));
    },

    profileOptions() {
      const options = [];

      options.push({
        label: this.$t('nav.links.settings'),
        value: 'settings',
        iconCode: IconCode.gear
      });

      options.push({
        label: this.$t('nav.profile.options.logout'),
        value: 'logout',
        iconCode: IconCode.arrowRightFromBracket
      });

      return options;
    },

    organizationOptions() {
      return get(this, 'config.user.organizationUsers', []).map(org => ({
        label: org.groupName,
        value: org.id
      }));
    },

    roleOptions() {
      const options = get(this, 'config.user.canViewAs', []).map(role => ({
        label: this.$t(`nav.profile.options.${role}`),
        value: role
      }));
      return options;
    },

    logoWhite() {
      return {
        label: this.$t('nav.logo.label'),
        href: '/',
        alt: this.$t('nav.logo.imgAlt'),
        img: flatfairLogoWhite
      };
    },

    logo() {
      return {
        label: this.$t('nav.logo.label'),
        href: '/',
        alt: this.$t('nav.logo.imgAlt'),
        img: flatfairLogoBlue
      };
    },

    profile() {
      const { fullName, firstName, lastName } = this.config.user || {
        fullName: '',
        firstName: '',
        lastName: ''
      };
      const mapToInitial = word => {
        const match = word.match(/^\w/);
        return match ? match[0] : '';
      };

      const initials = [firstName, lastName]
        .filter(Boolean)
        .map(mapToInitial)
        .join(' ')
        .trim();

      return {
        alt: this.$t('nav.profile.imgAlt'),
        img: initials
          ? `https://eu.ui-avatars.com/api/?name=${initials}&background=f4f4f4&color=3c4155&size=285`
          : null,
        name: fullName.trim()
      };
    },
    isDemoAndViewingAsAgent() {
      return config.isDemo && config.viewingAs === USER_TYPES.AGENT;
    }
  },

  mounted() {
    const elWidth = document.getElementById('aside');
    if (elWidth) {
      this.marginLeftContent = elWidth.clientWidth + 'px';
    }
    this.fetchNotificationCount();
    const root = document.getElementById('nav-container-app');
    if (root) root.classList.remove('navbar-placeholder');
  },
  created() {
    this.IconCode = IconCode;
    this.propertyApplicationApi = new PropertyApplicationApi();
    let mediaQuery = window.matchMedia(`(min-width: ${MEDIUM_BREAK_POINT}em)`);

    this.userAliasApi = new UserAliasApi(this);
    this.notificationsApi = new NotificationsApi();
    this.config = config;
    this.trackEvent = trackEvent;

    this.walkthroughSteps = [];
    if (
      get(this, 'config.user.state.hasToSeeNewVerticalNavbarWalkThrough', false) &&
      mediaQuery.matches
    ) {
      this.walkthroughSteps = [
        {
          querySelector: '[data-testid="button__button"][href="/agent/"]',
          headerLabel: 'walkthrough.navbar.newVerticalNavbar.1.header',
          bodyLabel: 'walkthrough.navbar.newVerticalNavbar.1.body',
          finalAction: this.dismissNewVerticalNavbarWalkThrough.bind(this)
        },
        {
          querySelector: '[data-testid="button__button"][alt="notification"]',
          headerLabel: 'walkthrough.navbar.newVerticalNavbar.5.header',
          bodyLabel: 'walkthrough.navbar.newVerticalNavbar.5.body',
          finalAction: this.dismissNewVerticalNavbarWalkThrough.bind(this)
        },
        {
          querySelector: '[data-testid="button__button"][href="/agent/plans"]',
          headerLabel: 'walkthrough.navbar.newVerticalNavbar.2.header',
          bodyLabel: 'walkthrough.navbar.newVerticalNavbar.2.body',
          finalAction: this.dismissNewVerticalNavbarWalkThrough.bind(this)
        },
        {
          querySelector: '[data-testid="button__button"][href="/agent/materials"]',
          headerLabel: 'walkthrough.navbar.newVerticalNavbar.3.header',
          bodyLabel: 'walkthrough.navbar.newVerticalNavbar.3.body',
          finalAction: this.dismissNewVerticalNavbarWalkThrough.bind(this)
        },
        {
          querySelector: '[data-testid="button__button"][href="/agent/learn/training-videos"]',
          headerLabel: 'walkthrough.navbar.newVerticalNavbar.4.header',
          bodyLabel: 'walkthrough.navbar.newVerticalNavbar.4.body',
          finalAction: this.dismissNewVerticalNavbarWalkThrough.bind(this)
        }
      ];
    }
    if (
      get(this, 'config.user.state.hasToSeeTenantUpsellWalkThrough', false) &&
      mediaQuery.matches
    ) {
      this.walkthroughSteps.push({
        querySelector: '[data-testid="button__button"][href="/agent/tenants"]',
        headerLabel: 'walkthrough.navbar.newVerticalNavbar.6.header',
        bodyLabel: 'walkthrough.navbar.newVerticalNavbar.6.body',
        finalAction: this.dismissNewVerticalNavbarWalkThrough.bind(this)
      });
    }
    if (
      get(this, 'config.hasFlatfairDepositsEnabled', false) &&
      !get(this, 'config.user.state.hasSeenFlatfairDepositsWalkthrough', true) &&
      mediaQuery.matches
    ) {
      this.walkthroughSteps.push({
        querySelector: '[data-testid="button__button"][href="/agent/plans"]',
        headerLabel: 'walkthrough.navbar.newVerticalNavbar.7.header',
        bodyLabel: 'walkthrough.navbar.newVerticalNavbar.7.body',
        finalAction: this.dismissNewVerticalNavbarWalkThrough.bind(this)
      });
    }
  },

  methods: {
    dismissNewVerticalNavbarWalkThrough() {
      try {
        this.trackEvent.button({ label: 'New navbar walk through - got it' });
        const userStateApi = new UserStateApi(this);
        const data = config.user.state;
        data.hasToSeeNewVerticalNavbarWalkThrough = false;
        data.hasToSeeTenantUpsellWalkThrough = false;
        data.hasSeenFlatfairDepositsWalkthrough = true;
        userStateApi.update(config.user.state.id, data.toFlatpieModel());
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },

    handleHelpOptionClick(href) {
      window.open(href, '_blank');
    },

    handleNotificationsLinkClick() {
      window.location = '/agent/notifications/';
    },

    handleProfileOptionClick(value) {
      switch (value) {
        case 'logout':
          this.logout();
          break;

        case 'settings':
          window.location = settingsRoute();
          break;

        default:
          this._viewAs(value);
          break;
      }
    },
    handleRoleOptionClick(value) {
      this._viewAs(value);
    },
    async _viewAs(alias) {
      try {
        await this.userAliasApi.viewAs(alias);
        this.$nextTick(() => (window.location = '/'));
      } catch (error) {
        // todo - implement error handling (edge case)
        // eslint-disable-next-line no-console
        console.error(error.response.data);
      }
    },
    async fetchNotificationCount() {
      if (!config.featureEnabled(FEATURE_FLAGS.NOTIFICATIONS)) {
        this.count = 0;
        return;
      }
      try {
        const data = await this.notificationsApi.count();
        if (data.unseenNotificationsCount) {
          this.count = data.unseenNotificationsCount;
        }
      } catch (error) {
        this.count = 0;
      }
    },
    async logout() {
      window.location = '/accounts/logout/';
    },

    async switchOrganization(id) {
      try {
        const data = new FormData();
        data.append('organization_user_id', id);
        data.append('csrfmiddlewaretoken', this.config.csrfToken);
        await axios.post('/organization/changeCurrentUser/', data);
        window.location = '/';
      } catch (error) {
        window.location.reload();
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }
};
</script>
<style scoped>
#navbar-content-container {
  margin-left: var(--marginLeftContent);
}
@media (max-width: 1151px) {
  #navbar-content-container {
    margin-left: 0;
  }
}
</style>
