import BaseModel from '@/models/base';

class SimpleTenantModel extends BaseModel {
  defaults() {
    return {
      id: null,
      email: '',
      fullName: ''
    };
  }
}

export default class ChangeOfTenantsModel extends BaseModel {
  constructor(data) {
    super(data);

    this.newTenant = this.newTenant ? new SimpleTenantModel(this.newTenant) : null;
    this.oldTenant = this.oldTenant ? new SimpleTenantModel(this.oldTenant) : null;
  }
  defaults() {
    return {
      newTenant: null,
      oldTenant: null
    };
  }
}
