import VueRouter from 'vue-router';
import publicRoutes from '@/pages/public';
import agentRoutes from '@/pages/agent';
import landlordRoutes from '@/pages/landlord';
import opsRoutes from '@/pages/ops';
import guarantorRoutes from '@/pages/guarantor';
import tenantRoutes from '@/pages/tenant';
import signupRoutes from '@/pages/signup';
import roleRoutes from '@/pages/role';
import loginRoutes from '@/pages/login';
import settingsRoutes from '@/pages/common/settings';

export const ROUTE_404_NAME = '404';

export const routes = [
  ...publicRoutes,
  ...agentRoutes,
  ...landlordRoutes,
  ...opsRoutes,
  ...guarantorRoutes,
  ...tenantRoutes,
  ...signupRoutes,
  ...roleRoutes,
  ...loginRoutes,
  ...settingsRoutes,
  {
    path: '*',
    component: () => import('@/pages/404.vue')
  },
  {
    path: '/404',
    name: ROUTE_404_NAME,
    component: () => import('@/pages/404.vue')
  }
];

export default new VueRouter({
  mode: 'history',
  routes
});
