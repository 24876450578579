import { ApiBase } from '@/libs/apis/base';
import qs from 'querystring';
import ProspectiveLandlordModel from '@/models/landlord/prospective_landlord';

export class ProspectiveLandlordApi extends ApiBase {
  constructor(vue) {
    super(vue, 'landlord/prospective');
    this.errorName = 'ProspectiveLandlordApiError';
  }

  async list(page, pageSize, q, planType, interestType) {
    const params = {
      page,
      page_size: pageSize,
      search: q,
      plan_type: planType,
      interest_type: interestType
    };
    const { data } = await super.get(`?${qs.encode(params)}`);
    data.results = data.results.map(result => {
      return new ProspectiveLandlordModel(result);
    });
    return data;
  }

  async invite(email) {
    const body = { email };
    const { data } = await super.post('', body);
    return new ProspectiveLandlordModel(data);
  }

  async publicDetail(inviteId, email, groupId) {
    const params = { invite_id: inviteId, email, group_uuid: groupId };
    const { data } = await super.get(`public-detail/?${qs.encode(params)}`);
    return new ProspectiveLandlordModel(data);
  }

  async updateRent(inviteId, rent, rentPeriod) {
    const body = { invite_id: inviteId, rent, rent_period: rentPeriod };
    const { data } = await super.post('rent/', body);
    return new ProspectiveLandlordModel(data);
  }

  async updateFirstUpsellViewOn(inviteId) {
    const body = { invite_id: inviteId };
    const { data } = await super.post('first-upsell-view-on/', body);
    return new ProspectiveLandlordModel(data);
  }

  async updateNotInterestedReason(inviteId, notInterestedReason, otherReasonDescription) {
    const body = {
      invite_id: inviteId,
      not_interested_reason: notInterestedReason,
      not_interested_reason_description: otherReasonDescription
    };
    const { data } = await super.post('not-interested-reason/', body);
    return new ProspectiveLandlordModel(data);
  }

  async updateInterestInNoDeposit(inviteId, isInterested, email) {
    const body = { invite_id: inviteId, is_interested: isInterested, email };
    const { data } = await super.post('no-deposit-interest/', body);
    return new ProspectiveLandlordModel(data);
  }
}
