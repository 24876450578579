import config, { FEATURE_FLAGS } from '@/libs/utils/config';
import { closeFlatbondRoute } from './close/routes';
import { PRODUCT_TYPE_OPTIONS } from '@/models/flatbond_draft';

export const ROUTES = {
  SELECT_TYPE: 'SELECT_TYPE',
  SELECT_TENANCY_BOOST: 'SELECT_TENANCY_BOOST',

  CREATE_DRAFT: 'CREATE_DRAFT',
  CREATE_DRAFT_REFERENCING_ACCEPTANCE: 'CREATE_DRAFT_REFERENCING_ACCEPTANCE',
  CREATE_DRAFT_UPLOAD_AGREEMENT: 'CREATE_DRAFT_UPLOAD_AGREEMENT',
  CREATE_DRAFT_BASIC_DETAILS: 'CREATE_DRAFT_BASIC_DETAILS',
  CREATE_DRAFT_MANAGEMENT: 'CREATE_DRAFT_MANAGEMENT',
  CREATE_DRAFT_PLAN_PROTECTION: 'CREATE_DRAFT_PLAN_PROTECTION',

  UPDATE_DRAFT: 'UPDATE_DRAFT',
  UPDATE_DRAFT_UPLOAD_AGREEMENT: 'UPDATE_DRAFT_UPLOAD_AGREEMENT',
  UPDATE_DRAFT_BASIC_DETAILS: 'UPDATE_DRAFT_BASIC_DETAILS',
  UPDATE_DRAFT_MANAGEMENT: 'UPDATE_DRAFT_MANAGEMENT',
  UPDATE_DRAFT_TRADITIONAL_DEPOSIT_DETAILS: 'UPDATE_DRAFT_TRADITIONAL_DEPOSIT_DETAILS',
  UPDATE_DRAFT_TENANT: 'UPDATE_DRAFT_TENANT',
  DRAFT_TENANT_OVERVIEW: 'DRAFT_TENANT_OVERVIEW',
  ADD_DRAFT_TENANT: 'ADD_DRAFT_TENANT',
  UPDATE_DRAFT_LANDLORD: 'UPDATE_DRAFT_LANDLORD',
  INTERESTED_IN_BOOST_DRAFT_LANDLORD: 'INTERESTED_IN_BOOST_DRAFT_LANDLORD',
  UPDATE_DRAFT_GUARANTOR: 'UPDATE_DRAFT_GUARANTOR',
  DRAFT_GUARANTOR_OVERVIEW: 'DRAFT_GUARANTOR_OVERVIEW',
  ADD_DRAFT_GUARANTOR: 'ADD_DRAFT_GUARANTOR',

  FLATBONDS_REVIEW_DRAFT: 'FLATBONDS_REVIEW_DRAFT',
  FLATBONDS_SIGN_DRAFT_DOCUMENTS: 'FLATBONDS_SIGN_DRAFT_DOCUMENTS',

  FLATBONDS_SIGN_FLATBOND_DOCUMENTS: 'FLATBONDS_SIGN_FLATBOND_DOCUMENTS',

  TRADITIONAL_DEPOSIT_DETAIL: 'TRADITIONAL_DEPOSIT_DETAIL',
  PLAN_DETAILS: 'AGENT_PLAN_DETAILS',
  PLAN_DOCUMENTS: 'AGENT_PLAN_DOCUMENTS',
  CHARGES_DETAIL: 'CHARGES_DETAIL',
  CHARGES_PLAN_DETAIL: 'CHARGES_PLAN_DETAIL',
  CHARGES_PLAN_DOCUMENTS: 'CHARGES_PLAN_DOCUMENTS',

  EXTEND_FLATBOND: 'EXTEND_FLATBOND'
};

export const createSelectType = {
  name: ROUTES.SELECT_TYPE,
  path: '/agent/flatbonds/create-select-type',
  beforeEnter: (to, from, next) => {
    if (config.features.has(FEATURE_FLAGS.UNLOCK_DEPOSIT)) {
      next();
    } else {
      next({ name: ROUTES.SELECT_TENANCY_BOOST, query: to.query });
    }
  },
  component: () => {
    return import('@/pages/agent/flatbonds/create/select_tenancy_type');
  }
};

export const selectTenancyBoost = {
  name: ROUTES.SELECT_TENANCY_BOOST,
  path: '/agent/flatbonds/select-tenancy-type',
  component: () => import('@/pages/agent/flatbonds/create/select_tenancy_boost.vue')
};

export const createDraftRoute = {
  name: ROUTES.CREATE_DRAFT,
  path: `/agent/flatbonds/create`,
  component: () => import('@/pages/agent/flatbonds/create/create.vue')
};

export const reviewDraftRoute = {
  name: ROUTES.FLATBONDS_REVIEW_DRAFT,
  path: '/agent/flatbonds/draft/:draftId/review',
  component: () => import('@/pages/agent/flatbonds/create/review.vue')
};

export const signDraftDocumentsRoute = {
  name: ROUTES.FLATBONDS_SIGN_DRAFT_DOCUMENTS,
  path: '/agent/flatbonds/draft/:draftId/sign-documents',
  component: () => import('@/pages/agent/flatbonds/create/sign_draft_documents.vue'),
  props: true
};

export const selectPlanProtection = {
  name: ROUTES.CREATE_DRAFT_PLAN_PROTECTION,
  path: '/agent/flatbonds/draft/:draftId/protection',
  component: () => import('@/pages/agent/flatbonds/create/plan_protection'),
  props: true
};

export const signFlatbondDocumentsRoute = {
  name: ROUTES.FLATBONDS_SIGN_FLATBOND_DOCUMENTS,
  path: '/agent/flatbonds/:flatbondId/sign-documents',
  component: () => import('@/pages/agent/flatbonds/create/sign_flatbond_documents.vue')
};

const updateTotalStepsFunction = draft => (draft.traditionalDeposit ? 4 : 3);
const updateShouldSaveTraditionalDepositFunction = draft => !!draft.traditionalDeposit;
const updateShouldSaveNoDepositFunction = draft =>
  !updateShouldSaveTraditionalDepositFunction(draft);
const updateShouldSaveDraftLandlord = (draft, boostSettings) =>
  !config.features.has(FEATURE_FLAGS.FLATFAIR_2_0) ||
  !config.features.has(FEATURE_FLAGS.BOOST) ||
  !!draft.landlordInterestedInBoost ||
  (draft.productType === PRODUCT_TYPE_OPTIONS.FLATBOND && !boostSettings.noDepositBoostBillingType);
const updateShouldShowInterestedInBoostDraftLandlord = (draft, boostSettings) =>
  updateShouldSaveDraftLandlord(draft, boostSettings)
    ? ROUTES.FLATBONDS_REVIEW_DRAFT
    : ROUTES.INTERESTED_IN_BOOST_DRAFT_LANDLORD;

export const updateDraftRoute = {
  name: ROUTES.UPDATE_DRAFT,
  path: `/agent/flatbonds/draft/:draftId`,
  component: () => import('@/pages/agent/flatbonds/create/update.vue'),
  redirect: { name: ROUTES.FLATBONDS_REVIEW_DRAFT },
  children: [
    {
      name: ROUTES.UPDATE_DRAFT_UPLOAD_AGREEMENT,
      path: 'upload-agreement',
      component: () => import('@/components/draft_flatbond/create/upload_agreement'),
      meta: {
        PREV_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        NEXT_STEP: (_, __, route) =>
          route.query.fromReference || route.query.fromReviewDraft
            ? ROUTES.FLATBONDS_REVIEW_DRAFT
            : ROUTES.UPDATE_DRAFT_BASIC_DETAILS,
        TOTAL_STEPS: updateTotalStepsFunction,
        STEP_NUMBER: 1,
        SHOULD_SAVE: true
      }
    },
    {
      name: ROUTES.UPDATE_DRAFT_BASIC_DETAILS,
      path: 'rent',
      component: () => import('@/components/draft_flatbond/create/basic_details'),
      meta: {
        PREV_STEP: ROUTES.UPDATE_DRAFT_UPLOAD_AGREEMENT,
        NEXT_STEP: ROUTES.UPDATE_DRAFT_MANAGEMENT,
        TOTAL_STEPS: updateTotalStepsFunction,
        STEP_NUMBER: 2
      }
    },
    {
      name: ROUTES.UPDATE_DRAFT_MANAGEMENT,
      path: 'management',
      component: () => import('@/components/draft_flatbond/create/management_details'),
      meta: {
        PREV_STEP: ROUTES.UPDATE_DRAFT_BASIC_DETAILS,
        NEXT_STEP: draft =>
          draft.traditionalDeposit
            ? ROUTES.UPDATE_DRAFT_TRADITIONAL_DEPOSIT_DETAILS
            : ROUTES.FLATBONDS_REVIEW_DRAFT,
        TOTAL_STEPS: updateTotalStepsFunction,
        STEP_NUMBER: 3,
        SHOULD_SAVE: updateShouldSaveNoDepositFunction
      }
    },
    {
      name: ROUTES.UPDATE_DRAFT_TRADITIONAL_DEPOSIT_DETAILS,
      path: 'deposit',
      component: () => import('@/components/draft_flatbond/create/deposit_details'),
      meta: {
        PREV_STEP: ROUTES.UPDATE_DRAFT_MANAGEMENT,
        NEXT_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        TOTAL_STEPS: updateTotalStepsFunction,
        STEP_NUMBER: 4,
        SHOULD_SAVE: updateShouldSaveTraditionalDepositFunction
      }
    },
    {
      name: ROUTES.ADD_DRAFT_TENANT,
      path: 'tenant/add',
      component: () => import('@/pages/agent/flatbonds/create/tenant_add.vue'),
      meta: {
        PREV_STEP: ROUTES.DRAFT_TENANT_OVERVIEW,
        NEXT_STEP: ROUTES.DRAFT_TENANT_OVERVIEW
      }
    },
    {
      name: ROUTES.UPDATE_DRAFT_TENANT,
      path: 'tenant/:tenantNumber',
      component: () => import('@/pages/agent/flatbonds/create/tenant_update.vue'),
      meta: {
        PREV_STEP: ROUTES.DRAFT_TENANT_OVERVIEW,
        NEXT_STEP: (_, __, route) =>
          route.query.fromReviewDraft
            ? ROUTES.FLATBONDS_REVIEW_DRAFT
            : ROUTES.DRAFT_TENANT_OVERVIEW,
        SHOULD_SAVE: true
      }
    },
    {
      name: ROUTES.DRAFT_TENANT_OVERVIEW,
      path: 'tenant',
      component: () => import('@/components/draft_flatbond/tenant/overview.vue'),
      meta: {
        PREV_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        NEXT_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        SHOULD_SAVE: true
      }
    },
    {
      name: ROUTES.UPDATE_DRAFT_LANDLORD,
      path: 'landlord',
      component: () => import('@/components/draft_flatbond/landlord/landlord_details.vue'),
      meta: {
        PREV_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        NEXT_STEP: updateShouldShowInterestedInBoostDraftLandlord,
        SHOULD_SAVE: updateShouldSaveDraftLandlord
      }
    },
    {
      name: ROUTES.INTERESTED_IN_BOOST_DRAFT_LANDLORD,
      path: 'landlord/boost',
      component: () => import('@/components/draft_flatbond/landlord/landlord_boost_interest.vue'),
      beforeEnter: (to, from, next) => {
        if (!config.features.has(FEATURE_FLAGS.FLATFAIR_2_0)) {
          next({ name: ROUTES.FLATBONDS_REVIEW_DRAFT });
          return;
        }

        next();
      },
      meta: {
        PREV_STEP: ROUTES.UPDATE_DRAFT_LANDLORD,
        NEXT_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        SHOULD_SAVE: true
      }
    },
    {
      name: ROUTES.ADD_DRAFT_GUARANTOR,
      path: 'guarantor/add',
      component: () => import('@/pages/agent/flatbonds/create/guarantor_add.vue'),
      meta: {
        PREV_STEP: ROUTES.DRAFT_GUARANTOR_OVERVIEW,
        NEXT_STEP: ROUTES.DRAFT_GUARANTOR_OVERVIEW
      }
    },
    {
      name: ROUTES.UPDATE_DRAFT_GUARANTOR,
      path: 'guarantor/:guarantorNumber',
      component: () => import('@/pages/agent/flatbonds/create/guarantor_update.vue'),
      meta: {
        PREV_STEP: ROUTES.DRAFT_GUARANTOR_OVERVIEW,
        NEXT_STEP: (_, __, route) =>
          route.query.fromReviewDraft
            ? ROUTES.FLATBONDS_REVIEW_DRAFT
            : ROUTES.DRAFT_GUARANTOR_OVERVIEW,
        SHOULD_SAVE: true
      }
    },
    {
      name: ROUTES.DRAFT_GUARANTOR_OVERVIEW,
      path: 'guarantor',
      component: () => import('@/components/draft_flatbond/guarantor/overview.vue'),
      meta: {
        PREV_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        NEXT_STEP: ROUTES.FLATBONDS_REVIEW_DRAFT,
        SHOULD_SAVE: true
      }
    }
  ]
};

export const traditionalDepositDetail = {
  name: ROUTES.TRADITIONAL_DEPOSIT_DETAIL,
  path: `/agent/traditional-tenancy/:flatbondId`,
  component: () => import('@/pages/agent/flatbonds/traditional_deposit/detail.vue'),
  props: true
};

export const extendFlatbondFlow = {
  name: ROUTES.EXTEND_FLATBOND,
  path: `/agent/flatbonds/:flatbondId/extend`,
  component: () => import('@/pages/agent/flatbonds/extend/flow.vue'),
  props: true
};

export const chargesDetail = {
  path: '/agent/plan/:planId/charges',
  component: () => import('@/pages/common/charges/detail.vue'),
  props: true,
  children: [
    {
      name: ROUTES.CHARGES_DETAIL,
      path: '',
      component: () => import('@/components/plan/charges/detail'),
      props: true
    },
    {
      name: ROUTES.CHARGES_PLAN_DETAIL,
      path: 'plan',
      props: true,
      component: () => import('@/components/plan/charges/plan_details')
    },
    {
      name: ROUTES.CHARGES_PLAN_DOCUMENTS,
      path: 'documents',
      props: true,
      component: () => import('@/components/plan/charges/documents')
    }
  ]
};

export const planPage = {
  path: '/agent/plan/:planId',
  component: () => import('@/pages/common/plan/tabs'),
  props: true,
  children: [
    {
      name: ROUTES.PLAN_DETAILS,
      path: '',
      component: () => import('@/pages/common/plan/details_tab.vue'),
      props: true
    },
    {
      name: ROUTES.PLAN_DOCUMENTS,
      path: '/agent/plan/:planId/documents',
      component: () => import('@/pages/common/plan/documents_tab.vue'),
      props: true
    }
  ]
};

export default [
  createSelectType,
  selectTenancyBoost,
  createDraftRoute,
  extendFlatbondFlow,
  reviewDraftRoute,
  updateDraftRoute,
  signDraftDocumentsRoute,
  selectPlanProtection,
  signFlatbondDocumentsRoute,
  traditionalDepositDetail,
  chargesDetail,
  closeFlatbondRoute,
  planPage
];
