var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.steps.length && _vm.isEnabled && _vm.targetElement
    ? _c(
        "FpDropdown",
        {
          attrs: {
            id: "walkthroughTooltip",
            "is-open": _vm.isDropdownOpen,
            "for-element": _vm.targetElement,
            role: "tooltip"
          }
        },
        [
          _c("FpHeader3", { attrs: { "margin-bottom": 1 } }, [
            _vm._v(_vm._s(_vm.headingText))
          ]),
          _vm._v(" "),
          _c("FpBody", { attrs: { "margin-bottom": 2 } }, [
            _vm._v(_vm._s(_vm.bodyText))
          ]),
          _vm._v(" "),
          _c(
            "FpFlex",
            { attrs: { "align-items": _vm.FlexAlignItems.center } },
            [
              _c(
                "FpFlexItem",
                [
                  _c("FpButton", { on: { click: _vm.next } }, [
                    _vm._v(_vm._s(_vm.nextButtonText))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FpFlexItem",
                [
                  _vm.displaySkipButton
                    ? _c(
                        "FpLink",
                        { attrs: { as: "button" }, on: { click: _vm.skip } },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.skipButtonText) +
                              "\n      "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }