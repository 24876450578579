import {
  FlexDirection,
  FlexJustifyContent,
  FlexAlignItems,
  FlexSpaceAround
} from '@flatfair/vue-fairplay/';

export default {
  created() {
    this.FlexDirection = FlexDirection;
    this.FlexJustifyContent = FlexJustifyContent;
    this.FlexAlignItems = FlexAlignItems;
    this.FlexSpaceAround = FlexSpaceAround;
  }
};
