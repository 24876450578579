var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FpContainer",
    { attrs: { grow: "" } },
    [
      _c(
        "FpFlex",
        {
          staticStyle: { height: "100%" },
          attrs: {
            compact: "",
            "justify-content": _vm.FlexJustifyContent.spaceBetween,
            direction: _vm.FlexDirection.column
          }
        },
        [
          _vm.loading
            ? [
                _c(
                  "FpFlexItem",
                  [
                    _c(
                      "FpFlexGrid",
                      { attrs: { compact: "", "space-around": "" } },
                      [
                        _c("FpFlexGridItem", [_c("FpPlaceholder")], 1),
                        _vm._v(" "),
                        _c("FpFlexGridItem", [_c("FpPlaceholder")], 1),
                        _vm._v(" "),
                        _c("FpFlexGridItem", [_c("FpPlaceholder")], 1)
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : [
                _c(
                  "FpFlexItem",
                  { attrs: { grow: "" } },
                  [
                    _c(
                      "FpFlexGrid",
                      [
                        _c(
                          "FpFlexGridItem",
                          {
                            style: { height: "235px" },
                            attrs: { id: "player" }
                          },
                          [
                            _c("VuePlyr", { ref: "plyr" }, [
                              _c("video", [
                                _c("source", {
                                  attrs: {
                                    size: "720",
                                    src: _vm.video.url,
                                    type: "video/mp4"
                                  }
                                })
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "FpFlexGrid",
                      { attrs: { compact: "", "space-around": "" } },
                      [
                        _c(
                          "FpFlexGridItem",
                          [_c("FpHeader3", [_vm._v(_vm._s(_vm.title))])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }