import BaseModel from '../base';
import { AddressModel } from '@/models/user';
import PhoneNumber from 'awesome-phonenumber';
import check from 'check-types';

export default class TenantContactModel extends BaseModel {
  constructor(data) {
    super(data);

    this.address = this.address ? new AddressModel(this.address) : null;
    this.manualAddressMode = !!this.address;
  }

  defaults() {
    return {
      tenantId: null,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      invalidPhoneNumber: false,
      address: null
    };
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  toFlatpieModel() {
    const data = super.toFlatpieModel();

    const instance = PhoneNumber(this.phoneNumber);
    if (!instance.getNumber('significant')) {
      data['phone_number'] = '';
    }
    if (check.object(this.newReferencingDocument)) {
      data['new_referencing_document_id'] = this.newReferencingDocument.id;
    }

    return data;
  }
}
