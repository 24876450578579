export class FlatfairError {
  constructor(error, message = null) {
    this.error = error;
    this.message = message;
  }

  getMessages() {
    let messages = [];

    if (this.message && this.message.length > 0) {
      messages.push(this.message);
    }
    if (this.error) {
      if (this.error.response && Array.isArray(this.error.response.data)) {
        messages = messages.concat(this.error.response.data);
      } else if (this.error.response && this.error.response.data) {
        Object.keys(this.error.response.data).forEach(key => {
          messages = messages.concat(this.error.response.data[key]);
        });
      }

      if (!messages && this.error.message && this.error.message.length > 0) {
        messages.push(this.error.message);
      }
    }

    return messages;
  }
}
