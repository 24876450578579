import _snakeCase from 'lodash/snakeCase';
import _camelCase from 'lodash/camelCase';
import _toPairs from 'lodash/toPairs';
import _fromPairs from 'lodash/fromPairs';
import check from 'check-types';

function mapper({ obj, caseFunc }) {
  check.assert.object(obj, 'obj');
  const map = ([key, val]) => [caseFunc(key), val];
  return _fromPairs(_toPairs(obj).map(map));
}

export function snakeCaseKeys(obj) {
  return mapper({ obj, caseFunc: _snakeCase });
}

export function camelCaseKeys(obj) {
  return mapper({ obj, caseFunc: _camelCase });
}
