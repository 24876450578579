import BaseModel from '../base';

export default class TrainingStatusModel extends BaseModel {
  defaults() {
    return {
      displayTraining: true,
      branchSelectionComplete: false,
      videoComplete: false,
      quizComplete: false,
      trainingComplete: false,
      organizationHasEmailDomains: false
    };
  }
}
