import BaseModel from '../base';
import DocumentModel from '@/models/document';
import check from 'check-types';

export default class UnpaidRentModel extends BaseModel {
  constructor(data) {
    super(data);
    this.rentSchedule = this.parseDocument(this.rentSchedule);
    this.additionalDocument = this.parseDocument(this.additionalDocument);
  }

  parseDocument(data) {
    if (check.object(data)) {
      return new DocumentModel(data);
    }

    if (check.number(data)) {
      return new DocumentModel({ id: data });
    }

    return null;
  }

  defaults() {
    return {
      unpaidRentAmount: null,
      rentDefaultStartedOn: '',
      rentSchedule: null,
      additionalDocument: null,
      hasBecomeStatutory: null,
      isUnpaidRentInLieuOfNotice: null,
      rentArrearsDetails: null
    };
  }

  toFlatpieModel() {
    const result = super.toFlatpieModel();
    result.rent_schedule_id = this.rentSchedule ? this.rentSchedule.id : null;
    result.additional_document_id = this.additionalDocument ? this.additionalDocument.id : null;
    return result;
  }
}
