import { InternalFlatbondApiBase } from './base';

export class InternalFlatbondAdministratorApi extends InternalFlatbondApiBase {
  list(flatbondId) {
    return super.get(
      `administrator/?flatbond__id=${flatbondId}&page_size=50`,
      'Failed to list the flatbond managers'
    );
  }

  updateFlatbondAdministrator(flatbondId, organizationUserId) {
    const payload = {
      flatbond_id: flatbondId,
      organization_user_id: organizationUserId
    };

    return super.put(
      `administrator/update-flatbond-administrator/`,
      payload,
      'Failed to update the manager for this flatbond'
    );
  }

  updateAllFromSameCurrentAdministrator(flatbondId, organizationUserId) {
    const payload = {
      flatbond_id: flatbondId,
      organization_user_id: organizationUserId
    };

    return super.put(
      `administrator/update-all-from-same-current-administrator/`,
      payload,
      'Failed to update the manager for all flatbonds from the same current administrator'
    );
  }

  updateAllFromSameBranch(flatbondId, organizationUserId) {
    const payload = {
      flatbond_id: flatbondId,
      organization_user_id: organizationUserId
    };

    return super.put(
      `administrator/update-all-from-same-branch/`,
      payload,
      'Failed to update the manager for all flatbonds from the same branch'
    );
  }
}
