import BaseModel from '@/models/base';

export class GuarantorConfirmationModel extends BaseModel {
  defaults() {
    return {
      id: null,
      tenantNotPaidDeposit: false,
      tenantMustPayAtEndOfTenancy: false,
      guarantorPotentiallyResponsibleForPaying: false
    };
  }
}
