export const DOCUMENT_AST = 'assured_shorthold_tenancy';
export const DOCUMENT_CHECK_IN = 'check_in';
export const DOCUMENT_CHECK_OUT = 'check_out';

export const SHOULD_ACCEPT_TERMS_ON_SELECT_UNIT_STEP = true;

export const PRODUCT_STATUS_CANCEL = 'cancelled';

export const PRODUCT_TAGS = [
  'Landlord needs to sign',
  'Pending new AST',
  'Tenants paid to agent',
  'Tenants moved in without paying',
  "Tenants didn't pass referencing",
  "Tenants didn't move in",
  'Tenants moving date unknown',
  'Notice served - tenants still in property',
  'Approved on behalf of the landlord',
  'Needs attention'
];
