export const ROUTES = {
  BASE: 'BASE'
};

export const base = {
  name: ROUTES.BASE,
  path: '/settings',
  component: () => import('@/pages/common/settings/base.vue')
};

export default [base];
