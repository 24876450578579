export const DEFAULT_TENANCY_TYPE_VALUE = 'ASSURED_SHORTHOLD';
export const DEFAULT_TENANCY_TYPE_LABEL = 'Assured Shorthold Tenancy';

export const DEFAULT_TENANT_TYPE = {
  label: DEFAULT_TENANCY_TYPE_LABEL,
  value: DEFAULT_TENANCY_TYPE_VALUE
};

export const FLATBOND_TYPES = {
  NEW_TENANCY: 'new_tenancy',
  CONVERSION: 'conversion'
};

export const FIRST_PAGE = 1;

export const PROSPECTIVE_TENANT_INTEREST_OPTIONS = {
  PENDING: 'pending',
  NOT_INTERESTED: 'not_interested',
  INTERESTED: 'interested'
};

export const DEPOSIT_TENANCY_TYPES = [DEFAULT_TENANT_TYPE];

export const DOCUMENT_AST = 'assured_shorthold_tenancy';
export const DOCUMENT_CHECK_IN = 'check_in';
export const DOCUMENT_CHECK_OUT = 'check_out';

export const SHOULD_ACCEPT_TERMS_ON_SELECT_UNIT_STEP = true;

export const UNKNOWN_NOTE_AUTHOR = 'unknown';

export const SENTRY_DSN = 'https://0a78cf40db02414ab8ab945259d2fe30@sentry.io/1837950';

export const ORGANIZATION_LEVEL_BRANCH = 'Branch';

export const ORGANIZATION_LEVEL_BRANCH_LABEL = 'Branch';
export const ORGANIZATION_LEVEL_AREA_LABEL = 'Area';

export const AVAILABLE_POSITIONS = ['Manager', 'Property Manager', 'Negotiator', 'Lister'];

export const MAX_NUMBER_OF_MONTHS_ALLOWED_TO_CREATE_FOR = 6;
export const MINIMUM_DURATION_IN_MONTHS = 6;
export const MAXIMUM_DURATION_IN_MONTHS = 24;
export const DEFAULT_DURATION_IN_MONTHS = MINIMUM_DURATION_IN_MONTHS;

export const SUBSCRIPTION_ONE_OFF_FEE = '£15';
export const RENT_TO_MONTHLY_FEE_RATIO = 0.05;

export const PRODUCT_NAME = 'flatfair';

export const PAY_TO_TYPES = {
  FLATFAIR: 'pay_to_flatfair',
  PARTNER: 'pay_to_partner'
};

export const PAYMENT_LOG_TYPES = {
  DEFAULT: 'default',
  WIPED_COMMISSION: 'wiped_commission'
};

export const CANCELATION_REASONS = [
  'Landlord (unknown)',
  'Landlord not happy with the T&Cs',
  'Landlord prefers a cash deposit',
  'Landlord concerns that it is not secure enough',
  'Tenant (unknown)',
  'Tenant too expensive',
  'Tenant prefer to pay a deposit (believe they will get it all back)',
  'Tenant prefer to put a deposit on Credit',
  'Tenant not happy with the T&Cs',
  "Tenant didn't pass referencing criteria",
  'Not a flatfair tenancy - created by mistake',
  'Duplicate flatbond',
  'Let fall-through',
  'Tenants unable to pay with separate cards',
  'Cannot Accept Shortfalls',
  'Other'
];

export const MYDEPOSITS_LOCAL_AUTHORITIES = {
  ABERDEEN_CITY: 'AberdeenCity',
  ABERDEENSHIRE: 'Aberdeenshire',
  ANGUS: 'Angus',
  ARGYLL_AND_BUTE: 'ArgyllAndBute',
  CLACKMANNANSHIRE: 'Clackmannanshire',
  DUMFRIES_AND_GALLOWAY: 'DumfriesAndGalloway',
  DUNDEE_CITY: 'DundeeCity',
  EAST_AYRSHIRE: 'EastAyrshire',
  EAST_DUNBARTONSHIRE: 'EastDunbartonshire',
  EAST_LOTHIAN: 'EastLothian',
  EAST_RENFREWSHIRE: 'EastRenfrewshire',
  EDINBURGH: 'Edinburgh',
  EILEAN_SIAR: 'EileanSiar',
  FALKIRK: 'Falkirk',
  FIFE: 'Fife',
  GLASGOW_CITY: 'GlasgowCity',
  HIGHLAND: 'Highland',
  INVERCLYDE: 'Inverclyde',
  MIDLOTHIAN: 'Midlothian',
  MORAY: 'Moray',
  NORTH_AYRSHIRE: 'NorthAyrshire',
  NORTH_LANARKSHIRE: 'NorthLanarkshire',
  ORKNEY_ISLANDS: 'OrkneyIslands',
  PERTH_AND_KINROSS: 'PerthAndKinross',
  RENFREWSHIRE: 'Renfrewshire',
  SCOTTISH_BORDERS: 'ScottishBorders',
  SHETLAND_ISLANDS: 'ShetlandIslands',
  SOUTH_AYRSHIRE: 'SouthAyrshire',
  SOUTH_LANARKSHIRE: 'SouthLanarkshire',
  STIRLING: 'Stirling',
  WEST_DUNBARTONSHIRE: 'WestDunbartonshire',
  WEST_LOTHIAN: 'WestLothian'
};

export const MYDEPOSITS_LOCAL_AUTHORITIES_OPTIONS = Object.entries(
  MYDEPOSITS_LOCAL_AUTHORITIES
  // eslint-disable-next-line no-unused-vars
).map(([_, value]) => {
  const label = value.split(/(?=[A-Z])/).join(' ');
  return {
    label: label,
    value: value
  };
});

export const PRODUCT_TAGS = [];

export const NON_EXISTING_CATEGORY = 'Unknown category';
export const NON_EXISTING_STATUS = 'Unknown status';

export const ALL = 'all';

export const CLAIM_CATEGORIES = [
  {
    value: 'other',
    label: 'Other',
    helpText: ''
  },
  {
    value: 'rent',
    label: 'Non payment of rent',
    helpText:
      'Please ensure you submit a statement of account for rental payments and ' +
      'any other relevant proof of payment e.g. bank account statements. Please also ensure ' +
      'you clearly set out your calculations and the reason for the arrears.'
  },
  {
    value: 'professional_cleaning',
    label: 'Cleaning',
    helpText:
      'Please provide evidence of the cleanliness of the property at both the start ' +
      'and end of the tenancy, as well as evidence of any professional cleaning carried out, ' +
      'e.g. invoices.'
  },
  {
    value: 'damage',
    label: 'Damage to property or items',
    helpText:
      'Please provide evidence of the condition of the property at both the start and ' +
      'end of the tenancy, as well as evidence of any dilapidations which occurred during the ' +
      'course of the tenancy. This could be inventory and check-out reports, digitally dated ' +
      'photographs and invoices/receipts in respect of any works carried out. Any relevant emails ' +
      'discussing the dilapidations would also assist your case.'
  },
  {
    value: 'gardening',
    label: 'Gardening',
    helpText:
      'Please ensure that you provide evidence of how the garden was presented at the ' +
      'start of the tenancy, as well as how it was left at the end. Remember, an allowance must ' +
      'always be made for seasonal changes.'
  },
  {
    value: 'bills',
    label: 'Outstanding utility bills',
    helpText:
      'Please provide copies of any relevant bills and proof of payment if possible. ' +
      "Remember, bills that are in the name of the Member are generally the Tenant's " +
      'responsibility to pay and can only be claimed for if the Landlord has paid on ' +
      'behalf of the Tenant.'
  },
  {
    value: 'contractors',
    label: "Contractors' fees for maintenance or repair of the property",
    helpText:
      'Please provide evidence of the works required, as well as any costs ' +
      'that have been incurred, e.g. invoices or receipts. Quotes can be useful, ' +
      'however, they will not serve as evidence of actual costs incurred or work carried out.'
  },
  {
    value: 'agency',
    label: 'Agency fees / Admin fees / Legal fees',
    helpText:
      'Please provide evidence of the fees being claimed and any relevant ' +
      'agreement showing liability for these.'
  }
];

export const CLAIM_STATUSES = [
  {
    value: 'created',
    label: 'Created',
    labelClass: 'success'
  },
  {
    value: 'canceled',
    label: 'Canceled',
    labelClass: 'neutral'
  },
  {
    value: 'sent',
    label: 'Sent',
    labelClass: 'success'
  },
  {
    value: 'in_review',
    label: 'In review',
    labelClass: 'warning'
  },
  {
    value: 'waived',
    label: 'Waived',
    labelClass: 'neutral'
  },
  {
    value: 'disputed',
    label: 'Disputed',
    labelClass: 'danger'
  },
  {
    value: 'adjudicated',
    label: 'Adjudicated',
    labelClass: 'warning'
  },
  {
    value: 'accepted',
    label: 'Accepted',
    labelClass: 'success'
  },
  {
    value: 'deemed_accepted',
    label: 'Deemed accepted',
    labelClass: 'success'
  },
  {
    value: 'countered',
    label: 'Counter',
    labelClass: 'warning'
  },
  {
    value: 'counter_accepted',
    label: 'Counter accepted',
    labelClass: 'success'
  },
  {
    value: 'counter_declined',
    label: 'Counter declined',
    labelClass: 'danger'
  },
  {
    value: 'paid',
    label: 'Paid',
    labelClass: 'success'
  }
];
export const PARTNER_SIGNUP_STATUS_COMPANY_VERIFICATION = 'pending_company_verification';
export const PARTNER_SIGNUP_STATUS_PENDING_WEBINAR = 'pending_webinar_registration';
export const DEFAULT_COMMISSION = 10;

export const FLATBOND_CLAIM_STATUS_CREATED = 'created';
export const FLATBOND_CLAIM_STATUS_PENDING_TENANT_RESPONSE = 'pending_tenant_response';
export const FLATBOND_CLAIM_STATUS_TO_BE_DEEMED_ACCEPTED = 'to_be_deemed_accepted';
export const FLATBOND_CLAIM_STATUS_IN_NEGOTIATION = 'in_negotiation';
export const FLATBOND_CLAIM_STATUS_DISPUTE_COOL_DOWN = 'dispute_cool_down';
export const FLATBOND_CLAIM_STATUS_DISPUTE_PENDING_DECISION = 'dispute_pending_decision';
export const FLATBOND_CLAIM_STATUS_DISPUTE_PENDING_DECISION_UPLOAD =
  'dispute_pending_decision_upload';

export const FLATBOND_CLAIM_STATUS_PENDING_PAYMENT = 'pending_payment';
export const FLATBOND_CLAIM_STATUS_TO_BE_CHARGED = 'to_be_charged';
export const FLATBOND_CLAIM_STATUS_PENDING_LANDLORD_OFFER = 'pending_landlord_offer';
export const FLATBOND_CLAIM_STATUS_LANDLORD_OFFER_SENT = 'landlord_offer_sent';
export const FLATBOND_CLAIM_STATUS_PENDING_DEBT_COLLECTION = 'pending_debt_collection';
export const FLATBOND_CLAIM_STATUS_IN_DEBT_COLLECTION = 'in_debt_collection';
export const FLATBOND_CLAIM_STATUS_CLOSED = 'closed';

export const FLATBOND_UPDATE_CATEGORY_TYPES = {
  TENANCY: 'tenancy',
  CASE: 'case',
  DEBT_COLLECTION: 'debt_collection'
};

export const REMINDER_CATEGORY_TYPES = {
  DEBT_COLLECTION: 'debt_collection',
  PLAN_CLOSED: 'plan_closed'
};

export const DEPOSIT_ENTITY_TYPES = {
  PRIVATE: 'private',
  COMPANY: 'company'
};

export const DEPOSIT_PERSON_TITLES = {
  Mx: 'Mx',
  MR: 'Mr',
  MRS: 'Mrs',
  MISS: 'Miss',
  MS: 'Ms',
  DR: 'Dr',
  LADY: 'Lady',
  LORD: 'Lord',
  PROFESSOR: 'Professor',
  SIR: 'Sir'
};

export const DEPOSIT_PROPERTY_TYPES = {
  HOUSE: 'HOUSE',
  FLAT: 'FLAT',
  MAISONETTE: 'MAISONETTE',
  ROOM: 'ROOM'
};

export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405
};

export const INPUT_TYPE = {
  EMAIL: 'email',
  MONEY: 'money',
  TEXT: 'text'
};

export const SORT_CODE_PART_LENGTH = 2;

export const GROUP_LIST_PAGE_SIZE = 50;

export const LANDLORD_TERMS_SLUG = 'landlord-terms';
export const LANDLORD_BROCHURE_SLUG = 'landlord-brochure';
export const TENANT_BROCHURE_SLUG = 'tenant-brochure';
export const TENANT_TERMS_SLUG = 'tenant-terms';
export const QUICKSTART_SLUG = 'quickstart';

export const MAILTO_CARRIAGE_RETURN = '%0D%0A';
export const MAILTO_BODY =
  'subject=Let%20me%20tell%20you%20about%20flatfair!&body=Hi%2C%0D%0A%0D%0AI%20wanted%20to%20let%20you%20know%20about%20flatfair%2C%20our%20deposit%20alternative%20partner.%20By%20choosing%20flatfair%20you%20can%20move%20into%20your%20next%20property%20with%20much%20lower%20upfront%20costs%20compared%20to%20a%20traditional%20deposit!%0D%0A%0D%0AHave%20a%20look%20for%20yourself%20by%20clicking%20the%20link%20below%3A%0D%0A';

export const PROSPECTIVE_LANDLORD_MAILTO_BODY =
  'subject=Let%20me%20tell%20you%20about%20flatfair!&body=Hi%20there!%0D%0A%0D%0AI%20wanted%20to%20let%20you%20know%20about%20flatfair%2C%20our%20deposit%20alternative%20partner.%20By%20choosing%20flatfair%2C%20you%20can%20potentially%20rent%20out%20your%20property%20and%20get%20increased%20protection%20compared%20to%20a%20traditional%20deposit!%0D%0A%0D%0AHave%20a%20look%20for%20yourself%20by%20clicking%20the%20link%20below%3A%0D%0A%0D%0A';
export const MSG_FILE_EXTENSION = '.msg';

export const UNACCEPTABLE_FILE_EXTENSIONS = [MSG_FILE_EXTENSION];

export const RENT_TYPES = {
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly'
};

export const INCOME_TYPES = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly'
};
