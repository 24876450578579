import BaseModel from '@/models/base';

export const OUTCOME_OPTIONS = {
  UNKNOWN: 'unknown',
  FELL_THROUGH: 'fell_through',
  TENANTS_TRADITION_DEPOSIT: 'tenants_traditional_deposit',
  LANDLORD_TRADITION_DEPOSIT: 'landlord_traditional_deposit',
  PLAN_ALREADY_CREATED: 'plan_already_created',
  ELIGIBILITY_NOT_MET: 'eligibility_not_met',
  PROGRESSED_AS_NORMAL: 'progressed_as_normal',
  GUARANTOR_WAS_PROVIDED: 'guarantor_was_provided',
  OTHER: 'other'
};

export class PropertyApplicationOutcomeModel extends BaseModel {
  defaults() {
    return {
      outcome: null,
      comments: ''
    };
  }
}

export class PropertyApplicationModel extends BaseModel {
  defaults() {
    return {};
  }
}

export class ConvertToPlanDraftModel extends BaseModel {
  defaults() {
    return { draftId: '' };
  }
}
