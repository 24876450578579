import { ApiBase } from '@/libs/apis/base';

export class PlanApi extends ApiBase {
  constructor(vue) {
    super(vue, 'plan', true);
    this.errorName = 'PlanApiError';
  }

  closeWithoutCharges(id) {
    return super.post(`${id}/close-without-charges/`);
  }

  productOptionSelected(id, productType) {
    return super.post(`${id}/product-option-selected/`, { product: productType });
  }

  update(id, data) {
    return super.put(`${id}/`, data);
  }

  get(id) {
    return super.get(`${id}/`);
  }

  addDocuments(planId, documentIds) {
    return super.post(`${planId}/add-documents/`, { document_ids: documentIds });
  }

  cancelPlan(id, reason) {
    return super.post(`${id}/cancel/`, { reason });
  }

  requestCancelPlan(id, reason) {
    return super.post(`${id}/request-cancellation/`, { reason });
  }

  downloadPrescribedInformation(id) {
    return super.get(`${id}/download-prescribed-information/`);
  }

  downloadTenantLeaflet(id) {
    return super.get(`${id}/download-tenant-leaflet/`);
  }

  downloadUnsignedPrescribedInformation(id) {
    return super.post(`${id}/download-unsigned-prescribed-information/`, {});
  }

  signPrescribedInformation(id) {
    return super.get(`${id}/sign-prescribed-information/`);
  }

  removeTenants(id, tenantEmails) {
    return super.post(`${id}/remove-tenants/`, { emails: tenantEmails });
  }

  changeTenants(id, ast, changes) {
    return super.post(`${id}/change-tenants/`, {
      ast_id: ast,
      changes: changes.map(c => c.toFlatpieModel())
    });
  }

  calculateMembershipFeeWithDiscount(weeklyRent, numberOfTenants) {
    const data = {
      rent: weeklyRent,
      number_of_tenants: numberOfTenants
    };
    return super.post(`calculate-membership-fee-with-discount/`, data);
  }

  calculateUnlockDepositBreakdown(weeklyRent, numberOfTenants) {
    const data = {
      rent: weeklyRent,
      number_of_tenants: numberOfTenants
    };
    return super.post(`calculate-unlock-deposit-breakdown/`, data);
  }

  downloadAsPdfLink(id) {
    return `${this.baseUrl}/${this.namespace}/${id}/download-as-pdf/`;
  }

  deleteDraft(id) {
    return super.delete(`draft/${id}`);
  }

  addNoDepositNotOfferedTag(id, reason, otherReasonDescription) {
    const data = {
      reason: reason,
      other_reason_description: otherReasonDescription
    };
    return super.post(`${id}/add-no-deposit-not-offered-tag/`, data);
  }

  addAlternativeDepositPayer(id, alternativePayer) {
    const data = {
      line_1: alternativePayer.address.address,
      line_2: alternativePayer.address.line2,
      city: alternativePayer.address.city,
      postcode: alternativePayer.address.postcode,
      country: alternativePayer.address.country,
      first_name: alternativePayer.firstName,
      last_name: alternativePayer.lastName,
      title: alternativePayer.title,
      email: alternativePayer.email
    };
    return super.post(
      `${id}/add-alternative-deposit-payer/`,
      data,
      'Failed to add alternative deposit payer'
    );
  }

  setIsTenantPayingDeposit(id, isTenantPaying) {
    const data = {
      is_tenant_paying: isTenantPaying
    };

    return super.post(
      `${id}/set-is-tenant-paying-deposit/`,
      data,
      'Failed to set if tenant is paying deposit'
    );
  }

  getAlternativeDepositPayerDetails(id) {
    return super.get(
      `${id}/get-alternative-deposit-payer-details/`,
      'Failed to get alternative deposit payer details'
    );
  }

  getLandlord(flatbondId) {
    return super.get(`${flatbondId}/get-flatbond-landlord/`);
  }

  updateFlatbondLandlordWithEmailAndDetails(flatbondId, landlord) {
    return super.put(
      `${flatbondId}/update-flatbond-landlord-with-email-and-details/`,
      landlord,
      'Failed to update landlord'
    );
  }

  updateFlatbondLandlordWithEmail(flatbondId, email) {
    return super.put(
      `${flatbondId}/update-flatbond-landlord-with-email/`,
      email,
      'Failed to update landlord'
    );
  }

  UpdateToLandlordUpsellStatus(planId) {
    return super.post(
      `${planId}/update-to-status-landlord-upsell/`,
      'failed to update flatbond status'
    );
  }

  cancelUpsellStatus(planId) {
    return super.post(`/${planId}/cancel-upsell-status/`, 'failed to cancel upsell status');
  }
}
