var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { backgroundColor: "#FFD601" } },
    [
      _c(
        "FpWrapper",
        [
          _c(
            "FpFlex",
            {
              attrs: {
                "space-around": "",
                compact: "",
                "justify-content": _vm.flexJustifyContent.spaceBetween
              }
            },
            [
              _c(
                "FpFlexItem",
                [
                  _c("FpHeader4", [
                    _vm._v(_vm._s(_vm.$t("common.demoAlert.textTitle")))
                  ]),
                  _vm._v(" "),
                  _c("FpBody", [
                    _vm._v(_vm._s(_vm.$t("common.demoAlert.textDescription")))
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "FpFlexItem",
                [
                  _c("FpDropdownButton", {
                    attrs: { open: _vm.actionsOpen },
                    on: {
                      toggle: function($event) {
                        _vm.actionsOpen = !_vm.actionsOpen
                      }
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "button",
                        fn: function() {
                          return [
                            _vm._v(
                              _vm._s(_vm.$t("common.demoAlert.actionButton"))
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "dropdown",
                        fn: function() {
                          return [
                            _c(
                              "FpDropdownButtonItem",
                              {
                                on: {
                                  click: _vm.createNoDepositReadyForEndOfTenancy
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "common.demoAlert.actions.createNoDepositReadyForEndOfTenancy"
                                    )
                                  )
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "FpDropdownButtonItem",
                              {
                                on: {
                                  click:
                                    _vm.createUnselectedReadyForTenantDecision
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "common.demoAlert.actions.createUnselectedReadyForTenantDecision"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "FpDropdownButtonItem",
                              { on: { click: _vm.createDraftPlan } },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "common.demoAlert.actions.createDraftPlan"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "FpDropdownButtonItem",
                              {
                                on: { click: _vm.createTDReadyForTenantAction }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "common.demoAlert.actions.createTDReadyForTenantAction"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _vm.hasPlan
                              ? _c(
                                  "FpDropdownButtonItem",
                                  { on: { click: _vm.activateDeposit } },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "common.demoAlert.actions.activateDeposit"
                                          )
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.canOfferReferencing
                              ? _c(
                                  "FpDropdownButtonItem",
                                  {
                                    on: { click: _vm.createCompletedReference }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "common.demoAlert.actions.createCompletedReference"
                                        )
                                      )
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.actionFeedbackModalVisible
            ? _c("ApiFeedbackModal", {
                attrs: {
                  resolved: _vm.actionResolved,
                  "resolved-with-error": _vm.actionResolvedWithError,
                  "header-on-success": _vm.successHeader,
                  "extra-message-on-success": _vm.successHeaderExtra,
                  "header-on-error": _vm.errorHeader,
                  "extra-success-action-label": _vm.extraSuccessActionLabel
                },
                on: {
                  close: _vm.closeActionFeedbackModal,
                  retry: _vm.retryLastAction,
                  extra: _vm.viewLastPlanCreated
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.createReferenceFeedbackModalVisible
            ? _c("ApiFeedbackModal", {
                attrs: {
                  resolved: _vm.actionResolved,
                  "resolved-with-error": _vm.actionResolvedWithError,
                  "header-on-success": _vm.$t(
                    "common.demoAlert.actions.createCompletedReference.successHeader"
                  ),
                  "extra-message-on-success": _vm.$t(
                    "common.demoAlert.actions.createCompletedReference.successExtra"
                  ),
                  "header-on-error": _vm.$t(
                    "common.demoAlert.actions.createCompletedReference.errorHeader"
                  ),
                  "extra-success-action-label": _vm.createdReferenceId
                    ? _vm.$t(
                        "common.demoAlert.actions.createCompletedReference.extraActionLabel"
                      )
                    : null
                },
                on: {
                  close: _vm.closeActionFeedbackModal,
                  retry: _vm.retryLastAction,
                  extra: _vm.viewLastReferenceCreated
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.createDraftPlanModalVisible
            ? _c("ApiFeedbackModal", {
                attrs: {
                  resolved: _vm.actionResolved,
                  "resolved-with-error": _vm.actionResolvedWithError,
                  "header-on-success": _vm.$t(
                    "common.demoAlert.actions.createDraftPlan.successHeader"
                  ),
                  "extra-message-on-success": _vm.$t(
                    "common.demoAlert.actions.createDraftPlan.successExtra"
                  ),
                  "header-on-error": _vm.$t(
                    "common.demoAlert.actions.createDraftPlan.errorHeader"
                  ),
                  "extra-success-action-label": _vm.createdDraftId
                    ? _vm.$t(
                        "common.demoAlert.actions.createDraftPlan.extraActionLabel"
                      )
                    : null
                },
                on: {
                  close: _vm.closeActionFeedbackModal,
                  retry: _vm.retryLastAction,
                  extra: _vm.viewLastDraftCreated
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }