import flatbondRoutes from '@/pages/agent/flatbonds';
import trainingRoutes, { ROUTES as TRAINING_ROUTES } from '@/pages/agent/training';
import onboardingRoutes from '@/pages/agent/onboarding_training';
import arrearsRoutes from '@/pages/agent/arrears';
import incentiveRoutes from '@/pages/agent/incentive';
import depositRoutes from '@/pages/agent/deposits';
import rewardsRoutes from '@/pages/agent/rewards';
import notificationsRoutes from '@/pages/agent/notifications';
import prospectiveTenantRoutes from '@/pages/agent/tenants';
import prospectiveLandlordRoutes from '@/pages/agent/landlords';
import { TABS } from '@/pages/agent/learn';
import config from '@/libs/utils/config';
import { USER_TYPES } from '@/libs/constants/generatedConstants';

export const ROUTES = {
  HOMEPAGE: 'AGENT_HOMEPAGE',
  PLAN_LIST: 'AGENT_PLAN_LIST',
  MISSING_REPORT: 'MISSING_REPORT',
  SCRATCH_CARD_EXPIRED: 'SCRATCH_CARD_EXPIRED',
  AGENT_SETTINGS: 'AGENT_SETTINGS',
  MATERIALS: 'AGENT_MATERIALS',
  LEARN: 'AGENT_LEARN',
  INFORMATION: 'AGENT_INFORMATION',
  LEARN_TRAINING_SESSIONS: 'AGENT_LEARN_TRAINING_SESSIONS',
  LEARN_TRAINING_VIDEOS: 'AGENT_LEARN_TRAINING_VIDEOS'
};

const REDIRECT_TO_AGENT_TRAINING_IF_NO_HOME_UNIT = async (to, from, next) => {
  if (config.viewingAs === USER_TYPES.AGENT && !config.user.currentOrganizationUser.homeUnit) {
    next({ name: TRAINING_ROUTES.WELCOME });
    return;
  }
  next();
};

export const homepage = {
  name: ROUTES.HOMEPAGE,
  path: '/agent',
  beforeEnter: REDIRECT_TO_AGENT_TRAINING_IF_NO_HOME_UNIT,
  component: () => import('@/pages/agent/homepage.vue')
};

export const materials = {
  name: ROUTES.MATERIALS,
  path: '/agent/materials',
  component: () => import('@/pages/agent/materials.vue')
};

export const information = {
  name: ROUTES.INFORMATION,
  path: '/agent/information',
  component: () => import('@/pages/agent/information.vue')
};

export const learn = {
  name: ROUTES.LEARN,
  path: '/agent/learn',
  component: () => import('@/pages/agent/learn.vue'),
  redirect: { name: ROUTES.LEARN_TRAINING_VIDEOS }
};

export const learnTrainingSessions = {
  name: ROUTES.LEARN_TRAINING_SESSIONS,
  path: '/agent/learn/training-sessions',

  component: () => import('@/pages/agent/learn.vue'),
  props: {
    defaultTabSelected: TABS.TRAINING_SESSIONS
  }
};

export const learnTrainingVideos = {
  name: ROUTES.LEARN_TRAINING_VIDEOS,
  path: '/agent/learn/training-videos',
  component: () => import('@/pages/agent/learn.vue'),
  props: {
    defaultTabSelected: TABS.TRAINING_VIDEOS
  }
};

export const planList = {
  name: ROUTES.PLAN_LIST,
  path: '/agent/plans',
  component: () => import('@/pages/agent/agent_plan_dashboard.vue')
};

export const agentSettings = {
  name: ROUTES.AGENT_SETTINGS,
  path: '/agent/settings',
  component: () => import('@/pages/common/settings/base.vue')
};

export default [
  ...flatbondRoutes,
  ...trainingRoutes,
  ...onboardingRoutes,
  ...arrearsRoutes,
  ...depositRoutes,
  homepage,
  planList,
  materials,
  information,
  learn,
  learnTrainingSessions,
  learnTrainingVideos,
  agentSettings,
  ...incentiveRoutes,
  ...rewardsRoutes,
  ...notificationsRoutes,
  ...prospectiveTenantRoutes,
  ...prospectiveLandlordRoutes,
  {
    path: '/agent/referencing/missing-report',
    name: ROUTES.MISSING_REPORT,
    component: () => import('@/pages/agent/referencing/missing_report.vue')
  },
  {
    path: '/agent/rewards/scratch-card-expired',
    name: ROUTES.SCRATCH_CARD_EXPIRED,
    component: () => import('@/pages/agent/rewards/scratch_card_expired.vue')
  }
];
