import landlordOfferRoutes, { ROUTES as LANDLORD_OFFER_ROUTES } from './landlord_offer';
import flatbondExtensionRoutes, { ROUTES as FLATBOND_EXTENSION_ROUTES } from './flatbond_extension';
import chargesRoutes, { ROUTES as CHARGES_ROUTES } from './charges';
import payoutRoutes, { ROUTES as PAYOUT_ROUTES } from './payout';
import planRoutes, { ROUTES as PLAN_ROUTES } from './plan';
import organisationRoutes, { ROUTES as ORGANISATION_ROUTES } from './organisation';
import billingRoutes, { ROUTES as BILLING_ROUTES } from './billing';
import toolsRoutes, { ROUTES as TOOLS_ROUTES } from './tools';

export const ROUTES = {
  ...LANDLORD_OFFER_ROUTES,
  ...CHARGES_ROUTES,
  ...FLATBOND_EXTENSION_ROUTES,
  ...PAYOUT_ROUTES,
  ...PLAN_ROUTES,
  ...ORGANISATION_ROUTES,
  ...BILLING_ROUTES,
  ...TOOLS_ROUTES,
  OPS_SETTINGS: 'OPS_SETTINGS',
  OPS_HOMEPAGE: 'OPS_HOMEPAGE'
};

export const opsSettings = {
  name: ROUTES.OPS_SETTINGS,
  path: '/ops/settings',
  component: () => import('@/pages/common/settings/base.vue')
};

export const opsHomepage = {
  name: ROUTES.OPS_HOMEPAGE,
  path: '/ops/homepage',
  component: () => import('@/pages/ops/homepage')
};

export default [
  ...landlordOfferRoutes,
  ...flatbondExtensionRoutes,
  ...chargesRoutes,
  ...payoutRoutes,
  ...planRoutes,
  ...organisationRoutes,
  ...billingRoutes,
  ...toolsRoutes,
  opsSettings,
  opsHomepage
];
