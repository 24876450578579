export const ROUTES = {
  ORGANISATION_CREATE_PAGE: 'ops-organisation-create',
  ORGANISATION_DETAIL_PAGE: 'ops-organisation-detail'
};

const organisationCreate = {
  path: '/ops/organisation/create',
  component: () => import('@/pages/ops/organisation/create'),
  name: ROUTES.ORGANISATION_CREATE_PAGE
};

const organisationDetail = {
  path: '/ops/organisation/:organisationId',
  component: () => import('@/pages/ops/organisation/detail'),
  name: ROUTES.ORGANISATION_DETAIL_PAGE
};

export default [organisationCreate, organisationDetail];
