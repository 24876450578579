import { InternalClaimApiBase } from './base';
import querystring from 'querystring';
import { InternalLandlordOfferModel } from '@/models/landlord_offer';

export class InternalLandlordOffer extends InternalClaimApiBase {
  constructor(vue) {
    super(vue);
    this.errorName = 'InternalLandlordOffer';
  }

  approveOffer(offerId, { caseNote, offerNote, actions } = {}) {
    return super.put(
      `landlord-offer/${offerId}/approve/`,
      { case_note: caseNote, offer_note: offerNote, actions },
      'Failed to mark offer as approved'
    );
  }

  addAction(offerId, action) {
    return super.post(
      `landlord-offer/action/`,
      {
        offer_id: offerId,
        name: action
      },
      'Failed to create action'
    );
  }

  async completeAction(actionId) {
    return await super.put(
      `landlord-offer/action/${encodeURIComponent(actionId)}/complete/`,
      null,
      'Failed to mark action as completed'
    );
  }

  async resetAction(actionId) {
    return await super.put(
      `landlord-offer/action/${encodeURIComponent(actionId)}/reset/`,
      null,
      'Failed to mark action as completed'
    );
  }

  requestAction(offerId, { caseNote, offerNote, actions } = {}) {
    return super.put(
      `landlord-offer/${offerId}/request-action/`,
      { case_note: caseNote, offer_note: offerNote, actions },
      'Request action'
    );
  }

  requestApproval(offerId, { caseNote, offerNote, actions } = {}) {
    return super.put(
      `landlord-offer/${offerId}/request-approval/`,
      { case_note: caseNote, offer_note: offerNote, actions },
      'Request action'
    );
  }

  requestAppointmentApproval(offerId, { caseNote, offerNote, actions } = {}) {
    return super.put(
      `landlord-offer/${offerId}/request-appointment-approval/`,
      { case_note: caseNote, offer_note: offerNote, actions },
      'Request action'
    );
  }

  requestAppointment(offerId, { caseNote, offerNote, actions } = {}) {
    return super.put(
      `landlord-offer/${offerId}/request-appointment/`,
      { case_note: caseNote, offer_note: offerNote, actions },
      'Request action'
    );
  }

  cancelOffer(offerId, { caseNote, offerNote, actions } = {}) {
    return super.put(
      `landlord-offer/${offerId}/cancel/`,
      { case_note: caseNote, offer_note: offerNote, actions },
      'Failed to cancel offer'
    );
  }

  deleteOffer(offerId) {
    return super.put(`landlord-offer/${offerId}/delete/`, 'Failed to delete offer');
  }

  reactivateOffer(offerId) {
    return super.put(`landlord-offer/${offerId}/reactivate_offer/`, 'Failed to reactivate offer');
  }

  rejectOffer(offerId, { caseNote, offerNote, actions, reason }) {
    return super.put(
      `landlord-offer/${offerId}/reject/`,
      {
        case_note: caseNote,
        offer_note: offerNote,
        actions,
        reason
      },
      'Failed to reject offer'
    );
  }

  create(payload) {
    return super.post(`landlord-offer/`, payload, 'Landlord offer creation failed');
  }

  getInitialOffer(caseId) {
    return super.get(
      `case/${caseId}/initial-landlord-offer-values/`,
      'Failed to retrieve initial landlord offer'
    );
  }

  getOffer(offerId) {
    return super.get(`landlord-offer/${offerId}/`, 'Failed to retrieve landlord offer details');
  }

  list(query = '') {
    return super.get(`landlord-offer/${query}`, 'Failed to retrieve landlord cases');
  }

  async list_new({ q, status, planId, pageSize, page } = {}) {
    const qs = querystring.stringify({
      search: q,
      status,
      plan_id: planId,
      page_size: pageSize,
      page
    });
    const { data } = await this.get(`landlord-offer/?${qs}`);
    return {
      ...data,
      results: data.results.map(data => new InternalLandlordOfferModel(data))
    };
  }

  markPaid(offerId, { caseNote, offerNote, actions } = {}) {
    return super.put(
      `landlord-offer/${offerId}/mark-as-paid/`,
      { case_note: caseNote, offer_note: offerNote, actions },
      'Failed to mark offer as paid'
    );
  }
}
