import BaseModel from '../base';
import DocumentModel from '@/models/document';
import InsuranceModel from '@/models/draft_charges_case/insurance';
import OtherChargeModel from '@/models/draft_charges_case/other_charge';
import TenantContactModel from '@/models/draft_charges_case/tenant_contact';
import UnpaidRentModel from '@/models/draft_charges_case/unpaid_rent';
import BankAccountModel from '@/models/draft_charges_case/bank_account';
import EarlyTerminationModel from '@/models/draft_charges_case/early_termination';
import check from 'check-types';

export const CLAIMS_CASE_PAY_TO_OPTIONS = [
  {
    label: 'plan.close.withCharges.steps.payment.payToOptions.landlord',
    value: 'pay_to_landlord'
  },
  {
    label: 'plan.close.withCharges.steps.payment.payToOptions.agent',
    value: 'pay_to_agent'
  }
];

export default class DraftChargesCaseModel extends BaseModel {
  constructor(data) {
    super(data);
    this.checkInReport = this.parseDocument(this.checkInReport);
    this.checkOutReport = this.parseDocument(this.checkOutReport);
    this.insurance = this.insurance ? new InsuranceModel(this.insurance) : null;
    this.otherCharges = this.otherCharges
      ? this.otherCharges.map(charge => new OtherChargeModel(charge))
      : [];
    this.payToAccount = new BankAccountModel(this.payToAccount || {});
    this.tenancyAgreement = this.parseDocument(this.tenancyAgreement);
    this.tenantContactDetails = this.tenantContactDetails
      ? this.tenantContactDetails.map(contact => new TenantContactModel(contact))
      : [];
    this.unpaidRent = this.unpaidRent ? new UnpaidRentModel(this.unpaidRent) : null;
    this.earlyTermination = this.earlyTermination
      ? new EarlyTerminationModel(this.earlyTermination)
      : null;
  }

  parseDocument(data) {
    if (check.object(data)) {
      return new DocumentModel(data);
    }

    if (check.number(data)) {
      return new DocumentModel({ id: data });
    }

    return null;
  }

  toFlatpieModel() {
    const data = super.toFlatpieModel();

    data.tenancy_agreement_id = this.tenancyAgreement ? this.tenancyAgreement.id : null;
    data.check_in_report_id = this.checkInReport ? this.checkInReport.id : null;
    data.check_out_report_id = this.checkOutReport ? this.checkOutReport.id : null;
    if (!this.hasUnpaidRent) data.unpaid_rent = null;
    if (!this.hasInsurance) data.insurance = null;
    if (!this.hasEarlyTermination) data.early_termination = null;

    return data;
  }

  defaults() {
    return {
      id: null,
      checkInReport: null,
      checkOutReport: null,
      hasInsurance: null,
      hasInsurancePristine: true,
      hasOtherCharges: null,
      hasOtherChargesPristine: true,
      hasUnpaidRent: null,
      hasUnpaidRentPristine: true,
      hasEarlyTermination: null,
      hasEarlyTerminationPristine: true,
      insurance: null,
      evictions: [],
      otherCharges: [],
      earlyTermination: null,
      payTo: null,
      payToAccount: null,
      tenancyAgreement: null,
      tenantContactDetails: [],
      unpaidRent: null
    };
  }
}
