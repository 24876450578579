// import config from '@/libs/utils/config';

const enabled = false; //We've decided to pause using mixpanel for now because we are not taking advantage of it

if (enabled) {
  // mixpanel.init('c805ed9c4e73d1093d18978a1d79ed1b');
  // if (config.user) {
  //   mixpanel.identify(config.user.id);
  //   mixpanel.people.set({
  //     name: config.user.fullName,
  //     email: config.user.email,
  //     experiments: config.experiments,
  //     org: config.user.organizationId,
  //     organisationName:
  //       config.user.currentOrganizationUser && config.user.currentOrganizationUser.group.name,
  //     viewingAs: config.viewingAs,
  //     isBTR:
  //       config.user.currentOrganizationUser && config.user.currentOrganizationUser.group.isLandlord
  //   });
  // }
}

export const track = (...args) => {
  if (enabled) {
    // mixpanel.track(...args);
  } else {
    console.debug('Would have tracked', ...args);
  }
};

export const register = (...args) => {
  if (enabled) {
    // mixpanel.register(...args);
  } else {
    console.debug('Would have registered', ...args);
  }
};
