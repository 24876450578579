import { LandlordFlatbondApi, SubscriptionApi } from '@/libs/apis';
import { HTTP_STATUS_CODES } from '@/libs/utils/constants';
import { ROUTES } from '@/pages/landlord/billing/index';
import { STATUS_OPTIONS } from '@/models/flatbond/flatbond_subscription_item';

export const hasSubscription = async () => {
  const subscriptionApi = new SubscriptionApi();
  try {
    await subscriptionApi.status();
    return true;
  } catch (error) {
    if (error.response && error.response.status === HTTP_STATUS_CODES.NOT_FOUND) return false;
    throw error;
  }
};

export const billingOverviewRedirect = async (to, from, next) => {
  try {
    const landlordFlatbondApi = new LandlordFlatbondApi();
    const resultsArray = await Promise.all([
      hasSubscription(),
      landlordFlatbondApi.list({
        boost_subscription__status: STATUS_OPTIONS.ACTIVE
      })
    ]);
    const subscriptionExists = resultsArray[0];
    const numberOfPlansWithBoost = resultsArray[1].data.count;
    const onlyHasNonSubscriptionBoost = !subscriptionExists && numberOfPlansWithBoost > 0;

    if (onlyHasNonSubscriptionBoost) next({ name: ROUTES.PLAN });
  } finally {
    next();
  }
};

export const subscribeRedirect = async (to, from, next) => {
  try {
    const redirectToPlanPage = await hasSubscription();
    if (redirectToPlanPage) next({ name: ROUTES.PLAN });
  } finally {
    next();
  }
};
