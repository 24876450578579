import BaseModel from './base';
import {
  DOCUMENT_ADDITIONAL_REFERENCING_DOCUMENT,
  DOCUMENT_ADJUDICATION_REPORT,
  DOCUMENT_AST,
  DOCUMENT_AST_ADDENDUM_TEMPLATE,
  DOCUMENT_AST_TEMPLATE,
  DOCUMENT_CHARGE_DOCUMENT,
  DOCUMENT_CHARGES_INVOICE,
  DOCUMENT_CHECK_IN,
  DOCUMENT_CHECK_OUT,
  DOCUMENT_COMPANY_AST,
  DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE,
  DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE_SIGNED,
  DOCUMENT_DEPOSIT_REPLACEMENT_AGREEMENT,
  DOCUMENT_DEPOSIT_REPLACEMENT_SCHEDULE,
  DOCUMENT_GUARANTOR_INFO,
  DOCUMENT_INVENTORY_REPORT,
  DOCUMENT_LANDLORD_INSTRUCTION_FORM,
  DOCUMENT_LANDLORD_OFFER_SIGNED,
  DOCUMENT_LANDLORD_SUBJECT_TO_CONTRACT_LETTER,
  DOCUMENT_LANDLORD_TERMS_AND_CONDITIONS,
  DOCUMENT_LANDLORD_TERMS_OF_BUSINESS_TEMPLATE,
  DOCUMENT_ONBOARDING_DOCUMENT,
  DOCUMENT_PLAN_CERTIFICATE,
  DOCUMENT_PROOF_OF_ADDRESS,
  DOCUMENT_REFERENCING_RESULT,
  DOCUMENT_RENT_ARREARS_AGREEMENT,
  DOCUMENT_RENT_ARREARS_REPORT,
  DOCUMENT_RENT_SCHEDULE,
  DOCUMENT_RLP_PROOF,
  DOCUMENT_SECURE_DOCUMENT,
  DOCUMENT_SITUATION_CHANGE_PROOF,
  DOCUMENT_TENANCY_ADDENDUM,
  DOCUMENT_TENANCY_AGREEMENT,
  DOCUMENT_TENANT_OFFER_LETTER,
  DOCUMENT_TENANT_SUBJECT_TO_CONTRACT_LETTER,
  DOCUMENT_TENANT_TERMS_AND_CONDITIONS,
  DOCUMENT_TENANT_TERMS_OF_BUSINESS,
  DOCUMENT_UNKNOWN
} from '@/libs/utils/constants';
import { UserModel } from '@/models/user';
import check from 'check-types';

export const DOCUMENT_TYPES = {
  ADDITIONAL_REFERENCING_DOCUMENT: DOCUMENT_ADDITIONAL_REFERENCING_DOCUMENT,
  ADJUDICATION_REPORT: DOCUMENT_ADJUDICATION_REPORT,
  AST: DOCUMENT_AST,
  AST_ADDENDUM: DOCUMENT_AST_ADDENDUM_TEMPLATE,
  AST_TEMPLATE: DOCUMENT_AST_TEMPLATE,
  CHARGE_DOCUMENT: DOCUMENT_CHARGE_DOCUMENT,
  CHARGES_INVOICE: DOCUMENT_CHARGES_INVOICE,
  CHECK_IN: DOCUMENT_CHECK_IN,
  CHECK_OUT: DOCUMENT_CHECK_OUT,
  COMPANY_AST: DOCUMENT_COMPANY_AST,
  DEPOSIT_PROTECTION_CERTIFICATE: DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE,
  DEPOSIT_PROTECTION_CERTIFICATE_SIGNED: DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE_SIGNED,
  DEPOSIT_REPLACEMENT_AGREEMENT: DOCUMENT_DEPOSIT_REPLACEMENT_AGREEMENT,
  DEPOSIT_REPLACEMENT_SCHEDULE: DOCUMENT_DEPOSIT_REPLACEMENT_SCHEDULE,
  GUARANTOR_INFORMATION: DOCUMENT_GUARANTOR_INFO,
  INVENTORY_REPORT: DOCUMENT_INVENTORY_REPORT,
  LANDLORD_INSTRUCTION_FORM: DOCUMENT_LANDLORD_INSTRUCTION_FORM,
  LANDLORD_OFFER_SIGNED: DOCUMENT_LANDLORD_OFFER_SIGNED,
  LANDLORD_SUBJECT_TO_CONTRACT_LETTER: DOCUMENT_LANDLORD_SUBJECT_TO_CONTRACT_LETTER,
  LANDLORD_TERMS_OF_BUSINESS: DOCUMENT_LANDLORD_TERMS_OF_BUSINESS_TEMPLATE,
  ONBOARDING_DOCUMENT: DOCUMENT_ONBOARDING_DOCUMENT,
  PLAN_CERTIFICATE: DOCUMENT_PLAN_CERTIFICATE,
  PROOF_OF_ADDRESS: DOCUMENT_PROOF_OF_ADDRESS,
  REFERENCING_RESULT: DOCUMENT_REFERENCING_RESULT,
  RENT_ARREARS_AGREEMENT: DOCUMENT_RENT_ARREARS_AGREEMENT,
  RENT_ARREARS_REPORT: DOCUMENT_RENT_ARREARS_REPORT,
  RENT_SCHEDULE: DOCUMENT_RENT_SCHEDULE,
  RLP_PROOF: DOCUMENT_RLP_PROOF,
  SECURE_DOCUMENT: DOCUMENT_SECURE_DOCUMENT,
  SITUATION_CHANGE_PROOF: DOCUMENT_SITUATION_CHANGE_PROOF,
  TENANCY_ADDENDUM: DOCUMENT_TENANCY_ADDENDUM,
  TENANCY_AGREEMENT: DOCUMENT_TENANCY_AGREEMENT,
  TENANT_OFFER_LETTER: DOCUMENT_TENANT_OFFER_LETTER,
  TENANT_SUBJECT_TO_CONTRACT_LETTER: DOCUMENT_TENANT_SUBJECT_TO_CONTRACT_LETTER,
  TENANT_TERMS_OF_BUSINESS: DOCUMENT_TENANT_TERMS_OF_BUSINESS,
  LANDLORD_TERMS_AND_CONDITIONS: DOCUMENT_LANDLORD_TERMS_AND_CONDITIONS,
  TENANT_TERMS_AND_CONDITIONS: DOCUMENT_TENANT_TERMS_AND_CONDITIONS,
  UNKNOWN: DOCUMENT_UNKNOWN
};

export const DOCUMENT_TYPE_TO_LABEL_ID = {
  [DOCUMENT_TYPES.ADDITIONAL_REFERENCING_DOCUMENT]:
    'common.document.type.additionalReferencingDocument',
  [DOCUMENT_TYPES.ADJUDICATION_REPORT]: 'common.document.type.adjudicationReport',
  [DOCUMENT_TYPES.AST]: 'common.document.type.ast',
  [DOCUMENT_TYPES.AST_ADDENDUM]: 'common.document.type.astAddendum',
  [DOCUMENT_TYPES.AST_TEMPLATE]: 'common.document.type.astTemplate',
  [DOCUMENT_TYPES.CHARGE_DOCUMENT]: 'common.document.type.chargeDocument',
  [DOCUMENT_TYPES.CHARGES_INVOICE]: 'common.document.type.chargesInvoice',
  [DOCUMENT_TYPES.CHECK_IN]: 'common.document.type.checkIn',
  [DOCUMENT_TYPES.CHECK_OUT]: 'common.document.type.checkOut',
  [DOCUMENT_TYPES.COMPANY_AST]: 'common.document.type.companyAst',
  [DOCUMENT_TYPES.DEPOSIT_PROTECTION_CERTIFICATE]:
    'common.document.type.depositProtectionCertificate',
  [DOCUMENT_TYPES.DEPOSIT_PROTECTION_CERTIFICATE_SIGNED]:
    'common.document.type.depositProtectionCertificateSigned',
  [DOCUMENT_TYPES.DEPOSIT_REPLACEMENT_AGREEMENT]:
    'common.document.type.depositReplacementAgreement',
  [DOCUMENT_TYPES.DEPOSIT_REPLACEMENT_SCHEDULE]: 'common.document.type.depositReplacementSchedule',
  [DOCUMENT_TYPES.GUARANTOR_INFORMATION]: 'common.document.type.guarantorInformation',
  [DOCUMENT_TYPES.INVENTORY_REPORT]: 'common.document.type.inventoryReport',
  [DOCUMENT_TYPES.LANDLORD_INSTRUCTION_FORM]: 'common.document.type.landlordInstructionForm',
  [DOCUMENT_TYPES.LANDLORD_OFFER_SIGNED]: 'common.document.type.landlordOfferSigned',
  [DOCUMENT_TYPES.LANDLORD_SUBJECT_TO_CONTRACT_LETTER]:
    'common.document.type.landlordSubjectToContractLetter',
  [DOCUMENT_TYPES.LANDLORD_TERMS_OF_BUSINESS]: 'common.document.type.landlordTermsOfBusiness',
  [DOCUMENT_TYPES.ONBOARDING_DOCUMENT]: 'common.document.type.onboardingDocument',
  [DOCUMENT_TYPES.PLAN_CERTIFICATE]: 'common.document.type.planCertificate',
  [DOCUMENT_TYPES.PROOF_OF_ADDRESS]: 'common.document.type.proofOfAddress',
  [DOCUMENT_TYPES.REFERENCING_RESULT]: 'common.document.type.referencingResult',
  [DOCUMENT_TYPES.RENT_ARREARS_AGREEMENT]: 'common.document.type.rentArrearsAgreement',
  [DOCUMENT_TYPES.RENT_ARREARS_REPORT]: 'common.document.type.rentArrearsReport',
  [DOCUMENT_TYPES.RENT_SCHEDULE]: 'common.document.type.rentSchedule',
  [DOCUMENT_TYPES.RLP_PROOF]: 'common.document.type.rlpProof',
  [DOCUMENT_TYPES.SECURE_DOCUMENT]: 'common.document.type.secureDocument',
  [DOCUMENT_TYPES.SITUATION_CHANGE_PROOF]: 'common.document.type.situationChangeProof',
  [DOCUMENT_TYPES.TENANCY_ADDENDUM]: 'common.document.type.tenancyAddendum',
  [DOCUMENT_TYPES.TENANCY_AGREEMENT]: 'common.document.type.tenancyAgreement',
  [DOCUMENT_TYPES.TENANT_OFFER_LETTER]: 'common.document.type.tenantOfferLetter',
  [DOCUMENT_TYPES.TENANT_SUBJECT_TO_CONTRACT_LETTER]:
    'common.document.type.tenantSubjectToContractLetter',
  [DOCUMENT_TYPES.TENANT_TERMS_OF_BUSINESS]: 'common.document.type.tenantTermsOfBusiness',
  [DOCUMENT_TYPES.LANDLORD_TERMS_AND_CONDITIONS]: 'common.document.type.landlordTermsAndConditions',
  [DOCUMENT_TYPES.TENANT_TERMS_AND_CONDITIONS]: 'common.document.type.tenantTermsAndConditions',
  [DOCUMENT_TYPES.UNKNOWN]: 'common.document.type.unknown'
};

export default class DocumentModel extends BaseModel {
  constructor(data) {
    super(data);

    this.createdBy = check.object(this.createdBy) ? new UserModel(this.createdBy) : this.createdBy;
  }

  defaults() {
    return {
      fileName: '',
      id: null,
      createdBy: null,
      createdOn: null,
      type: ''
    };
  }

  typeLabel($t) {
    return this.type in DOCUMENT_TYPE_TO_LABEL_ID
      ? $t(DOCUMENT_TYPE_TO_LABEL_ID[this.type])
      : $t('common.document.type.unknown');
  }

  set document(value) {
    this.id = value;
  }

  set fileName(name) {
    this.name = name;
  }
}
