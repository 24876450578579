import referencingRoutes from '@/pages/tenant/referencing';
import { TenantGuarantorApi } from '@/libs/apis';

export const TENANT_DECISIONS = {
  SECURE: 'secure',
  COMPLICATED: 'complicated',
  REFUNDABLE: 'refundable',
  DONT_KNOW: 'dont_know'
};

export const TENANT_ROUTES = {
  GUARANTOR_DETAILS: 'TENANT_GUARANTOR_DETAILS',
  PLAN_DETAILS: 'TENANT_PLAN_DETAILS',
  PLAN_DOCUMENTS: 'TENANT_PLAN_DOCUMENTS',
  KEY_POINTS: 'TENANT_KEY_POINTS',
  TENANT_SETTINGS: 'TENANT_SETTINGS',
  HOMEPAGE: 'TENANT_HOMEPAGE',
  TENANCIES: 'TENANT_TENANCIES',
  UNSELECTED_PLAN_TYPE: 'TENANT_UNSELECTED_PLAN_TYPE',
  DEPOSIT_PAYMENT: 'TENANT_DEPOSIT_PAYMENT',
  DEPOSIT_PAYMENT_LOADING: 'TENANT_DEPOSIT_PAYMENT_LOADING',
  NO_DEPOSIT_INFO: 'TENANT_NO_DEPOSIT_INFO',
  TRADITIONAL_DEPOSIT_HANDLED_OFF_PLATFORM: 'TRADITIONAL_DEPOSIT_HANDLED_OFF_PLATFORM'
};

export const homepage = {
  name: TENANT_ROUTES.HOMEPAGE,
  path: '/tenant',
  component: () => import('@/pages/tenant/homepage.vue')
};

export const tenancies = {
  name: TENANT_ROUTES.TENANCIES,
  path: '/tenant/tenancies',
  component: () => import('@/pages/tenant/tenancies.vue')
};
export const guarantorDetails = {
  name: TENANT_ROUTES.GUARANTOR_DETAILS,
  path: '/tenant/:tenantId/guarantor_details',
  component: () => import('@/pages/tenant/guarantor_details'),
  beforeEnter: async (to, from, next) => {
    let id = Number.parseInt(to.params.tenantId, 10);
    if (Number.isNaN(id)) {
      window.location = '/';
    } else {
      const api = new TenantGuarantorApi();
      try {
        const response = await api.list(to.params.tenantId);
        const hasAtLeastOneGuarantorWithoutAccount = response.data.results.find(
          guarantor => !guarantor.user
        );

        if (hasAtLeastOneGuarantorWithoutAccount) {
          next();
        } else {
          window.location = `/tenants/${to.params.tenantId}/sign/`;
        }
      } catch (error) {
        window.location = '/';
      }
    }
  },
  props: true
};

export const keyPoints = {
  name: TENANT_ROUTES.KEY_POINTS,
  path: '/tenant/:tenantId/key-points',
  component: () => import('@/components/terms_confirmation/terms_confirmation_new'),
  beforeEnter: async (to, from, next) => {
    let id = Number.parseInt(to.params.tenantId, 10);
    if (Number.isNaN(id)) {
      window.location = '/';
    } else {
      next();
    }
  },
  props: true
};

export const planDetail = {
  path: '/tenant/plan/:planId',
  component: () => import('@/pages/common/plan/tabs'),
  props: true,
  children: [
    {
      name: TENANT_ROUTES.PLAN_DETAILS,
      path: '',
      component: () => import('@/pages/common/plan/details_tab.vue'),
      props: true
    },
    {
      name: TENANT_ROUTES.PLAN_DOCUMENTS,
      path: '/tenant/plan/:planId/documents',
      component: () => import('@/pages/common/plan/documents_tab.vue'),
      props: true
    }
  ]
};

export const tenantUnselectedPlan = {
  name: TENANT_ROUTES.UNSELECTED_PLAN_TYPE,
  path: '/tenant/:planId/select',
  component: () => import('@/pages/tenant/unselected_plan_type')
};

export const tenantDepositPayment = {
  name: TENANT_ROUTES.DEPOSIT_PAYMENT,
  path: '/tenant/:planId/select/deposit',
  component: () => import('@/pages/tenant/deposit_payment'),
  props: true
};

export const tenantTraditionalDepositHandledOffPlatform = {
  name: TENANT_ROUTES.TRADITIONAL_DEPOSIT_HANDLED_OFF_PLATFORM,
  path: '/tenant/:planId/off-platform-td',
  component: () => import('@/pages/tenant/traditional_deposit_handled_off_platform')
};

export const tenantDepositPaymentLoading = {
  name: TENANT_ROUTES.DEPOSIT_PAYMENT_LOADING,
  path: '/tenant/select/deposit/loading',
  component: () => import('@/pages/tenant/deposit_payment_loading')
};

export const tenantNoDepositInfo = {
  name: TENANT_ROUTES.NO_DEPOSIT_INFO,
  path: '/tenant/:planId/no-deposit-info',
  component: () => import('@/pages/tenant/no_deposit_info')
};

export const tenantSettings = {
  name: TENANT_ROUTES.TENANT_SETTINGS,
  path: '/tenant/settings',
  component: () => import('@/pages/common/settings/base.vue')
};

export default [
  guarantorDetails,
  planDetail,
  ...referencingRoutes,
  homepage,
  tenantSettings,
  keyPoints,
  tenantUnselectedPlan,
  tenancies,
  tenantDepositPayment,
  tenantDepositPaymentLoading,
  tenantTraditionalDepositHandledOffPlatform,
  tenantNoDepositInfo
];
