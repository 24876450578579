import { PaymentApiBase } from './base';

export class PaymentBankAccountApi extends PaymentApiBase {
  update(bankAccount) {
    const data = {
      name_on_account: bankAccount.nameOnAccount,
      account_number: bankAccount.accountNumber,
      sort_code: bankAccount.sortCode
    };

    return super.put(
      `bank-account/${bankAccount.id}/`,
      data,
      'Failed to update the bank account details'
    );
  }
}
