<template>
  <FpFlex
    space-around
    :justify-content="{
      xLarge: FlexJustifyContent.spaceAround,
      large: FlexJustifyContent.spaceAround,
      medium: FlexJustifyContent.center,
      small: FlexJustifyContent.center
    }"
    :direction="{ small: FlexDirection.column, medium: FlexDirection.column }"
  >
    <FpFlexItem align="center">
      <FpHeader3 :margin-bottom="2">{{ $t('footer.helpCentre.title') }}</FpHeader3>
      <FpLink :href="HELP_ARTICLES_URL" target="_blank" external>
        {{ $t('footer.helpCentre.link') }}
      </FpLink>
    </FpFlexItem>
    <FpDevice mobile-only>
      <FpFlexItem>
        <FpSeparator />
      </FpFlexItem>
    </FpDevice>
    <FpFlexItem v-if="onPage === PAGES.MATERIALS" align="center">
      <FpHeader3 :margin-bottom="2">{{ $t('footer.materials.title') }}</FpHeader3>
      <FpLink external @click="navigateToPage">
        {{ $t('footer.materials.link') }}
      </FpLink>
    </FpFlexItem>
    <FpFlexItem v-if="onPage === PAGES.LEARN" align="center">
      <FpHeader3 :margin-bottom="2">{{ $t('footer.learn.title') }}</FpHeader3>
      <FpLink @click="navigateToPage">
        {{ $t('footer.learn.link') }}
      </FpLink>
    </FpFlexItem>
  </FpFlex>
</template>
<script>
export const PAGES = {
  LEARN: 'learn',
  MATERIALS: 'materials'
};

import {
  FpFlex,
  FpFlexItem,
  FpSeparator,
  FpLink,
  FpHeader3,
  FpDevice
} from '@flatfair/vue-fairplay';
import { HELP_ARTICLES_URL } from '@/libs/utils/constants';
import FlexMixin from '@/libs/constants/flexMixin';
import { ROUTES } from '@/pages/agent/';
import { hideZendeskOnMobileMixin } from '@/libs/mixins';

export default {
  components: {
    FpFlex,
    FpFlexItem,
    FpLink,
    FpDevice,
    FpHeader3,
    FpSeparator
  },
  mixins: [FlexMixin, hideZendeskOnMobileMixin],
  props: {
    onPage: {
      type: String,
      required: true
    }
  },
  created() {
    this.PAGES = PAGES;
    this.HELP_ARTICLES_URL = HELP_ARTICLES_URL;
  },
  methods: {
    navigateToPage() {
      if (this.onPage === this.PAGES.LEARN) {
        this.$router.push({
          name: ROUTES.MATERIALS
        });
      }
      if (this.onPage === this.PAGES.MATERIALS) {
        this.$router.push({
          name: ROUTES.LEARN_TRAINING_SESSIONS
        });
      }
    }
  }
};
</script>
