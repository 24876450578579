<template>
  <FpDropdown
    v-if="steps.length && isEnabled && targetElement"
    id="walkthroughTooltip"
    :is-open="isDropdownOpen"
    :for-element="targetElement"
    role="tooltip"
  >
    <FpHeader3 :margin-bottom="1">{{ headingText }}</FpHeader3>
    <FpBody :margin-bottom="2">{{ bodyText }}</FpBody>
    <FpFlex :align-items="FlexAlignItems.center">
      <FpFlexItem>
        <FpButton @click="next">{{ nextButtonText }}</FpButton>
      </FpFlexItem>
      <FpFlexItem>
        <FpLink v-if="displaySkipButton" as="button" @click="skip">
          {{ skipButtonText }}
        </FpLink>
      </FpFlexItem>
    </FpFlex>
  </FpDropdown>
</template>

<script>
import { onMounted, reactive, toRefs, watchEffect } from '@vue/composition-api';
import { FpDropdown, FpButton, FpLink, FpFlex } from '@flatfair/vue-fairplay/molecules';
import { FpBody, FpHeader3, FpFlexItem, FlexAlignItems } from '@flatfair/vue-fairplay/atoms';

const TWO_SECONDS = 2000;

export default {
  components: {
    FpDropdown,
    FpBody,
    FpHeader3,
    FpButton,
    FpLink,
    FpFlexItem,
    FpFlex
  },
  props: {
    steps: {
      type: Array,
      required: true
    },
    callFinalActionOnSkip: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const state = reactive({
      isEnabled: true,
      displaySkipButton: true,
      isDropdownOpen: false,
      currentStep: 0,
      headingText: '',
      bodyText: '',
      nextButtonText: '',
      targetElement: null,
      skipButtonText: '',
      finalAction: () => {}
    });
    const next = () => {
      if (props.steps.length <= state.currentStep + 1) {
        state.isDropdownOpen = false;
        if (state.finalAction && typeof state.finalAction === 'function') {
          state.finalAction.call();
        }
      } else {
        state.currentStep++;
      }
    };
    const skip = () => {
      if (
        props.callFinalActionOnSkip &&
        state.finalAction &&
        typeof state.finalAction === 'function'
      ) {
        state.finalAction.call();
      }
      state.isEnabled = false;
    };
    const resolveTargetElement = onResolve => {
      const targetElement = document.querySelector(props.steps[state.currentStep].querySelector);
      if (targetElement) {
        state.targetElement = targetElement;
        if (typeof onResolve === 'function') onResolve();
      }
    };
    watchEffect(() => {
      const { steps } = props;
      const { $t } = context.root;
      if (!state.isEnabled || !steps.length) return;
      const isLast = steps.length === state.currentStep + 1;
      state.headingText = $t(steps[state.currentStep].headerLabel);
      state.bodyText = $t(steps[state.currentStep].bodyLabel);
      state.skipButtonText = $t('walkthrough.generic.skipButton');
      state.nextButtonText = $t(
        isLast ? 'walkthrough.generic.doneButton' : 'walkthrough.generic.nextButton'
      );
      state.displaySkipButton = !isLast;

      if (steps[state.currentStep].finalAction) {
        state.finalAction = steps[state.currentStep].finalAction;
      }

      resolveTargetElement();
    });
    onMounted(() => {
      resolveTargetElement(() => {
        setTimeout(() => (state.isDropdownOpen = true), TWO_SECONDS);
      });
    });
    return {
      next,
      skip,
      ...toRefs(state)
    };
  },
  created() {
    this.FlexAlignItems = FlexAlignItems;
  }
};
</script>
