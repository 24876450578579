<template>
  <ThemeProvider :theme="defaultTheme">
    <slot></slot>
  </ThemeProvider>
</template>
<script>
import { defaultTheme, ThemeProvider } from '@flatfair/vue-fairplay/atoms';
import '@fortawesome/fontawesome-pro/css/light.css';
import '@fortawesome/fontawesome-pro/css/regular.css';
import '@fortawesome/fontawesome-pro/css/solid.css';

export default {
  components: {
    ThemeProvider
  },
  data() {
    return { defaultTheme };
  }
};
</script>
