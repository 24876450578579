import { PUBLIC_PROSPECTIVE_LANDLORD_ROUTES } from './constants';
import { ROUTE_404_NAME } from '@/pages';
import { ProspectiveLandlordApi } from '@/libs/apis/landlord/prospective_landlord';
import config, { FEATURE_FLAGS } from '@/libs/utils/config';

const REDIRECT_TO_404_WHEN_INVITE_NOT_FOUND = async (to, from, next) => {
  if (!config.featureEnabled(FEATURE_FLAGS.PROSPECTIVE_LANDLORD)) {
    next({ name: ROUTE_404_NAME });
  }

  const api = new ProspectiveLandlordApi(this);
  try {
    to.params.initialProspectiveLandlord = await api.publicDetail(
      to.query.inviteId,
      to.query.email,
      to.query.groupId
    );
    next();
  } catch (e) {
    next({ name: ROUTE_404_NAME });
  }
};

const publicProspectiveLandlordRoute = {
  name: PUBLIC_PROSPECTIVE_LANDLORD_ROUTES.LET_ME_TELL_YOU,
  path: '/public/landlord/let-me-tell-you-about-flatfair',
  component: () => import('@/pages/public/prospective_landlord/let_me_tell_you_about_flatfair.vue'),
  props: true,
  beforeEnter: REDIRECT_TO_404_WHEN_INVITE_NOT_FOUND
};

const publicProspectiveLandlordFeedbackRoute = {
  name: PUBLIC_PROSPECTIVE_LANDLORD_ROUTES.LET_ME_TELL_YOU_FEEDBACK,
  path: '/public/landlord/let-me-tell-you-about-flatfair/feedback',
  component: () =>
    import('@/pages/public/prospective_landlord/let_me_tell_you_about_flatfair_feedback.vue'),
  props: true,
  beforeEnter: REDIRECT_TO_404_WHEN_INVITE_NOT_FOUND
};

const landlordDecisionRoute = {
  name: PUBLIC_PROSPECTIVE_LANDLORD_ROUTES.LANDLORD_UPSELL_DECISION,
  path: '/public/landlord/landlord-decision',
  component: () => import('@/pages/public/landlord_upsell/landlord_upsell_decision.vue'),
  props: true
};

export default [
  publicProspectiveLandlordRoute,
  publicProspectiveLandlordFeedbackRoute,
  landlordDecisionRoute
];
