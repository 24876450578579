import { landlordWelcomeRedirect } from '@/pages/landlord/redirects';

export const LANDLORD_WELCOME_ROUTES = {
  WELCOME: 'LANDLORD_WELCOME_FLOW'
};

const landlordWelcomeFlow = {
  path: '/landlord/welcome',
  name: LANDLORD_WELCOME_ROUTES.WELCOME,
  component: () => import('@/pages/landlord/welcome/flow.vue'),
  beforeEnter: landlordWelcomeRedirect,
  props: route => {
    const startAt = route.params.startAt || null;
    return startAt ? { startAt } : {};
  }
};

export default [landlordWelcomeFlow];
