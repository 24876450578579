import { MyDepositsDepositApiBase } from './base';

export class MyDepositsDepositApi extends MyDepositsDepositApiBase {
  getTenancy(myDepositsConnectionId, tenancyId) {
    return super.get(
      `${myDepositsConnectionId}/tenancies/${tenancyId}/?format=json`,
      'Could not retrieve the tenancy from MyDeposits'
    );
  }

  getLandlord(myDepositsConnectionId, landlordId) {
    return super.get(
      `${myDepositsConnectionId}/landlords/${landlordId}/?format=json`,
      'Could not retrieve the landlord from MyDeposits'
    );
  }

  getProperty(myDepositsConnectionId, propertyId) {
    return super.get(
      `${myDepositsConnectionId}/properties/${propertyId}/?format=json`,
      'Could not retrieve the property from MyDeposits'
    );
  }
}
