import BaseModel from '../base';

export const IS_RENTAL_AND_LEGAL_PROTECTION_OPTIONS = [
  {
    label: 'plan.close.withCharges.review.insurance.typeOptions.rentalAndLegalProtection',
    value: true
  },
  {
    label: 'plan.close.withCharges.review.insurance.typeOptions.other',
    value: false
  }
];

export default class InsuranceModel extends BaseModel {
  constructor(data) {
    super(data);
  }

  defaults() {
    return {
      isRentalAndLegalProtection: true,
      insuranceType: '',
      insuranceProvider: '',
      totalChargesValue: null,
      paidByInsurance: null,
      excessPaid: null
    };
  }
}
