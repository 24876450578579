export * from './internal';

import { LandlordApiBase } from '@/libs/apis/landlord/base';
import LandlordModel from '@/models/landlord';

export class LandlordApi extends LandlordApiBase {
  async get(id) {
    const { data } = await super.get(id, 'Failed to retrieve landlord details');
    return new LandlordModel(data);
  }

  async update(landlord) {
    const { data } = await super.put(
      `${landlord.id}/`,
      landlord.toFlatpieModel(),
      'Failed to update landlord details'
    );
    return new LandlordModel(data);
  }

  invite(landlordEmail) {
    const data = {
      invite_email: landlordEmail
    };

    return super.post(`invite/`, data);
  }

  getLandlordOffers() {
    return super.get(`offers/`);
  }

  reSendLandlordOffer(landlordOfferId) {
    return super.post(`${landlordOfferId}/re-send-offer/`);
  }

  async isLandlordEmail(email) {
    const { data } = await super.get(
      `landlord-email/is-landlord-email/?email=${encodeURIComponent(email)}`,
      'Failed to check if email exists'
    );
    return data.is_landlord_email;
  }

  async markLandlordAndFlatbondAsSigned(landlordId, flatbondId) {
    return super.patch(
      `${landlordId}/mark-landlord-and-flatbond-as-signed/`,
      { flatbond_id: flatbondId },
      'Failed to mark landlord and flatbond as signed'
    );
  }
}
