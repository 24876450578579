import BaseModel from '@/models/base';
import { TraditionalDepositModel } from '@/models/flatbond/traditional_deposit';
import GuarantorModel from '@/models/guarantor';
import LandlordModel from '@/models/landlord';
import FlatbondTenantModel from '@/models/tenant';
import moment from 'moment';
import { UserModel } from '@/models/user';
import { addVat, convertAndRoundWeeklyToMonthlyRent } from '@/libs/utils/helpers';
import DocumentModel from '@/models/document';
import { FLATBOND_PRODUCT_TYPES } from '@/libs/constants/generatedConstants';

export const PRODUCT_TYPE = {
  NO_DEPOSIT: 'flatbond',
  DEPOSIT: 'deposit',
  TRADITIONAL_DEPOSIT: 'traditional_deposit',
  UNSELECTED: 'unselected'
};

export class ChangeTenantModel extends BaseModel {
  defaults() {
    return {
      newEmail: null,
      oldEmail: null,
      newFirstName: null,
      newLastName: null
    };
  }
}

export default class PlanModel extends BaseModel {
  constructor(data) {
    super(data);

    this.activelyAdministeredBy = this.activelyAdministeredBy
      ? new UserModel(this.activelyAdministeredBy)
      : null;
    this.guarantors = this.guarantors.map(guarantor => new GuarantorModel(guarantor));
    this.landlord = this.landlord ? new LandlordModel(this.landlord) : null;
    this.tenants = this.tenants.map(tenant => new FlatbondTenantModel(tenant));
    this.removedTenants = this.removedTenants.map(tenant => new FlatbondTenantModel(tenant));
    this.traditionalDeposit = this.traditionalDeposit
      ? new TraditionalDepositModel(this.traditionalDeposit)
      : null;
    this.documents = this.documents.map(document => new DocumentModel(document));
  }

  get durationMonths() {
    const start = this.parsedStartDate;
    const end = this.parsedCloseDate;
    return Math.ceil(end.diff(start, 'month', true));
  }

  get monthlyRent() {
    return convertAndRoundWeeklyToMonthlyRent(this.rent);
  }

  get parsedStartDate() {
    return moment(this.startDate);
  }

  get parsedCloseDate() {
    return moment(this.closeDate);
  }

  get isUnselectedPlanType() {
    return this.productType === FLATBOND_PRODUCT_TYPES.UNSELECTED;
  }

  get isNoDeposit() {
    return this.productType === FLATBOND_PRODUCT_TYPES.NO_DEPOSIT;
  }

  get isTraditionalDeposit() {
    return this.productType === FLATBOND_PRODUCT_TYPES.TRADITIONAL_DEPOSIT;
  }

  get membershipFeeWithVat() {
    return addVat(this.membershipFee);
  }

  defaults() {
    return {
      id: null,
      activelyAdministeredBy: null,
      address: null,
      branch: null,
      closeDate: null,
      depositProvider: null,
      depositScheme: null,
      mydepositsCertificateVersion: null,
      guarantors: [],
      hasBoostActive: false,
      isInChargeRelatedState: false,
      isPreVerified: false,
      isVerified: false,
      isPastPeriodAllowedForCancellation: false,
      landlord: null,
      membershipFee: null,
      productType: null,
      rent: null,
      startDate: null,
      status: null,
      tenants: [],
      removedTenants: [],
      requiresLandlordSignature: false,
      traditionalDeposit: null,
      tenancyTerminationDate: null,
      chargeSubmissionDeadline: null,
      chargeSubmissionStarted: false,
      documents: [],
      shouldDisplayDownloadAsPdfButton: false,
      needsAgentToSign: false,
      needsLandlordToSign: false,
      eligibleForBoost: false,
      daysLeftToUpgradeToBoost: 0,
      daysLeftToUpgradeToBoostFree: 0,
      hasSubscriptionItem: false,
      hasLandlordSignatureImprovementsEnabled: false,
      managedBy: null,
      applyProtectionMultiplierOn: null,
      totalProtectionCapMultiplier: 0,
      rentArrearsProtectionCapMultiplier: 0,
      dilapidationsProtectionCapMultiplier: 0,
      externalTenancyId: null,
      requiresExtendOrClose: false,
      requiresReplyToCharges: false,
      notActivatedAndPastStartDate: false,
      organizationSellerId: null,
      hasReferencingDocuments: false,
      depositAmount: null,
      isBankTransfer: false,
      tenancyType: null,
      traditionalDepositHandledOffPlatform: false
    };
  }

  fullAddress($t) {
    return [this.address, this.postcode, this.city, this.country && $t(`countries.${this.country}`)]
      .filter(Boolean)
      .join(', ');
  }
}

export * from './close';
