import { MEDIUM_BREAK_POINT } from '@flatfair/vue-fairplay/atoms';
import confetti from 'canvas-confetti';

export const confettiMixin = {
  data() {
    return {
      mediaQuery: null,
      showConfetti: false
    };
  },
  watch: {
    showConfetti() {
      this._showConfetti();
    }
  },
  created() {
    this.mediaQuery = window.matchMedia(`(max-width: ${MEDIUM_BREAK_POINT}em)`);
    this._confettiListener(this.mediaQuery);
  },
  mounted() {
    this.mediaQuery.addListener(this._confettiListener);
    this._showConfetti();
  },
  destroyed() {
    this.mediaQuery.removeListener(this._confettiListener);
    this.mediaQuery = null;
    this.showConfetti = false;
  },
  methods: {
    _confettiListener(query) {
      this.showConfetti = !query.matches;
    },
    _showConfetti() {
      if (this.showConfetti) {
        let end = Date.now() + 5 * 1000;
        let y = 0.75;
        let x = 0.75;
        if (window.innerWidth < 875) {
          x = 0.5;
        }
        const frame = () => {
          confetti({
            particleCount: 5,
            spread: window.innerWidth / 20,
            origin: { y: y, x: x },
            colors: ['#EF8568', '#859CFF', '#4CC4B5', '#FFC64A']
          });

          if (Date.now() < end && this.showConfetti) {
            requestAnimationFrame(frame);
          }
        };

        frame();
      }
    }
  }
};
