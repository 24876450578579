import * as mixpanel from '@/libs/utils/mixpanel';
import config from '@/libs/utils/config';

// Adds the specified category to all tracking events thereafter
export function registerCategory(category) {
  mixpanel.register({ category });
}

export function init(category) {
  registerCategory(category);
  mixpanel.track('page viewed', {
    'page name': document.title,
    'url': window.location.pathname,
    'org': config.organizationId
  });
}

export function button({ label }) {
  mixpanel.track(label, { action: 'Button' });
}

export function link({ label }) {
  mixpanel.track(label, { action: 'Link' });
}

export function radio({ label, value = '' }) {
  mixpanel.track(label, { action: 'Radio button', value });
}

export function checkbox({ label, value = '' }) {
  mixpanel.track(label, { action: 'Checkbox', value });
}

export function dropdown({ label, value = '' }) {
  mixpanel.track(label, { action: 'Dropdown', value });
}

export function input({ label, value = '' }) {
  mixpanel.track(label, { action: 'Input', value });
}
