import BaseModel from '../base';

export default class TimelineModel extends BaseModel {
  defaults() {
    return {
      createdStepComplete: false,
      acceptedStepComplete: false,
      negotiationStepComplete: false,
      agreedStepComplete: false,
      paidStepComplete: false
    };
  }
}
