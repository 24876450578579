import BaseModel from '../base';

export default class IncomeExpenditureModel extends BaseModel {
  constructor(data) {
    super(data);
  }

  defaults() {
    return {
      id: null,
      flatbondTenantId: null,
      accountReference: null,
      employmentStatus: null,
      occupation: null,
      employerName: null,
      takeHomeIncome: 0,
      benefitIncome: 0,
      benefitType: null,
      otherIncome: 0,
      rent: 0,
      councilTax: 0,
      utilityBills: 0,
      loanRepayments: 0,
      creditCards: 0,
      telephones: 0,
      childMaintenance: 0,
      courtFines: 0,
      otherOneCategory: null,
      otherOneAmount: 0,
      otherTwoCategory: null,
      otherTwoAmount: 0,
      otherThreeCategory: null,
      otherThreeAmount: 0,
      otherFourCategory: null,
      otherFourAmount: 0,
      totalExpenditure: 0,
      totalIncome: 0
    };
  }
}
