import BaseModel from '../base';
import { InternalUserModel } from '@/models/internal/user';
import moment from 'moment';
import FlatbondTenantModel from '@/models/tenant';
import ChargesCaseModel from '@/models/charges_case';
import { formatCurrencyFromCents } from '@/libs/utils/helpers';
import {
  CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS,
  CHARGE_PAYMENT_PLAN_STATUS
} from '@/libs/constants/generatedConstants';

export class BillingDetailsModel extends BaseModel {
  defaults() {
    return {
      cardName: null,
      cardNumberComplete: false,
      cardCvcComplete: false,
      cardExpiryComplete: false,
      line1: null,
      city: null,
      postcode: null,
      country: null
    };
  }

  toStripeModel() {
    return {
      name: this.cardName,
      address: {
        city: this.city,
        country: this.country,
        line1: this.line1,
        postal_code: this.postcode
      }
    };
  }
}

export class CardModel extends BaseModel {
  defaults() {
    return {
      brand: null,
      last4: null
    };
  }
}

export class PaymentMethodModel extends BaseModel {
  constructor(data) {
    super(data);

    this.card = this.card ? new CardModel(this.card) : null;
    this.billingDetails = this.billingDetails ? new BillingDetailsModel(this.billingDetails) : null;
  }

  defaults() {
    return {
      id: null,
      card: null,
      billingDetails: null
    };
  }

  toStripeModel() {
    return this.id;
  }
}

export class SubscriptionModel extends BaseModel {
  constructor(data) {
    super(data);

    this.paymentMethod = this.paymentMethod ? new PaymentMethodModel(this.paymentMethod) : null;
    this.currentPeriodEnd = new Date(this.currentPeriodEnd);
  }

  defaults() {
    return {
      id: null,
      status: null,
      currency: null,
      interval: null,
      paymentMethod: null,
      paymentStatus: null,
      monthlyAmount: 0,
      upcomingAmount: 0,
      currentPeriodEnd: null
    };
  }
}

export class SubscriptionInvoiceModel extends BaseModel {
  defaults() {
    return {
      invoiceNumber: null,
      createdAt: null,
      total: null,
      invoicePdf: null
    };
  }
}

const PAYMENT_PLAN_STATUS_LABEL_MAP = {
  [CHARGE_PAYMENT_PLAN_STATUS.CREATED]: 'ops.charges.case.stripePaymentPlan.statusLabel.created',
  [CHARGE_PAYMENT_PLAN_STATUS.ACCEPTED]: 'ops.charges.case.stripePaymentPlan.statusLabel.accepted',
  [CHARGE_PAYMENT_PLAN_STATUS.CLOSED]: 'ops.charges.case.stripePaymentPlan.statusLabel.closed',
  [CHARGE_PAYMENT_PLAN_STATUS.IN_PROGRESS]:
    'ops.charges.case.stripePaymentPlan.statusLabel.inProgress',
  [CHARGE_PAYMENT_PLAN_STATUS.PENDING_AGREEMENT]:
    'ops.charges.case.stripePaymentPlan.statusLabel.pendingAgreement',
  [CHARGE_PAYMENT_PLAN_STATUS.PENDING_INITIAL_PAYMENT]:
    'ops.charges.case.stripePaymentPlan.statusLabel.pendingInitialPayment'
};

export class InternalChargePaymentPlanModel extends BaseModel {
  constructor(data) {
    super(data);

    this.flatbondTenant = this.flatbondTenant ? new FlatbondTenantModel(this.flatbondTenant) : null;
    this.chargeCase = this.chargeCase ? new ChargesCaseModel(this.chargeCase) : null;
  }

  defaults() {
    return {
      accepted: null,
      acceptedOn: null,
      chargeCase: null,
      closedOn: null,
      createdOn: null,
      flatbondTenant: null,
      id: null,
      period: null,
      status: null,
      stripeSubscriptionId: null
    };
  }

  statusLabel($t) {
    return $t(PAYMENT_PLAN_STATUS_LABEL_MAP[this.status]);
  }

  get isPending() {
    return [
      CHARGE_PAYMENT_PLAN_STATUS.CREATED,
      CHARGE_PAYMENT_PLAN_STATUS.PENDING_AGREEMENT
    ].includes(this.status);
  }

  get isClosed() {
    return [CHARGE_PAYMENT_PLAN_STATUS.CLOSED].includes(this.status);
  }
}

const INSTALLMENT_STATUS_LABEL_MAP = {
  [CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.CREATED]:
    'ops.charges.case.stripePaymentPlan.table.statusLabel.created',
  [CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.PAID_IN_FULL]:
    'ops.charges.case.stripePaymentPlan.table.statusLabel.paidInFull',
  [CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.PARTIALLY_PAID]:
    'ops.charges.case.stripePaymentPlan.table.statusLabel.partiallyPaid',
  [CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.PAYMENT_PAST_DUE]:
    'ops.charges.case.stripePaymentPlan.table.statusLabel.paymentPastDue',
  [CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.PENDING_PAYMENT]:
    'ops.charges.case.stripePaymentPlan.table.statusLabel.pendingPayment'
};

export class InternalChargePaymentPlanInstallmentModel extends BaseModel {
  defaults() {
    return {
      amount: null,
      chargePaymentPlan: null,
      createdOn: null,
      dueDate: null,
      id: null,
      status: null
    };
  }

  formattedDueDate() {
    if (this.dueDate) {
      return new moment(this.dueDate).format('DD/MM/YYYY');
    }
    return null;
  }

  formattedAmount() {
    if (this.amount) {
      return formatCurrencyFromCents(this.amount);
    }
    return null;
  }

  statusLabel($t) {
    return $t(INSTALLMENT_STATUS_LABEL_MAP[this.status]);
  }

  get isPending() {
    return [
      CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.CREATED,
      CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.PARTIALLY_PAID,
      CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.PENDING_PAYMENT
    ].includes(this.status);
  }

  get isFailed() {
    return [CHARGE_PAYMENT_PLAN_INSTALLMENT_STATUS.PAYMENT_PAST_DUE].includes(this.status);
  }
}

export const PAYOUT_TYPE = {
  LANDLORD_OFFER_ASSIGNMENT: 'landlord_offer_assignment',
  CHARGE: 'charge',
  INSTALLMENT: 'installment'
};

export const PAYOUT_STATUS = {
  PENDING_APPROVAL: 'pending_approval',
  PENDING_PAYMENT_TRANSFER: 'pending_payment_transfer',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled'
};

export const PAYOUT_STATUS_TO_LABEL_ID = {
  [PAYOUT_STATUS.PENDING_APPROVAL]: 'payout.list.status.pendingApproval',
  [PAYOUT_STATUS.PENDING_PAYMENT_TRANSFER]: 'payout.list.status.pendingPaymentTransfer',
  [PAYOUT_STATUS.COMPLETED]: 'payout.list.status.completed',
  [PAYOUT_STATUS.CANCELLED]: 'payout.list.status.cancelled'
};

export const PAYOUT_TYPE_TO_LABEL_ID = {
  [PAYOUT_TYPE.LANDLORD_OFFER_ASSIGNMENT]: 'payout.list.type.landlordOfferAssignment',
  [PAYOUT_TYPE.CHARGE]: 'payout.list.type.charge',
  [PAYOUT_TYPE.INSTALLMENT]: 'payout.list.type.installment'
};

const PAY_TO = {
  AGENT: 'pay_to_agent',
  LANDLORD: 'pay_to_landlord'
};

class InternalPaymentPayOutFlatbondModel extends BaseModel {
  defaults() {
    return {
      id: null
    };
  }
}

class InternalMetadataActionModel extends BaseModel {
  defaults() {
    return {};
  }
}

export class InternalPayoutSummaryModel extends BaseModel {
  defaults() {
    return {
      appointedPayouts: 0,
      sumOfPayouts: 0,
      collectionCost: 0,
      recoveryFee: 0,
      assignedDebt: 0
    };
  }
}

export class InternalPaymentPayOutModel extends BaseModel {
  constructor(data) {
    super(data);
    this.flatbond = new InternalPaymentPayOutFlatbondModel(this.flatbond);
    this.approvedBy = this.approvedBy && new InternalUserModel(this.approvedBy);
    this.paidBy = this.paidBy && new InternalUserModel(this.paidBy);
    this.actions = (this.actions || []).map(action => new InternalMetadataActionModel(action));
    this.createdOn = this.createdOn && moment(this.createdOn);
    this.lastTransitionedOn = this.lastTransitionedOn && moment(this.lastTransitionedOn);
  }

  defaults() {
    return {
      id: null,
      status: null,
      type: null,
      amount: null,
      nameOnAccount: null,
      reference: null,
      accountNumber: null,
      sortCode: null,
      payTo: null
    };
  }

  statusLabel($t) {
    return $t(PAYOUT_STATUS_TO_LABEL_ID[this.status]);
  }

  typeLabel($t) {
    return $t(PAYOUT_TYPE_TO_LABEL_ID[this.type]);
  }

  get isPending() {
    return (
      [PAYOUT_STATUS.PENDING_PAYMENT_TRANSFER, PAYOUT_STATUS.PENDING_APPROVAL].indexOf(
        this.status
      ) >= 0
    );
  }

  get isFailing() {
    return PAYOUT_STATUS.CANCELLED === this.status;
  }

  get payToAgent() {
    return this.payTo === PAY_TO.AGENT;
  }

  get payToLandlord() {
    return this.payTo === PAY_TO.LANDLORD;
  }
}

export class PublicChargePaymentSessionModel extends BaseModel {
  defaults() {
    return {
      id: '',
      tenantFirstName: '',
      postcode: ''
    };
  }
}

export class TruelayerManualTransactionModel extends BaseModel {
  defaults() {
    return {
      trueLayerId: null,
      amount: null,
      userName: null,
      reference: null
    };
  }
}

class SimplePaymentModel extends BaseModel {
  defaults() {
    return {
      id: null,
      amount: null,
      createdOn: null
    };
  }
}

class SimpleFlatbondTenantModel extends BaseModel {
  defaults() {
    return {
      firstName: null,
      lastName: null,
      flatbondId: null
    };
  }
}

export class PaymentModel extends BaseModel {
  constructor(data) {
    super(data);
    this.payment = new SimplePaymentModel(this.payment);
    this.flatbondTenant = new SimpleFlatbondTenantModel(this.flatbondTenant);
  }

  defaults() {
    return {
      payment: null,
      flatbondTenant: null
    };
  }
}
