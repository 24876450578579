export const ROUTES = {
  NOTIFICATIONS: 'NOTIFICATIONS'
};

export const notifications = {
  name: ROUTES.NOTIFICATIONS,
  path: '/agent/notifications/',
  component: () => import('@/pages/agent/notifications/notifications.vue')
};

export default [notifications];
