import { NotificationsApiBase } from '@/libs/apis/notifications/base';
import { NotificationsModel } from '@/models/notifications';
import { NotificationsCountModel } from '@/models/notifications/count';

export class NotificationsApi extends NotificationsApiBase {
  async list(pageNumber = 1) {
    const response = await super.get(`?page=${pageNumber}`);

    return [
      response.data.results.map(notification => new NotificationsModel(notification)),
      response.data.next
    ];
  }

  async count() {
    const response = await super.get('count/');
    return new NotificationsCountModel(response.data);
  }

  markSeen(seenNotificationsList) {
    return super.post('seen/', { seen_notifications_list: seenNotificationsList });
  }

  async markAsClicked(pk) {
    const response = await super.post(`${pk}/clicked/`, {});
    return new NotificationsModel(response.data);
  }
}
