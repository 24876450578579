<template>
  <div>
    <FpModal @close="$emit('close')">
      <template v-if="!resolved">
        <FpFlexGrid space-around compact>
          <FpFlexGridItem>
            <FpImage :style="{ width: 'auto' }" :title="$t('apiFeedbackModal.loading.image.title')">
              <img
                :style="{ height: '100px' }"
                :src="loadingCircle"
                :alt="$t('apiFeedbackModal.loading.image.alt')"
              />
            </FpImage>
          </FpFlexGridItem>
        </FpFlexGrid>
      </template>
      <template v-else-if="!resolvedWithError">
        <FpFlexGrid space-around compact>
          <FpFlexGridItem>
            <FpImage :style="{ width: 'auto' }" :title="$t('apiFeedbackModal.success.image.title')">
              <img
                :style="{ height: '100px' }"
                :src="successCheckNew"
                :alt="$t('apiFeedbackModal.success.image.alt')"
              />
            </FpImage>
          </FpFlexGridItem>
          <FpFlexGridItem>
            <FpHeader1>{{ headerOnSuccess }}</FpHeader1>
          </FpFlexGridItem>
          <FpFlexGridItem v-if="extraMessageOnSuccess">
            <FpBody>{{ extraMessageOnSuccess }}</FpBody>
          </FpFlexGridItem>
        </FpFlexGrid>
      </template>
      <template v-else-if="resolvedWithError">
        <FpFlexGrid space-around compact>
          <FpFlexGridItem>
            <FpImage :style="{ width: 'auto' }" :title="$t('apiFeedbackModal.error.image.title')">
              <img
                :style="{ height: '100px' }"
                :src="feedbackNegative"
                :alt="$t('apiFeedbackModal.error.image.alt')"
              />
            </FpImage>
          </FpFlexGridItem>
          <FpFlexGridItem>
            <FpHeader1>{{ headerOnError }}</FpHeader1>
          </FpFlexGridItem>
          <FpFlexGridItem v-if="messageToAskForHelpFromSupport">
            <FpBody>
              {{ $t('apiFeedbackModal.errorMessage.nonClickable') }}
              <FpLink button @click="contactSupport">
                {{ $t('apiFeedbackModal.errorMessage.clickable') }}
              </FpLink>
            </FpBody>
          </FpFlexGridItem>
        </FpFlexGrid>
      </template>
      <template v-if="resolved" #actions>
        <FpFlex compact>
          <FpFlexItem grow>
            <FpButton
              :data-testid="TEST_IDS.CLOSE_BUTTON"
              :secondary="resolvedWithError || !!extraSuccessActionLabel"
              fluid
              @click="$emit('close')"
            >
              {{ $t('apiFeedbackModal.actions.close') }}
            </FpButton>
          </FpFlexItem>
          <FpFlexItem v-if="resolvedWithError && allowsRetry" grow>
            <FpButton fluid :data-testid="TEST_IDS.TRY_AGAIN_BUTTON" @click="$emit('retry')">
              {{ $t('apiFeedbackModal.actions.tryAgain') }}
            </FpButton>
          </FpFlexItem>
          <FpFlexItem v-else-if="extraSuccessActionLabel" grow>
            <FpButton fluid :data-testid="TEST_IDS.EXTRA_SUCCESS_BUTTON" @click="$emit('extra')">
              {{ extraSuccessActionLabel }}
            </FpButton>
          </FpFlexItem>
        </FpFlex>
      </template>
    </FpModal>
    <FpModalTarget />
  </div>
</template>
<script>
import { FpImage, FpHeader1, FpBody, FpLink } from '@flatfair/vue-fairplay/atoms';
import {
  FpFlex,
  FpFlexItem,
  FpFlexGrid,
  FpFlexGridItem,
  FpModal,
  FpModalTarget,
  FpButton
} from '@flatfair/vue-fairplay/molecules';
import loadingCircle from '@/images/loading_circle.svg';
import successCheckNew from '@/images/success_check_new.svg';
import feedbackNegative from '@/images/feedback_negative.svg';
import * as Zendesk from '@/libs/services/integration/zendesk';

export const TEST_IDS = {
  CLOSE_BUTTON: 'api_feedback_modal__close_button',
  TRY_AGAIN_BUTTON: 'api_feedback_modal__try_again_button',
  EXTRA_SUCCESS_BUTTON: 'api_feedback_modal__extra_success_button'
};

export default {
  components: {
    FpHeader1,
    FpBody,
    FpImage,
    FpLink,
    FpModal,
    FpModalTarget,
    FpButton,
    FpFlex,
    FpFlexItem,
    FpFlexGrid,
    FpFlexGridItem
  },
  props: {
    resolved: {
      type: Boolean,
      default: false
    },
    resolvedWithError: {
      type: Boolean,
      default: false
    },
    headerOnSuccess: {
      type: String,
      required: true
    },
    extraMessageOnSuccess: {
      type: String,
      default: ''
    },
    headerOnError: {
      type: String,
      required: true
    },
    messageToAskForHelpFromSupport: {
      type: String,
      default: ''
    },
    extraSuccessActionLabel: {
      type: String,
      default: null
    },
    allowsRetry: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loadingCircle,
      successCheckNew,
      feedbackNegative
    };
  },
  created() {
    this.TEST_IDS = TEST_IDS;
  },
  methods: {
    contactSupport() {
      Zendesk.openAndPostMessage(this.messageToAskForHelpFromSupport);
    }
  }
};
</script>
