import { InternalMetadataApiBase } from '@/libs/apis/metadata/internal/base';
import * as querystring from 'querystring';
import { InternalMetadataNoteModel } from '@/models/internal/metadata';

export class InternalMetadataNoteApi extends InternalMetadataApiBase {
  async list({
    tenantId,
    userId,
    landlordId,
    guarantorId,
    flatbondId,
    landlordOfferId,
    chargeCaseId,
    chargePaymentPlanId,
    chargeId,
    payOutId,
    q,
    pageSize = 100,
    page = 1
  } = {}) {
    const qs = querystring.stringify({
      tenant: tenantId,
      user: userId,
      landlord: landlordId,
      guarantor: guarantorId,
      flatbond_id: flatbondId,
      landlord_offer_id: landlordOfferId,
      charge_case_id: chargeCaseId,
      charge_payment_plan_id: chargePaymentPlanId,
      pay_out_id: payOutId,
      charge_id: chargeId,
      page_size: pageSize,
      page,
      search: q
    });
    const { data } = await this.get(`note/?${qs}`);
    const results = data.results.map(result => new InternalMetadataNoteModel(result));
    return { ...data, results };
  }

  async create({
    comment,
    tenantId,
    userId,
    landlordId,
    guarantorId,
    flatbondId,
    landlordOfferId,
    chargePaymentPlanId,
    chargeCaseId,
    payOutId,
    chargeId
  }) {
    const body = {
      tenant_id: tenantId,
      user_id: userId,
      landlord_id: landlordId,
      guarantor_id: guarantorId,
      flatbond_id: flatbondId,
      landlord_offer_id: landlordOfferId,
      charge_payment_plan_id: chargePaymentPlanId,
      charge_case_id: chargeCaseId,
      pay_out_id: payOutId,
      charge_id: chargeId,
      comment
    };
    const { data } = await this.post('note/', body);
    return new InternalMetadataNoteModel(data);
  }

  async pin(noteId) {
    const { data } = await this.post(`note/${encodeURIComponent(noteId)}/pin/`);
    return new InternalMetadataNoteModel(data);
  }

  async unpin(noteId) {
    const { data } = await this.post(`note/${encodeURIComponent(noteId)}/unpin/`);
    return new InternalMetadataNoteModel(data);
  }
}
