import { NavItemModel } from '@/models/nav_item';
import get from 'lodash/get';
import { NavSectionModel } from '@/models/nav_section';
import { FEATURE_FLAGS } from '@/models/config';
import Moment from 'moment';

export default {
  main: new NavSectionModel({
    label: 'nav.section.main',
    items: [
      new NavItemModel({
        label: 'nav.links.homepage',
        href: '/agent/',
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser')
      }),
      new NavItemModel({
        label: 'nav.links.guarantor.homepage',
        href: '/guarantor/',
        newTab: false,
        userTypes: ['guarantor'],
        iconCode: '',
        resolve: context => !!get(context, 'user.isAGuarantorOnAPlan')
      }),
      new NavItemModel({
        label: 'nav.links.guarantor.plans',
        href: '/guarantor/tenancies',
        userTypes: ['guarantor'],
        iconCode: '',
        resolve: context => !!get(context, 'user.isAGuarantorOnAPlan')
      }),
      new NavItemModel({
        label: 'nav.links.tenant.homepage',
        href: '/tenant/',
        userTypes: ['tenant'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.tenants.plans',
        href: '/tenant/tenancies',
        userTypes: ['tenant'],
        iconCode: '',
        resolve: context => !!get(context, 'user.isATenantOnAPlan')
      }),
      new NavItemModel({
        label: 'nav.links.tenants.referencing',
        href: '/referencing/tenant',
        userTypes: ['tenant', 'guarantor'],
        iconCode: '',
        resolve: context =>
          !!get(context, 'user.isATenantOnAReferencingApplication') ||
          !!get(context, 'user.isAGuarantorOnAReferencingApplication')
      }),
      new NavItemModel({
        label: 'nav.links.plans',
        href: '/agent/plans',
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser.showPlansInSidebar')
      }),
      new NavItemModel({
        label: 'nav.links.landlord.homepage',
        href: '/landlord/',
        userTypes: ['landlord'],
        iconCode: '',
        resolve: context => context.featureEnabled(FEATURE_FLAGS.LANDLORD_SIGNATURE_IMPROVEMENTS)
      }),
      new NavItemModel({
        label: 'nav.links.landlord.plans',
        href: '/landlords',
        userTypes: ['landlord'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.landlord.billing',
        href: '/landlord/user/billing',
        userTypes: ['landlord'],
        iconCode: '',
        resolve: context =>
          !!context.featureEnabled(FEATURE_FLAGS.BOOST) ||
          !!context.hasAtLeastOneActivePaidBoostSubscription
      }),
      new NavItemModel({
        label: 'nav.links.landlord.arrears',
        href: '/landlord/arrears/flatbond',
        userTypes: ['landlord'],
        iconCode: '',
        newTab: false
      }),
      new NavItemModel({
        label: 'nav.links.landlord.offers',
        href: '/landlord/offers',
        userTypes: ['landlord'],
        iconCode: '',
        newTab: false
      }),
      new NavItemModel({
        label: 'nav.links.deposits',
        href: '/deposits',
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!context.canOfferDeposits
      }),
      new NavItemModel({
        label: 'nav.links.referencing',
        href: '/referencing',
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser.group.canOfferReferencing')
      }),
      new NavItemModel({
        label: 'nav.links.information',
        href: '/agent/information',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser.isGroupAdmin')
      }),
      new NavItemModel({
        label: 'nav.links.arrears',
        href: '/agent/arrears',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser.showArrearsInSidebar')
      }),
      new NavItemModel({
        label: 'nav.links.homepage',
        href: '/ops/homepage',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.plans',
        href: '/review/flatbonds#/flatbonds/',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      })
    ]
  }),
  selling: new NavSectionModel({
    label: 'nav.section.selling',
    items: [
      new NavItemModel({
        label: 'nav.links.prospectiveTenants',
        href: '/agent/tenants',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser.showPlansInSidebar')
      }),
      new NavItemModel({
        label: 'nav.links.prospectiveLandlords',
        href: '/agent/landlords',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context =>
          context.featureEnabled(FEATURE_FLAGS.PROSPECTIVE_LANDLORD) &&
          !!get(context, 'user.currentOrganizationUser.showPlansInSidebar')
      }),
      new NavItemModel({
        label: 'nav.links.materials',
        href: '/agent/materials',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser.showPlansInSidebar')
      }),
      new NavItemModel({
        label: 'nav.links.learn',
        href: '/agent/learn/training-videos',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!get(context, 'user.currentOrganizationUser.showPlansInSidebar')
      }),
      new NavItemModel({
        label: 'nav.links.incentive',
        href: '/agent/cash-rewards',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => {
          const sellersIncentiveUntil = context.organizationSellersIncentiveUntil;

          if (sellersIncentiveUntil === null) {
            return false;
          }

          return Moment().isSameOrBefore(sellersIncentiveUntil, 'day');
        }
      }),
      new NavItemModel({
        label: 'nav.links.rewards',
        href: '/agent/rewards',
        newTab: false,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => context.featureEnabled(FEATURE_FLAGS.AGENT_SCRATCH_CARDS)
      })
    ]
  }),
  settings: new NavSectionModel({
    label: 'nav.section.settings',
    items: [
      new NavItemModel({
        label: 'nav.links.organization',
        href: '/organization/',
        userTypes: ['agent'],
        iconCode: '',
        resolve: context =>
          !!get(context, 'user.currentOrganizationUser') &&
          !!get(context, 'user.currentOrganizationUser.showPlansInSidebar')
      })
    ]
  }),
  help: new NavSectionModel({
    label: 'nav.section.help',
    items: [
      new NavItemModel({
        label: 'nav.links.tenants.downloadCentre',
        href: '/tenants/download-centre',
        userTypes: ['tenant'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.landlord.documentCentre',
        href: '/landlords/document-centre',
        userTypes: ['landlord'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.helpCentre',
        href: '//help.flatfair.co.uk',
        newTab: true,
        userTypes: ['agent', 'landlord', 'guarantor', 'tenant'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.status',
        href: '//flatfair.statuspage.io/',
        newTab: true,
        userTypes: ['agent', 'landlord', 'guarantor', 'tenant'],
        iconCode: '',
        resolve: context => ['production', 'demo'].indexOf(get(context, 'env')) < 0
      }),
      new NavItemModel({
        label: 'nav.links.visitCommunity',
        href: '//community.flatfair.co.uk/',
        newTab: true,
        userTypes: ['agent'],
        iconCode: '',
        resolve: context => !!context.featureEnabled(FEATURE_FLAGS.FLATFAIR_COMMUNITY)
      })
    ]
  }),
  referencing: new NavSectionModel({
    label: 'nav.section.referencing',
    items: [
      new NavItemModel({
        label: 'nav.links.applications',
        href: '/review/ops/referencing#/referencing/',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      })
    ]
  }),
  endOfTenancy: new NavSectionModel({
    label: 'nav.section.endOfTenancy',
    items: [
      new NavItemModel({
        label: 'nav.links.charges',
        href: '/review/ops/charges#/case/',
        newTab: false,
        iconCode: '',
        userTypes: ['admin']
      }),
      new NavItemModel({
        label: 'nav.links.debtCollection',
        href: '/review/ops/debt-collection#/debt-collection/',
        newTab: false,
        iconCode: '',
        userTypes: ['admin']
      }),
      new NavItemModel({
        label: 'nav.links.landlordOffers',
        href: '/ops/landlord-offer',
        newTab: false,
        iconCode: '',
        userTypes: ['admin']
      }),
      new NavItemModel({
        label: 'nav.links.paymentDashboard',
        href: '/ops/payout',
        newTab: false,
        iconCode: '',
        userTypes: ['admin']
      })
    ]
  }),
  partners: new NavSectionModel({
    label: 'nav.section.partners',
    items: [
      new NavItemModel({
        label: 'nav.links.organizations',
        href: '/review/ops/organization#/organization/',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.billing',
        href: '/ops/billing',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      })
    ]
  }),
  traditionalDeposits: new NavSectionModel({
    label: 'nav.section.other',
    items: [
      new NavItemModel({
        label: 'nav.links.deposits',
        href: '/review/ops/deposits#/deposits/',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.tools',
        href: '/ops/tools',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      }),
      new NavItemModel({
        label: 'nav.links.manualTransactions',
        href: '/review/ops/deposits#/manual-transfers/',
        newTab: false,
        userTypes: ['admin'],
        iconCode: ''
      })
    ]
  })
};
