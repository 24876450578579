<template>
  <NavContainer>
    <slot />
  </NavContainer>
</template>
<script>
import NavContainer from '@/components/nav_container/nav_container.vue';

export default {
  components: {
    NavContainer
  }
};
</script>
