import { InternalFrontendApiBase } from './base';

export class InternalFrontendApi extends InternalFrontendApiBase {
  constructor(vue) {
    super(vue, `/feature`);
    this.errorName = 'InternalFrontendApi';
  }

  getVerificationStatus() {
    return super.get('get-auto-verification-flags/', 'Failed to get verification status');
  }

  toggleFeatureFlag(featureFlagType, isBlocked) {
    return super.post(
      'toggle-feature-flag/',
      {
        type: featureFlagType,
        block: isBlocked
      },
      'Failed to toggle feature flag status'
    );
  }
}
