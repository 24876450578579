export const ARREARS_ROUTES = {
  INTRO: 'AGENT_ARREARS_INTRO',
  BULK_SUBMISSION: 'AGENT_ARREARS_BULK_SUBMISSION',
  FLATBOND_SEARCH: 'AGENT_ARREARS_FLATBOND_SEARCH',
  FLATBOND_SUBMISSION: 'AGENT_ARREARS_FLATBOND_SUBMISSION',
  SUBMITTED: 'AGENT_SUBMITTED'
};

export const intro = {
  path: '/agent/arrears',
  component: () => import('@/pages/agent/arrears/intro.vue'),
  name: ARREARS_ROUTES.INTRO
};

export const bulk_submission = {
  path: '/agent/arrears/bulk',
  component: () => import('@/pages/agent/arrears/bulk_submission.vue'),
  name: ARREARS_ROUTES.BULK_SUBMISSION
};

export const flatbond_search = {
  path: '/agent/arrears/flatbond',
  component: () => import('@/pages/agent/arrears/flatbond_search.vue'),
  name: ARREARS_ROUTES.FLATBOND_SEARCH
};

export const flatbond_submission = {
  path: '/agent/arrears/flatbond/:flatbondId',
  component: () => import('@/pages/agent/arrears/flatbond_submission.vue'),
  name: ARREARS_ROUTES.FLATBOND_SUBMISSION
};

export const submitted = {
  path: '/agent/arrears/submitted',
  component: () => import('@/pages/agent/arrears/submitted.vue'),
  name: ARREARS_ROUTES.SUBMITTED
};

export default [intro, bulk_submission, flatbond_search, flatbond_submission, submitted];
