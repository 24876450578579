import { InternalChargeApiBase } from './base';

export class InternalChargePaymentPlanInstallmentApi extends InternalChargeApiBase {
  list(planId, query = '') {
    return super.get(
      `payment-plan/${planId}/installment/${query}`,
      'Failed to list payment plan installments'
    );
  }

  get(planId, installmentId) {
    return super.get(
      `payment-plan/${planId}/installment/${installmentId}`,
      'Failed to get payment plan installment'
    );
  }

  updateStatus(planId, installmentId, newStatus) {
    const data = {
      status: newStatus
    };
    return super.put(
      `payment-plan/${planId}/installment/${installmentId}/`,
      data,
      'Failed to update status of payment plan installment'
    );
  }
}
