import { InternalDepositApiBase } from './base';

export class InternalDepositApi extends InternalDepositApiBase {
  list(query = '') {
    return super.get(`${query}`, 'Could not retrieve a list of deposits!');
  }

  get(depositId) {
    return super.get(`${depositId}`, 'Could not retrieve the deposit');
  }
}
