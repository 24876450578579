import { InternalChargeApiBase } from './base';
import { formatDateForApi, currencyUnitsToCents } from '@/libs/utils/helpers';
import StripeSubscriptionModel from '@/models/charges_case/stripe_subscription';
import { InternalChargePaymentPlanInstallmentModel } from '@/models/payment';

export class InternalChargePaymentPlanApi extends InternalChargeApiBase {
  create(tenantId, startDate, amount, period, initialDueDate = null, initialAmount = null) {
    const data = {
      flatbond_tenant_id: tenantId,
      start_date: formatDateForApi(startDate),
      amount: currencyUnitsToCents(amount),
      period: period,
      initial_due_date: formatDateForApi(initialDueDate),
      initial_amount: currencyUnitsToCents(initialAmount)
    };
    return super.post(`payment-plan/`, data, 'Failed to create payment plan');
  }

  list(query = '') {
    return super.get(`payment-plan/${query}`, 'Failed to list payment plans');
  }

  get(planId) {
    return super.get(`payment-plan/${planId}`, 'Failed to get payment plan');
  }

  async getSubscriptionDetails(planId) {
    const { data } = await super.get(
      `payment-plan/${planId}/get-subscription-details`,
      'Failed to get subscription details'
    );
    return new StripeSubscriptionModel(data.stripe_subscription);
  }

  createStripePaymentPlanProduct(flatbondId) {
    return super.post(
      `payment-plan/create-stripe-payment-plan-product/`,
      { flatbond_id: flatbondId },
      'Failed to create stripe product for payment plan'
    );
  }

  async getPaymentPlanInstallments(planId) {
    const { data } = await super.get(
      `payment-plan/${planId}/get-payment-plan-installments/`,
      'Failed to get payment plan installments'
    );
    if (data) {
      return data.map(installment => new InternalChargePaymentPlanInstallmentModel(installment));
    }
    return new InternalChargePaymentPlanInstallmentModel();
  }

  acceptOnBehalfOfTenant(planId) {
    return super.put(
      `payment-plan/${planId}/accept/`,
      {},
      'Failed to accept plan on tenants behalf'
    );
  }
}
