import { InternalFlatbondApiBase } from './base';
import FlatbondUpdateModel from '@/models/flatbond/update';

export class InternalFlatbondUpdateApi extends InternalFlatbondApiBase {
  constructor(vue) {
    super(vue, `/update`);
    this.errorName = 'InternalFlatbondUpdateApi';
  }

  async getDebtCollectionActions(planId) {
    const { data } = await super.get(
      `${planId}/get-debt-collection-actions`,
      'Failed to get debt collection actions'
    );

    return data.map(flatbondUpdate => new FlatbondUpdateModel(flatbondUpdate));
  }

  create(flatbond, changeType, createdOn, category) {
    const data = {
      flatbond: flatbond,
      change_type: changeType,
      created_on: createdOn,
      category: category,
      tags: []
    };

    return super.post('', data, 'Failed to create flatbond update');
  }
}
