import { TrainingStatusApi } from '@/libs/apis';
import TrainingStatusModel from '@/models/training';
import config, { FEATURE_FLAGS } from '@/libs/utils/config';

const REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD = async (to, from, next) => {
  if (!config.features.has(FEATURE_FLAGS.AGENT_TRAINING_FLOW)) {
    window.location = '/';
    return;
  }

  next();
};

const REDIRECT_TO_QUIZ_START_OR_HOME_IF_FLAGS_NOT_ENABLED = (to, from, next) => {
  if (!config.features.has(FEATURE_FLAGS.AGENT_TRAINING_FLOW)) {
    window.location = '/';
    return;
  }

  if (!config.features.has(FEATURE_FLAGS.AGENT_TRAINING_INTERACTIVE_STEPS)) {
    next({ name: ROUTES.QUIZ_START });
    return;
  }

  next();
};

export const ROUTES = {
  WELCOME: 'WELCOME',
  PROFILE: 'PROFILE',
  VIDEO: 'VIDEO',
  QUIZ_START: 'QUIZ_START',
  QUIZ: 'QUIZ',
  QUIZ_RESULTS: 'QUIZ_RESULTS',
  INVITE: 'INVITE',
  COMPLETE: 'COMPLETE',
  TRAINING: 'TRAINING'
};

export const training = {
  name: ROUTES.TRAINING,
  path: '/agent/training',
  beforeEnter: async (to, from, next) => {
    if (!config.features.has(FEATURE_FLAGS.AGENT_TRAINING_FLOW)) {
      window.location = '/';
      return;
    }

    const api = new TrainingStatusApi(this);
    const response = await api.getOrCreate();
    const trainingStatus = new TrainingStatusModel(response.data);

    if (!trainingStatus.branchSelectionComplete) {
      next({ name: ROUTES.WELCOME });
    } else if (!trainingStatus.videoComplete) {
      next({ name: ROUTES.VIDEO });
    } else if (!trainingStatus.quizComplete) {
      next({ name: ROUTES.QUIZ_START });
    } else {
      window.location = '/';
    }
  }
};

export const trainingWelcome = {
  name: ROUTES.WELCOME,
  path: '/agent/training/welcome',
  beforeEnter: REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD,
  component: () => import('@/pages/agent/training/welcome.vue'),
  meta: {
    NEXT_STEP: ROUTES.PROFILE
  }
};

export const trainingProfile = {
  name: ROUTES.PROFILE,
  path: '/agent/training/profile',
  beforeEnter: REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD,
  component: () => import('@/pages/agent/training/profile.vue'),
  meta: {
    PREV_STEP: ROUTES.WELCOME,
    NEXT_STEP: ROUTES.VIDEO
  }
};

export const trainingVideo = {
  name: ROUTES.VIDEO,
  path: '/agent/training/video',
  beforeEnter: REDIRECT_TO_QUIZ_START_OR_HOME_IF_FLAGS_NOT_ENABLED,
  component: () => import('@/pages/agent/training/video.vue'),
  meta: {
    PREV_STEP: ROUTES.PROFILE,
    NEXT_STEP: ROUTES.QUIZ_START,
    SKIP_STEP: ROUTES.QUIZ_START
  }
};

export const trainingQuizStart = {
  name: ROUTES.QUIZ_START,
  path: '/agent/training/quiz/start',
  beforeEnter: REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD,
  component: () => import('@/pages/agent/training/quiz_start.vue'),
  meta: {
    PREV_STEP: config.features.has(FEATURE_FLAGS.AGENT_TRAINING_INTERACTIVE_STEPS)
      ? ROUTES.VIDEO
      : ROUTES.PROFILE,
    NEXT_STEP: ROUTES.QUIZ,
    SKIP_STEP: ROUTES.INVITE
  }
};

export const trainingQuiz = {
  name: ROUTES.QUIZ,
  path: '/agent/training/quiz',
  beforeEnter: REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD,
  component: () => import('@/pages/agent/training/quiz.vue'),
  meta: {
    PREV_STEP: ROUTES.QUIZ_START,
    NEXT_STEP: ROUTES.QUIZ_RESULTS,
    SKIP_STEP: ROUTES.INVITE
  }
};

export const trainingQuizResults = {
  name: ROUTES.QUIZ_RESULTS,
  path: '/agent/training/quiz/results',
  beforeEnter: REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD,
  component: () => import('@/pages/agent/training/quiz_results'),
  meta: {
    PREV_STEP: ROUTES.QUIZ,
    NEXT_STEP: ROUTES.INVITE
  }
};

export const trainingInvite = {
  name: ROUTES.INVITE,
  path: '/agent/training/invite',
  beforeEnter: REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD,
  component: () => import('@/pages/agent/training/invite.vue'),
  meta: {
    PREV_STEP: ROUTES.QUIZ_RESULTS,
    NEXT_STEP: ROUTES.COMPLETE
  }
};

export const trainingComplete = {
  name: ROUTES.COMPLETE,
  path: '/agent/training/complete',
  beforeEnter: REDIRECT_TO_HOME_IF_NO_AGENT_TRAINING_ENABLED_GUARD,
  component: () => import('@/pages/agent/training/complete.vue')
};

export default [
  training,
  trainingWelcome,
  trainingProfile,
  trainingVideo,
  trainingQuizStart,
  trainingQuiz,
  trainingQuizResults,
  trainingInvite,
  trainingComplete
];
