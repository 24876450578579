import { TENANT_REFERENCING_UP_SELL_ROUTES } from './constants';
import { TenantReferenceRequestApi } from '@/libs/apis/referencing/tenant';

export const referencingUpSellRoute = {
  name: TENANT_REFERENCING_UP_SELL_ROUTES.BASE,
  path: '/tenant/referencing/:referencingRequestId',
  component: () => import('./up_sell.vue'),
  beforeEnter: async (to, from, next) => {
    const api = new TenantReferenceRequestApi(this);
    try {
      const referenceRequest = await api.get(to.params.referencingRequestId);

      if (referenceRequest.hasReferencingUpsellFlowEnabled && referenceRequest.thirdPartyLink) {
        next();
      } else {
        window.location = '/';
      }
    } catch {
      window.location = '/';
    }
  },
  redirect: {
    name: TENANT_REFERENCING_UP_SELL_ROUTES.WELCOME
  },
  children: [
    {
      name: TENANT_REFERENCING_UP_SELL_ROUTES.WELCOME,
      path: 'welcome',
      props: true,
      component: () => import('@/components/referencing/tenant/up_sell/welcome')
    },
    {
      name: TENANT_REFERENCING_UP_SELL_ROUTES.INTEREST_IN_NO_DEPOSIT,
      path: 'no-deposit',
      props: true,
      component: () => import('@/components/referencing/tenant/up_sell/interest_in_no_deposit')
    },
    {
      name: TENANT_REFERENCING_UP_SELL_ROUTES.INTEREST_IN_NO_DEPOSIT_FEEDBACK,
      path: 'no-deposit-feedback',
      props: true,
      component: () =>
        import('@/components/referencing/tenant/up_sell/interest_in_no_deposit_feedback')
    },
    {
      name: TENANT_REFERENCING_UP_SELL_ROUTES.BEFORE_THIRD_PARTY,
      path: 'before-you-go',
      props: true,
      component: () => import('@/components/referencing/tenant/up_sell/before_third_party')
    }
  ]
};
