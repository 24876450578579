import { FlatbondApiBase } from './base';
import qs from 'querystring';

export class LandlordFlatbondApi extends FlatbondApiBase {
  list(queryParams = {}) {
    return super.get(`landlord/?${qs.encode(queryParams)}`);
  }

  get(flatbondId) {
    return super.get(`landlord/${flatbondId}`);
  }

  subscribe(flatbondId, paymentMethodId) {
    return super.post(`landlord/${flatbondId}/subscribe/`, { payment_method_id: paymentMethodId });
  }

  unsubscribe(id) {
    return super.post(`landlord/${id}/unsubscribe/`);
  }

  activateBoost(flatbondId) {
    return super.post(`landlord/${flatbondId}/activate-boost/`);
  }

  skipBoostForNow(flatbondId) {
    return super.post(`landlord/${flatbondId}/upgrade-to-boost-later/`);
  }

  getDocumentsToSign(flatbondId) {
    return super.get(`landlord/${flatbondId}/documents-to-sign/`);
  }
}
