import { ITEMS_PER_PAGE } from './utils/constants';

export class Pagination {
  constructor(vue, itemsPerPage = ITEMS_PER_PAGE) {
    this.vue = vue;
    this.itemsPerPage = itemsPerPage;
    this.clearData();
  }

  clearData() {
    this.count = 0;
    this.data = [];
    this.availablePages = { 0: true };
  }

  addData(response, pageIndex) {
    this._updateSize(response.count);
    this._updateAvailablePages(pageIndex);
    this._updateData(response.results, pageIndex);
  }

  _updateAvailablePages(page) {
    let newPages = this.availablePages;
    newPages[page] = true;
    this.availablePages = Object.assign({}, newPages);
  }

  _updateSize(count) {
    while (this.data.length < count) {
      this.data.push(null);
    }
  }

  _updateData(results, pageIndex) {
    const startIndex = pageIndex * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    let index = 0;
    for (let i = startIndex; i < endIndex; i++) {
      if (results[index]) {
        this.data[i] = results[index];
      }

      index++;
    }
  }
}
