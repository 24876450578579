export const ROUTES = {
  PLAN_PAYOUTS: 'ops-plan-payouts',
  PLAN_LANDLORD_OFFERS: 'ops-plan-landlord-offers'
};

const planPayouts = {
  path: '/ops/plan/:planId/payouts',
  component: () => import('@/pages/ops/plan/payouts'),
  name: ROUTES.PLAN_PAYOUTS
};

const planLandlordOffers = {
  path: '/ops/plan/:planId/landlord-offer',
  component: () => import('@/pages/ops/plan/landlord_offers'),
  name: ROUTES.PLAN_LANDLORD_OFFERS
};

export default [planPayouts, planLandlordOffers];
