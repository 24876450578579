import { ApiBase } from '@/libs/apis/base';
import { ReferenceRequestModel } from '@/models/referencing';

export class TenantReferenceRequestApi extends ApiBase {
  constructor(vue) {
    super(vue, 'referencing');
    this.errorName = 'TenantReferenceRequestApiError';
  }

  async list() {
    const { data } = await super.get(`tenant-request/`);
    return data.map(reference => new ReferenceRequestModel(reference));
  }

  async get(requestId) {
    const { data } = await super.get(`tenant-request/${requestId}/`);
    return new ReferenceRequestModel(data);
  }

  async interestedInNoDeposit(requestId, isInterested) {
    const { data } = await super.post(`tenant-request/${requestId}/interested-in-no-deposit/`, {
      is_interested: isInterested
    });
    return new ReferenceRequestModel(data);
  }
}
