import { PropertyApplicationApiBase } from './base';
import check from 'check-types';
import { ConvertToPlanDraftModel, PropertyApplicationOutcomeModel } from '@/models/properties';

export class PropertyApplicationApi extends PropertyApplicationApiBase {
  approveApplication(applicationId) {
    return super.post(`${applicationId}/approve/`, {}, 'Failed to approve property application');
  }

  conditionallyApproveApplication(applicationId, description, condition) {
    const data = {
      description: description,
      condition: condition
    };

    return super.post(
      `${applicationId}/conditionally-approve/`,
      data,
      'Failed to conditionally approve property application'
    );
  }

  rejectApplication(applicationId) {
    return super.post(`${applicationId}/reject/`, {}, 'Failed to reject property application');
  }

  async convertToPlanDraft(applicationId) {
    const result = await super.post(
      `${applicationId}/convert-to-plan-draft/`,
      {},
      'Failed to convert to draft'
    );
    return new ConvertToPlanDraftModel(result.data);
  }

  outcome(applicationId, outcome) {
    check.assert.instance(
      outcome,
      PropertyApplicationOutcomeModel,
      'payload must be an instance of PropertyApplicationOutcomeModel'
    );
    return super.post(`${applicationId}/outcome/`, outcome, 'Failed to save outcome');
  }
}
