import qs from 'querystring';
import { GuarantorApiBase } from '@/libs/apis/guarantor/base';
import GuarantorModel from '@/models/guarantor';
import FlatbondModel from '@/models/flatbond/flatbond';

export * from './terms_and_conditions';
export * from './confirmation';

export class GuarantorApi extends GuarantorApiBase {
  async list(query = {}) {
    const { data } = await super.get(`/?${qs.encode(query)}`, 'Failed to list guarantors');
    return {
      ...data,
      results: data.results.map(guarantor => new GuarantorModel(guarantor))
    };
  }

  async get(id) {
    const { data } = await super.get(id, 'Failed to fetch guarantor details');
    return new GuarantorModel(data);
  }

  planActivationCheck(guarantorId) {
    return super.post(`${guarantorId}/activate/`, {}, 'Failed to check plan activation');
  }

  resendInvite(guarantorId) {
    return super.post(`${guarantorId}/send_invite/`, {}, 'Failed re-send invite to guarantor');
  }

  async getTenancies(userId) {
    const { data } = await super.get(`${userId}/get-tenancies/`, 'Failed to get tenancies');
    if (data) return data.map(flatbond => new FlatbondModel(flatbond));
  }
}
