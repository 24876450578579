import BaseModel from '../base';
import DocumentModel from '@/models/document';

export default class EarlyTerminationModel extends BaseModel {
  constructor(data) {
    super(data);

    this.documents = this.documents
      ? this.documents.map(document => new DocumentModel(document))
      : [];
  }

  defaults() {
    return {
      amount: null,
      option: '',
      documents: [],
      otherText: ''
    };
  }

  toFlatpieModel() {
    const data = super.toFlatpieModel();
    data.document_ids = this.documents.map(document => document.id);
    return data;
  }
}
