import { billingOverviewRedirect, subscribeRedirect } from '@/pages/landlord/billing/redirects';

export const ROUTES = {
  BILLING: 'LANDLORD-USER-BILLING',
  OVERVIEW: 'LANDLORD-USER-BILLING-OVERVIEW',
  PLAN: 'LANDLORD-USER-BILLING-PLAN',
  SUBSCRIBE: 'LANDLORD-USER-BILLING-SUBSCRIBE',
  CONFIRMATION: 'LANDLORD-USER-BILLING-CONFIRMATION'
};

export const landlordUserBilling = {
  path: `/landlord/user/billing`,
  component: () => import('@/pages/landlord/billing/billing.vue'),
  children: [
    {
      path: ``,
      redirect: { name: ROUTES.OVERVIEW }
    },
    {
      name: ROUTES.OVERVIEW,
      path: `overview`,
      component: () => import('@/components/landlord/billing/overview.vue'),
      beforeEnter: billingOverviewRedirect
    },
    {
      name: ROUTES.PLAN,
      path: `plan`,
      component: () => import('@/components/landlord/billing/plan/plan.vue')
    },
    {
      name: ROUTES.SUBSCRIBE,
      path: `subscribe/:flatbondId`,
      beforeEnter: subscribeRedirect,
      component: () =>
        import('@/components/landlord/billing/new_subscription/new_subscription.vue'),
      props: true
    },
    {
      name: ROUTES.CONFIRMATION,
      path: `confirmation`,
      component: () => import('@/components/landlord/billing/confirmation.vue')
    }
  ]
};

export default [landlordUserBilling];
