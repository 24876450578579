import { redirectIfPublicPaymentFeatureFlagDisabled } from '@/pages/public/redirects';

export const PUBLIC_PAYMENT_ROUTES = {
  PAY_WITH_LINK_INTRO: 'PUBLIC_PAY_WITH_LINK_INTRO',
  PAYMENT_WITH_LINK_SUCCESSFUL: 'PUBLIC_PAYMENT_WITH_LINK_SUCCESSFUL',
  ATTEMPTED_PAYMENT_WITH_EXPIRED_LINK: 'PUBLIC_ATTEMPTED_PAYMENT_WITH_EXPIRED_LINK'
};

const publicPayWithLinkIntro = {
  name: PUBLIC_PAYMENT_ROUTES.PAY_WITH_LINK_INTRO,
  path: '/public/pay/:linkId',
  component: () => import('@/pages/public/payment/intro.vue'),
  props: true,
  beforeEnter: redirectIfPublicPaymentFeatureFlagDisabled
};

const publicPaymentWithLinkSuccessful = {
  name: PUBLIC_PAYMENT_ROUTES.PAYMENT_WITH_LINK_SUCCESSFUL,
  path: '/public/pay/:linkId/success',
  component: () => import('@/pages/public/payment/success.vue'),
  props: true,
  beforeEnter: redirectIfPublicPaymentFeatureFlagDisabled
};

const publicAttemptedPaymentWithExpiredLink = {
  name: PUBLIC_PAYMENT_ROUTES.ATTEMPTED_PAYMENT_WITH_EXPIRED_LINK,
  path: '/public/pay/:linkId/expired',
  component: () => import('@/pages/public/payment/expired.vue'),
  props: true,
  beforeEnter: redirectIfPublicPaymentFeatureFlagDisabled
};

export default [
  publicPayWithLinkIntro,
  publicPaymentWithLinkSuccessful,
  publicAttemptedPaymentWithExpiredLink
];
