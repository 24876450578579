import { TrainingApiBase } from '@/libs/apis/training/base';

export class ProfileApi extends TrainingApiBase {
  getOptions() {
    return super.get('profile/', 'Failed to get profile options');
  }

  save(homeUnitId, tags) {
    return super.post(
      'profile/',
      { home_unit_id: homeUnitId, tags: tags },
      'Failed to save profile options'
    );
  }
}
