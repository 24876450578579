export const ROUTES = {
  EXTENSION_DETAIL: 'ops-extension-detail'
};

const extensionDetail = {
  name: ROUTES.EXTENSION_DETAIL,
  path: '/ops/flatbond-extension/:extensionId',
  component: () => import('@/pages/ops/flatbond_extension/detail.vue')
};

export default [extensionDetail];
