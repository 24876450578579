import { PublicPaymentApiBase } from '@/libs/apis/payment/public/base';

export class PublicChargePaymentApi extends PublicPaymentApiBase {
  getKey() {
    return super.get('key/', 'Failed to retrieve payment key');
  }

  createSession(linkId) {
    const data = { link: linkId };

    return super.post('session/', data, 'Failed to create payment session');
  }
}
