import qs from 'querystring';
import ProspectiveTenantModel from '@/models/tenant/prospective_tenant';
import { ApiBase } from '@/libs/apis/base';

export class ProspectiveTenantApi extends ApiBase {
  constructor(vue) {
    super(vue, 'tenant');
    this.errorName = 'ProspectiveTenantApiError';
  }

  async invite(email) {
    const body = { email };
    const { data } = await super.post('prospective/', body);
    return new ProspectiveTenantModel(data);
  }

  async list(page, pageSize, q, planType, interestType) {
    const params = {
      page,
      page_size: pageSize,
      search: q,
      plan_type: planType,
      interest_type: interestType
    };

    const { data } = await super.get(`prospective/?${qs.encode(params)}`);
    data.results = data.results.map(result => {
      return new ProspectiveTenantModel(result);
    });
    return data;
  }

  async publicDetail(inviteId, email, groupId) {
    const params = { invite_id: inviteId, email, group_uuid: groupId };
    const { data } = await super.get(`prospective/public-detail/?${qs.encode(params)}`);
    return new ProspectiveTenantModel(data);
  }

  async updateRent(inviteId, rent, rentPeriod) {
    const body = { invite_id: inviteId, rent, rent_period: rentPeriod };
    const { data } = await super.post('prospective/rent/', body);
    return new ProspectiveTenantModel(data);
  }

  async updateNotInterestedReason(inviteId, notInterestedReason) {
    const body = { invite_id: inviteId, not_interested_reason: notInterestedReason };
    const { data } = await super.post('prospective/not-interested-reason/', body);
    return new ProspectiveTenantModel(data);
  }

  async updateInterestOnNoDeposit(inviteId, isInterested, email) {
    const body = { invite_id: inviteId, is_interested: isInterested, email };
    const { data } = await super.post('prospective/no-deposit-interest/', body);
    return new ProspectiveTenantModel(data);
  }

  async updateFirstUpsellViewOn(inviteId) {
    const body = { invite_id: inviteId };
    const { data } = await super.post('prospective/first-upsell-view-on/', body);
    return new ProspectiveTenantModel(data);
  }
}
