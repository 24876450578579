import { UserApiBase } from '@/libs/apis/user/base';

export class UserSecurityApi extends UserApiBase {
  async deactivateAccount() {
    return await super.post('deactivate-account/');
  }

  async cancelDeactivateAccount() {
    return await super.post('cancel-deactivate-account/');
  }

  async canDeactivateAccountCheck() {
    return await super.post('can-deactivate-account-check/');
  }
}
