export const CARD_TYPES = {
  AGENCY: 'agency',
  BTR: 'btr',
  COMMON: 'common'
};

export const CARD_CATEGORIES = {
  GET_STARTED: 'getStarted',
  ONLINE: 'online',
  IN_BRANCH: 'inBranch',
  TRAINING_SESSION: 'trainingSession',
  TRAINING_VIDEO: 'trainingVideo'
};

export const VIDEOCARD_CATEGORIES = {
  START_OF_TENANCY: 'startOfTenancy',
  END_OF_TENANCY: 'endOfTenancy',
  EXTENSION: 'extension',
  PLATFORM_ACCESS: 'platformAccess',
  TENANCY_UPDATES: 'tenancyUpdates'
};

export const CARD_TAGS = {
  FOR_TENANTS: 'forTenants',
  FOR_LANDLORDS: 'forLandlords',
  FOR_AGENTS: 'forAgents'
};
