import { InternalPropertyApiBase } from './base';

export class InternalPropertyApplicationApi extends InternalPropertyApiBase {
  list(query = '') {
    return super.get(`applications/${query}`, 'Failed to fetch the list of property applications');
  }

  retrieve(id) {
    return super.get(`applications/${id}`, `Failed to retrieve property application ${id}`);
  }
}
