import config from '@/libs/utils/config';

export const REWARDS_ROUTES = {
  ROOT: 'AGENT_REWARDS_ROOT',
  INTRO: 'AGENT_REWARDS_INTRO',
  DASHBOARD: 'AGENT_REWARDS_DASHBOARD'
};

export const root = {
  path: '/agent/rewards',
  name: REWARDS_ROUTES.ROOT,
  beforeEnter: (to, from, next) => {
    if (config.user && config.user.state.hasSeenRewardsDashboardIntro) {
      next({ name: REWARDS_ROUTES.DASHBOARD });
    } else {
      next({ name: REWARDS_ROUTES.INTRO });
    }
  }
};

export const intro = {
  path: '/agent/rewards/intro',
  component: () => import('@/pages/agent/rewards/intro.vue'),
  name: REWARDS_ROUTES.INTRO
};

export const dashboard = {
  path: '/agent/rewards/dashboard',
  component: () => import('@/pages/agent/rewards/dashboard.vue'),
  name: REWARDS_ROUTES.DASHBOARD
};

export default [root, intro, dashboard];
