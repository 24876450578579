import { ApiBase } from '@/libs/apis/base';

export class InternalPropertyApiBase extends ApiBase {
  constructor(vue) {
    super(vue, 'properties/internal', false);
  }

  list(query = '') {
    return super.get(query, 'Failed to fetch the list property applications');
  }

  retrieve(id) {
    return super.get(id, `Failed to retrieve property application ${id}`);
  }
}
