import { LANDLORD_CLOSE_PLAN_ROUTES } from './constants';

export const landlordClosePlanFlow = {
  name: LANDLORD_CLOSE_PLAN_ROUTES.BASE,
  path: '/landlord/flatbonds/:planId/close',
  component: () => import('./close.vue'),
  props: true,
  redirect: {
    name: LANDLORD_CLOSE_PLAN_ROUTES.START
  },
  children: [
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.START,
      path: 'start',
      component: () => import('@/components/plan/close/steps/start.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.VIDEO,
      path: 'video',
      component: () => import('@/components/plan/close/steps/video.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.INFO,
      path: 'info',
      component: () => import('@/components/plan/close/steps/info.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.TENANT_CONTACT,
      path: 'tenant-contact',
      component: () => import('@/components/plan/close/steps/tenant_contact.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.LANDLORD_CONTACT,
      path: 'landlord-contact',
      component: () => import('@/components/plan/close/steps/landlord_contact.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.TENANT_DOCUMENTS,
      path: 'documents',
      component: () => import('@/components/plan/close/steps/tenant_documents.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.INSURANCE,
      path: 'insurance',
      component: () => import('@/components/plan/close/steps/insurance.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.PAYMENT,
      path: 'payment',
      component: () => import('@/components/plan/close/steps/payment.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.ARREARS,
      path: 'arrears',
      component: () => import('@/components/plan/close/steps/arrears.vue')
    },
    {
      name: LANDLORD_CLOSE_PLAN_ROUTES.OTHER_CHARGES,
      path: 'other-charges',
      component: () => import('@/components/plan/close/steps/other_charges.vue')
    },
    {
      path: 'review',
      component: () => import('@/components/plan/close/review_draft_charges_case.vue'),
      props: true,
      children: [
        {
          name: LANDLORD_CLOSE_PLAN_ROUTES.DRAFT_CHARGES_REVIEW,
          path: 'draft',
          props: true,
          component: () => import('@/components/plan/close/steps/review')
        },
        {
          name: LANDLORD_CLOSE_PLAN_ROUTES.DRAFT_CHARGES_PLAN_DETAIL,
          path: 'plan',
          props: true,
          component: () => import('@/components/plan/charges/plan_details')
        },
        {
          name: LANDLORD_CLOSE_PLAN_ROUTES.DRAFT_CHARGES_PLAN_DOCUMENTS,
          path: 'documents',
          props: true,
          component: () => import('@/components/plan/charges/documents')
        }
      ]
    }
  ]
};
