import { InternalTenantApiBase } from '@/libs/apis/tenant/internal/base';

export class TenantReferenceEmployerApi extends InternalTenantApiBase {
  getAllEmployers() {
    return super.get(`/employer/get-all-employers/`, 'Failed to get all employers');
  }

  getOrCreateEmployer(employerName) {
    return super.post(
      `/employer/get-or-create-employer/`,
      { name: employerName },
      'Failed to get or create employer'
    );
  }

  listEmployers(query = '') {
    return super.get(`employer/${query}`, 'Failed to list employers');
  }

  get(id) {
    return super.get(`employer/${id}/`, 'Failed to get employer');
  }
}
