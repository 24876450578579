import BaseModel from '@/models/base';

export class NavSectionModel extends BaseModel {
  defaults() {
    return {
      label: '',
      items: []
    };
  }
}
