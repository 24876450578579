import BaseModel from '@/models/base';

export class AddressModel extends BaseModel {
  defaults() {
    return {
      line1: '',
      line2: '',
      city: '',
      postcode: '',
      country: ''
    };
  }
}
