import BaseModel from '@/models/base';
import Moment from 'moment';

export default class FlatbondUpdateModel extends BaseModel {
  constructor(data) {
    super(data);
    this.createdOnDate = this.createdOn ? Moment(this.createdOn).format('DD/MM/YYYY') : null;
  }

  defaults() {
    return {
      flatbond: null,
      changeType: null,
      category: null,
      createdOn: null,
      tags: []
    };
  }

  get daysSinceActionTaken() {
    if (this.createdOn) {
      const today = Moment().startOf('day');
      return today.diff(Moment(this.createdOn).startOf('day'), 'days');
    }
    return null;
  }
}
