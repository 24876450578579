import { AuthenticationApiBase } from '@/libs/apis/authentication/base';

export class AuthenticationPasswordApi extends AuthenticationApiBase {
  async changePassword(oldPassword, newPassword1, newPassword2) {
    return await super.post('password/change/', {
      old_password: oldPassword,
      new_password1: newPassword1,
      new_password2: newPassword2
    });
  }
}
