import { AuthenticationApiBase } from '@/libs/apis/authentication/base';

export class AuthenticationApi extends AuthenticationApiBase {
  async login(email, password) {
    return await super.post('login/', { email: email, password: password });
  }

  async resetPasswordEmail(email) {
    return await super.post('password/reset/', { email: email });
  }

  async resetPasswordConfirm(uuid, token, password1, password2) {
    return await super.post('password/reset/confirm/', {
      uid: uuid,
      token: token,
      new_password1: password1,
      new_password2: password2
    });
  }
}
