import { PUBLIC_NO_DEPOSIT_UPSELL_ROUTES } from './constants';
import { ROUTE_404_NAME } from '@/pages';
import { ProspectiveTenantApi } from '@/libs/apis/tenant/prospective_tenant';

const REDIRECT_TO_404_WHEN_INVITE_NOT_FOUND = async (to, from, next) => {
  const api = new ProspectiveTenantApi(this);
  try {
    to.params.initialProspectiveTenant = await api.publicDetail(
      to.query.inviteId,
      to.query.email,
      to.query.groupId
    );
    next();
  } catch (e) {
    next({ name: ROUTE_404_NAME });
  }
};

const publicUpsellLetMeTellYouRoute = {
  name: PUBLIC_NO_DEPOSIT_UPSELL_ROUTES.LET_ME_TELL_YOU,
  path: '/public/let-me-tell-you-about-flatfair',
  component: () => import('@/pages/public/upsell/let_me_tell_you_about_flatfair'),
  props: true,
  beforeEnter: REDIRECT_TO_404_WHEN_INVITE_NOT_FOUND
};

const publicUpsellLetMeTellYouFeedbackRoute = {
  name: PUBLIC_NO_DEPOSIT_UPSELL_ROUTES.LET_ME_TELL_YOU_FEEDBACK,
  path: '/public/let-me-tell-you-about-flatfair/feedback',
  component: () => import('@/pages/public/upsell/let_me_tell_you_about_flatfair_feedback'),
  props: true,
  beforeEnter: REDIRECT_TO_404_WHEN_INVITE_NOT_FOUND
};

export default [publicUpsellLetMeTellYouRoute, publicUpsellLetMeTellYouFeedbackRoute];
