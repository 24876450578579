export const ROUTES = {
  LANDLORD_OFFER_CREATE_PAGE: 'ops-charge-case-landlord-offer-create',
  CHARGE_CASE_DETAIL: 'ops-charge-case-detail'
};

const landlordOfferCreate = {
  path: '/ops/charges/case/:caseId/create-landlord-offer',
  component: () => import('@/pages/ops/charges/case/create_landlord_offer'),
  name: ROUTES.LANDLORD_OFFER_CREATE_PAGE
};

const chargeDetail = {
  name: ROUTES.CHARGE_CASE_DETAIL,
  path: '/ops/charges/case/:caseId',
  component: () => import('@/pages/ops/charges/case/detail.vue')
};

export default [landlordOfferCreate, chargeDetail];
