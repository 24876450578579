export const ARREARS_ROUTES = {
  INTRO: 'LANDLORD_ARREARS_FLATBOND_SEARCH',
  FLATBOND_SEARCH: 'LANDLORD_ARREARS_FLATBOND_SEARCH',
  FLATBOND_SUBMISSION: 'LANDLORD_ARREARS_FLATBOND_SUBMISSION',
  SUBMITTED: 'LANDLORD_SUBMITTED'
};

export const flatbond_search = {
  path: '/landlord/arrears/flatbond',
  component: () => import('@/pages/landlord/arrears/flatbond_search.vue'),
  name: ARREARS_ROUTES.FLATBOND_SEARCH
};

export const flatbond_submission = {
  path: '/landlord/arrears/flatbond/:flatbondId',
  component: () => import('@/pages/landlord/arrears/flatbond_submission.vue'),
  name: ARREARS_ROUTES.FLATBOND_SUBMISSION
};

export const submitted = {
  path: '/landlord/arrears/submitted',
  component: () => import('@/pages/landlord/arrears/submitted.vue'),
  name: ARREARS_ROUTES.SUBMITTED
};

export default [flatbond_search, flatbond_submission, submitted];
