export const AGENT_CLOSE_PLAN_ROUTES = {
  BASE: 'AGENT:PLAN:CLOSE:BASE',
  START: 'AGENT:PLAN:CLOSE:START',
  VIDEO: 'AGENT:PLAN:CLOSE:VIDEO',
  INFO: 'AGENT:PLAN:CLOSE:INFO',
  TENANT_CONTACT: 'AGENT:PLAN:CLOSE:TENANT_CONTACT',
  LANDLORD_CONTACT: 'AGENT:PLAN:CLOSE:LANDLORD_CONTACT',
  TENANT_DOCUMENTS: 'AGENT:PLAN:CLOSE:TENANT_DOCUMENTS',
  INSURANCE: 'AGENT:PLAN:CLOSE:INSURANCE',
  PAYMENT: 'AGENT:PLAN:CLOSE:PAYMENT',
  ARREARS: 'AGENT:PLAN:CLOSE:ARREARS',
  EARLY_TERMINATION: 'AGENT:PLAN:CLOSE:EARLY_TERMINATION',
  OTHER_CHARGES: 'AGENT:PLAN:CLOSE:OTHER_CHARGES',
  DRAFT_CHARGES_REVIEW: 'AGENT:PLAN:CLOSE:REVIEW',
  DRAFT_CHARGES_PLAN_DETAIL: 'AGENT:PLAN:CLOSE:PLAN_DETAIL',
  DRAFT_CHARGES_PLAN_DOCUMENTS: 'AGENT:PLAN:CLOSE_PLAN_DOCUMENTS'
};
