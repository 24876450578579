import BaseModel from '../base';
import TimelineModel from '@/models/charges_case/timeline';

export default class ChargesCaseModel extends BaseModel {
  constructor(data) {
    super(data);
    this.timeline = this.timeline ? new TimelineModel(this.timeline) : null;
  }

  defaults() {
    return {
      id: null,
      timeline: null
    };
  }
}
