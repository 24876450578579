import invoke from 'lodash/invoke';
import { MEDIUM_BREAK_POINT } from '@flatfair/vue-fairplay/atoms';

const _showButton = () => invoke(window, '$zopim.livechat.button.show');
const _hideButton = () => invoke(window, '$zopim.livechat.button.hide');

function whenReady(callback) {
  if (window.$zopim) {
    window.$zopim(callback);
  } else {
    const interval = setInterval(() => {
      if (window.$zopim) {
        window.$zopim(callback);
        clearInterval(interval);
      }
    }, 200);
  }
}

export function openAndPostMessage(message) {
  whenReady(() => {
    invoke(window, '$zopim.livechat.window.show');
    invoke(window, '$zopim.livechat.say', message);
  });
}

export function hideButtonOnMobile() {
  let mediaQuery = window.matchMedia(`(max-width: ${MEDIUM_BREAK_POINT}em)`);

  const listener = e => {
    if (e.matches) {
      _hideButton();
    } else {
      _showButton();
    }
  };

  whenReady(() => {
    if (!mediaQuery) return;
    if (mediaQuery.matches) _hideButton();
    mediaQuery.addListener(listener);
  });

  const unsubscribe = () => {
    _showButton();
    mediaQuery.removeListener(listener);
    mediaQuery = null;
  };

  return unsubscribe;
}

export function hideButton() {
  whenReady(_hideButton);
  const unsubscribe = () => {
    _showButton();
  };
  return unsubscribe;
}
