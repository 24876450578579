export const ROUTES = {
  BILLING_LIST: 'ops-billing-list',
  BILLING_DETAIL: 'ops-billing-detail'
};

const list = {
  path: '/ops/billing',
  component: () => import('@/pages/ops/billing/list'),
  name: ROUTES.BILLING_LIST
};

const detail = {
  path: '/ops/billing/:orgUnitId',
  component: () => import('@/pages/ops/billing/detail'),
  props: true,
  name: ROUTES.BILLING_DETAIL
};

export default [list, detail];
