import { ApiBase } from '@/libs/apis/base';

export class PropertyApplicationApiBase extends ApiBase {
  constructor(vue) {
    super(vue, 'properties/applications', false);
    this.errorName = 'PropertyApplicationApiError';
  }
  list(query = '') {
    return super.get(query, 'Failed to fetch the list property applications');
  }

  retrieve(id) {
    return super.get(id, `Failed to retrieve property application ${id}`);
  }

  cancelApplication(id) {
    return super.post(`${id}/cancel-application/`);
  }
}
