import { BatchPaymentDepositApiBase } from './base';

export class BatchPaymentDepositApi extends BatchPaymentDepositApiBase {
  get(batchPaymentId) {
    return super.get(batchPaymentId, 'Could not retrieve the batch payment!');
  }

  create(data) {
    return super.post('', data, 'Could not create the batch payment');
  }

  markAsPaid(batchPaymentId) {
    return super.post(
      `${batchPaymentId}/mark-as-paid/`,
      {},
      'Could not mark batch payment as paid'
    );
  }
}
