import { FlatfairError } from '@/libs/error';

export const ErrorMixin = {
  data: function() {
    return {
      showError: false,
      errorDetails: new FlatfairError(null, null)
    };
  }
};
