import { ApiBase } from '@/libs/apis/base';

export class SubscriptionApiBase extends ApiBase {
  constructor(vue) {
    super(vue, 'subscription');
    this.errorName = 'SubscriptionApiError';
  }
}

export class SubscriptionApi extends SubscriptionApiBase {
  status() {
    return super.get('status/', 'Failed to get subscription status');
  }

  updatePaymentMethod(paymentMethodId) {
    const data = {
      payment_method_id: paymentMethodId
    };
    return super.put('payment-method/', data, 'Failed to update payment method');
  }

  calculatePrice(monthlyRent) {
    const data = {
      monthly_rent: monthlyRent
    };
    return super.post('calculate-price/', data, 'Failed to calculate price');
  }
}
