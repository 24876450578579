import { InternalClaimApiBase } from './base';
import {
  InternalChargeCaseRlpCalculationDefaultValues,
  InternalChargeCaseRlpCalculations
} from '@/models/internal/rlp_calculations';

export class InternalClaimCaseRlpCalculationApi extends InternalClaimApiBase {
  async fetchRlpCalculations(caseId) {
    const { data } = await super.get(
      `rlp-calculation/?case_id=${caseId}&page_size=1000`,
      `Failed to fetch calculations ${caseId}`
    );
    return data.results.map(result => new InternalChargeCaseRlpCalculations(result));
  }

  async fetchDefaultValues(caseId) {
    const { data } = await super.get(
      `rlp-calculation/${caseId}/default-values/`,
      'Failed to fetch default values'
    );
    return new InternalChargeCaseRlpCalculationDefaultValues(data);
  }

  async createRlpCalculation(calculations) {
    const { data } = await super.post(
      `rlp-calculation/`,
      calculations.toFlatpieModel(),
      `Failed to create calculations`
    );
    return new InternalChargeCaseRlpCalculations(data);
  }

  async uploadProof(calculationId, documentId) {
    const { data } = await super.post(
      `rlp-calculation/${encodeURIComponent(calculationId)}/upload-proof/`,
      { proof_document_id: documentId },
      `Failed to upload proof`
    );
    return new InternalChargeCaseRlpCalculations(data);
  }
}

export class InternalClaimCaseApi extends InternalClaimApiBase {
  listCasesSuitableForPaymentPlan() {
    return super.get(
      'case/suitable-for-payment-plan',
      'Failed to list cases suitable for payment plan'
    );
  }

  completeReview(id) {
    return super.post(`case/${id}/complete-review/`, {}, `Failed to complete case review ${id}`);
  }

  getClaimsCaseVerification(id) {
    return super.get(`case/${id}/verification/`, `Failed to retrieve case verification  ${id}`);
  }

  updateCollectionStatus(id, status) {
    return super.post(
      `case/${id}/update-collection-status/`,
      { new_status: status },
      `Failed to update collection status case review ${id}`
    );
  }

  async getAdjudicationDecisionDocument(caseId) {
    const { data } = await super.get(
      `case/${caseId}/get-adjudication-decision-document/`,
      'Failed to fetch adjudication document'
    );
    return data;
  }

  addDocumentToFlatbondForClaimsCase(caseId, documentId, adjudicationDecisionReceivedOnDate) {
    const data = {
      document_id: documentId,
      received_on_date: adjudicationDecisionReceivedOnDate
    };

    return super.post(
      `case/${caseId}/update-adjudication-decision-status/`,
      data,
      'Failed to add document to flatbond for claims case'
    );
  }

  closeAdjudication(caseId) {
    return super.post(`case/${caseId}/close-adjudication/`, {}, 'Failed to close adjudication');
  }

  getWriteOffs(caseId) {
    return super.get(`case/${caseId}/get-write-offs/`, 'Failed to get write offs for claims case');
  }

  async createWriteOff(caseId, writeOffAmount, writeOffReason) {
    const data = {
      case: caseId,
      amount: writeOffAmount,
      reason: writeOffReason
    };

    return super.post(`case/write-off/`, data, 'Failed to create write off');
  }

  updateClaimsCaseChecks(id, verificationId, checklist) {
    const data = checklist.reduce(
      (acc, { key, value }) => {
        acc[key] = value;
        return acc;
      },
      { case_id: id }
    );

    return super.put(
      `case/${id}/verification/${verificationId}/`,
      data,
      `Failed to update case verification ${id}`
    );
  }

  async betterUpdateClaimsCaseChecks(caseId, verificationId, checks) {
    return await super.put(
      `case/${caseId}/verification/${verificationId}/`,
      checks,
      `Failed to update case verification ${caseId}`
    );
  }

  setTerminatedDates(id) {
    return super.post(
      `case/${id}/set-terminated-dates/`,
      {},
      `Failed to set terminated dates ${id}`
    );
  }
}
