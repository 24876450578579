import BaseModel from '@/models/base';

export class ClosePlanModel extends BaseModel {
  defaults() {
    return {
      withCharges: null,
      tenantsVacated: null,
      tenancyTerminationDate: null
    };
  }

  toFlatpieModel() {
    return {
      tenancy_termination_date: this.tenancyTerminationDate
    };
  }
}
