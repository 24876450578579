var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FpFlex",
    {
      attrs: {
        "space-around": "",
        "justify-content": {
          xLarge: _vm.FlexJustifyContent.spaceAround,
          large: _vm.FlexJustifyContent.spaceAround,
          medium: _vm.FlexJustifyContent.center,
          small: _vm.FlexJustifyContent.center
        },
        direction: {
          small: _vm.FlexDirection.column,
          medium: _vm.FlexDirection.column
        }
      }
    },
    [
      _c(
        "FpFlexItem",
        { attrs: { align: "center" } },
        [
          _c("FpHeader3", { attrs: { "margin-bottom": 2 } }, [
            _vm._v(_vm._s(_vm.$t("footer.helpCentre.title")))
          ]),
          _vm._v(" "),
          _c(
            "FpLink",
            {
              attrs: {
                href: _vm.HELP_ARTICLES_URL,
                target: "_blank",
                external: ""
              }
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("footer.helpCentre.link")) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "FpDevice",
        { attrs: { "mobile-only": "" } },
        [_c("FpFlexItem", [_c("FpSeparator")], 1)],
        1
      ),
      _vm._v(" "),
      _vm.onPage === _vm.PAGES.MATERIALS
        ? _c(
            "FpFlexItem",
            { attrs: { align: "center" } },
            [
              _c("FpHeader3", { attrs: { "margin-bottom": 2 } }, [
                _vm._v(_vm._s(_vm.$t("footer.materials.title")))
              ]),
              _vm._v(" "),
              _c(
                "FpLink",
                { attrs: { external: "" }, on: { click: _vm.navigateToPage } },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("footer.materials.link")) +
                      "\n    "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.onPage === _vm.PAGES.LEARN
        ? _c(
            "FpFlexItem",
            { attrs: { align: "center" } },
            [
              _c("FpHeader3", { attrs: { "margin-bottom": 2 } }, [
                _vm._v(_vm._s(_vm.$t("footer.learn.title")))
              ]),
              _vm._v(" "),
              _c("FpLink", { on: { click: _vm.navigateToPage } }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("footer.learn.link")) + "\n    "
                )
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }