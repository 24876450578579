import { ApiBase } from '@/libs/apis/base';
import qs from 'querystring';
import { RecipientsModel } from '@/models/messages';

export class MessagesApiBase extends ApiBase {
  constructor(vue) {
    super(vue, 'messages');
    this.errorName = 'MessagesApiError';
  }
}

export class MessagesApi extends MessagesApiBase {
  async getRecipients(flatbondId) {
    const query = qs.encode({
      flatbond_id: flatbondId
    });
    const response = await super.get(`recipients/?${query}`);
    return new RecipientsModel(response.data);
  }
}
