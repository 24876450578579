import publicPaymentRoutes from '@/pages/public/payment';
import rewardsRoutes from '@/pages/public/rewards';
import publicUpSellRoutes from '@/pages/public/upsell';
import publicProspectiveLandlordRoute from '@/pages/public/prospective_landlord';

export default [
  ...publicPaymentRoutes,
  ...rewardsRoutes,
  ...publicUpSellRoutes,
  ...publicProspectiveLandlordRoute
];
