import { ReferencingApiBase } from '@/libs/apis/referencing/base';

export class ReferenceResponseApi extends ReferencingApiBase {
  constructor(vue) {
    super(vue, 'response');
  }

  update(responseId, isApproved) {
    const data = {
      is_approved: isApproved
    };

    return this.put(`${responseId}/`, data, 'Failed to create reference request');
  }

  getDownloadLink(responseId) {
    return `${this.baseUrl}/${this.namespace}/${responseId}/download-pdf/`;
  }
}
