import { TenantApiBase } from './base';

export class TenantGuarantorApi extends TenantApiBase {
  list(tenantId) {
    return super.get(`${tenantId}/guarantor/`, 'Failed to fetch guarantors for tenant');
  }

  update(tenantId, guarantorId, data) {
    return super.put(`${tenantId}/guarantor/${guarantorId}/`, data, 'Failed to update guarantor');
  }
}
