import BaseModel from '@/models/base';

export class ReminderModel extends BaseModel {
  defaults() {
    return {
      id: null,
      createdOn: null,
      createdBy: null,
      flatbond: null,
      category: null,
      reminderDate: null,
      reminderDescription: null
    };
  }
}
