import get from 'lodash/get';
import { NavItemModel } from '@/models/nav_item';
import { FEATURE_FLAGS } from '@/libs/utils/config';
import Moment from 'moment';

export default {
  primaryLinks: [
    new NavItemModel({
      label: 'nav.links.homepage',
      href: '/agent/',
      userTypes: ['agent'],
      resolve: context => !!get(context, 'user.currentOrganizationUser')
    }),

    new NavItemModel({
      label: 'nav.links.guarantor.homepage',
      href: '/guarantor',
      newTab: false,
      userTypes: ['guarantor'],
      resolve: context => !!get(context, 'user.isAGuarantorOnAPlan')
    }),

    new NavItemModel({
      label: 'nav.links.landlord.homepage',
      href: '/tenant',
      userTypes: ['tenant']
    }),

    new NavItemModel({
      label: 'nav.links.tenants.plans',
      href: '/tenant/tenancies',
      userTypes: ['tenant'],
      resolve: context => !!get(context, 'user.isATenantOnAPlan')
    }),

    new NavItemModel({
      label: 'nav.links.tenants.referencing',
      href: '/referencing/tenant',
      userTypes: ['tenant', 'guarantor'],
      resolve: context =>
        !!get(context, 'user.isATenantOnAReferencingApplication') ||
        !!get(context, 'user.isAGuarantorOnAReferencingApplication')
    }),

    new NavItemModel({
      label: 'nav.links.plans',
      href: '/flatbonds/',
      userTypes: ['agent'],
      resolve: context => !!get(context, 'user.currentOrganizationUser.showPlansInSidebar')
    }),

    new NavItemModel({
      label: 'nav.links.landlord.homepage',
      href: '/landlord',
      userTypes: ['landlord'],
      resolve: context => context.featureEnabled(FEATURE_FLAGS.LANDLORD_SIGNATURE_IMPROVEMENTS)
    }),

    new NavItemModel({
      label: 'nav.links.landlord.plans',
      href: '/landlords/',
      userTypes: ['landlord']
    }),

    new NavItemModel({
      label: 'nav.links.landlord.billing',
      href: '/landlord/user/billing',
      userTypes: ['landlord'],
      resolve: context =>
        !!context.featureEnabled(FEATURE_FLAGS.BOOST) ||
        !!context.hasAtLeastOneActivePaidBoostSubscription
    }),

    new NavItemModel({
      label: 'nav.links.landlord.arrears',
      href: '/landlord/arrears/flatbond',
      userTypes: ['landlord'],
      newTab: false
    }),

    new NavItemModel({
      label: 'nav.links.landlord.offers',
      href: '/landlord/offers',
      userTypes: ['landlord'],
      newTab: false
    }),

    new NavItemModel({
      label: 'nav.links.deposits',
      href: '/deposits/',
      userTypes: ['agent'],
      resolve: context => !!context.canOfferDeposits
    }),

    new NavItemModel({
      label: 'nav.links.referencing',
      href: '/referencing/',
      userTypes: ['agent'],
      resolve: context => !!get(context, 'user.currentOrganizationUser.group.canOfferReferencing')
    }),

    new NavItemModel({
      label: 'nav.links.arrears',
      href: '/agent/arrears/',
      newTab: false,
      userTypes: ['agent'],
      resolve: context => !!get(context, 'user.currentOrganizationUser.showArrearsInSidebar')
    }),

    new NavItemModel({
      label: 'nav.links.organization',
      href: '/organization/',
      userTypes: ['agent'],
      resolve: context => !!get(context, 'user.currentOrganizationUser')
    }),

    new NavItemModel({
      label: 'nav.links.incentive',
      href: '/agent/cash-rewards/',
      newTab: false,
      userTypes: ['agent'],
      resolve: context => {
        const sellersIncentiveUntil = context.organizationSellersIncentiveUntil;

        if (sellersIncentiveUntil === null) {
          return false;
        }

        return Moment().isSameOrBefore(sellersIncentiveUntil, 'day');
      }
    }),

    new NavItemModel({
      label: 'nav.links.rewards',
      href: '/agent/rewards/',
      newTab: false,
      userTypes: ['agent'],
      resolve: context => context.featureEnabled(FEATURE_FLAGS.AGENT_SCRATCH_CARDS)
    })
  ],
  secondaryLinks: [
    new NavItemModel({
      label: 'nav.links.documentCentre',
      href: '/agent/onboarding/documents',
      userTypes: ['agent']
    }),

    new NavItemModel({
      label: 'nav.links.tenants.downloadCentre',
      href: '/tenants/download-centre',
      userTypes: ['tenant']
    }),

    new NavItemModel({
      label: 'nav.links.landlord.documentCentre',
      href: '/landlords/document-centre',
      userTypes: ['landlord']
    }),

    new NavItemModel({
      label: 'nav.links.helpCentre',
      href: '//help.flatfair.co.uk',
      newTab: true,
      userTypes: ['agent', 'landlord', 'guarantor', 'tenant']
    }),

    new NavItemModel({
      label: 'nav.links.status',
      href: '//flatfair.statuspage.io/',
      newTab: true,
      userTypes: ['agent', 'landlord', 'guarantor', 'tenant'],
      resolve: context => ['production', 'demo'].indexOf(get(context, 'env')) < 0
    }),

    new NavItemModel({
      label: 'nav.links.visitCommunity',
      href: '//community.flatfair.co.uk/',
      newTab: true,
      userTypes: ['agent'],
      resolve: context => !!context.featureEnabled(FEATURE_FLAGS.FLATFAIR_COMMUNITY)
    })
  ],
  userDropdown: []
};
