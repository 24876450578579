import { GuarantorApiBase } from '@/libs/apis/guarantor/base';

export class GuarantorTermsAndConditionsApi extends GuarantorApiBase {
  getOrCreate(guarantorId) {
    return super.get(
      `${guarantorId}/terms-and-conditions/get-or-create`,
      'Failed to fetch guarantor T&Cs'
    );
  }
}
