import BaseModel from './base';
import check from 'check-types';

export class NavItemModel extends BaseModel {
  constructor(data) {
    super(data);
    const { resolve } = this;

    if (check.not.function(resolve) && check.not.null(resolve)) {
      throw new TypeError('resolve must be a function');
    }
  }

  isEnabledForContext(context) {
    if (this.userTypes.indexOf(context.viewingAs) === -1) return false;
    if (check.function(this.resolve)) return this.resolve(context);
    return true;
  }

  withTranslations($t) {
    return new NavItemModel({ ...this, label: $t(this.label) });
  }

  defaults() {
    return {
      label: '',
      href: '',
      newTab: false,
      userTypes: [],
      resolve: null,
      iconCode: null
    };
  }
}
