<template>
  <FfSidebar>
    <FpPageBlock
      fullscreen
      :children="{ default: ['min-content', 'min-content', 'auto', 'min-content'] }"
    >
      <FpPageBlock contained hide-on="non-desktop">
        <FpFlexGrid
          compact
          :align-items="FlexAlignItems.center"
          :space-around="FlexSpaceAround.mainAxis"
        >
          <FpFlexGridItem :size="6">
            <FpHeader1>{{ $t('learn.title') }}</FpHeader1>
            <FpBody :margin-top="2">{{ $t('learn.description') }}</FpBody>
          </FpFlexGridItem>
          <FpFlexGridItem :size="6" style="display: inherit">
            <FpImage>
              <img :src="image" :alt="$t('learn.mainImageAlt')" style="margin-top: 40px" />
            </FpImage>
          </FpFlexGridItem>
        </FpFlexGrid>
      </FpPageBlock>

      <FpPageBlock contained hide-on="desktop">
        <FpFlexGrid compact space-around :align-items="FlexAlignItems.center">
          <FpFlexGridItem style="display: inherit">
            <FpImage>
              <img :src="image" :alt="$t('learn.mainImageAlt')" style="margin-top: 40px" />
            </FpImage>
          </FpFlexGridItem>
          <FpFlexGridItem>
            <FpHeader1>{{ $t('learn.title') }}</FpHeader1>
            <FpBody :margin-top="2">{{ $t('learn.description') }}</FpBody>
          </FpFlexGridItem>
        </FpFlexGrid>
      </FpPageBlock>
      <FpPageBlock contained border>
        <FpTabs>
          <FpTabsTab
            :active="tabSelected === TAB_TRAINING_VIDEOS"
            @click="navigateToTrainingVideos"
          >
            {{ $t('learn.tab.trainingVideos') }}
          </FpTabsTab>
          <FpTabsTab
            :active="tabSelected === TAB_TRAINING_SESSIONS"
            @click="navigateToTrainingSessions"
          >
            {{ $t('learn.tab.trainingSessions') }}
          </FpTabsTab>
        </FpTabs>
      </FpPageBlock>
      <FpPageBlock v-if="tabSelected === TAB_TRAINING_VIDEOS" contained secondary>
        <FpFlexGrid space-around>
          <FpFlexGridItem :size="{ small: 12, medium: 12, large: 3, xLarge: 3 }">
            <FpFilter
              v-model="category"
              :options="trainingVideoFilterOptions"
              @input="changeCategory(category)"
            />
          </FpFlexGridItem>

          <FpFlexGridItem :size="{ small: 12, medium: 12, large: 9, xLarge: 9 }">
            <FpFlexGrid :align-items="FlexAlignItems.stretch">
              <FpFlexGridItem
                v-for="card in getCards(category)"
                :key="card.link"
                :size="{ small: 12, medium: 12, large: 6, xLarge: 6 }"
              >
                <VideoCard
                  :key="card.link"
                  :title="card.title"
                  :video="{
                    url: card.link
                  }"
                  @play="play"
                />
              </FpFlexGridItem>
            </FpFlexGrid>
          </FpFlexGridItem>
        </FpFlexGrid>
      </FpPageBlock>
      <FpPageBlock v-if="tabSelected === TAB_TRAINING_SESSIONS" contained secondary>
        <FpFlexGrid space-around :justify-content="FlexJustifyContent.start">
          <FpFlexGridItem
            v-for="(card, index) in trainingSessionCards"
            :key="index"
            :size="{ xLarge: 4, large: 4 }"
          >
            <Card
              :on-page="PAGES.LEARN"
              :title="card.title"
              :description="card.description"
              :tags="card.tags"
              :link="{ url: card.link, label: card.linkLabel }"
            />
          </FpFlexGridItem>
        </FpFlexGrid>
      </FpPageBlock>
      <FpPageBlock contained>
        <Footer :on-page="PAGES.LEARN" />
      </FpPageBlock>
    </FpPageBlock>
  </FfSidebar>
</template>
<script>
import { PAGES } from '@/components/agent/materials/footer';
import {
  FpBody,
  FpFlexGrid,
  FpFlexGridItem,
  FpTabs,
  FpTabsTab,
  FpHeader1,
  FpFilter,
  FpImage,
  FpPageBlock
} from '@flatfair/vue-fairplay';
import FfSidebar from '@/elements/sidebar';
import learnMain from '@/images/full/new/learn_main.png';
import * as trackEvent from '@/libs/utils/track_event';
import FlexMixin from '@/libs/constants/flexMixin';
import Card from '@/components/agent/materials/card';
import VideoCard from '@/components/agent/materials/videoCard';
import Footer from '@/components/agent/materials/footer';
import TrainingMixin from '@/components/agent/materials/training_mixin';
import {
  CARD_CATEGORIES,
  CARD_TYPES,
  VIDEOCARD_CATEGORIES
} from '@/components/agent/materials/constants';
import { ROUTES } from '@/pages/agent';

export const CARD_TAGS = {
  NO_DEPOSIT: 'noDeposit',
  UNLOCK_DEPOSIT: 'unlockDeposit'
};

export const TABS = {
  TRAINING_SESSIONS: 'tabTrainingSessions',
  TRAINING_VIDEOS: 'tabTrainingVideos'
};

export default {
  components: {
    FpFlexGrid,
    FpPageBlock,
    FpFlexGridItem,
    FpFilter,
    FpHeader1,
    FfSidebar,
    FpTabs,
    FpTabsTab,
    Card,
    VideoCard,
    FpImage,
    Footer,
    FpBody
  },
  mixins: [FlexMixin, TrainingMixin],
  props: {
    defaultTabSelected: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      category: VIDEOCARD_CATEGORIES.START_OF_TENANCY,
      refToPause: null,
      tabSelected: this.defaultTabSelected,
      sizeItem: null,
      trainingVideoCards: [
        {
          title: this.$t('learn.trainingVideo0.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/create_a_no_deposit_plan.mp4',
          category: VIDEOCARD_CATEGORIES.START_OF_TENANCY,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo1.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/create_a_no_deposit_unlocking_plan.mp4',
          category: VIDEOCARD_CATEGORIES.START_OF_TENANCY,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo2.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/remove_a_tenant_from_plan.mp4',
          category: VIDEOCARD_CATEGORIES.TENANCY_UPDATES,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo3.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/upload_additional_documents.mp4',
          category: VIDEOCARD_CATEGORIES.TENANCY_UPDATES,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo4.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/change_of_sharers.mp4',
          category: VIDEOCARD_CATEGORIES.TENANCY_UPDATES,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo5.title'),
          link: 'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/extend_a_plan.mp4',
          category: VIDEOCARD_CATEGORIES.EXTENSION,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo6.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/report_rent_arrears.mp4',
          category: VIDEOCARD_CATEGORIES.TENANCY_UPDATES,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo13.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/helpful_guide_to_eot_process.mp4',
          category: VIDEOCARD_CATEGORIES.END_OF_TENANCY,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo7.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/close_plan_without_charges.mp4',
          category: VIDEOCARD_CATEGORIES.END_OF_TENANCY,
          type: CARD_TYPES.COMMON
        },

        {
          title: this.$t('learn.trainingVideo8.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/close_plan_with_charges.mp4',
          category: VIDEOCARD_CATEGORIES.END_OF_TENANCY,
          type: CARD_TYPES.COMMON
        },

        {
          title: this.$t('learn.trainingVideo9.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/counter_charge.mp4',
          category: VIDEOCARD_CATEGORIES.END_OF_TENANCY,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo10.title'),
          link:
            'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/send_charges_to_adjudication.mp4',
          category: VIDEOCARD_CATEGORIES.END_OF_TENANCY,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo11.title'),
          link: 'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/add_user.mp4',
          category: VIDEOCARD_CATEGORIES.PLATFORM_ACCESS,
          type: CARD_TYPES.COMMON
        },
        {
          title: this.$t('learn.trainingVideo12.title'),
          link: 'https://s3.eu-west-2.amazonaws.com/flatfair.static/public/Learn/remove_user.mp4',
          category: VIDEOCARD_CATEGORIES.PLATFORM_ACCESS,
          type: CARD_TYPES.COMMON
        }
      ],
      cards: [
        {
          title: this.$t('learn.trainingSession0.title'),
          description: this.$t(`learn.trainingSession0.description`),
          tags: [CARD_TAGS.NO_DEPOSIT, CARD_TAGS.UNLOCK_DEPOSIT],
          link: 'https://calendly.com/d/cjf-93d-2vf/introduction-to-flatfair',
          linkLabel: this.$t('learn.trainingSession.link'),
          category: CARD_CATEGORIES.TRAINING_SESSION,
          type: CARD_TYPES.AGENCY,
          saleBooster: false
        },
        {
          title: this.$t('learn.trainingSession4.title'),
          description: this.$t(`learn.trainingSession4.description`),
          tags: [CARD_TAGS.NO_DEPOSIT, CARD_TAGS.UNLOCK_DEPOSIT],
          link: 'https://calendly.com/flatfair-agency-team/flatfair-weekly-refresher-1',
          linkLabel: this.$t('learn.trainingSession.link'),
          category: CARD_CATEGORIES.TRAINING_SESSION,
          type: CARD_TYPES.AGENCY,
          saleBooster: false
        },
        {
          title: this.$t('learn.trainingSession1.title'),
          description: this.$t(`learn.trainingSession1.description`),
          tags: [CARD_TAGS.NO_DEPOSIT, CARD_TAGS.UNLOCK_DEPOSIT],
          link:
            'https://calendly.com/ben-flatfair/introduction-to-flatfair-and-how-to-create-your-first-flatfair-no-deposit',
          linkLabel: this.$t('learn.trainingSession.link'),
          category: CARD_CATEGORIES.TRAINING_SESSION,
          type: CARD_TYPES.BTR,
          saleBooster: false
        },
        {
          title: this.$t('learn.trainingSession2.title'),
          description: this.$t(`learn.trainingSession2.description`),
          tags: [CARD_TAGS.NO_DEPOSIT, CARD_TAGS.UNLOCK_DEPOSIT],
          link: 'https://calendly.com/ben-flatfair/ending-a-flatfair-tenancy-with-charges',
          linkLabel: this.$t('learn.trainingSession.link'),
          category: CARD_CATEGORIES.TRAINING_SESSION,
          type: CARD_TYPES.BTR,
          saleBooster: false
        },
        {
          title: this.$t('learn.trainingSession3.title'),
          description: this.$t(`learn.trainingSession3.description`),
          tags: [CARD_TAGS.UNLOCK_DEPOSIT],
          link: 'https://calendly.com/d/ckm-h55-c47/deposit-unlocking',
          linkLabel: this.$t('learn.trainingSession.link'),
          category: CARD_CATEGORIES.TRAINING_SESSION,
          type: CARD_TYPES.AGENCY,
          saleBooster: false
        }
      ]
    };
  },
  computed: {
    trainingVideoFilterOptions() {
      return [
        {
          label: this.$t('card.categories.startOfTenancy'),
          value: VIDEOCARD_CATEGORIES.START_OF_TENANCY
        },
        {
          label: this.$t('card.categories.endOfTenancy'),
          value: VIDEOCARD_CATEGORIES.END_OF_TENANCY
        },
        {
          label: this.$t('card.categories.tenancyUpdates'),
          value: VIDEOCARD_CATEGORIES.TENANCY_UPDATES
        },
        {
          label: this.$t('card.categories.platformAccess'),
          value: VIDEOCARD_CATEGORIES.PLATFORM_ACCESS
        },
        {
          label: this.$t('card.categories.extension'),
          value: VIDEOCARD_CATEGORIES.EXTENSION
        }
      ];
    },
    trainingSessionCards() {
      return this.filterCardsForCategory(this.cards, CARD_CATEGORIES.TRAINING_SESSION);
    }
  },
  created() {
    this.PAGES = PAGES;
    this.VIDEOCARD_CATEGORIES = VIDEOCARD_CATEGORIES;
    this.TAB_TRAINING_SESSIONS = TABS.TRAINING_SESSIONS;
    this.TAB_TRAINING_VIDEOS = TABS.TRAINING_VIDEOS;
    this.image = learnMain;
    this.trackEvent = trackEvent;
    this.trackEvent.init('Learn');
  },
  methods: {
    navigateToTrainingVideos() {
      this.$router.push({ name: ROUTES.LEARN_TRAINING_VIDEOS });
      this.tabSelected = TABS.TRAINING_VIDEOS;
    },
    navigateToTrainingSessions() {
      this.$router.push({ name: ROUTES.LEARN_TRAINING_SESSIONS });
      this.tabSelected = TABS.TRAINING_SESSIONS;
    },
    getCards(category) {
      return this.filterCardsForCategory(this.trainingVideoCards, category);
    },
    play(refPlaying) {
      //check if a player is already playing
      if (this.refToPause !== refPlaying) {
        if (this.refToPause !== null) {
          this.refToPause.pause();
        }
        this.refToPause = refPlaying;
      }
    },
    changeCategory(category) {
      this.refTopause = null;
      this.category = category;
    }
  }
};
</script>
