import config from '@/libs/utils/config';
import { REWARDS_ROUTES } from '@/pages/agent/rewards';

export const PUBLIC_REWARDS_ROUTES = {
  INTRO: 'PUBLIC_REWARDS_INTRO'
};

const rewardsIntro = {
  name: PUBLIC_REWARDS_ROUTES.INTRO,
  path: '/public/rewards',
  component: () => import('@/pages/public/rewards/intro.vue'),
  beforeEnter: (to, from, next) => {
    if (config.isUserLoggedIn) {
      next({ name: REWARDS_ROUTES.ROOT });
    } else {
      next();
    }
  }
};

export default [rewardsIntro];
