import { InternalTenantApiBase } from '@/libs/apis/tenant/internal/base.js';

export class InternalFlatbondTransactionApi extends InternalTenantApiBase {
  list(query = '') {
    return super.get(
      `flatbond-transaction/${query}`,
      'Could not retrieve the list of transactions'
    );
  }

  processRefund(transactionId, amount, reason) {
    const data = {
      amount: amount,
      reason: reason
    };

    return super.post(
      `flatbond-transaction/${transactionId}/refund/`,
      data,
      `Could not process refund for transaction ${transactionId}`
    );
  }
}
