import arrearsRoutes from '@/pages/landlord/arrears';
import flatbondRoutes from '@/pages/landlord/flatbonds';
import billingRoutes from '@/pages/landlord/billing';
import welcomeRoutes from '@/pages/landlord/welcome';
import landlordOfferRoutes from '@/pages/landlord/offers';
import { landlordHomepageRedirect } from '@/pages/landlord/redirects';

export const LANDLORD_ROUTES = {
  LANDLORD_HOMEPAGE: 'LANDLORD_HOMEPAGE',
  LANDLORD_SETTINGS: 'LANDLORD_SETTINGS'
};

export const landlordHomepage = {
  name: LANDLORD_ROUTES.LANDLORD_HOMEPAGE,
  path: '/landlord',
  beforeEnter: landlordHomepageRedirect,
  component: () => import('@/pages/landlord/homepage.vue')
};

export const landlordSettings = {
  name: LANDLORD_ROUTES.LANDLORD_SETTINGS,
  path: '/landlord/settings',
  component: () => import('@/pages/common/settings/base.vue')
};

export default [
  ...arrearsRoutes,
  ...flatbondRoutes,
  ...billingRoutes,
  ...welcomeRoutes,
  ...landlordOfferRoutes,
  landlordSettings,
  landlordHomepage
];
