export const ROUTES = {
  PAYOUT_LIST_PAGE: 'ops-payout-list',
  PAYOUT_DETAIL_PAGE: 'ops-payout-detail'
};

const payoutList = {
  path: '/ops/payout',
  component: () => import('@/pages/ops/payout/list'),
  name: ROUTES.PAYOUT_LIST_PAGE
};

const payoutDetail = {
  path: '/ops/payout/:payoutId',
  component: () => import('@/pages/ops/payout/detail'),
  name: ROUTES.PAYOUT_DETAIL_PAGE
};

export default [payoutList, payoutDetail];
