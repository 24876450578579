import BaseModel from '../base';
import DocumentModel from '@/models/document';

export default class OtherChargeModel extends BaseModel {
  constructor(data) {
    super(data);

    this.documents = this.documents
      ? this.documents.map(document => new DocumentModel(document))
      : [];
  }

  defaults() {
    return {
      category: null,
      description: '',
      amount: null,
      documents: [],
      showEvidence: false,
      showModal: false
    };
  }

  toFlatpieModel() {
    const data = super.toFlatpieModel();
    data.document_ids = this.documents.map(document => document.id);
    return data;
  }
}
