var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FpContainer",
    { attrs: { grow: "", elevated: "" } },
    [
      _c(
        "FpFlex",
        {
          staticStyle: { height: "100%" },
          attrs: {
            compact: "",
            "justify-content": _vm.FlexJustifyContent.spaceBetween,
            direction: _vm.FlexDirection.column
          }
        },
        [
          _vm.loading
            ? [
                _c(
                  "FpFlexItem",
                  [
                    _c(
                      "FpFlexGrid",
                      { attrs: { compact: "" } },
                      [
                        _c("FpFlexGridItem", [_c("FpPlaceholder")], 1),
                        _c("FpFlexGridItem", [_c("FpPlaceholder")], 1),
                        _c("FpFlexGridItem", [_c("FpPlaceholder")], 1)
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "FpFlexItem",
                  { style: { height: "100%" } },
                  [
                    _c("FpPlaceholder", {
                      style: { height: "100%", width: "100%" }
                    })
                  ],
                  1
                )
              ]
            : [
                _c(
                  "FpFlexItem",
                  { attrs: { grow: "" } },
                  [
                    _c(
                      "FpFlexGrid",
                      { attrs: { compact: "", "space-around": "" } },
                      [
                        _vm.tags || _vm.saleBooster
                          ? _c(
                              "FpFlexGridItem",
                              [
                                _c(
                                  "FpFlex",
                                  {
                                    attrs: {
                                      compact: "",
                                      "justify-content":
                                        _vm.FlexJustifyContent.spaceBetween,
                                      "align-items": _vm.FlexAlignItems.center
                                    }
                                  },
                                  [
                                    _c(
                                      "FpFlexItem",
                                      [
                                        _c(
                                          "FpFlex",
                                          {
                                            attrs: {
                                              wrap: "",
                                              compact: "",
                                              "align-items":
                                                _vm.FlexAlignItems.center
                                            }
                                          },
                                          _vm._l(_vm.tags, function(
                                            tag,
                                            index
                                          ) {
                                            return _c(
                                              "FpFlexItem",
                                              { key: index },
                                              [
                                                _c("FpBadge", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "card.tags." + tag
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.saleBooster
                                      ? _c(
                                          "FpFlexItem",
                                          [
                                            _c("FpAvatar", {
                                              attrs: {
                                                info: "",
                                                "icon-code": "",
                                                size: _vm.AvatarSize.small,
                                                title: _vm.$t(
                                                  "card.tags.saleBooster.title"
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "FpFlexGridItem",
                          { style: { minHeight: "48px" } },
                          [_c("FpHeader3", [_vm._v(_vm._s(_vm.title))])],
                          1
                        ),
                        _vm._v(" "),
                        _vm.description
                          ? _c(
                              "FpFlexGridItem",
                              { style: { height: "100px" } },
                              [
                                _c(
                                  "FpBody",
                                  { attrs: { "margin-bottom": 4 } },
                                  [_vm._v(_vm._s(_vm.description))]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.link
                          ? _c(
                              "FpFlexGridItem",
                              [
                                _c(
                                  "FpLink",
                                  {
                                    attrs: {
                                      external: "",
                                      text: "",
                                      href: _vm.link.url,
                                      target: "_blank"
                                    },
                                    on: { click: _vm.trackClick }
                                  },
                                  [_vm._v(_vm._s(_vm.link.label))]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }