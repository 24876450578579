import { OrganisationSellerApiBase } from './base';
import qs from 'querystring';

export class OrganisationSellerApi extends OrganisationSellerApiBase {
  listSellers() {
    return super.get('', 'Failed to list sellers');
  }

  get(sellerId) {
    return super.get(`${sellerId}`, 'Failed to get seller');
  }

  create(seller) {
    return super.post('get-or-create/', seller);
  }

  rank(periodDates) {
    return super.get(`rank/?${qs.encode(periodDates)}`);
  }

  plansSold(periodDates) {
    return super.get(`plans-sold/?${qs.encode(periodDates)}`);
  }

  boostSold(periodDates) {
    return super.get(`boost-sold/?${qs.encode(periodDates)}`);
  }

  balance(periodDates) {
    return super.get(`balance/?${qs.encode(periodDates)}`);
  }

  ranks(periodDates) {
    return super.get(`ranks/?${qs.encode(periodDates)}`);
  }

  rewardStats() {
    return super.get(`reward-stats/`);
  }

  rewards(page, pageSize, hasLinkOpenedOn) {
    const params = {
      page,
      page_size: pageSize,
      has_link_opened_on: hasLinkOpenedOn
    };
    return super.get(`rewards/?${qs.encode(params)}`);
  }
}
