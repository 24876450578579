import config, { FEATURE_FLAGS } from '@/libs/utils/config';

export const REDIRECT_TO_OLD_SIGNUP_IF_NO_SIGNUP_FLAG = async (to, from, next) => {
  if (!config.featureEnabled(FEATURE_FLAGS.NEW_SIGNUP_PAGE)) {
    window.location = '/accounts/signup/';
    return;
  }

  next();
};

export const REDIRECT_TO_TENANT_SIGNUP_IF_NO_SIGNUP_FLAG = async (to, from, next) => {
  if (!config.featureEnabled(FEATURE_FLAGS.NEW_SIGNUP_PAGE)) {
    window.location = '/accounts-signup/tenant';
    return;
  }

  next();
};

export const REDIRECT_TO_GUARANTOR_SIGNUP_IF_NO_SIGNUP_FLAG = async (to, from, next) => {
  if (!config.featureEnabled(FEATURE_FLAGS.NEW_SIGNUP_PAGE)) {
    window.location = '/accounts-signup/guarantor';
    return;
  }

  next();
};

export const REDIRECT_TO_LANDLORD_SIGNUP_IF_NO_SIGNUP_FLAG = async (to, from, next) => {
  if (!config.featureEnabled(FEATURE_FLAGS.NEW_SIGNUP_PAGE)) {
    window.location = '/accounts-signup/landlord';
    return;
  }

  next();
};

export const REDIRECT_TO_AGENT_SIGNUP_IF_NO_SIGNUP_FLAG = async (to, from, next) => {
  if (!config.featureEnabled(FEATURE_FLAGS.NEW_SIGNUP_PAGE)) {
    window.location = '/accounts-signup/agent';
    return;
  }

  next();
};

export const ROUTES = {
  PARTNER_SIGNUP: 'PARTNER_SIGNUP',
  BTR_LEAD: 'BTR_LEAD',
  REFERENCING_INVITE: 'REFERENCING_INVITE',
  EMAIL_CONFIRMATION: 'EMAIL_CONFIRMATION',
  SIGNUP: 'SIGNUP',
  TENANT_SIGNUP: 'TENANT_SIGNUP',
  GUARANTOR_SIGNUP: 'GUARANTOR_SIGNUP',
  LANDLORD_SIGNUP: 'LANDLORD_SIGNUP',
  AGENT_SIGNUP: 'AGENT_SIGNUP',
  ADDITIONAL_SIGNUP_DETAILS: 'ADDITIONAL_SIGNUP_DETAILS'
};

export const partnerSignup = {
  name: ROUTES.PARTNER_SIGNUP,
  path: '/signup/partner',
  component: () => import('@/pages/signup/signup_flow.vue')
};

export const btrLead = {
  name: ROUTES.BTR_LEAD,
  path: '/signup/btr-lead',
  component: () => import('@/pages/signup/btr_lead.vue')
};

export const referencingInvite = {
  name: ROUTES.REFERENCING_INVITE,
  path: '/signup/referencing',
  component: () => import('@/pages/signup/referencing.vue')
};

export const emailConfirmation = {
  name: ROUTES.EMAIL_CONFIRMATION,
  path: '/signup/email-confirmation',
  component: () => import('@/pages/signup/email_confirmation.vue')
};

export const signup = {
  name: ROUTES.SIGNUP,
  path: '/signup',
  beforeEnter: REDIRECT_TO_OLD_SIGNUP_IF_NO_SIGNUP_FLAG,
  component: () => import('@/pages/signup/signup')
};

export const tenantSignup = {
  name: ROUTES.TENANT_SIGNUP,
  path: '/signup',
  beforeEnter: REDIRECT_TO_TENANT_SIGNUP_IF_NO_SIGNUP_FLAG,
  component: () => import('@/pages/signup/signup')
};

export const guarantorSignup = {
  name: ROUTES.GUARANTOR_SIGNUP,
  path: '/signup',
  beforeEnter: REDIRECT_TO_GUARANTOR_SIGNUP_IF_NO_SIGNUP_FLAG,
  component: () => import('@/pages/signup/signup')
};

export const landlordSignup = {
  name: ROUTES.LANDLORD_SIGNUP,
  path: '/signup',
  beforeEnter: REDIRECT_TO_LANDLORD_SIGNUP_IF_NO_SIGNUP_FLAG,
  component: () => import('@/pages/signup/signup')
};

export const agentSignup = {
  name: ROUTES.AGENT_SIGNUP,
  path: '/signup',
  beforeEnter: REDIRECT_TO_AGENT_SIGNUP_IF_NO_SIGNUP_FLAG,
  component: () => import('@/pages/signup/signup')
};

export const additionalSignupDetails = {
  name: ROUTES.ADDITIONAL_SIGNUP_DETAILS,
  path: '/signup/:userId/details',
  props: true,
  component: () => import('@/pages/signup/additional_signup_details')
};

export default [
  partnerSignup,
  additionalSignupDetails,
  btrLead,
  referencingInvite,
  emailConfirmation,
  signup,
  tenantSignup,
  guarantorSignup,
  landlordSignup,
  agentSignup
];
