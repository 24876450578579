import BaseModel from '../base';
import moment from 'moment';

export default class LandlordModel extends BaseModel {
  defaults() {
    return {
      id: null,
      agentsCanSignOnMyBehalf: null,
      email: null,
      phoneNumber: null,
      fullName: null,
      hasSignedUp: false,
      hasSignedTc: false,
      invitedOn: null
    };
  }

  get invitedDaysAgo() {
    return this.invitedOn ? moment(this.invitedOn).diff(moment(), 'day') : undefined;
  }
}
