import * as zendeskService from '@/libs/services/integration/zendesk';

export const hideZendeskOnMobileMixin = {
  created() {
    this.zendesk = zendeskService;
  },
  mounted() {
    this.unsubscribeHideButtonOnMobile = this.zendesk.hideButtonOnMobile();
  },
  destroyed() {
    this.unsubscribeHideButtonOnMobile();
  }
};
