import BaseModel from '../base';
import { GenericDocumentModel } from '@/models/internal/flatbond_guarantors';
import { PROTECTION_CAP_MULTIPLIER } from '@/libs/utils/constants';

const FIRST_OF_JUNE_2019 = new Date('2019-06-01');
const MAX_LIABILITY_BEFORE_JUNE_2019 = 6;
const MAX_LIABILITY_AFTER_JUNE_2019 = 5;

export class InternalChargeCaseRlpCalculationDefaultValues extends BaseModel {
  constructor(data) {
    super(data);
    this.startDate = new Date(this.startDate);
  }

  defaults() {
    return {
      startDate: new Date(0),
      weeklyRent: 0,
      membershipFee: 0,
      dilapidationsProtectionCapMultiplier: 0,
      applyProtectionMultiplierOn: '',
      dilapidations: 0,
      rentArrears: 0,
      paidToDateByHomelet: 0,
      excessPayableByHomelet: 0
    };
  }

  get liabilityFactor() {
    return FIRST_OF_JUNE_2019.getTime() <= this.startDate.getTime()
      ? MAX_LIABILITY_AFTER_JUNE_2019
      : MAX_LIABILITY_BEFORE_JUNE_2019;
  }

  get maxLiability() {
    return this.weeklyRent * this.liabilityFactor;
  }

  get totalPayable() {
    return this.rentArrears - this.excessPayableByHomelet;
  }

  get oweDilapidations() {
    return Math.max(Math.min(this.totalPayable, this.maxLiability - this.dilapidations), 0);
  }

  get remainderToBePaidByHomelet() {
    return this.totalPayable - this.oweDilapidations;
  }

  get toPayHomelet() {
    return Math.max(0, this.oweDilapidations);
  }

  get rentToAssignFromLandlord() {
    return this.toPayHomelet;
  }

  get dilapidationsToAssignFromLandlord() {
    const amount =
      this.applyProtectionMultiplierOn === PROTECTION_CAP_MULTIPLIER.WEEKLY
        ? this.weeklyRent
        : this.membershipFee;

    return Math.min(amount * this.dilapidationsProtectionCapMultiplier, this.dilapidations);
  }

  get dilapidationsToAppointFromLandlord() {
    return this.dilapidations - this.dilapidationsToAssignFromLandlord;
  }

  toCalculation(defaults = {}) {
    return new InternalChargeCaseRlpCalculations({
      weeklyRent: this.weeklyRent,
      dilapidations: this.dilapidations,
      rentArrears: this.rentArrears,
      paidToDateByHomelet: this.paidToDateByHomelet,
      excessPayableByHomelet: this.excessPayableByHomelet,
      remainderToBePaidByHomelet: this.remainderToBePaidByHomelet,
      maxLiability: this.maxLiability,
      oweDilapidations: this.oweDilapidations,
      totalPayable: this.totalPayable,
      rentToAssignFromLandlord: this.rentToAssignFromLandlord,
      dilapidationsToAssignFromLandlord: this.dilapidationsToAssignFromLandlord,
      dilapidationsToAppointFromLandlord: this.dilapidationsToAppointFromLandlord,
      startDate: this.startDate,
      ...defaults
    });
  }
}

export class InternalChargeCaseRlpCalculationUser extends BaseModel {
  defaults() {
    return {
      firstName: '',
      lastName: ''
    };
  }
}

export class InternalChargeCaseRlpCalculations extends BaseModel {
  constructor(data) {
    super(data);
    this.startDate = new Date(this.startDate);
    this.createdOn = new Date(this.createdOn);
    this.createdBy = this.createdBy && new InternalChargeCaseRlpCalculationUser(this.createdBy);
    this.proofDocument = this.proofDocument && new GenericDocumentModel(this.proofDocument);
  }

  defaults() {
    return {
      weeklyRent: 0,
      dilapidations: 0,
      rentArrears: 0,
      paidToDateByHomelet: 0,
      excessPayableByHomelet: 0,
      remainderToBePaidByHomelet: 0,
      maxLiability: 0,
      oweDilapidations: 0,
      totalPayable: 0,
      rentToAssignFromLandlord: 0,
      dilapidationsToAssignFromLandlord: 0,
      dilapidationsToAppointFromLandlord: 0,
      startDate: new Date(0),
      createdOn: new Date(0),
      createdBy: null
    };
  }
}
