import config, { FEATURE_FLAGS } from '@/libs/utils/config';
import { LandlordFlatbondApi as PlanApi } from '@/libs/apis';

async function shouldShowWelcomePage() {
  if (!config.features.has(FEATURE_FLAGS.BOOST)) {
    return false;
  }

  const planApi = new PlanApi();
  const resultsArray = await Promise.all([
    planApi.list(),
    planApi.list({
      only_eligible_for_boost: true,
      landlord_has_not_clicked_upgrade_to_boost_later: true,
      only_without_active_boost: true
    })
  ]);
  const totalNumberOfPlans = resultsArray[0].data.count;
  const totalNumberOfPlansEligibleForBoost = resultsArray[1].data.count;

  return totalNumberOfPlans === 1 && totalNumberOfPlans === totalNumberOfPlansEligibleForBoost;
}

export async function landlordHomepageRedirect(to, from, next) {
  const redirectToWelcomePage = await shouldShowWelcomePage();

  if (!config.user) {
    window.location.href = '/login/';
  }
  if (redirectToWelcomePage) {
    next({ name: 'LANDLORD_WELCOME_FLOW', params: { skipCheck: true } });
  } else if (config.features.has(FEATURE_FLAGS.LANDLORD_SIGNATURE_IMPROVEMENTS)) {
    next();
  } else {
    window.location.href = '/landlords/';
  }
}

export async function landlordWelcomeRedirect(to, from, next) {
  if (to.params.skipCheck) {
    next();
  } else {
    const continueToWelcomePage = await shouldShowWelcomePage();
    if (continueToWelcomePage) {
      next();
    } else {
      window.location.href = '/';
    }
  }
}
