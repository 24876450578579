import { ApiBase } from '@/libs/apis/base';

export class PlanDemoApi extends ApiBase {
  constructor(vue) {
    super(vue, 'plan');
    this.errorName = 'PlanDemoApi';
  }

  createNoDepositReadyForEndOfTenancy() {
    return super.post('demo/no-deposit-ready-for-end-of-tenancy/', {});
  }

  createCompletedReference() {
    return super.post('demo/create-completed-reference/', {});
  }

  createDraftPlan() {
    return super.post('demo/create-draft-plan/');
  }

  createUnselectedPlanReadyForTenantDecision() {
    return super.post('demo/unselected-plan-ready-for-tenant-decision/', {});
  }

  createTraditionalDepositPlanReadyForTenantAction() {
    return super.post('demo/traditional-deposit-plan-ready-for-tenant-action/', {});
  }

  activateDeposit(planId) {
    return super.post(`demo/${planId}/activate-deposit/`, {});
  }
}
