import BaseModel from '@/models/base';
import { formatCurrencyFromCents } from '@/libs/utils/helpers';

export default class TenantReferenceTotalDataModel extends BaseModel {
  defaults() {
    return {
      totalRent: null,
      totalIncome: null,
      totalAffordability: null,
      totalIncomeAffordability: null
    };
  }

  get formattedTotalRent() {
    return formatCurrencyFromCents(this.totalRent);
  }

  get formattedTotalIncome() {
    return formatCurrencyFromCents(this.totalIncome);
  }

  get formattedTotalAffordability() {
    return formatCurrencyFromCents(this.totalAffordability);
  }
}
