import * as mixpanel from '@/libs/utils/mixpanel';

export const TrackMixin = {
  methods: {
    trackButtonClick(context, extraData = {}) {
      const eventName = `${context}-button-click`;
      this.trackEvent(eventName, extraData);
    },
    trackPageLoad(context, extraData = {}) {
      const eventName = `${context}-page-load`;
      this.trackEvent(eventName, extraData);
    },
    trackToggleClick(context, extraData = {}) {
      const eventName = `${context}-toggle-click`;
      this.trackEvent(eventName, extraData);
    },
    trackInputFocus(context, extraData = {}) {
      const eventName = `${context}-input-focus`;
      this.trackEvent(eventName, extraData);
    },
    trackOptionSelected(context, extraData = {}) {
      const eventName = `${context}-option-selected`;
      this.trackEvent(eventName, extraData);
    },
    trackClicked(context, extraData = {}) {
      const eventName = `${context}-clicked`;
      this.trackEvent(eventName, extraData);
    },
    trackEvent(event, data = {}) {
      mixpanel.track(event, data);
    }
  }
};
