import { SelfBillingApiBase } from './base';

export class SelfBillingApi extends SelfBillingApiBase {
  get(id) {
    return super.get(`billing-details/${id}/`, 'Could not retrieve self-billing details');
  }

  updateAddress(data) {
    return super.put(`address/${data.id}/`, data, 'Could not update address');
  }

  updateBillingDetails(billingDetailsCore) {
    return super.put(
      `billing-details/${billingDetailsCore.id}/`,
      billingDetailsCore,
      'Could not update self-billing details'
    );
  }
}
