export const ROUTES = {
  LANDLORDS: 'LANDLORDS'
};

export const landlords = {
  path: '/agent/landlords/',
  name: ROUTES.LANDLORDS,
  component: () => import('@/pages/agent/landlords/landlords.vue')
};

export default [landlords];
