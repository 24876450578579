var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FfSidebar",
    [
      _c(
        "FpPageBlock",
        {
          attrs: {
            fullscreen: "",
            children: {
              default: ["min-content", "min-content", "auto", "min-content"]
            }
          }
        },
        [
          _c(
            "FpPageBlock",
            { attrs: { contained: "", "hide-on": "non-desktop" } },
            [
              _c(
                "FpFlexGrid",
                {
                  attrs: {
                    compact: "",
                    "align-items": _vm.FlexAlignItems.center,
                    "space-around": _vm.FlexSpaceAround.mainAxis
                  }
                },
                [
                  _c(
                    "FpFlexGridItem",
                    { attrs: { size: 6 } },
                    [
                      _c("FpHeader1", [_vm._v(_vm._s(_vm.$t("learn.title")))]),
                      _vm._v(" "),
                      _c("FpBody", { attrs: { "margin-top": 2 } }, [
                        _vm._v(_vm._s(_vm.$t("learn.description")))
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FpFlexGridItem",
                    { staticStyle: { display: "inherit" }, attrs: { size: 6 } },
                    [
                      _c("FpImage", [
                        _c("img", {
                          staticStyle: { "margin-top": "40px" },
                          attrs: {
                            src: _vm.image,
                            alt: _vm.$t("learn.mainImageAlt")
                          }
                        })
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FpPageBlock",
            { attrs: { contained: "", "hide-on": "desktop" } },
            [
              _c(
                "FpFlexGrid",
                {
                  attrs: {
                    compact: "",
                    "space-around": "",
                    "align-items": _vm.FlexAlignItems.center
                  }
                },
                [
                  _c(
                    "FpFlexGridItem",
                    { staticStyle: { display: "inherit" } },
                    [
                      _c("FpImage", [
                        _c("img", {
                          staticStyle: { "margin-top": "40px" },
                          attrs: {
                            src: _vm.image,
                            alt: _vm.$t("learn.mainImageAlt")
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "FpFlexGridItem",
                    [
                      _c("FpHeader1", [_vm._v(_vm._s(_vm.$t("learn.title")))]),
                      _vm._v(" "),
                      _c("FpBody", { attrs: { "margin-top": 2 } }, [
                        _vm._v(_vm._s(_vm.$t("learn.description")))
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "FpPageBlock",
            { attrs: { contained: "", border: "" } },
            [
              _c(
                "FpTabs",
                [
                  _c(
                    "FpTabsTab",
                    {
                      attrs: {
                        active: _vm.tabSelected === _vm.TAB_TRAINING_VIDEOS
                      },
                      on: { click: _vm.navigateToTrainingVideos }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("learn.tab.trainingVideos")) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "FpTabsTab",
                    {
                      attrs: {
                        active: _vm.tabSelected === _vm.TAB_TRAINING_SESSIONS
                      },
                      on: { click: _vm.navigateToTrainingSessions }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("learn.tab.trainingSessions")) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.tabSelected === _vm.TAB_TRAINING_VIDEOS
            ? _c(
                "FpPageBlock",
                { attrs: { contained: "", secondary: "" } },
                [
                  _c(
                    "FpFlexGrid",
                    { attrs: { "space-around": "" } },
                    [
                      _c(
                        "FpFlexGridItem",
                        {
                          attrs: {
                            size: { small: 12, medium: 12, large: 3, xLarge: 3 }
                          }
                        },
                        [
                          _c("FpFilter", {
                            attrs: { options: _vm.trainingVideoFilterOptions },
                            on: {
                              input: function($event) {
                                return _vm.changeCategory(_vm.category)
                              }
                            },
                            model: {
                              value: _vm.category,
                              callback: function($$v) {
                                _vm.category = $$v
                              },
                              expression: "category"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "FpFlexGridItem",
                        {
                          attrs: {
                            size: { small: 12, medium: 12, large: 9, xLarge: 9 }
                          }
                        },
                        [
                          _c(
                            "FpFlexGrid",
                            {
                              attrs: {
                                "align-items": _vm.FlexAlignItems.stretch
                              }
                            },
                            _vm._l(_vm.getCards(_vm.category), function(card) {
                              return _c(
                                "FpFlexGridItem",
                                {
                                  key: card.link,
                                  attrs: {
                                    size: {
                                      small: 12,
                                      medium: 12,
                                      large: 6,
                                      xLarge: 6
                                    }
                                  }
                                },
                                [
                                  _c("VideoCard", {
                                    key: card.link,
                                    attrs: {
                                      title: card.title,
                                      video: {
                                        url: card.link
                                      }
                                    },
                                    on: { play: _vm.play }
                                  })
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.tabSelected === _vm.TAB_TRAINING_SESSIONS
            ? _c(
                "FpPageBlock",
                { attrs: { contained: "", secondary: "" } },
                [
                  _c(
                    "FpFlexGrid",
                    {
                      attrs: {
                        "space-around": "",
                        "justify-content": _vm.FlexJustifyContent.start
                      }
                    },
                    _vm._l(_vm.trainingSessionCards, function(card, index) {
                      return _c(
                        "FpFlexGridItem",
                        {
                          key: index,
                          attrs: { size: { xLarge: 4, large: 4 } }
                        },
                        [
                          _c("Card", {
                            attrs: {
                              "on-page": _vm.PAGES.LEARN,
                              title: card.title,
                              description: card.description,
                              tags: card.tags,
                              link: { url: card.link, label: card.linkLabel }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "FpPageBlock",
            { attrs: { contained: "" } },
            [_c("Footer", { attrs: { "on-page": _vm.PAGES.LEARN } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }