import { InternalNotificationsApiBase } from '@/libs/apis/notifications/internal/base';
import { ReminderModel } from '@/models/notifications/reminder';
import querystring from 'querystring';

export class InternalNotificationsRemindersApi extends InternalNotificationsApiBase {
  constructor(vue) {
    super(vue, '/reminders', true);
  }

  async getNextDebtCollectionReminder(flatbondId) {
    const { data } = await super.get(
      `${flatbondId}/get-next-debt-collection-reminder`,
      'Failed to get reminders'
    );
    return new ReminderModel(data);
  }

  create(flatbondId, category, reminderDate, reminderDescription = null) {
    super.post(
      '',
      {
        flatbond: flatbondId,
        category: category,
        reminder_date: reminderDate,
        reminder_description: reminderDescription
      },
      'Failed to create reminder'
    );
  }

  toggleIsCompleted(id, isCompleted) {
    const data = {
      completed: isCompleted
    };

    return this.put(`${id}/toggle-completed/`, data);
  }

  async list({ q, page, category = 'debt_collection', date, completed }) {
    const qs = querystring.stringify({
      search: q,
      page,
      category,
      reminder_date: date,
      completed
    });

    const response = await this.get(`/?${qs}`);
    return [
      response.data.results.map(reminder => new ReminderModel(reminder)),
      response.data.count
    ];
  }

  async update(reminder) {
    const { data } = super.put(`${reminder.id}/`, reminder, 'Failed to update reminder');
    return new ReminderModel(data);
  }
}
