import BaseModel from '@/models/base';

export const DUMP_STATUS_STATUS = {
  CREATED: 'created',
  PENDING: 'pending',
  FAILED: 'failed',
  COMPLETED: 'completed'
};

export class InternalFlatbondDumpStatusModel extends BaseModel {
  defaults() {
    return {
      status: undefined,
      id: undefined,
      total: 0,
      completed: 0,
      message: undefined,
      url: undefined
    };
  }
}
