<template>
  <FpContainer grow>
    <FpFlex
      compact
      :justify-content="FlexJustifyContent.spaceBetween"
      :direction="FlexDirection.column"
      style="height: 100%"
    >
      <template v-if="loading">
        <FpFlexItem>
          <FpFlexGrid compact space-around>
            <FpFlexGridItem><FpPlaceholder /> </FpFlexGridItem>
            <FpFlexGridItem><FpPlaceholder /> </FpFlexGridItem>
            <FpFlexGridItem><FpPlaceholder /> </FpFlexGridItem>
          </FpFlexGrid>
        </FpFlexItem>
      </template>
      <template v-else>
        <FpFlexItem grow>
          <FpFlexGrid>
            <FpFlexGridItem id="player" :style="{ height: '235px' }">
              <VuePlyr ref="plyr">
                <video>
                  <source size="720" :src="video.url" type="video/mp4" />
                </video>
              </VuePlyr>
            </FpFlexGridItem>
          </FpFlexGrid>
          <FpFlexGrid compact space-around>
            <FpFlexGridItem>
              <FpHeader3>{{ title }}</FpHeader3>
            </FpFlexGridItem>
          </FpFlexGrid>
        </FpFlexItem>
      </template>
    </FpFlex>
  </FpContainer>
</template>
<script>
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import {
  FpFlex,
  FpHeader3,
  FpPlaceholder,
  FpFlexGridItem,
  FpContainer,
  FpFlexGrid,
  FpFlexItem
} from '@flatfair/vue-fairplay';
import FlexMixin from '@/libs/constants/flexMixin';

export default {
  components: {
    FpFlex,
    FpFlexGridItem,
    FpFlexGrid,
    FpHeader3,
    FpPlaceholder,
    FpContainer,
    VuePlyr,
    FpFlexItem
  },
  mixins: [FlexMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    video: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.$refs.plyr.player.on('play', () => {
      this.$emit('play', this.$refs.plyr.player);
    });
  }
};
</script>
