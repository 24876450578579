import { FlatbondApiBase } from './base';
import qs from 'querystring';

export class FlatbondDraftApi extends FlatbondApiBase {
  create(draft) {
    return super.post('draft/', draft, 'Unable to create a draft plan.');
  }

  update(draft) {
    return super.put(`draft/${draft.id}/`, draft, 'Unable to update a draft plan.');
  }

  list(queryParams = {}) {
    queryParams.product_type = queryParams.type;
    delete queryParams.type;
    queryParams.filter_plans_requiring_action = queryParams.plansRequiringAction;
    delete queryParams.plansRequiringAction;
    queryParams.draft_created_by = queryParams.draftCreatedBy;

    return super.get(`draft/?${qs.encode(queryParams)}`, 'Unable to list any draft plans');
  }

  retrieve(draftId) {
    return super.get(`draft/${draftId}/`, `Could not retrieve draft ${draftId}`);
  }

  async getDocumentsToSign(draftId) {
    return this.get(`draft/${draftId}/documents-to-sign/`);
  }

  async convertToFlatbond(draftId) {
    return this.post(`draft/${draftId}/convert-to-flatbond/`);
  }

  async checkEligibilityForNoDeposit(draftId) {
    return this.get(`draft/${draftId}/check-eligibility-for-no-deposit/`);
  }
}
