import BaseModel from '@/models/base';

export class BankTransferIntentModel extends BaseModel {
  defaults() {
    return {
      id: null,
      amount: null,
      flatbondTenantId: null
    };
  }
}
