import GuarantorModel from '../guarantor';
import DocumentModel from '../document';
import check from 'check-types';

export default class DraftGuarantorModel extends GuarantorModel {
  constructor(data) {
    super(data);

    this.newReferencingDocument = this.newReferencingDocument
      ? new DocumentModel(this.newReferencingDocument)
      : null;
  }

  toFlatpieModel() {
    const result = super.toFlatpieModel();

    if (check.object(this.newReferencingDocument)) {
      result['new_referencing_document_id'] = this.newReferencingDocument.id;
    }

    return result;
  }

  fixupGuarantor(tenants) {
    if (!this.guarantorFor) return this;
    const tenantEmails = new Set([...tenants.map(tenant => tenant.inviteEmail)]);
    const filteredGuarantorFor = this.guarantorFor.filter(guarantor => tenantEmails.has(guarantor));
    const guarantorFor = filteredGuarantorFor.length ? filteredGuarantorFor : [...tenantEmails];
    return new DraftGuarantorModel({ ...this, guarantorFor });
  }
}
