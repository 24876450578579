import { InternalTenantApiBase } from './base';
import IncomeExpenditureModel from '@/models/charges_case/payment_plan_model';

export class InternalTenantApi extends InternalTenantApiBase {
  list(query = '') {
    return super.get(`${query}`, 'Failed to list tenants');
  }

  get(tenantId) {
    return super.get(`${tenantId}`, 'Failed to get tenant');
  }

  updateDetails(tenantId, tenant) {
    const data = {
      first_name: tenant.firstName,
      last_name: tenant.lastName,
      company_name: tenant.companyName,
      company_director_name: tenant.companyDirectorName,
      invite_email: tenant.email
    };
    return super.put(`${tenantId}/`, data, 'Failed to update tenant details');
  }

  disablePaymentNameChecking(tenantId) {
    return super.put(
      `${tenantId}/disable-payment-name-checking/`,
      {},
      'Failed to disable payment name checking'
    );
  }

  enablePaymentNameChecking(tenantId) {
    return super.put(
      `${tenantId}/enable-payment-name-checking/`,
      {},
      'Failed to enable payment name checking'
    );
  }

  updateUser(tenantId, phoneNumber, address) {
    const data = {
      phone_number: phoneNumber,
      address: address
    };

    return super.put(`${tenantId}/update-user/`, data, 'Failed to update the tenant user details');
  }

  updateFlatbondLeadTenant(tenantId) {
    return super.put(`${tenantId}/update-lead-tenant/`, {}, 'Failed to update lead tenant');
  }

  async getIncomeAndExpenditureReportForTenant(tenantId) {
    const { data } = await super.get(
      `${tenantId}/get-income-and-expenditure-report/`,
      `Failed to get income and expenditure report for tenant`
    );
    return data ? new IncomeExpenditureModel(data) : new IncomeExpenditureModel();
  }
}
