import PlanModel from '@/models/plan';
import { ERROR, FAILED, FETCHING, PLAN } from '@/store/plan/state';
import { SET_ERROR, SET_FAILED, SET_FETCHING, SET_PLAN } from '@/store/plan/mutations';
import { FETCH } from '@/store/plan/actions';
import { PlanApi } from '@/libs/apis';

const planService = new PlanApi();

export const namespaced = true;

export const state = {
  [FETCHING]: false,
  [FAILED]: false,
  [ERROR]: null,
  [PLAN]: new PlanModel()
};

export const mutations = {
  [SET_FETCHING](state, fetching) {
    state[FETCHING] = fetching;
  },
  [SET_FAILED](state, failed) {
    state[FAILED] = failed;
  },
  [SET_ERROR](state, error) {
    state[ERROR] = error;
  },
  [SET_PLAN](state, plan) {
    state[PLAN] = state[PLAN].merge(plan);
  }
};

export const actions = {
  async [FETCH]({ commit }, id) {
    commit(SET_FETCHING, true);
    commit(SET_FAILED, false);
    commit(SET_ERROR, null);
    try {
      const { data } = await planService.get(id);
      commit(SET_PLAN, new PlanModel(data));
    } catch (error) {
      console.error(error);
      commit(SET_FAILED, true);
      commit(SET_ERROR, error);
    } finally {
      commit(SET_FETCHING, false);
    }
  }
};
