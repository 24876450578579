import { GUARANTOR_SIGN_UP_ROUTES } from '@/pages/guarantor/sign_up';
import { GUARANTOR_ROUTES } from '@/pages/guarantor/index';
import { GuarantorApi, UserApi } from '@/libs/apis';
import { FLATBOND_STATUSES } from '@/libs/constants/generatedConstants';
import config from '@/libs/utils/config';

export async function guarantorHomepageRedirect(to, from, next) {
  const guarantorApi = new GuarantorApi(this);
  const resultsArray = await Promise.all([
    guarantorApi.list(),
    guarantorApi.list({
      flatbond__status: FLATBOND_STATUSES.PENDING_TENANT_ACTION
    })
  ]);
  const allGuarantors = resultsArray[0];
  const guarantorsWithPendingFlatbond = resultsArray[1];
  const guarantorsRequiringAction = guarantorsWithPendingFlatbond.results
    .filter(guarantor => !guarantor.hasCompletedFlow)
    .sort((g1, g2) => g1.id - g2.id);

  if (allGuarantors.count > 0 && guarantorsRequiringAction.length === allGuarantors.count) {
    const guarantor = guarantorsRequiringAction[0];

    if (guarantor.has20FlowEnabled) {
      next({
        name: GUARANTOR_SIGN_UP_ROUTES.FLOW_2_0,
        params: { guarantorId: guarantor.id }
      });
    } else {
      next({ name: GUARANTOR_SIGN_UP_ROUTES.FLOW_1_0 });
    }
  } else {
    if (!config.user.isAGuarantorOnAPlan && config.user.isAGuarantorOnAReferencingApplication) {
      window.location = `/referencing/tenant`;
    } else {
      next();
    }
  }
}

export async function redirectToHomepageIfUserHasSignedUp(to, from, next) {
  const userApi = new UserApi(this);
  const user = await userApi.get();

  if (user.activeAddress) {
    next({ name: GUARANTOR_ROUTES.GUARANTOR_HOMEPAGE });
  } else {
    next();
  }
}

export async function redirectToFlow10IfFlow20Disabled(to, from, next) {
  const guarantorApi = new GuarantorApi(this);

  try {
    const guarantor = await guarantorApi.get(to.params.guarantorId);

    if (guarantor.has20FlowEnabled) {
      next();
    } else {
      next({ name: GUARANTOR_SIGN_UP_ROUTES.FLOW_1_0 });
    }
  } catch {
    next({ name: GUARANTOR_ROUTES.GUARANTOR_HOMEPAGE });
  }
}
