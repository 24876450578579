import { Pagination } from '@/libs/pagination';
import { ITEMS_PER_PAGE } from '@/libs/utils/constants';

export const TableMixin = {
  data: function() {
    return {
      pagination: new Pagination(),
      itemsPerPage: ITEMS_PER_PAGE,
      availablePages: {},
      query: {
        search: null,
        page: 1
      },
      tableData: { columns: {}, rows: [] }
    };
  },
  methods: {
    clearPagination: function() {
      this.pagination.clearData();
      this.availablePages = this.pagination.availablePages;
    },
    updatePagination: function(data) {
      this.pagination.addData(data, this.query.page - 1);
      this.availablePages = this.pagination.availablePages;
    },
    pageRequested: function(page) {
      this.query.page = page + 1;
      this._fetchData();
    },
    onClear: function() {
      this.query.search = null;
      this._fetchData();
    }
  }
};
