import BaseModel from '../base';
import moment from 'moment';
import {
  NOTIFICATION_CATEGORY_AND_ICON,
  NOTIFICATION_TYPE
} from '@/pages/agent/notifications/constants';
import { getActionLabelAndLink, getFromDictionary } from '@/libs/utils/helpers';

export const NOTIFICATION_DESCRIPTION_TYPES = {
  LAST_WEEK: 'Last week'
};

export class NotificationsModel extends BaseModel {
  constructor(data) {
    super(data);

    this.notificationCategory = getFromDictionary(
      NOTIFICATION_CATEGORY_AND_ICON,
      this.notificationCategory,
      'No matching category'
    );

    if (typeof this.notificationData === 'string') {
      this.notificationData = JSON.parse(this.notificationData);
    }
  }

  defaults() {
    return {
      id: null,
      createdOn: null,
      organizationGroup: null,
      notificationCategory: '',
      notificationType: '',
      notificationData: null,
      actionType: '',
      actionData: null,
      seenOn: null,
      clickedOn: null
    };
  }

  getActionLabelAndLink() {
    return getActionLabelAndLink(this.actionType);
  }

  formatNotificationData() {
    if (this.notificationType === NOTIFICATION_DESCRIPTION_TYPES.LAST_WEEK) {
      const changeInNumberOfLets = Math.abs(
        this.notificationData.flatfairListings - this.notificationData.numberOfLetsLastWeek
      );
      const percentageOfFlatfairLets = Number(
        this.notificationData.percentageOfFlatfairLets * 100
      ).toFixed(0);

      return {
        numberOfLets: this.notificationData.flatfairListings,
        changeInNumberOfLets: changeInNumberOfLets,
        percentageOfFlatfairLets: percentageOfFlatfairLets
      };
    }
    return this.notificationData;
  }

  title() {
    const notificationType = getFromDictionary(
      NOTIFICATION_TYPE,
      this.notificationType,
      'No matching title'
    );
    if (notificationType) {
      return notificationType.title;
    }
    return '';
  }

  description() {
    const notificationDescription = getFromDictionary(
      NOTIFICATION_TYPE,
      this.notificationType,
      'No matching description'
    );
    if (notificationDescription) {
      return this.getNotificationTypeDescription(this.notificationType, notificationDescription);
    }
    return '';
  }

  getNotificationTypeDescription(notificationType, notificationDescription) {
    if (notificationType === NOTIFICATION_DESCRIPTION_TYPES.LAST_WEEK) {
      if (this.notificationData.flatfairListings === 0) {
        return notificationDescription.description.noListings;
      }
      const changeInNumberListed =
        this.notificationData.flatfairListings - this.notificationData.numberOfLetsLastWeek;
      if (changeInNumberListed > 0) {
        return notificationDescription.description.increased;
      }
      if (changeInNumberListed < 0) {
        return notificationDescription.description.decreased;
      }
      if (changeInNumberListed === 0) {
        return notificationDescription.description.noChange;
      }
    }
    return notificationDescription.description;
  }

  timestamp() {
    return this.createdOn && moment(this.createdOn).format('DD, MMM');
  }
}
