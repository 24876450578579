import BaseModel from '../base';
import DocumentModel from '../document';
import check from 'check-types';

export const TENANT_TYPE_OPTIONS = {
  PRIVATE: 'private',
  COMPANY: 'company'
};
export const REFERENCING_RESULTS = {
  APPROVED: 'approved',
  CONDITIONALLY_APPROVED: 'conditionally_approved',
  REJECTED: 'rejected'
};
export const REFERENCING_CONDITION_TYPES = {
  PROOF_OF_ADDRESS: 'proof_of_address',
  GUARANTOR: 'guarantor',
  RENT_UPFRONT: 'rent_upfront',
  OTHER: 'other'
};

export const REFERENCING_CONDITION_TYPE_OPTIONS = [
  {
    label: 'draftFlatbond.tenant.referencingConditionOptions.proofOfAddress',
    value: REFERENCING_CONDITION_TYPES.PROOF_OF_ADDRESS
  },
  {
    label: 'draftFlatbond.tenant.referencingConditionOptions.rentUpfront',
    value: REFERENCING_CONDITION_TYPES.RENT_UPFRONT
  },
  {
    label: 'draftFlatbond.tenant.referencingConditionOptions.guarantor',
    value: REFERENCING_CONDITION_TYPES.GUARANTOR
  },
  {
    label: 'draftFlatbond.tenant.referencingConditionOptions.other',
    value: REFERENCING_CONDITION_TYPES.OTHER
  }
];
export const REFERENCING_RESULT_OPTIONS = [
  {
    label: 'draftFlatbond.tenant.referencingResultOptions.approved',
    value: REFERENCING_RESULTS.APPROVED
  },
  {
    label: 'draftFlatbond.tenant.referencingResultOptions.conditionallyApproved',
    value: REFERENCING_RESULTS.CONDITIONALLY_APPROVED
  },
  {
    label: 'draftFlatbond.tenant.referencingResultOptions.rejected',
    value: REFERENCING_RESULTS.REJECTED
  }
];

export const mapTranslatedOptions = $t => ({ label, value }) => ({ label: $t(label), value });

export default class DraftTenant extends BaseModel {
  constructor(data) {
    super(data);

    this.newReferencingDocument = this.newReferencingDocument
      ? new DocumentModel(this.newReferencingDocument)
      : null;

    this.newAdditionalReferencingDocuments = check.array(this.newAdditionalReferencingDocuments)
      ? this.newAdditionalReferencingDocuments.map(d => new DocumentModel(d))
      : null;
  }

  defaults() {
    return {
      inviteEmail: '',
      guarantorEmail: '',
      hasGuarantor: false,
      companyName: '',
      companyDirectorName: '',
      companyAddress: '',
      companyCity: '',
      companyPostcode: '',
      companyCountry: '',
      title: '',
      firstName: '',
      lastName: '',
      newReferencingDocument: null,
      referencingResult: '',
      referencingCondition: '',
      referencingConditionType: '',
      newAdditionalReferencingDocuments: [],
      isLeadTenant: false
    };
  }

  toFlatpieModel() {
    const result = super.toFlatpieModel();

    if (check.nonEmptyArray(this.newAdditionalReferencingDocuments)) {
      result[
        'new_additional_referencing_document_ids'
      ] = this.newAdditionalReferencingDocuments.filter(d => check.object(d)).map(d => d.id);
    }

    if (check.object(this.newReferencingDocument)) {
      result['new_referencing_document_id'] = this.newReferencingDocument.id;
    }

    return result;
  }

  displayName() {
    return this.companyName || `${this.firstName} ${this.lastName}`;
  }

  displayReferencingConditionType($t) {
    if (!this.referencingConditionType) return this.referencingConditionType;

    const option = REFERENCING_CONDITION_TYPE_OPTIONS.find(
      opt => opt.value === this.referencingConditionType
    );

    return $t(option.label) || this.referencingConditionType;
  }

  displayReferencingResult($t) {
    if (!this.referencingResult) return this.referencingResult;

    const option = REFERENCING_RESULT_OPTIONS.find(opt => opt.value === this.referencingResult);

    return $t(option.label) || this.referencingResult;
  }
}
