export const RENT_PERIODS = {
  MONTH: 'month',
  WEEK: 'week'
};

export const ACTION_LABEL_AND_LINK = {
  open_insights: {
    link: 'notifications.actionType.openInsights.link',
    label: 'notifications.actionType.openInsights.label'
  }
};

export const CAP_TO_ROUND_MONTHLY_RENT = 0.1;

export const MINIMUM_DURATION_IN_MONTHS = 6;
export const MAXIMUM_DURATION_IN_MONTHS = 24;
export const DEFAULT_FLATBOND_DURATION_IN_MONTHS = MINIMUM_DURATION_IN_MONTHS;

export const MINIMUM_REFERENCING_DURATION_IN_MONTHS = 1;
export const MAXIMUM_REFERENCING_DURATION_IN_MONTHS = 120;
export const DEFAULT_REFERENCING_DURATION_IN_MONTHS = DEFAULT_FLATBOND_DURATION_IN_MONTHS;
export const MAXIMUM_REFERENCING_DELTA_IN_DAYS = 180;

export const MANAGED_BY_AGENT_VALUE = 'agent';
export const MANAGED_BY_LANDLORD_VALUE = 'landlord';
export const DEFAULT_MANAGED_BY = MANAGED_BY_AGENT_VALUE;
export const MANAGED_BY_OPTIONS = [
  {
    value: MANAGED_BY_AGENT_VALUE,
    label: 'Agent'
  },
  {
    value: MANAGED_BY_LANDLORD_VALUE,
    label: 'Landlord'
  }
];

export const DEFAULT_COUNTRY = 'gb-eng';
export const COUNTRY_OPTIONS = [
  {
    value: DEFAULT_COUNTRY,
    label: 'England'
  },
  {
    value: 'gb-sct',
    label: 'Scotland'
  },
  {
    value: 'gb-wls',
    label: 'Wales'
  }
];

export const DEFAULT_TENANT_TYPE = 'private';

export const LL_NOT_INTERESTED_REASON = 'No Deposit not offered > LL not interested';
export const TT_NOT_INTERESTED_REASON = 'No Deposit not offered > TT not interested';
export const OTHER_REASON = 'No Deposit not offered > Other';
export const NO_REASON = 'No Deposit not offered > No reason provided';

export const LL_NOT_INTERESTED_IN_EXTRA_PROTECTION = 'extra_protection';
export const LL_NOT_INTERESTED_IN_DEBTS_RECOVERED = 'debts_recovered';
export const LL_NOT_INTERESTED_IN_FLATFAIR = 'not_interested';
export const LL_NOT_INTERESTED_OTHER = 'other';

export const DEBT_COLLECTION_COST_PERCENTAGE = 15;

export const COLLECTION_STATUS_FILTER_OPTIONS = [
  { value: '', label: 'All' },
  { value: 'payment_letter', label: 'Payment letter' },
  { value: 'force_payment', label: 'Force payment' },
  { value: 'force_payment_dispute', label: 'Force payment dispute' },
  { value: 'payment_plan', label: 'Payment plan' },
  { value: 'call_1', label: 'Call 1' },
  { value: 'call_2', label: 'Call 2' },
  { value: 'trace', label: 'Trace' },
  { value: 'quarterly_trace', label: 'Quarterly trace' },
  { value: 'collection_cost_letter', label: 'Collection cost letter' },
  { value: 'call_3', label: 'Call 3' },
  { value: 'call_3_guarantor', label: 'Call 3 guarantor' },
  { value: 'legal_instructed', label: 'Legal instructed' },
  { value: 'legal_claim', label: 'Legal claim' },
  { value: 'legal_judgement', label: 'Legal judgement' },
  { value: 'legal_enforcement_aoe', label: 'Legal enforcement aoe' },
  { value: 'legal_enforcement_hceo', label: 'Legal enforcement hceo' },
  {
    value: 'legal_enforcement_third_party_debt_order',
    label: 'Legal enforcement third party debt order'
  },
  { value: 'legal_enforcement_other', label: 'Legal enforcement other' },
  { value: 'legal_enforcement_defended', label: 'Legal enforcement defended' },
  { value: 'legal_payment_plan', label: 'Legal payment plan' },
  { value: 're_trace', label: 'Re-trace' },
  { value: 'settlement_letter', label: 'Settlement letter' },
  { value: 'external_collection', label: 'External collection' },
  { value: 'write_off_gone_away', label: 'Write off gone away' },
  { value: 'write_off_settlement', label: 'Write off settlement' },
  { value: 'write_off_uneconomical_to_pursue', label: 'Write off uneconomical to pursue' },
  { value: 'write_off_insolvency', label: 'Write off insolvency' },
  { value: 'write_off_landlord_offer', label: 'Write off landlord offer' },
  { value: 'write_off_other', label: 'Write off other' },
  { value: 'financial_difficulty', label: 'Financial difficulty' }
];

export const COLLECTION_STATUS_OPTIONS = [
  { value: 'null', label: 'None', disabled: true },
  { value: 'payment_letter', label: 'Payment letter' },
  { value: 'force_payment', label: 'Force payment' },
  { value: 'force_payment_dispute', label: 'Force payment dispute' },
  { value: 'payment_plan', label: 'Payment plan' },
  { value: 'call_1', label: 'Call 1' },
  { value: 'call_2', label: 'Call 2' },
  { value: 'trace', label: 'Trace' },
  { value: 'quarterly_trace', label: 'Quarterly trace' },
  { value: 'collection_cost_letter', label: 'Collection cost letter' },
  { value: 'call_3', label: 'Call 3' },
  { value: 'call_3_guarantor', label: 'Call 3 guarantor' },
  { value: 'legal_instructed', label: 'Legal instructed' },
  { value: 'legal_claim', label: 'Legal claim' },
  { value: 'legal_judgement', label: 'Legal judgement' },
  { value: 'legal_enforcement_aoe', label: 'Legal enforcement aoe' },
  { value: 'legal_enforcement_hceo', label: 'Legal enforcement hceo' },
  {
    value: 'legal_enforcement_third_party_debt_order',
    label: 'Legal enforcement third party debt order'
  },
  { value: 'legal_enforcement_other', label: 'Legal enforcement other' },
  { value: 'legal_enforcement_defended', label: 'Legal enforcement defended' },
  { value: 'legal_payment_plan', label: 'Legal payment plan' },
  { value: 're_trace', label: 'Re-trace' },
  { value: 'settlement_letter', label: 'Settlement letter' },
  { value: 'external_collection', label: 'External collection' },
  { value: 'write_off_gone_away', label: 'Write off gone away' },
  { value: 'write_off_settlement', label: 'Write off settlement' },
  { value: 'write_off_uneconomical_to_pursue', label: 'Write off uneconomical to pursue' },
  { value: 'write_off_insolvency', label: 'Write off insolvency' },
  { value: 'write_off_landlord_offer', label: 'Write off landlord offer' },
  { value: 'write_off_other', label: 'Write off other' },
  { value: 'financial_difficulty', label: 'Financial difficulty' }
];

export const REFERENCE_APPLICANT_TYPE_TENANT = 'tenant';
export const REFERENCE_APPLICANT_TYPE_GUARANTOR = 'guarantor';
export const REFERENCE_APPLICANT_TYPE_OPTIONS = [
  {
    value: REFERENCE_APPLICANT_TYPE_TENANT,
    label: 'Tenant'
  },
  {
    value: REFERENCE_APPLICANT_TYPE_GUARANTOR,
    label: 'Guarantor'
  }
];

export const MINIMUM_NUMBER_OF_TENANTS = 1;
export const MAXIMUM_NUMBER_OF_TENANTS = 4;
export const EXTENDED_MAXIMUM_NUMBER_OF_TENANTS = 10;
export const MAXIMUM_NUMBER_OF_COMPANY_TENANTS = 1;
export const DEFAULT_NUMBER_OF_TENANTS = MINIMUM_NUMBER_OF_TENANTS;

export const TENANTS_FIELD_NAME = 'tenants';

export const FLATBOND_TYPE_NEW = 'new_tenancy';
export const FLATBOND_TYPE_CONVERSION = 'conversion';

export const DOCUMENT_ADDITIONAL_REFERENCING_DOCUMENT = 'additional_referencing_document';
export const DOCUMENT_ADJUDICATION_REPORT = 'adjudication_report';
export const DOCUMENT_AST = 'assured_shorthold_tenancy';
export const DOCUMENT_AST_ADDENDUM_TEMPLATE = 'ast_addendum_template';
export const DOCUMENT_AST_TEMPLATE = 'ast_template';
export const DOCUMENT_CHARGE_DOCUMENT = 'claim_document';
export const DOCUMENT_CHARGES_INVOICE = 'claims_invoice';
export const DOCUMENT_CHECK_IN = 'check_in';
export const DOCUMENT_CHECK_OUT = 'check_out';
export const DOCUMENT_COMPANY_AST = 'ast_company_template';
export const DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE = 'deposit_protection_certificate';
export const DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE_SIGNED =
  'deposit_protection_certificate_signed';
export const DOCUMENT_DEPOSIT_PROTECTION_REGISTRATION_CERTIFICATE =
  'deposit_registration_certificate';
export const DOCUMENT_DEPOSIT_REPLACEMENT_AGREEMENT = 'deposit_replacement_agreement';
export const DOCUMENT_DEPOSIT_REPLACEMENT_SCHEDULE = 'deposit_replacement_schedule';
export const DOCUMENT_GUARANTOR_INFO = 'guarantor_info';
export const DOCUMENT_INVENTORY_REPORT = 'inventory_report';
export const DOCUMENT_LANDLORD_INSTRUCTION_FORM = 'instruction_form_template';
export const DOCUMENT_LANDLORD_OFFER_SIGNED = 'landlord_offer_signed';
export const DOCUMENT_LANDLORD_SUBJECT_TO_CONTRACT_LETTER = 'landlord_subject_to_contract_template';
export const DOCUMENT_LANDLORD_TERMS_OF_BUSINESS_TEMPLATE = 'terms_of_business_template';
export const DOCUMENT_ONBOARDING_DOCUMENT = 'onboarding_document';
export const DOCUMENT_PLAN_CERTIFICATE = 'flatbond_certificate';
export const DOCUMENT_PROOF_OF_ADDRESS = 'proof_of_address';
export const DOCUMENT_POST_ADJUDICATION_SUMMARY = 'post_adjudication_summary';
export const DOCUMENT_REFERENCING_RESULT = 'referencing_result';
export const DOCUMENT_RENT_ARREARS_AGREEMENT = 'rent_arrears_agreement';
export const DOCUMENT_RENT_ARREARS_REPORT = 'rent_arrears_report';
export const DOCUMENT_RENT_SCHEDULE = 'rent_schedule';
export const DOCUMENT_RLP_PROOF = 'rlp_proof';
export const DOCUMENT_SECURE_DOCUMENT = 'secure_document';
export const DOCUMENT_SITUATION_CHANGE_PROOF = 'tenant_situation_change';
export const DOCUMENT_TENANCY_ADDENDUM = 'tenancy_addendum';
export const DOCUMENT_TENANCY_AGREEMENT = 'tenancy_agreement';
export const DOCUMENT_TENANT_OFFER_LETTER = 'tenant_offer_letter_template';
export const DOCUMENT_TENANT_SUBJECT_TO_CONTRACT_LETTER = 'tenant_subject_to_contract_template';
export const DOCUMENT_TENANT_TERMS_OF_BUSINESS = 'tenant_terms_of_business_template';
export const DOCUMENT_LANDLORD_TERMS_AND_CONDITIONS = 'landlord_terms_and_conditions_signed';
export const DOCUMENT_TENANT_TERMS_AND_CONDITIONS = 'tenant_terms_and_conditions_signed';
export const DOCUMENT_PRE_ADJUDICATION_REPORT = 'pre_adjudication_report';

export const DOCUMENT_UNKNOWN = 'unknown';
export const DOCUMENT_INVOICE = 'invoice';

export const DOCUMENT_PROPENSITY_SCORE = 'propensity_score';

export const DOCUMENT_TEMPLATES_TYPES = [
  {
    label: 'AST',
    value: DOCUMENT_AST_TEMPLATE
  },
  {
    label: 'Landlord terms of business',
    value: DOCUMENT_LANDLORD_TERMS_OF_BUSINESS_TEMPLATE
  },
  {
    label: 'Landlord instruction form',
    value: DOCUMENT_LANDLORD_INSTRUCTION_FORM
  },
  {
    label: 'Tenant offer letter',
    value: DOCUMENT_TENANT_OFFER_LETTER
  },
  {
    label: 'AST addendum',
    value: DOCUMENT_AST_ADDENDUM_TEMPLATE
  },
  {
    label: 'Company AST',
    value: DOCUMENT_COMPANY_AST
  },
  {
    label: 'Landlord subject to contract letter',
    value: DOCUMENT_LANDLORD_SUBJECT_TO_CONTRACT_LETTER
  },
  {
    label: 'Tenant subject to contract letter',
    value: DOCUMENT_TENANT_SUBJECT_TO_CONTRACT_LETTER
  },
  {
    label: 'Tenant terms of business',
    value: DOCUMENT_TENANT_TERMS_OF_BUSINESS
  },
  {
    label: 'Unknown',
    value: DOCUMENT_UNKNOWN
  }
];

export const DOCUMENT_TEMPLATES_TYPES_DICT = DOCUMENT_TEMPLATES_TYPES.reduce((object, item) => {
  object[item.value] = item.label;
  return object;
}, {});

export const DOCUMENT_TYPES = [
  {
    value: DOCUMENT_UNKNOWN,
    label: 'Unknown'
  },
  {
    value: DOCUMENT_TENANCY_AGREEMENT,
    label: 'Tenancy Agreement'
  },
  {
    value: DOCUMENT_TENANCY_ADDENDUM,
    label: 'Tenancy Agreement Addendum'
  },
  {
    value: DOCUMENT_INVENTORY_REPORT,
    label: 'Inventory Report'
  },
  {
    value: DOCUMENT_AST,
    label: 'AST'
  },
  {
    value: DOCUMENT_CHECK_IN,
    label: 'Check-in report'
  },
  {
    value: DOCUMENT_CHECK_OUT,
    label: 'Check-out report'
  },
  {
    value: DOCUMENT_CHARGE_DOCUMENT,
    label: 'Claim Document'
  },
  {
    value: DOCUMENT_PLAN_CERTIFICATE,
    label: 'Flatbond Certificate'
  },
  {
    value: DOCUMENT_CHARGES_INVOICE,
    label: 'Claims invoice'
  },
  {
    value: DOCUMENT_AST_TEMPLATE,
    label: 'AST template'
  },
  {
    value: DOCUMENT_LANDLORD_TERMS_OF_BUSINESS_TEMPLATE,
    label: 'Terms of Business Template'
  },
  {
    value: DOCUMENT_LANDLORD_INSTRUCTION_FORM,
    label: 'Confirmation of Marketing Template'
  },
  {
    value: DOCUMENT_TENANT_OFFER_LETTER,
    label: 'Tenant Offer Letter Template'
  },
  {
    value: DOCUMENT_AST_ADDENDUM_TEMPLATE,
    label: 'Tenant Offer Letter Template'
  },
  {
    value: DOCUMENT_COMPANY_AST,
    label: 'Company AST Template'
  },
  {
    value: DOCUMENT_LANDLORD_SUBJECT_TO_CONTRACT_LETTER,
    label: 'Landlord subject to contract letter'
  },
  {
    value: DOCUMENT_TENANT_SUBJECT_TO_CONTRACT_LETTER,
    label: 'Tenant subject to contract letter'
  },
  {
    value: DOCUMENT_TENANT_TERMS_OF_BUSINESS,
    label: 'Tenant terms of business'
  },
  {
    value: DOCUMENT_DEPOSIT_REPLACEMENT_AGREEMENT,
    label: 'Deposit replacement agreement'
  },
  {
    value: DOCUMENT_DEPOSIT_REPLACEMENT_SCHEDULE,
    label: 'Deposit replacement schedule'
  },
  {
    value: DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE,
    label: 'Unsigned deposit protection certificate'
  },
  {
    value: DOCUMENT_DEPOSIT_PROTECTION_CERTIFICATE_SIGNED,
    label: 'Signed deposit protection certificate'
  },
  {
    value: DOCUMENT_DEPOSIT_PROTECTION_REGISTRATION_CERTIFICATE,
    label: 'Deposit registration certificate'
  },
  {
    value: DOCUMENT_REFERENCING_RESULT,
    label: 'Referencing result'
  },
  {
    value: DOCUMENT_ADDITIONAL_REFERENCING_DOCUMENT,
    label: 'Additional referencing document'
  },
  {
    value: DOCUMENT_LANDLORD_OFFER_SIGNED,
    label: 'Signed landlord offer'
  },
  {
    value: DOCUMENT_RENT_ARREARS_REPORT,
    label: 'Rent Arrears Report'
  },
  {
    value: DOCUMENT_RENT_SCHEDULE,
    label: 'Rent Schedule'
  },
  {
    value: DOCUMENT_RENT_ARREARS_AGREEMENT,
    label: 'Rent Arrears Agreement'
  },
  {
    value: DOCUMENT_ONBOARDING_DOCUMENT,
    label: 'Onboarding Document'
  },
  {
    value: DOCUMENT_SECURE_DOCUMENT,
    label: 'Secure Document'
  },
  {
    value: DOCUMENT_ADJUDICATION_REPORT,
    label: 'Adjudication report'
  },
  {
    value: DOCUMENT_GUARANTOR_INFO,
    label: 'Guarantor info'
  },
  {
    value: DOCUMENT_PROOF_OF_ADDRESS,
    label: 'Proof of address'
  },
  {
    value: DOCUMENT_POST_ADJUDICATION_SUMMARY,
    label: 'Post adjudication summary'
  },
  {
    value: DOCUMENT_INVOICE,
    label: 'Invoice'
  },
  {
    value: DOCUMENT_LANDLORD_TERMS_AND_CONDITIONS,
    label: 'Landlord terms and conditions'
  },
  {
    value: DOCUMENT_TENANT_TERMS_AND_CONDITIONS,
    label: 'Tenant terms and conditions'
  },
  {
    value: DOCUMENT_PRE_ADJUDICATION_REPORT,
    label: 'Pre adjudication report'
  },
  {
    value: DOCUMENT_PROPENSITY_SCORE,
    label: 'Propensity score'
  }
];

export const WRITE_OFF_REASONS = [
  {
    value: 'gone_away',
    label: 'Write off - Gone away'
  },
  {
    value: 'settlement',
    label: 'Write off - Settlement'
  },
  {
    value: 'uneconomical_to_pursue',
    label: 'Write off - Uneconomical to pursue'
  },
  {
    value: 'insolvency',
    label: 'Write off - Insolvency'
  },
  {
    value: 'landlord_offer',
    label: 'Write off - Landlord offer'
  },
  {
    value: 'other',
    label: 'Write off - Other'
  }
];

export const NON_EXISTING_DOCUMENT_TYPE = 'Unknown document type';

export const ONBOARDING_STATE_CREATED = 'created';
export const ONBOARDING_STATE_DOCUMENTS_UPLOAD = 'documents upload';
export const ONBOARDING_STATE_DOCUMENTS_REVIEW = 'documents review';
export const ONBOARDING_STATE_DOCUMENTS_FINAL = 'final documents';

export const FLATBOND_TAGS = [
  'Landlord needs to sign',
  'Pending new AST',
  'Pending deposit replacement form',
  'Tenants paid to agent',
  'Tenants moved in without paying',
  "Tenants didn't pass referencing",
  "Tenants didn't move in",
  'Tenants moving date unknown',
  'Notice served - tenants still in property',
  'Approved on behalf of the landlord',
  'Needs attention'
];

export const STRIPE_FLATFAIR_IMAGE =
  'https://s3.eu-west-2.amazonaws.com/flatfair.static/static/logo-stripe.png';

export const NON_EXISTING_CATEGORY = 'Unknown category';
export const NON_EXISTING_STATUS = 'Unknown status';

export const PAY_TO_AGENT_VALUE = 'pay_to_agent';
export const PAY_TO_LANDLORD_VALUE = 'pay_to_landlord';
export const CLAIM_CASE_PAY_TO_OPTIONS = [
  {
    value: PAY_TO_AGENT_VALUE,
    label: 'Agent'
  },
  {
    value: PAY_TO_LANDLORD_VALUE,
    label: 'Landlord'
  }
];

export const CLAIM_CASE_HAS_INSURANCE_OPTIONS = [
  {
    value: false,
    label: 'No'
  },
  {
    value: true,
    label: 'Yes'
  }
];

export const REFERENCE_STATUS_INVITED = 'invited';
export const REFERENCE_STATUS_IN_PROGRESS = 'in_progress';
export const REFERENCE_STATUS_CANCELLED = 'cancelled';
export const REFERENCE_STATUS_PENDING_REVIEW = 'pending_review';

export const REFERENCE_REQUEST_DEFAULT_STATUS = {
  value: 'in_progress',
  label: 'In progress',
  body: 'This reference is currently in process. Please check back later for the result.',
  colourClass: 'info',
  iconClass: 'fa-ellipsis-h'
};

export const REFERENCE_APPLICATION_STATUSES = [
  {
    value: 'creating',
    label: 'Creating',
    body: 'The reference is being created...',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'invited',
    label: 'Invited',
    body:
      'The applicants have been invited to complete the referencing process. Results will be shown as they are available.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'in_progress',
    label: 'In progress',
    body: 'This application is currently in process. Please check back later for the result(s).',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'action_required',
    label: 'Action required',
    body: 'Please check the details of the application below and choose an appropriate action.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'pending_review',
    label: 'Pending offline check',
    body: 'This application is pending an offline check.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'pending_review',
    label: 'Pending offline check',
    body: 'This application is pending an offline check.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'completed',
    label: 'Completed',
    body: 'This reference is completed.',
    colourClass: 'success',
    iconClass: 'fa-check'
  },
  {
    value: 'expired',
    label: 'Expired',
    body:
      'This application has expired. The tenant(s) will each need to apply for a new reference.',
    colourClass: 'warning',
    iconClass: 'fa-warning'
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
    body: 'This application has been cancelled.',
    colourClass: 'danger',
    iconClass: 'fa-warning'
  }
];

export const REFERENCE_APPLICATION_STATUSES_WITH_ALL = [
  {
    value: null,
    label: 'All'
  },
  ...REFERENCE_APPLICATION_STATUSES
];

export const REFERENCE_REQUEST_STATUS = [
  {
    value: 'invited',
    label: 'Invited',
    body:
      'The applicant has been invited to complete a reference. Once it has been completed, the result will be shown here.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'pending_case_creation',
    label: 'Creating',
    body: 'The reference request is being created .',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'on_hold',
    label: 'On hold',
    body: 'The reference request is on hold, please check with the referencing provider',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'creating',
    label: 'Creating',
    body: 'The reference request is being created .',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'in_progress',
    label: 'In progress',
    body: 'This reference is currently in process. Please check back later for the result.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'pending_review',
    label: 'Pending manual check',
    body:
      'This reference is being reviewed offline by an agent. The agent will be in touch if they require more information from you.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'completed',
    label: 'Completed',
    body: `This reference is complete. You can review the summary, or download the full result as a PDF.`,
    colourClass: 'success',
    iconClass: 'fa-check'
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
    body: 'This reference has been cancelled.',
    colourClass: 'danger',
    iconClass: 'fa-warning'
  },
  {
    value: 'cancelled_not_billable',
    label: 'Cancelled without charge',
    body: 'This reference has been cancelled without charge.',
    colourClass: 'danger',
    iconClass: 'fa-warning'
  }
];

export const TENANT_REFERENCE_REQUEST_STATUS = [
  {
    value: 'invited',
    label: 'Invited',
    body:
      'The applicant has been invited to complete a reference. Once it has been completed, the result will be shown here.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'pending_case_creation',
    label: 'Creating',
    body: 'The reference request is being created .',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'creating',
    label: 'Creating',
    body: 'The reference request is being created .',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'in_progress',
    label: 'In progress',
    body: 'This reference is currently in process. Please check back later for the result.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'pending_review',
    label: 'Pending manual check',
    body:
      'This reference is being reviewed offline by an agent. The agent will be in touch if they require more information from you.',
    colourClass: 'pending',
    iconClass: 'fa-ellipsis-h'
  },
  {
    value: 'completed',
    label: 'Completed',
    body: `This reference is complete. You can review the summary, or download the full result as a PDF.`,
    colourClass: 'success',
    iconClass: 'fa-check'
  },
  {
    value: 'cancelled',
    label: 'Cancelled',
    body: `This reference has been cancelled.`,
    colourClass: 'danger',
    iconClass: 'fa-warning'
  },
  {
    value: 'cancelled_not_billable',
    label: 'Cancelled without charge',
    body: `This reference has been cancelled without charge.`,
    colourClass: 'danger',
    iconClass: 'fa-warning'
  }
];

export const MOVEM_INDIVIDUAL_CHECK_RESULTS = {
  Caution: {
    value: 'Caution',
    symbolClass: 'fa-warning',
    colourClass: 'text-flatfair-warning'
  },
  Pass: {
    value: 'Pass',
    symbolClass: 'fa-check',
    colourClass: 'text-flatfair-success'
  },
  Fail: {
    value: 'Fail',
    symbolClass: 'fa-close',
    colourClass: 'text-flatfair-danger'
  }
};

export const MOVEM_SUGGESTIONS = {
  PASSED: 'PASSED',
  FAILED: 'FAILED',
  MANUAL_PASSED: 'MANUAL_PASSED',
  MANUAL_FAILED: 'MANUAL_FAILED'
};

export const COLLECTION_COST_TYPES = {
  DEBT: 'debt_collection_cost',
  LEGAL_FEES: 'legal_fees_collection_cost',
  LEGAL_INTERESTS: 'legal_interests_collection_cost'
};

export const COLLECTION_COST_TYPES_OPTIONS = [
  {
    value: COLLECTION_COST_TYPES.DEBT,
    label: 'Debt collection cost'
  },
  {
    value: COLLECTION_COST_TYPES.LEGAL_FEES,
    label: 'Legal fees'
  },
  {
    value: COLLECTION_COST_TYPES.LEGAL_INTERESTS,
    label: 'Legal interests'
  }
];

export const REFERENCE_SUGGESTED_DECISION_OBJECTS = {
  acceptable_with_condition: {
    colourClass: 'success',
    iconClass: 'fa-check'
  },
  acceptable: {
    colourClass: 'success',
    iconClass: 'fa-check'
  },
  not_acceptable: {
    colourClass: 'danger',
    iconClass: 'fa-times'
  },
  pending: {
    value: 'PENDING',
    label: 'Pending',
    colourClass: 'warning',
    body: 'The applicant is still undergoing referencing. No recommendation has been provided yet.',
    iconClass: 'fa-ellipsis-h'
  },
  on_hold: {
    value: 'PENDING',
    label: 'On hold',
    colourClass: 'warning',
    body:
      'The reference is on hold, the applicant has been unresponsive to the referencing provider requests for several days. Please ask the applicant to contact the referencing provider.',
    iconClass: 'fa-ellipsis-h'
  }
};

export const REFERENCE_SUGGESTIONS = {
  ACCEPTABLE: 'ACCEPTABLE',
  ACCEPTABLE_WITH_CONDITION: 'ACCEPTABLE_WITH_CONDITION',
  NOT_ACCEPTABLE: 'NOT_ACCEPTABLE',
  PENDING: 'PENDING'
};

export const MOVEM_SUGGESTED_DECISIONS = [
  {
    value: 'PASSED',
    colourClass: 'success',
    body:
      'Movem suggests that this reference has passed. The applicant has met all the necessary criteria.',
    label: 'Acceptable'
  },
  {
    value: 'FAILED',
    colourClass: 'danger',
    body:
      'Movem suggests that this reference has failed. The applicant does not meet enough criteria to be sucessful.',
    label: 'Rejected'
  },
  {
    value: 'MANUAL_PASSED',
    colourClass: 'success',
    body:
      'This reference could not be completed automatically. A Movem operator has reviewed it manually, and suggests that the applicant has passed.',
    label: 'Acceptable'
  },
  {
    value: 'MANUAL_FAILED',
    colourClass: 'danger',
    body:
      'This reference could not be completed automatically. A Movem operator has reviewed it manually, and suggests that the applicant has failed.',
    label: 'Rejected'
  },
  {
    value: '',
    colourClass: 'warning',
    body:
      'Movem is still reviewing this reference. Please check back later to view the suggested decision.',
    label: 'Pending'
  }
];

export const REFERENCE_CONDITION_GUARANTOR = 'guarantor';

export const REFERENCE_CONDITIONS_OPTIONS = [
  {
    value: REFERENCE_CONDITION_GUARANTOR,
    label: 'Guarantor'
  },
  {
    value: 'prepayment_of_rent',
    label: 'Prepayment of rent'
  }
];

export const REFERENCE_DECISION_CONDITIONS = {
  guarantor: 'Guarantor',
  prepayment_of_rent: 'Prepayment of rent',
  total_affordability: 'Total affordability',
  other: 'Other'
};

export const ITEMS_PER_PAGE = 10;
export const DEFAULT_ITEMS_PER_PAGE = 50;

export const COMPANY_TYPE_LETTING_AGENT = 'letting_agent';
export const COMPANY_TYPE_INSTITUTIONAL_LANDLORD = 'institutional_landlord';
export const COMPANY_TYPE_PORTFOLIO_LANDLORD = 'portfolio_landlord';

export const COMPANY_TYPES_OPTIONS = [
  {
    value: COMPANY_TYPE_LETTING_AGENT,
    label: 'Letting agent'
  },
  {
    value: COMPANY_TYPE_INSTITUTIONAL_LANDLORD,
    label: 'Institutional Landlord'
  },
  {
    value: COMPANY_TYPE_PORTFOLIO_LANDLORD,
    label: 'Portfolio Landlord'
  }
];

export const ONBOARDING_STATUS_OPTIONS = {
  pending_company_verification: {
    title: 'Pending',
    text: `We'll review your application and email you with next steps`
  },
  onboarding: {
    title: 'Approved',
    text: `Your application has been accepted. Please login bellow.`
  }
};

export const TRANSACTION_REFUND_REASONS = {
  FRAUDULENT: 'fraudulent',
  REQUESTED_BY_CUSTOMER: 'requested_by_customer',
  DUPLICATE: 'duplicate'
};

export const FLATBOND_TYPES = [
  {
    value: FLATBOND_TYPE_CONVERSION,
    label: 'Conversion',
    colourClass: 'pending'
  },
  {
    value: FLATBOND_TYPE_NEW,
    label: 'New tenancy',
    colourClass: 'success'
  }
];

export const NON_EXISTING_FLATBOND_TYPE = 'Unknown flatbond type';

export const FLATBOND_TYPE_OPTIONS = [
  {
    value: 'new_tenancy',
    label: 'New tenancy'
  },
  {
    value: 'conversion',
    label: 'Conversion'
  }
];

export const FLATBOND_STATUS_OBJECTS = [
  {
    value: 'active',
    label: 'Active',
    colourClass: 'success'
  },
  {
    value: 'canceled',
    label: 'Cancelled',
    colourClass: 'danger'
  },
  {
    value: 'claim_disputed',
    label: 'Charges disputed',
    colourClass: 'success'
  },
  {
    value: 'claim_pending_payment',
    label: 'Charges pending payment',
    colourClass: 'warning'
  },
  {
    value: 'claim_started',
    label: 'Pending charges submission',
    colourClass: 'success'
  },
  {
    value: 'claim_sent',
    label: 'Charges sent',
    colourClass: 'pending'
  },
  {
    value: 'claim_in_review',
    label: 'Charges in review',
    colourClass: 'warning'
  },
  {
    value: 'closed',
    label: 'Closed',
    colourClass: 'danger'
  },
  {
    value: 'created',
    label: 'Awaiting verification',
    colourClass: 'success'
  },
  {
    value: 'expired',
    label: 'Service expired',
    colourClass: 'neutral'
  },
  {
    value: 'pending_agent_action',
    label: 'Pending agent action',
    colourClass: 'warning'
  },
  {
    value: 'pending_landlord_upsell',
    label: 'Pending landlord decision',
    colourClass: 'warning'
  },
  {
    value: 'pending_landlord_action',
    label: 'Pending landlord action',
    colourClass: 'warning'
  },
  {
    value: 'pending_tenant_action',
    label: 'Pending member action',
    colourClass: 'pending'
  },
  {
    value: 'pending_tenant_referencing',
    label: 'Pending member referencing',
    colourClass: 'pending'
  },
  {
    value: 'pending_verification',
    label: 'Pending verification',
    colourClass: 'pending'
  },
  {
    value: 'pending_receipt_of_deposit_funds',
    label: 'Pending receipt of deposit funds',
    colourClass: 'pending'
  },
  {
    value: 'pending_deposit_registration',
    label: 'Pending deposit registration',
    colourClass: 'pending'
  },
  {
    value: 'pending_deposit_signature',
    label: 'Pending prescribed information signature',
    colourClass: 'pending'
  }
];

export const NON_EXISTING_FLATBOND_STATUS = 'Unknown flatbond status';

export const CLAIMS_CASE_STATUSES = [
  {
    value: 'closed',
    label: 'Closed',
    colourClass: 'neutral'
  },
  {
    value: 'created',
    label: 'Created',
    colourClass: 'success'
  },
  {
    value: 'dispute_cool_down',
    label: 'Dispute in cool down',
    colourClass: 'pending'
  },
  {
    value: 'dispute_pending_decision',
    label: 'Dispute',
    colourClass: 'warning'
  },
  {
    value: 'dispute_pending_decision_upload',
    label: 'Dispute pending decision upload',
    colourClass: 'pending'
  },
  {
    value: 'in_debt_collection',
    label: 'In debt collection',
    colourClass: 'pending'
  },
  {
    value: 'in_review',
    label: 'In review',
    colourClass: 'warning'
  },
  {
    value: 'in_negotiation',
    label: 'In negotiation',
    colourClass: 'warning'
  },
  {
    value: 'landlord_offer_sent',
    label: 'Offer sent to landlord',
    colourClass: 'pending'
  },
  {
    value: 'pending_debt_collection',
    label: 'Pending debt collection',
    colourClass: 'warning'
  },
  {
    value: 'pending_landlord_offer',
    label: 'Pending landlord offer',
    colourClass: 'pending'
  },
  {
    value: 'pending_payment',
    label: 'Pending payment',
    colourClass: 'warning'
  },
  {
    value: 'pending_tenant_response',
    label: 'Pending tenant response',
    colourClass: 'pending'
  },
  {
    value: 'to_be_charged',
    label: 'To be charged',
    colourClass: 'pending'
  },
  {
    value: 'to_be_deemed_accepted',
    label: 'To be deemed accepted',
    colourClass: 'success'
  },
  {
    value: 'expired',
    label: 'Service expired',
    colourClass: 'neutral'
  }
];

export const NON_EXISTING_CLAIMS_CASE_STATUS = 'Unknown claims case status';

export const LANDLORD_OFFER_STATUS = {
  PENDING_APPROVAL: 'pending_approval',
  PENDING_APPOINTMENT: 'pending_appointment',
  PENDING_APPOINTMENT_APPROVAL: 'pending_appointment_approval',
  PENDING_ACTION: 'pending_action',
  PENDING_INTERNAL_SIGNATURE: 'pending_internal_signature',
  PENDING_LANDLORD_SIGNATURE: 'pending_landlord_signature',
  PENDING_OFFER_PAYMENT: 'pending_offer_payment',
  COMPLETED: 'completed',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  REJECTED: 'rejected'
};

export const NON_EXISTING_LANDLORD_OFFER_STATUS = 'Unknown landlord offer status';

export const LANDLORD_OFFER_STATUSES = [
  {
    value: LANDLORD_OFFER_STATUS.PENDING_APPROVAL,
    label: 'Pending approval',
    colourClass: 'warning'
  },
  {
    value: LANDLORD_OFFER_STATUS.PENDING_INTERNAL_SIGNATURE,
    label: 'Pending internal signature',
    colourClass: 'warning'
  },
  {
    value: LANDLORD_OFFER_STATUS.PENDING_LANDLORD_SIGNATURE,
    label: 'Pending landlord signature',
    colourClass: 'warning'
  },
  {
    value: LANDLORD_OFFER_STATUS.PENDING_OFFER_PAYMENT,
    label: 'Pending offer payment',
    colourClass: 'warning'
  },
  {
    value: LANDLORD_OFFER_STATUS.COMPLETED,
    label: 'Completed',
    colourClass: 'success'
  },
  {
    value: LANDLORD_OFFER_STATUS.EXPIRED,
    label: 'Expired',
    colourClass: 'danger'
  },
  {
    value: LANDLORD_OFFER_STATUS.CANCELLED,
    label: 'Cancelled',
    colourClass: 'danger'
  }
];

export const LANDLORD_OFFER_TYPE = {
  ASSIGNMENT: 'assignment',
  APPOINTMENT: 'appointment',
  APPOINTMENT_AND_ASSIGNMENT: 'appointment_and_assignment'
};

export const HELLOSIGN_SIGNATURE_REQUEST_SIGNED_EVENT = 'signature_request_signed';

export const ROLES = {
  USER: 'u',
  ADMIN: 'a'
};

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error'
};

export const EVENT_NAMESPACE = {
  FLATBOND: 'flatbond',
  CHARGE: 'claim',
  CHARGE_CASE: 'claims_case',
  CHARGE_PAYMENT_PLAN: 'charge_payment_plan'
};

export const TRANSFER_FUNDS_STATUS_OPTIONS = [
  {
    value: 'pending',
    label: 'Pending transfer',
    colourClass: 'danger'
  },
  {
    value: 'sent',
    label: 'Marked as transferred',
    colourClass: 'warning'
  },
  {
    value: 'received',
    label: 'MyDeposits confirmed transfer',
    colourClass: 'success'
  }
];

export const UK_POSTCODE_REGEX = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/;
export const UNSPACED_UK_POSTCODE_REGEX = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) ?[0-9][A-Za-z]{2})$/;

export const SUCCESS_TEXT = 'text-flatfair-success';
export const DANGER_TEXT = 'text-flatfair-danger';

export const NOT_AVAILABLE = 'Not available';

const DANGER = 'danger';
const SUCCESS = 'success';

export class STATUS {
  static get SUCCESS() {
    return SUCCESS;
  }

  static get DANGER() {
    return DANGER;
  }
}

export const googleMapsBaseUrl =
  'https://www.google.com/maps/embed/v1/place?key=AIzaSyCplKe7HA9FNKhvzvtb1rbj6XL_7I5b4UA&q=';

export const PAYMENT_NAME_CHECK_WAIT_TIME = 3000;

export const INTERCOM_CHAT_SYSTEM = 'intercom';

export const FORM_CHECK_ERRORS_BELOW = 'Please check the errors below';

export const FLATBOND_MANAGER_ACTION_SINGLE_UPDATE_ID = 0;
export const FLATBOND_MANAGER_ACTION_MULTIPLE_UPDATE_ID = 1;
export const FLATBOND_MANAGER_ACTION_MULTIPLE_UPDATE_BY_BRANCH_ID = 2;
export const FLATBOND_MANAGER_ACTION_MULTIPLE_UPDATE_BY_BRANCH_AND_USER_ID = 3;
export const FLATBOND_MANAGER_ACTIONS = [
  {
    labelKey: 'onlyFlatbond',
    value: FLATBOND_MANAGER_ACTION_SINGLE_UPDATE_ID
  },
  {
    labelKey: 'allFlatbondsFromCurrentAdmin',
    value: FLATBOND_MANAGER_ACTION_MULTIPLE_UPDATE_ID
  },
  {
    labelKey: 'allFlatbondsFromBranch',
    value: FLATBOND_MANAGER_ACTION_MULTIPLE_UPDATE_BY_BRANCH_ID
  },
  {
    labelKey: 'allFlatbondsFromBranchAndUser',
    value: FLATBOND_MANAGER_ACTION_MULTIPLE_UPDATE_BY_BRANCH_AND_USER_ID
  }
];

export const FIND_ADDRESS_API_KEY = '6r7bM5df_EaajpRw2K9EPA10627';

export const UK_DEPOSIT_CAP_BREAK_POINT = 50000;
export const UK_DEPOSIT_CAP_BREAK_POINT_IN_CENTS = 5000000;
export const UK_DEPOSIT_CAP_LOW_WEEKS_LIMIT = 5;
export const UK_DEPOSIT_CAP_HIGH_WEEKS_LIMIT = 6;

//Scotland deposit cap is 2 months rent; calculation from week to 2 months
export const SCOTLAND_DEPOSIT_CAP_WEEKS_LIMIT = (52 / 12) * 2;

export const TRAINING_LAST_QUESTION = 'last';

export const HTTP_STATUS_CODES = {
  NOT_FOUND: 404
};

export const SUPPORT_EMAIL = 'mailto:support@flatfair.co.uk';

export const HELP_ARTICLES_URL = 'https://help.flatfair.co.uk/hc/en-gb/';
export const HELP_ARTICLES_EOT_URL =
  'https://help.flatfair.co.uk/hc/en-gb/sections/360004471140-End-of-Tenancy-';

export const FLATFAIR_TERMS_OF_USE_URL = 'https://flatfair.co.uk/terms-conditions/';
export const FLATFAIR_PRIVACY_POLICY_URL = 'https://flatfair.co.uk/privacy-policy/';
export const FLATFAIR_PORTAL_TERMS_URL = 'https://flatfair.co.uk/portal-terms/';

export const FLATFAIR_DEPOSITS_URL = 'https://flatfair.co.uk/deposits/';
export const NEW_RENT_ARREARS_URL = 'https://help.flatfair.co.uk/hc/en-gb/articles/7570682246044';

export const EARLY_TERMINATION_FEES_INFORMATION =
  'https://www.tenancydepositscheme.com/wp-content/uploads/2020/06/Early_Termination_Fees_What_does_it_mean_for_you_-_Joint_guidance.pdf';

export const LOCATION_DISPLAY_TEST_ID = 'LOCATION_DISPLAY';

export const CHARGES_CREATION_VIDEO_URL =
  'https://www.youtube.com/watch?v=yjo_tUHu648&feature=youtu.be';

export const TRUSTPILOT_FLATFAIR_REVIEWS_URL = 'https://uk.trustpilot.com/review/flatfair.co.uk';

export const EXPIRING_DAY_COUNT = 30;

export const MINIMUM_PASSWORD_LENGTH = 8;

export const PROTECTION_CAP_MULTIPLIER = {
  WEEKLY: 'weekly_rent',
  FIXED: 'membership_fee'
};

export const ACCOUNT_LOGIN_ATTEMPTS_LIMIT = 15;

export const ADD_FLATBOND_DOCUMENT_ENDPOINT = '/flatbonds/addFlatbondDocument/';

export const NO_DEPOSIT_NEW_TENANCY = 'new_tenancy';
export const NO_DEPOSIT_UNLOCKING_DEPOSIT = 'unlocking_deposit';
